import styled  from "styled-components";

export const IroniaMessageStyle = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  .changes_overlay {
    width: 60vw;
    @media (max-width: 450px) {
      width: 90vw;
    }
    height: auto;
    margin:auto;
    opacity: 1;
    background-color: #e2e2e2f7;
    border-radius:20px;
    margin-top: 200px;
    box-shadow: 5px 5px 5px #999999f5;
  }
  .changes {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 4vw;
  }
  img {
    margin: 2rem auto;
    width: 5rem;
    height: 5rem;
    @media (max-width: 450px) {
      width: 4rem;
      height: 4rem;
    }
  }
  .divTextoPopup{
    text-align:center;
    margin-bottom: 30px;
  }
  .bold{
    font-weight:bold;
  }
  .semibold{
    font-weight:500;
  }
  .closeText{
    width:100%;
    padding:0 15px;
    text-align:right;
    cursor:pointer;
  }
`;
export default IroniaMessageStyle;
