import styled from "styled-components";

const GraphStyle = styled.div`
  .margin30 {
    margin-top: 1.87rem !important;
  }

  .marginRight {
    margin-right: 1rem;
  }

  .graficoContainer {
    padding: 1.25rem;
  }

  .graph-main-container {
    height: 15%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 1rem;
    flex-wrap: wrap;
    @media (max-width: 1260px) {
      flex-direction: column;
      justify-content: normal;
      align-items: flex-start;
      flex-wrap: nowrap;
      gap: 0;
    }
    @media (max-width: 1040px) {
      flex-wrap: wrap;
      display: flex;
      align-items: flex-end;
      flex-direction: row;
      justify-content: space-between;
    }
    @media (max-width: 991px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    @media (max-width: 720px) {
      flex-direction: column;
      justify-content: normal;
      align-items: flex-start;
    }
    @media (max-width: 600px) {
      height: 20%;
    }
    @media (max-width: 340px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  .graph-data {
    display: flex;
    flex-direction: row;
  }
  .float {
    float: left;
  }

  .floatRight {
    float: right;
  }
  .graph-button-container {
    display: flex;
    position: relative;
    max-height: 3rem;
  }
  .valorTexto {
    width: max-content;
    height: 1rem;
    margin: 0 0.68rem 0 0.125rem;
    font-weight: 500;
    color: ${(props) => props.theme.colors.fontColor.titleFontColor};
  }
  .valorNumero {
    display: flex;
    align-items: baseline;
    height: 2.5rem;
    margin: 0;
    font-weight: bold;
    color: ${(props) => props.theme.colors.fontColor.titleFontColor};
  }
  .value-container {
    @media (max-width: 340px) {
      display: flex;
      flex-direction: column;
    }
  }
  .rentabilidadNumero {
    color: ${(props) => props.theme.colors.background.primaryCardBackground};
    font-weight: bold;
    margin: 0.625rem 0 0 0;
    display: flex;
    align-items: baseline;
    padding-top: 0.187rem;
  }
  .negative-value {
    color: ${(props) => props.theme.colors.fontColor.negativeGraphValueColor};
    font-weight: bold;
    margin: 0.625rem 0 0 0;
    display: flex;
    align-items: baseline;
    padding-top: 0.187rem;
  }

  .rentabilidadPorcentaje {
    margin-left: 0;
    @media (max-width: 380px) {
      margin-left: 0;
    }
  }

  .text-style {
    font-weight: normal;
  }
  .graficoDatosContainer {
    margin-top: 3.12rem;
    height: 75%;
    min-height: 18.75rem;
    position: relative;
    @media (max-width: 600px) {
      height: 70%;
      max-height: 18.75rem;
    }
    .alert-container {
      display: flex;
      justify-content: center;
      width: 100%;
      position: absolute;
      top: 6rem;
      .alert-message {
        color: ${(props) => props.theme.colors.fontColor.titleFontColor};
        background-color: ${(props) =>
          props.theme.colors.button.background.navabarButtonBackground};
        padding: 1rem;
        border-radius: 1rem;
        font-weight: 500;
        max-width: 24rem;
        text-align: center;
        z-index: 1;
      }
    }

    .recharts-responsive-container {
      height: 18.75rem !important;
      .alert-main-container {
        height: 100%;
        width: 100%;
      }
      .alert-message {
        color: ${(props) => props.theme.colors.fontColor.titleFontColor};
        background-color: ${(props) =>
          props.theme.colors.button.background.navabarButtonBackground};
        padding: 1rem;
        border-radius: 1rem;
        font-weight: 500;
        max-width: 24rem;
        text-align: center;
        z-index: 1;
      }
    }
  }
  .value-container {
    display: flex;
    @media (max-width: 380px) {
      display: block;
    }
    .value-inner-container-renta {
      display: flex;
      flex-direction: column;
      justify-content: end;
      align-items: flex-start;
    }
    .value-inner-container {
      display: flex;
      flex-direction: column;
      justify-content: end;
      align-items: flex-start;
      height: fit-content;
    }
  }

  .graficoBoton {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0.3125rem 0 0.3125rem;
    padding: 0.31rem 0.8rem;
    border-radius: 1.125rem;
    border-width: 0rem;
    background-color: transparent;
    text-align: center;
    color: ${(props) => props.theme.colors.fontColor.titleFontColor};
    @media (max-width: 360px) {
      margin: 0rem;
    }
  }
  .icon-toggle {
    display: flex;
    align-items: center;
    padding: 0.31rem 0rem;
    background-color: transparent;

    @media (max-width: 360px) {
      margin: 0rem;
    }
    .MuiSwitch-root {
      padding: 0.56rem;
      .MuiSwitch-switchBase {
        padding: 0.56rem 0.5rem;
      }
      .MuiSwitch-track {
        border-radius: 0.93rem;
        background-color: ${(props) =>
          props.theme.colors.button.background.navabarButtonBackground};
      }
    }
  }
  .investment-value-toggle {
    color: ${(props) => props.theme.colors.background.teritiaryMapLabelColor};
    .MuiSwitch-root {
      .MuiSwitch-switchBase {
        color: ${(props) =>
          props.theme.colors.background.teritiaryMapLabelColor};
      }
    }
  }
  .contribution-value-toggle {
    color: ${(props) => props.theme.colors.fontColor.titleFontColor};
    .MuiSwitch-root {
      .MuiSwitch-switchBase {
        color: ${(props) => props.theme.colors.fontColor.titleFontColor};
      }
    }
  }
  .arrow-icon-toggle {
    color: ${(props) => props.theme.colors.background.secondaryLoadingColor};
    .MuiSwitch-root {
      .MuiSwitch-switchBase {
        color: ${(props) =>
          props.theme.colors.background.secondaryLoadingColor};
      }
    }
  }

  .date-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    img {
      width: 1.2rem;
    }
  }
  .calendar-button {
    @media (max-width: 620px) {
      display: none;
    }
  }
  .graficoBotonSelected {
    font-weight: bold;
    color: ${(props) => props.theme.colors.background.primaryCardBackground};
    background-color: ${(props) =>
      props.theme.colors.background.menuButtonBackground};
  }
  .labelContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    .inner-label-container {
      display: flex;
      gap: 0.5rem;
      @media (max-width: 500px) {
        flex-direction: column;
      }
    }
    .circuloPequeño {
      border-radius: 50%;
      width: 0.5rem;
      height: 0.5rem;
      margin-right: 0.312rem;
    }
    .verde {
      background-color: ${(props) =>
        props.theme.colors.background.teritiaryMapLabelColor};
    }
    .azul {
      background-color: ${(props) =>
        props.theme.colors.fontColor.titleFontColor};
    }
    .enLinea {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .graphLabel {
      display: flex;
      align-items: center;
    }
    .alert-message {
      color: ${(props) => props.theme.colors.fontColor.titleFontColor};
      background-color: ${(props) =>
        props.theme.colors.button.background.navabarButtonBackground};
      padding: 1rem;
      border-radius: 1rem;
      font-weight: 500;
      max-width: 24rem;
      text-align: center;
      z-index: 1;
    }
  }
`;

export default GraphStyle;
