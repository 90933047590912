import React, { createContext, useState } from "react";
import Toast from "./toast/toast";
// import { Spinner } from "./spinner/spinner";
import { LoaderGif } from "./spinner/spinner";

const AlertContext = createContext(null);

export const AlertProvider = ({ children }) => {
  const [alertData, setAlertData] = useState({
    title: "",
    message: "",
    type: "",
    open: false,
    alertType: "",
    buttonleft: "",
    buttonright: "",
    callback: null,
    index: null,
  });

  const open = (data) => {
    setAlertData({ ...alertData, ...data });
  };
  return (
    <AlertContext.Provider value={{ open }}>
      {alertData.open && alertData.alertType === "toast" && (
        <Toast
          title={alertData.title}
          type={alertData.type}
          open={alertData.open}
        />
      )}

      {alertData.open && alertData.alertType === "spinner" && <LoaderGif />}
      {children}
    </AlertContext.Provider>
  );
};

export default AlertContext;
