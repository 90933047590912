import React, { useEffect, useState } from "react";
// import Button from "../../../components/button/button";
import defaultTheme from "../../../../theme/theme";
import ImpactControlPanelStyle from "./impactControlPanelStyle.js";
import { ThemeProvider } from "styled-components";
import Text from "../../../../theme/font";
import List from "../../../../components/list/list";
import ListItem from "../../../../components/listItem/listItem";

const evolutionStaticData = [
  {
    title: "Valor",
    valueSymbol: "€",
    apiKey: "valorTotal",
  },
  {
    title: "Rentabilidad",
    valueSymbol: "%",
    apiKey: "rentabilidadPor",
  },
  {
    title: "Rentabilidad €",
    valueSymbol: "€",
    apiKey: "rentabilidad",
  },
];

const ImpactControlPanel = React.memo((data) => {
  const theme = defaultTheme;
  const [fundData, setFundData] = useState([]);


  useEffect(() => {
    if (data.data) {
      const combinedData = [];
      if (evolutionStaticData) {
        // eslint-disable-next-line array-callback-return
        evolutionStaticData.map((items) => {
          const dataItems = {
            primary: items.title,
            secondary: data.data[items.apiKey],
            subData: items.valueSymbol,
          };
          combinedData.push(dataItems);
        });
        setFundData([...fundData, ...combinedData]);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.data]);

  return (
    <ThemeProvider theme={theme}>
      <ImpactControlPanelStyle>
        <div className="impact-control-panel-container box-container">
          <Text variant="titleFont" className="impact-control-panel-title">
            Panel de control Eco
          </Text>

          <Text
            variant="normal"
            type="primary"
            className="impact-control-panel-description"
          >
            Esta sección es un resumen de como va tu inversión con
            <span> Micappital Eco.</span>
          </Text>
          <List data={fundData} limit={5}>
            <ListItem variant="ecoPanel" />
          </List>

          <div className="fund-evolution-details">
            {/* <div className="eco-data">
              <img alt="x" src={ImageConstants.eco} />
              <div className="eco-value">50</div>
            </div>
            <div className="eco-data">
              <img alt="x" src={ImageConstants.eco} />
              <div className="eco-value">3.000L</div>
            </div>
            <div className="eco-data">
              <img alt="x" src={ImageConstants.eco} />
              <div className="eco-value">600gr</div>
            </div> */}
          </div>
          {/*
          <div className="button-container">
            <Button value="Ver impacto" className="see-impact-button" />
          </div>*/}
        </div>
      </ImpactControlPanelStyle>
    </ThemeProvider>
  );
});

export default ImpactControlPanel;
