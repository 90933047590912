import { ThemeProvider } from "styled-components";
import { useNavigate } from "react-router-dom";
import defaultTheme from "../../../../theme/theme";
import ImageConstants from "../../../../constants/imageConstants/image_constants";
import AutopilotStepStyle from "./autopilotStepStyle";
import Text from "../../../../theme/font";

const AutopilotStep = ({ title, subtitle, completed, buttonText, path, hidden=false, disabled=false}) => {
    const theme = defaultTheme;
    const navigate=useNavigate();
    if(buttonText==null){
        buttonText="Completar";
    }

    const handleClick = () => {
        if (isExternalURL(path)) {
          // Si es una URL externa
          window.open(path);
        } else {
          navigate(path);
        }
      };
    
      const isExternalURL = (url) => {
        // Verifica si la URL comienza con "http://" o "https://"
        return url.startsWith('http://') || url.startsWith('https://');
      };
    
    if(hidden){
        return null;
    }
    else{
        if(completed){
            return (
                <ThemeProvider theme={theme}>
                    <AutopilotStepStyle>
                        <div className="autopilot-step-row">
                            <div className="autopilot-step-img">
                                <img src={ImageConstants.TicRound}></img>
                            </div>
                            <div className="autopilot-step-text">
                                <Text variant="labelFont" className="bold">
                                    {title}
                                </Text>
                            </div>
                            <div className="autopilot-step-button">
                            
                            </div>
                        </div>
                    </AutopilotStepStyle>
                </ThemeProvider>
            );
        }
        else{
            return(
                <ThemeProvider theme={theme}>
                    <AutopilotStepStyle>
                        <div className="autopilot-step-row">
                            <div className="autopilot-step-img">
                                <img src={ImageConstants.GrayRound}></img>
                            </div>
                            <div className="autopilot-step-text">
                                <Text variant="labelFont" className="bold">
                                    {title}
                                </Text>
                                <Text variant="normal" type="primary">
                                    {subtitle}
                                </Text>
                            </div>
                        </div>
                        <div className="autopilot-step-button">
                            <button disabled={disabled} onClick={handleClick}>{buttonText}</button>
                        </div>
                    </AutopilotStepStyle>
                </ThemeProvider>
            )
        }
    }
};

export default AutopilotStep;
