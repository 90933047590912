import React, { useState, useEffect, useContext} from 'react';
import Select from 'react-select'
import AltaStyle from './altaStyle';
import { getLoginData, patchNR} from './procesoAltaServices';
import { useNavigate } from "react-router-dom";
import { Form,Row, Col } from "react-bootstrap";
import { ContainerContext } from '../../components/navbarContainer/containerProvider';
import { AuthTokenService } from '../../services';
import { patchIroniaData, getIroniaCities, getIroniaZipCodes } from '../investment/autopilot/autopilotServices';
import { viaTypes, regions } from './autopilotConstants';


export default function AutopilotAdditionalData(){
    const [error, setError]=useState(false);
    const [intento, setIntento]=useState(false);
    const [menuHidden] = useState(true);
    const navigate=useNavigate();
    const { changeMenuStatus, changeNavBarStatus, changeAlertStatus } =
    useContext(ContainerContext);
    const [inputValues, setInputValues] = useState({
        roadType: 'CL',
        streetName: '',
        streetNumber: '',
        streetFloor: 0,
        streetDoor: 0,
        zipCode: '',
        city: '',
        state: '',
        country: 'ES'
        // Agrega los nombres de los campos adicionales
    });
    const [cities, setCities]= useState([]);
    const [selectedCity, setSelectedCity]= useState(null);
    const [citiesDisabled, setCitiesDisabled]=useState(true);
    const [zipCodes, setZipCodes]= useState([]);
    const [selectedZip, setSelectedZip]= useState(null);
    const [ZipDisabled, setZipDisabled]=useState(true);

    
        
    const nombre = AuthTokenService.getNombre();

    useEffect(() => {
        window.scrollTo(0, 0);
        changeMenuStatus(menuHidden);
        changeNavBarStatus(true);
        changeAlertStatus(false);
        return () => {
          changeMenuStatus(!menuHidden);
          changeNavBarStatus(false);
          changeAlertStatus(true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [changeMenuStatus, changeNavBarStatus]);

    const handleInputChange = (event) => {
        setInputValues({
            ...inputValues,
            [event.target.name]: event.target.value,
        });
    };

    const handleRegionChange= (region) => {
        setCitiesDisabled(true);
        setSelectedCity(null);
        setZipDisabled(true);
        setSelectedZip(null);
        getIroniaCities(region.value)
            .then((result)=>{
                if (result.data.status==200){
                    const citiesTemp = result.data.data.map(item => ({
                        value: item.value,
                        label: item.text
                      }));
                    setCities(citiesTemp);
                    setCitiesDisabled(false);

                }
                else{
                    setError(true);
                }
            });
        setInputValues({
            ...inputValues,
            ["state"]: region.label,
        });
        
    };

    const handleCityChange= (city) => {
        setSelectedCity(city);
        setZipDisabled(true);
        setSelectedZip(null);
        getIroniaZipCodes(city.value)
            .then((result)=>{
                if (result.data.status==200){
                    const ZipCodesTemp = result.data.data.map(item => ({
                        value: item.value,
                        label: item.text
                      }));

                    setZipCodes(ZipCodesTemp);
                    setZipDisabled(false);
                    if(ZipCodesTemp.length==1){
                        setSelectedZip(ZipCodesTemp[0]);
                        setInputValues({
                            ...inputValues,
                            ["zipCode"]: ZipCodesTemp[0].label,
                            ["city"]: city.label,
                        });
                    }
                    else{
                        setInputValues({
                            ...inputValues,
                            ["city"]: city.label,
                        });
                    }

                }
                else{
                    setError(true);
                }
            });
        
        
    };

    const handleZipChange= (zip) => {
        setSelectedZip(zip);
        setInputValues({
            ...inputValues,
            ["zipCode"]: zip.label,
        });
    };



    const handleGuardar = (datos) => {//funcion que llama al patch con los datos de formularios
        
        var datos=inputValues;
        datos.idCliente=AuthTokenService.getUserId();

        
        patchIroniaData(datos)
            .then((result)=>{
                if (result.data.status==200){
                    navigate("/datosAutopilot2");
                }
                else{
                    setError(true);
                }
            });
        
      };


    return (
        <AltaStyle >
            <div className="bodyProcesoAlta">
                <div className="navbar navbar-expand-md navbar-light" style={{paddingLeft:'2.5rem'}}>
                    
                </div>
                <div className="process-popup container">
                    <div className="main-step-container">

                        <a className="navbar-brand">
                            <img height="40" src="https://micappital.com/assets/img/logo-micappital.png" alt="Micappital"></img>
                        </a>
                        
                    </div>
                    <div className="process-popup-inner-container">
                        <h3 className="process-heading mt-5 mb-4">
                            {nombre}, necesitamos un par de datos más para crearte tu cuenta Autopilot
                        </h3>

                        <Form className="form-insurance-data">
                            <Row>
                                <Select className="mb-3 col-sm-3 col-12" defaultValue={{value: "CL",label: "Calle"}} options={viaTypes} />
                                <Form.Group as={Col} xs={12} sm={9} className="mb-3" controlId="direccion">
                                    <Form.Control className={(intento==true && inputValues.streetName == '') && 'campo-incompleto'} placeholder="Dirección" name="streetName" type="text" onChange={handleInputChange}/>
                                </Form.Group>
                                <Form.Group as={Col} xs={12} sm={4} className="mb-3" controlId="numero">
                                    <Form.Control className={(intento==true && inputValues.streetNumber == '') && 'campo-incompleto'} placeholder="Número" name="streetNumber" type="text" onChange={handleInputChange}/>
                                </Form.Group>
                                <Form.Group as={Col} xs={12} sm={4} className="mb-3" controlId="piso">
                                    <Form.Control placeholder="Piso" name="streetFloor" type="text" onChange={handleInputChange}/>
                                </Form.Group>
                                <Form.Group as={Col} xs={12} sm={4} className="mb-3" controlId="numero">
                                    <Form.Control placeholder="Puerta" name="streetDoor" type="text" onChange={handleInputChange}/>
                                </Form.Group>

                            </Row>
                            <Row>
                                <Select className="mb-3 col-sm-3 col-12" placeholder="Provincia"  options={regions} onChange={handleRegionChange}/>
                                <Select className="mb-3 col-sm-6 col-12" value={selectedCity} placeholder="Ciudad"  options={cities} isDisabled={citiesDisabled} onChange={handleCityChange}/>
                                <Select className="mb-3 col-sm-3 col-12" value={selectedZip} placeholder="C. postal"  options={zipCodes} isDisabled={ZipDisabled} onChange={handleZipChange}/>
                                
                            </Row>
                            
                                
                            {error && <p>HA HABIDO UN ERROR</p>}
                            
                            
                            
                        </Form>

                        <div className="start-button-container">
                            <button className="btn" onClick={() =>navigate("/autopilot")}>
                                Atrás
                            </button>
                            <button className="botonComienza btn" onClick={handleGuardar} disabled={selectedZip === null}>
                                Continuar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </AltaStyle>
    );
}