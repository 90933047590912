import styled from "styled-components";

const MakeInvestmentStyle = styled.div`
  border-radius: 1rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  position: fixed;
  background-color: ${(props) =>
    props.theme.colors.background.defaultBackground};
  width: 29%;
  @media (max-width: 1200px) {
    width: 28%;
  }
  @media (max-width: 1040px) {
    position: relative;
    width: 100%;
    max-width: 28.12rem;
  }
  .make-investment-heading {
    font-weight: bold;
    color: ${(props) => props.theme.colors.fontColor.titleFontColor};
    @media (max-width: 540px) {
      text-align: center;
    }
  }
  .make-investment-sub-heading {
    font-weight: bold;
    color: ${(props) => props.theme.colors.background.secondaryLoadingColor};
    @media (max-width: 540px) {
      text-align: center;
    }
  }
  .make-investment-description {
    color: ${(props) => props.theme.colors.fontColor.descriptionFontColor};
    @media (max-width: 540px) {
      text-align: center;
    }
  }
  .btn-container {
    width: 100%;
    .invest-button {
      width: 100%;
    }
  }
`;
export default MakeInvestmentStyle;
