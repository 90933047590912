import styled from "styled-components";

const EcoFundRecommendationStyle = styled.div`
  width: 100%;
  background-color: ${(props) =>
    props.theme.colors.background.defaultBackground};
  padding: 2rem 1rem;
  border-radius: 1rem;
  @media (max-width: 1040px) {
    width: 100%;
  }
  .fund-recommendation_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .add-investment-icon {
      .add-icon {
        height: 7.2rem;
        cursor: pointer;
        @media (max-width: 450px) {
          height: 5.3rem;
        }
      }
    }
    .primary-description {
      font-weight: bold;
      color: ${(props) => props.theme.colors.fontColor.descriptionFontColor};
      padding: 1rem 0rem;
      text-align: center;
    }
    .secondary-description {
      color: ${(props) => props.theme.colors.fontColor.descriptionFontColor};
      font-weight: 500;
      text-align: center;
    }
    .recommendation-options {
      gap: 1rem;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
      padding-top: 4rem;
      .change-recommendation-container {
        padding: 0.5rem;
        background-color: ${(props) =>
          props.theme.colors.background.defaultCardBackground};
        flex: 0 1 31%;
        min-width: 12.5rem;
        min-height: 12.8125rem;
        display: flex;
        flex-direction: column;
        border-radius: 1rem;
        align-items: center;
        .title-container {
          width: 100%;
          padding: 0 0.5rem;
          .card-title {
            font-weight: bold;
            width: 100%;
            padding-left: 0.5rem;
            border-bottom: solid 0.0625rem
              ${(props) => props.theme.colors.background.defaultBackground};
            color: ${(props) => props.theme.colors.fontColor.titleFontColor};
          }
        }
        .amount-container {
          width: 100%;
          padding-left: 1rem;
          .amount-title {
            color: ${(props) => props.theme.colors.fontColor.titleFontColor};
            font-weight: 500;
          }
          .amount {
            color: ${(props) => props.theme.colors.fontColor.titleFontColor};
            font-weight: bold;
            word-break: break-all;
          }
        }
        .amount-details {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 1.5rem;
          .leaves-details {
            background-color: ${(props) =>
              props.theme.colors.background.cardBackground};

            padding: 0.5rem 0.5rem 0.5rem 1rem;
            border-radius: 1rem;
            position: relative;
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;
            .up-arrow {
              width: 1rem;
              height: 0;
              border-left: 0.5rem solid transparent;
              border-right: 0.5rem solid transparent;
              border-top: 0.6875rem solid
                ${(props) => props.theme.colors.fontColor.titleFontColor};
              position: absolute;
              bottom: -0.7rem;
            }
            .leaves-primary-details {
              color: ${(props) => props.theme.colors.fontColor.titleFontColor};
              font-weight: 500;
              width: 100%;
              word-break: break-word;
            }
            .leaves-secondary-details {
              color: ${(props) => props.theme.colors.fontColor.titleFontColor};
              font-weight: 500;
              width: 100%;
              word-break: break-word;
            }
            .copy-section {
              display: flex;
              width: 100%;
              justify-content: space-between;
              align-items: center;
              .leaves-ISIN {
                color: ${(props) =>
                  props.theme.colors.fontColor.titleFontColor};
                font-style: italic;
                .ISIN-number {
                  font-weight: bold;
                }
              }
              .copy-icon {
                cursor: pointer;
                display: flex;
                img {
                  height: 1rem;
                }
              }
            }
          }
          .enter-details {
            background-color: ${(props) =>
              props.theme.colors.background.cardBackground};

            padding: 0.5rem;
            border-radius: 1rem;
            display: flex;
            flex-direction: column;
            width: 100%;
            .enter-details-title {
              color: ${(props) =>
                props.theme.colors.fontColor.optimizationCardTitle};
              font-weight: bold;
            }
            .enter-primary-details {
              color: ${(props) => props.theme.colors.fontColor.titleFontColor};
              font-weight: 500;
            }
            .enter-secondary-details {
              color: ${(props) => props.theme.colors.fontColor.titleFontColor};
              font-weight: 500;
            }
            .copy-section {
              display: flex;
              width: 100%;
              justify-content: space-between;
              align-items: center;
              .enter-ISIN {
                color: ${(props) =>
                  props.theme.colors.fontColor.titleFontColor};
                font-style: italic;
              }
              .copy-icon {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
`;
export default EcoFundRecommendationStyle;
