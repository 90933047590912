import InvestmentDetailsPopUpStyle from "./investmentDetailsPopUpStyle";
import Text from "../../../../theme/font";
import { useEffect, useState, useContext } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { Doughnut } from "react-chartjs-2";
import { AlertContext } from "../../../common/alertContainer";
import { AuthTokenService } from "../../../../services";
import { cargarDiversificacion } from "../proposalServices";
import { getMapData } from "../proposalServices";
import ScrollBar from "../../../../components/scrollbar/scrollbar";

const InvestmentDetailsPopUp = ({ data }) => {
  const fundDetails = data?.detallesFondos;
  const [lessThan3, setLessThan3] = useState("");
  const [between3And10, setBetween3And10] = useState("");
  const [moreThan10, setMoreThan10] = useState("");
  const [fixedRent, setFixedRent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [variableIncome, setVariableIncom] = useState(null);
  const [liquidity, setLiquidity] = useState(null);
  const { open } = useContext(AlertContext);

  useEffect(() => {
    open({
      alertType: "spinner",
      open: true,
    });
    const userId = AuthTokenService.getUserId();
    cargarDiversificacion(userId)
      .then((result) => {
        open({
          alertType: "spinner",
          open: false,
        });
        if (result.statusText === "OK" && result.status === 200) {
          const diversificacion = result.data.data;
          const rentaFija = diversificacion.rentaFija;
          const rentaVariable = diversificacion.rentaVariable;
          const liquidez = diversificacion.liquidez;

          setFixedRent(rentaFija);
          setVariableIncom(rentaVariable);
          setLiquidity(liquidez);
          setIsLoading(false);
        }
      })
      .catch(() => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });
    getMapData(userId)
      .then((result) => {
        open({
          alertType: "spinner",
          open: false,
        });
        const tempData = JSON.parse(result.data.data);
        setLessThan3(tempData[`Menos del 3%`]);
        setBetween3And10(tempData[`Entre el 3% y el 10%`]);
        setMoreThan10(tempData[`Más del 10%`]);
      })
      .catch(() => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mapHandler = () => {
    if (fixedRent == null || isLoading) {
      return null;
    } else {
      const geoUrl = require("./worldMap.json");
      const data = {
        datasets: [
          {
            data: [variableIncome, fixedRent, liquidity],
            backgroundColor: ["#30b2b8", "#1c88c8", "#a8d115"],
          },
        ],
      };

      const options = {
        cutout: "75%",
        borderWidth: 0,
        responsive: true,

        plugins: {
          legend: {
            position: "bottom",
            labels: {
              usePointStyle: true,
              pointStyle: "circle",
            },
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                let label = context.formattedValue;
                if (label) {
                  label += "%";
                }
                return label;
              },
            },
          },
        },
      };

      const checkHandler = (lessThan3, between3And10, moreThan10) => {
        if (lessThan3) {
          return "#a8d115";
        } else if (between3And10) {
          return "#1c88c8";
        } else if (moreThan10) {
          return "#30b2b8";
        } else {
          return "#ccc";
        }
      };

      return (
        <div className="row rowStyle">
          <div className="col-lg-6 col-12 chart-container">
            <div className="float titlechart asapText">
              <Text variant="titleFont">¿Cómo lo invertirás?</Text>
            </div>
            <Doughnut className="doughnut" data={data} options={options} />
            <div className="map-labels">
              <div className="label-div">
                <div className="primary-label-color label-color"></div>
                <Text variant="smallFont">Liquidez</Text>
              </div>
              <div className="label-div">
                <div className="secondary-label-color label-color"></div>
                <Text variant="smallFont">Renta Fija</Text>
              </div>
              <div className="label-div">
                <div className="teritiary-label-color label-color"></div>
                <Text variant="smallFont">Renta Variable</Text>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-12 mapContainer">
            <div className="float titlechart asapText">
              <Text variant="titleFont">¿Dónde lo invertirás?</Text>
            </div>
            <ComposableMap>
              <Geographies geography={geoUrl}>
                {({ geographies }) =>
                  geographies.map((geo) => {
                    const islessThan3 =
                      lessThan3.indexOf(geo.properties.name) !== -1;
                    const isbetween3And10 =
                      between3And10.indexOf(geo.properties.name) !== -1;
                    const ismoreThan10 =
                      moreThan10.indexOf(geo.properties.name) !== -1;
                    return (
                      <Geography
                        key={geo.rsmKey}
                        geography={geo}
                        fill={checkHandler(
                          islessThan3,
                          isbetween3And10,
                          ismoreThan10
                        )}
                      />
                    );
                  })
                }
              </Geographies>
            </ComposableMap>

            <div className="map-labels">
              <div className="label-div">
                <div className="primary-label-color label-color"></div>
                <Text variant="smallFont">Menos del 3%</Text>
              </div>
              <div className="label-div">
                <div className="secondary-label-color label-color"></div>
                <Text variant="smallFont">Entre el 3% y el 10%</Text>
              </div>
              <div className="label-div">
                <div className="teritiary-label-color label-color"></div>
                <Text variant="smallFont">Más del 10%</Text>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <InvestmentDetailsPopUpStyle>
      <Text variant="titleFont" className="investment-details-popup-heading">
        Información adicional de tu cartera
      </Text>
      <div className="content-section">
        <ScrollBar
          elementName={"card_header_container"}
          max_height={"100%"}
          className="scroll-bar-container"
        >
          <div className="commission-section">
            <div className="total-portfolio-commission">
              <Text variant="popUpText" className="commission-title">
                Comisión total de la cartera
              </Text>
              <Text variant="titleFont" className="commission-value">
                {data && data.comisionTotal}%
              </Text>
            </div>
            <div className="total-portfolio-commission">
              <Text variant="popUpText" className="commission-title">
                Comisión total de la cartera €
              </Text>
              <Text variant="titleFont" className="commission-value">
                {data && data.comisionTotalEuros}€/año
              </Text>
            </div>
            <div className="total-portfolio-commission">
              <Text variant="popUpText" className="commission-title">
                Riesgo de tu cartera
              </Text>
              <Text variant="titleFont" className="commission-value">
                {data && data.riesgo}/6
              </Text>
            </div>
          </div>
          <div className="each-fund-details-section">
            <Text variant="popUpText" className="each-fund-details-title">
              Detalle de cada fondo
            </Text>
            {fundDetails &&
              fundDetails.map((value) => {
                return (
                  <div className="fund-details">
                    <div className="name-section responsive-container">
                      <Text
                        variant="tableTitle"
                        className="title width-divider"
                      >
                        Nombre
                      </Text>
                      <Text
                        variant="tableTitle"
                        className="value width-divider"
                      >
                        {value.nombre}
                      </Text>
                    </div>
                    <div className="portfolio-section responsive-container">
                      <Text
                        variant="tableTitle"
                        className="title width-divider text-container"
                      >
                        Peso en cartera
                      </Text>
                      <div className="percentage-container width-divider">
                        <Text variant="tableTitle" className="value">
                          {value.porcentaje}%
                        </Text>
                        <div className="loading-container">
                          <div
                            className="loader"
                            style={{ width: `${value.porcentaje}%` }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="amount-section responsive-container">
                      <Text
                        variant="tableTitle"
                        className="title width-divider"
                      >
                        valor
                      </Text>
                      <Text
                        variant="tableTitle"
                        className="value width-divider"
                      >
                        {value.valor}€
                      </Text>
                    </div>
                    <div className="investment-commission-section responsive-container">
                      <Text
                        variant="tableTitle"
                        className="title width-divider"
                      >
                        Comisiones
                      </Text>
                      <div className="commission-inner-container width-divider">
                        <Text variant="tableTitle" className="value">
                          {value.comision}%
                        </Text>
                        <Text
                          variant="seeMore"
                          className="more-text"
                          onClick={() => window.open(value.link, "_blank")}
                        >
                          Más información
                        </Text>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <Text className="investment-description" variant="popUpFont">
            * Hay fondos con mejores comisiones disponibles en tu banco, pero
            para poder acceder a ellos es necesario que&nbsp;
            <span className="inner-text">tengas un patrimonio más elevado</span>
            &nbsp;del que dispones para invertir ahora mismo.
          </Text>
          <Text className="investment-description" variant="popUpFont">
            **Es importante comprender que si un fondo tiene una comisión del 2%
            y en el año ha ganado un 8%, en tu zona privada te va a a aparecer
            que solo ganas un 6% porque&nbsp;
            <span className="inner-text">contabilizamos las comisiones</span>
            &nbsp;en el cálculo de tu rentabilidad.
          </Text>
          <div className="map-section">{mapHandler()}</div>
        </ScrollBar>
      </div>
    </InvestmentDetailsPopUpStyle>
  );
};

export default InvestmentDetailsPopUp;
