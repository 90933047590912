 export const viaTypes=[
    {
        value: "AL",
        label: "Alameda"
    },
    {
        value: "AD",
        label: "Aldea"
    },
    {
        value: "AP",
        label: "Apartamento"
    },
    {
        value: "AV",
        label: "Avenida"
    },
    {
        value: "BO",
        label: "Barrio"
    },
    {
        value: "BL",
        label: "Bloque"
    },
    {
        value: "CL",
        label: "Calle"
    },
    {
        value: "CM",
        label: "Camino"
    },
    {
        value: "CR",
        label: "Carretera"
    },
    {
        value: "CS",
        label: "Caserío"
    },
    {
        value: "CH",
        label: "Chalet"
    },
    {
        value: "CO",
        label: "Colonia"
    },
    {
        value: "CT",
        label: "Cuesta"
    },
    {
        value: "ED",
        label: "Edificio"
    },
    {
        value: "GL",
        label: "Glorieta"
    },
    {
        value: "GR",
        label: "Grupo"
    },
    {
        value: "LG",
        label: "Lugar"
    },
    {
        value: "MZ",
        label: "Manzana"
    },
    {
        value: "MC",
        label: "Mercado"
    },
    {
        value: "MN",
        label: "Municipio"
    },
    {
        value: "PQ",
        label: "Parque"
    },
    {
        value: "PJ",
        label: "Pasaje"
    },
    {
        value: "PS",
        label: "Paseo"
    },
    {
        value: "PZ",
        label: "Plaza"
    },
    {
        value: "PB",
        label: "Poblado"
    },
    {
        value: "PG",
        label: "Polígono"
    },
    {
        value: "PR",
        label: "Prolongación"
    },
    {
        value: "RB",
        label: "Rambla"
    },
    {
        value: "RD",
        label: "Ronda"
    },
    {
        value: "TR",
        label: "Travesía"
    },
    {
        value: "UR",
        label: "Urbanización"
    }
];

export const regions= [
    {
        value: "592c3af4a591700d7c70cbf8",
        label: "ALAVA"
    },
    {
        value: "592c3af5a591700d7c70d042",
        label: "ALBACETE"
    },
    {
        value: "592c3af5a591700d7c70d25b",
        label: "ALICANTE"
    },
    {
        value: "592c3af7a591700d7c70dd01",
        label: "ALMERÍA"
    },
    {
        value: "592c3af8a591700d7c70e299",
        label: "ASTURIAS"
    },
    {
        value: "592c3b0aa591700d7c7117ee",
        label: "AVILA"
    },
    {
        value: "592c3b0ca591700d7c711b57",
        label: "BADAJOZ"
    },
    {
        value: "592c3b0da591700d7c711d32",
        label: "BALEARES"
    },
    {
        value: "592c3b0fa591700d7c712261",
        label: "BARCELONA"
    },
    {
        value: "592c3b12a591700d7c7126f1",
        label: "BURGOS"
    },
    {
        value: "592c3b18a591700d7c7131de",
        label: "CANTABRIA"
    },
    {
        value: "592c3b1da591700d7c713a8d",
        label: "CASTELLÓN"
    },
    {
        value: "592c3b1ea591700d7c713c7e",
        label: "CEUTA"
    },
    {
        value: "592c3b1ea591700d7c713c88",
        label: "CIUDAD REAL"
    },
    {
        value: "592c3b1fa591700d7c713df1",
        label: "CORUÑA, LA"
    },
    {
        value: "592c3b89a591700d7c71b2c0",
        label: "CUENCA"
    },
    {
        value: "592c3b8ca591700d7c71b55d",
        label: "CÁCERES"
    },
    {
        value: "592c3b8fa591700d7c71b7d3",
        label: "CÁDIZ"
    },
    {
        value: "592c3b92a591700d7c71ba31",
        label: "CÓRDOBA"
    },
    {
        value: "592c3b96a591700d7c71bdac",
        label: "GERONA"
    },
    {
        value: "592c3b9fa591700d7c71c4f6",
        label: "GRANADA"
    },
    {
        value: "592c3ba3a591700d7c71c7d0",
        label: "GUADALAJARA"
    },
    {
        value: "592c3ba8a591700d7c71cbeb",
        label: "GUIPUZCOA"
    },
    {
        value: "592c3baba591700d7c71ce74",
        label: "HUELVA"
    },
    {
        value: "592c3bada591700d7c71cfaa",
        label: "HUESCA"
    },
    {
        value: "592c3bb5a591700d7c71d625",
        label: "JAEN"
    },
    {
        value: "592c3bb8a591700d7c71d844",
        label: "LEÓN"
    },
    {
        value: "592c3bc8a591700d7c71e398",
        label: "LUGO"
    },
    {
        value: "592c3bd7a591700d7c71ee90",
        label: "LÉRIDA"
    },
    {
        value: "592c3be3a591700d7c71f666",
        label: "MADRID"
    },
    {
        value: "592c3beea591700d7c71fe3f",
        label: "MELILLA"
    },
    {
        value: "592c3beea591700d7c71fe4b",
        label: "MURCIA"
    },
    {
        value: "592c3bfba591700d7c720661",
        label: "MÁLAGA"
    },
    {
        value: "592c3c0aa591700d7c720f7f",
        label: "NAVARRA"
    },
    {
        value: "592c3c16a591700d7c72175f",
        label: "ORENSE"
    },
    {
        value: "592c3c49a591700d7c723437",
        label: "PALENCIA"
    },
    {
        value: "592c3c50a591700d7c723844",
        label: "PALMAS (LAS)"
    },
    {
        value: "592c3c64a591700d7c724325",
        label: "PONTEVEDRA"
    },
    {
        value: "592c3c79a591700d7c724f63",
        label: "RIOJA (LA)"
    },
    {
        value: "592c3c7da591700d7c725181",
        label: "S.C.TENERIFE"
    },
    {
        value: "592c3c8ca591700d7c725945",
        label: "SALAMANCA"
    },
    {
        value: "592c3c98a591700d7c725f24",
        label: "SEGOVIA"
    },
    {
        value: "592c3c9fa591700d7c726299",
        label: "SEVILLA"
    },
    {
        value: "592c3ca2a591700d7c726463",
        label: "SORIA"
    },
    {
        value: "592c3caba591700d7c726888",
        label: "TARRAGONA"
    },
    {
        value: "592c3cb1a591700d7c726bc0",
        label: "TERUEL"
    },
    {
        value: "592c3cb6a591700d7c726e2e",
        label: "TOLEDO"
    },
    {
        value: "592c3cbaa591700d7c72705a",
        label: "VALENCIA"
    },
    {
        value: "592c3cc4a591700d7c727526",
        label: "VALLADOLID"
    },
    {
        value: "592c3cc9a591700d7c727767",
        label: "VIZCAYA"
    },
    {
        value: "592c3cd5a591700d7c727d31",
        label: "ZAMORA"
    },
    {
        value: "592c3cdda591700d7c728164",
        label: "ZARAGOZA"
    }
];

export const countries= [
    {
        value: "AF",
        label: "Afganistán"
    },
    {
        value: "AX",
        label: "Alandia"
    },
    {
        value: "AL",
        label: "Albania"
    },
    {
        value: "DE",
        label: "Alemania"
    },
    {
        value: "AD",
        label: "Andorra"
    },
    {
        value: "AO",
        label: "Angola"
    },
    {
        value: "AI",
        label: "Anguilla"
    },
    {
        value: "AG",
        label: "Antigua y Barbuda"
    },
    {
        value: "AQ",
        label: "Antártida"
    },
    {
        value: "SA",
        label: "Arabia Saudí"
    },
    {
        value: "DZ",
        label: "Argelia"
    },
    {
        value: "AR",
        label: "Argentina"
    },
    {
        value: "AM",
        label: "Armenia"
    },
    {
        value: "AW",
        label: "Aruba"
    },
    {
        value: "AU",
        label: "Australia"
    },
    {
        value: "AT",
        label: "Austria"
    },
    {
        value: "AZ",
        label: "Azerbaiyán"
    },
    {
        value: "BS",
        label: "Bahamas"
    },
    {
        value: "BH",
        label: "Bahrein"
    },
    {
        value: "BD",
        label: "Bangladesh"
    },
    {
        value: "BB",
        label: "Barbados"
    },
    {
        value: "BZ",
        label: "Belice"
    },
    {
        value: "BJ",
        label: "Benín"
    },
    {
        value: "BM",
        label: "Bermudas"
    },
    {
        value: "BY",
        label: "Bielorrusia"
    },
    {
        value: "BO",
        label: "Bolivia"
    },
    {
        value: "BA",
        label: "Bosnia y Herzegovina"
    },
    {
        value: "BW",
        label: "Botswana"
    },
    {
        value: "BR",
        label: "Brasil"
    },
    {
        value: "BE",
        label: "Bélgica"
    },
    {
        value: "BN",
        label: "Brunei"
    },
    {
        value: "BG",
        label: "Bulgaria"
    },
    {
        value: "BF",
        label: "Burkina Faso"
    },
    {
        value: "BI",
        label: "Burundi"
    },
    {
        value: "BT",
        label: "Bután"
    },
    {
        value: "CV",
        label: "Cabo Verde"
    },
    {
        value: "KH",
        label: "Camboya"
    },
    {
        value: "CM",
        label: "Camerún"
    },
    {
        value: "CA",
        label: "Canadá"
    },
    {
        value: "QA",
        label: "Catar"
    },
    {
        value: "TD",
        label: "Chad"
    },
    {
        value: "CL",
        label: "Chile"
    },
    {
        value: "CN",
        label: "China"
    },
    {
        value: "CY",
        label: "Chipre"
    },
    {
        value: "VA",
        label: "Ciudad del Vaticano"
    },
    {
        value: "CO",
        label: "Colombia"
    },
    {
        value: "KM",
        label: "Comoras"
    },
    {
        value: "CG",
        label: "Congo"
    },
    {
        value: "CD",
        label: "Congo (Rep. Dem.)"
    },
    {
        value: "KP",
        label: "Corea del Norte"
    },
    {
        value: "KR",
        label: "Corea del Sur"
    },
    {
        value: "CR",
        label: "Costa Rica"
    },
    {
        value: "CI",
        label: "Costa de Marfil"
    },
    {
        value: "HR",
        label: "Croacia"
    },
    {
        value: "CU",
        label: "Cuba"
    },
    {
        value: "CW",
        label: "Curazao"
    },
    {
        value: "DK",
        label: "Dinamarca"
    },
    {
        value: "DM",
        label: "Dominica"
    },
    {
        value: "EC",
        label: "Ecuador"
    },
    {
        value: "EG",
        label: "Egipto"
    },
    {
        value: "SV",
        label: "El Salvador"
    },
    {
        value: "AE",
        label: "Emiratos Árabes Unidos"
    },
    {
        value: "ER",
        label: "Eritrea"
    },
    {
        value: "SK",
        label: "Eslovaquia"
    },
    {
        value: "SI",
        label: "Eslovenia"
    },
    {
        value: "ES",
        label: "España"
    },
    {
        value: "US",
        label: "Estados Unidos"
    },
    {
        value: "EE",
        label: "Estonia"
    },
    {
        value: "ET",
        label: "Etiopía"
    },
    {
        value: "PH",
        label: "Filipinas"
    },
    {
        value: "FI",
        label: "Finlandia"
    },
    {
        value: "FJ",
        label: "Fiyi"
    },
    {
        value: "FR",
        label: "Francia"
    },
    {
        value: "GA",
        label: "Gabón"
    },
    {
        value: "GM",
        label: "Gambia"
    },
    {
        value: "GE",
        label: "Georgia"
    },
    {
        value: "GH",
        label: "Ghana"
    },
    {
        value: "GI",
        label: "Gibraltar"
    },
    {
        value: "GR",
        label: "Grecia"
    },
    {
        value: "GD",
        label: "Grenada"
    },
    {
        value: "GL",
        label: "Groenlandia"
    },
    {
        value: "GP",
        label: "Guadalupe"
    },
    {
        value: "GU",
        label: "Guam"
    },
    {
        value: "GT",
        label: "Guatemala"
    },
    {
        value: "GF",
        label: "Guayana Francesa"
    },
    {
        value: "GG",
        label: "Guernsey"
    },
    {
        value: "GN",
        label: "Guinea"
    },
    {
        value: "GQ",
        label: "Guinea Ecuatorial"
    },
    {
        value: "GW",
        label: "Guinea-Bisáu"
    },
    {
        value: "GY",
        label: "Guyana"
    },
    {
        value: "HT",
        label: "Haiti"
    },
    {
        value: "HN",
        label: "Honduras"
    },
    {
        value: "HK",
        label: "Hong Kong"
    },
    {
        value: "HU",
        label: "Hungría"
    },
    {
        value: "IN",
        label: "India"
    },
    {
        value: "ID",
        label: "Indonesia"
    },
    {
        value: "IQ",
        label: "Irak"
    },
    {
        value: "IR",
        label: "Iran"
    },
    {
        value: "IE",
        label: "Irlanda"
    },
    {
        value: "BV",
        label: "Isla Bouvet"
    },
    {
        value: "IM",
        label: "Isla de Man"
    },
    {
        value: "CX",
        label: "Isla de Navidad"
    },
    {
        value: "NF",
        label: "Isla de Norfolk"
    },
    {
        value: "IS",
        label: "Islandia"
    },
    {
        value: "KY",
        label: "Islas Caimán"
    },
    {
        value: "CC",
        label: "Islas Cocos o Islas Keeling"
    },
    {
        value: "CK",
        label: "Islas Cook"
    },
    {
        value: "FO",
        label: "Islas Faroe"
    },
    {
        value: "GS",
        label: "Islas Georgias del Sur y Sandwich del Sur"
    },
    {
        value: "HM",
        label: "Islas Heard y McDonald"
    },
    {
        value: "FK",
        label: "Islas Malvinas"
    },
    {
        value: "MP",
        label: "Islas Marianas del Norte"
    },
    {
        value: "MH",
        label: "Islas Marshall"
    },
    {
        value: "PN",
        label: "Islas Pitcairn"
    },
    {
        value: "SB",
        label: "Islas Salomón"
    },
    {
        value: "SJ",
        label: "Islas Svalbard y Jan Mayen"
    },
    {
        value: "TK",
        label: "Islas Tokelau"
    },
    {
        value: "TC",
        label: "Islas Turks y Caicos"
    },
    {
        value: "UM",
        label: "Islas Ultramarinas Menores de Estados Unidos"
    },
    {
        value: "VI",
        label: "Islas Vírgenes de los Estados Unidos"
    },
    {
        value: "VG",
        label: "Islas Vírgenes del Reino Unido"
    },
    {
        value: "IL",
        label: "Israel"
    },
    {
        value: "IT",
        label: "Italia"
    },
    {
        value: "JM",
        label: "Jamaica"
    },
    {
        value: "JP",
        label: "Japón"
    },
    {
        value: "JE",
        label: "Jersey"
    },
    {
        value: "JO",
        label: "Jordania"
    },
    {
        value: "KZ",
        label: "Kazajistán"
    },
    {
        value: "KE",
        label: "Kenia"
    },
    {
        value: "KG",
        label: "Kirguizistán"
    },
    {
        value: "KI",
        label: "Kiribati"
    },
    {
        value: "XK",
        label: "Kosovo"
    },
    {
        value: "KW",
        label: "Kuwait"
    },
    {
        value: "LA",
        label: "Laos"
    },
    {
        value: "LS",
        label: "Lesotho"
    },
    {
        value: "LV",
        label: "Letonia"
    },
    {
        value: "LR",
        label: "Liberia"
    },
    {
        value: "LY",
        label: "Libia"
    },
    {
        value: "LI",
        label: "Liechtenstein"
    },
    {
        value: "LT",
        label: "Lituania"
    },
    {
        value: "LU",
        label: "Luxemburgo"
    },
    {
        value: "LB",
        label: "Líbano"
    },
    {
        value: "MO",
        label: "Macao"
    },
    {
        value: "MK",
        label: "Macedonia"
    },
    {
        value: "MG",
        label: "Madagascar"
    },
    {
        value: "MY",
        label: "Malasia"
    },
    {
        value: "MW",
        label: "Malawi"
    },
    {
        value: "MV",
        label: "Maldivas"
    },
    {
        value: "ML",
        label: "Mali"
    },
    {
        value: "MT",
        label: "Malta"
    },
    {
        value: "MA",
        label: "Marruecos"
    },
    {
        value: "MQ",
        label: "Martinica"
    },
    {
        value: "MU",
        label: "Mauricio"
    },
    {
        value: "MR",
        label: "Mauritania"
    },
    {
        value: "YT",
        label: "Mayotte"
    },
    {
        value: "FM",
        label: "Micronesia"
    },
    {
        value: "MD",
        label: "Moldavia"
    },
    {
        value: "MN",
        label: "Mongolia"
    },
    {
        value: "ME",
        label: "Montenegro"
    },
    {
        value: "MS",
        label: "Montserrat"
    },
    {
        value: "MZ",
        label: "Mozambique"
    },
    {
        value: "MM",
        label: "Myanmar"
    },
    {
        value: "MX",
        label: "México"
    },
    {
        value: "MC",
        label: "Mónaco"
    },
    {
        value: "NA",
        label: "Namibia"
    },
    {
        value: "NR",
        label: "Nauru"
    },
    {
        value: "NP",
        label: "Nepal"
    },
    {
        value: "NI",
        label: "Nicaragua"
    },
    {
        value: "NG",
        label: "Nigeria"
    },
    {
        value: "NU",
        label: "Niue"
    },
    {
        value: "NO",
        label: "Noruega"
    },
    {
        value: "NC",
        label: "Nueva Caledonia"
    },
    {
        value: "NZ",
        label: "Nueva Zelanda"
    },
    {
        value: "NE",
        label: "Níger"
    },
    {
        value: "OM",
        label: "Omán"
    },
    {
        value: "PK",
        label: "Pakistán"
    },
    {
        value: "PW",
        label: "Palau"
    },
    {
        value: "PS",
        label: "Palestina"
    },
    {
        value: "PA",
        label: "Panamá"
    },
    {
        value: "PG",
        label: "Papúa Nueva Guinea"
    },
    {
        value: "PY",
        label: "Paraguay"
    },
    {
        value: "NL",
        label: "Países Bajos"
    },
    {
        value: "PE",
        label: "Perú"
    },
    {
        value: "PF",
        label: "Polinesia Francesa"
    },
    {
        value: "PL",
        label: "Polonia"
    },
    {
        value: "PT",
        label: "Portugal"
    },
    {
        value: "PR",
        label: "Puerto Rico"
    },
    {
        value: "GB",
        label: "Reino Unido"
    },
    {
        value: "CF",
        label: "República Centroafricana"
    },
    {
        value: "CZ",
        label: "República Checa"
    },
    {
        value: "DO",
        label: "República Dominicana"
    },
    {
        value: "ZA",
        label: "República de Sudáfrica"
    },
    {
        value: "RE",
        label: "Reunión"
    },
    {
        value: "RW",
        label: "Ruanda"
    },
    {
        value: "RO",
        label: "Rumanía"
    },
    {
        value: "RU",
        label: "Rusia"
    },
    {
        value: "EH",
        label: "Sahara Occidental"
    },
    {
        value: "MF",
        label: "Saint Martin"
    },
    {
        value: "WS",
        label: "Samoa"
    },
    {
        value: "AS",
        label: "Samoa Americana"
    },
    {
        value: "BL",
        label: "San Bartolomé"
    },
    {
        value: "KN",
        label: "San Cristóbal y Nieves"
    },
    {
        value: "SM",
        label: "San Marino"
    },
    {
        value: "PM",
        label: "San Pedro y Miquelón"
    },
    {
        value: "VC",
        label: "San Vicente y Granadinas"
    },
    {
        value: "LC",
        label: "Santa Lucía"
    },
    {
        value: "ST",
        label: "Santo Tomé y Príncipe"
    },
    {
        value: "SN",
        label: "Senegal"
    },
    {
        value: "RS",
        label: "Serbia"
    },
    {
        value: "SC",
        label: "Seychelles"
    },
    {
        value: "SL",
        label: "Sierra Leone"
    },
    {
        value: "SG",
        label: "Singapur"
    },
    {
        value: "SX",
        label: "Sint Maarten"
    },
    {
        value: "SY",
        label: "Siria"
    },
    {
        value: "SO",
        label: "Somalia"
    },
    {
        value: "LK",
        label: "Sri Lanka"
    },
    {
        value: "SZ",
        label: "Suazilandia"
    },
    {
        value: "SD",
        label: "Sudán"
    },
    {
        value: "SS",
        label: "Sudán del Sur"
    },
    {
        value: "SE",
        label: "Suecia"
    },
    {
        value: "CH",
        label: "Suiza"
    },
    {
        value: "SR",
        label: "Surinam"
    },
    {
        value: "TH",
        label: "Tailandia"
    },
    {
        value: "TW",
        label: "Taiwán"
    },
    {
        value: "TZ",
        label: "Tanzania"
    },
    {
        value: "TJ",
        label: "Tayikistán"
    },
    {
        value: "IO",
        label: "Territorio Británico del Océano Índico"
    },
    {
        value: "TF",
        label: "Tierras Australes y Antárticas Francesas"
    },
    {
        value: "TL",
        label: "Timor Oriental"
    },
    {
        value: "TG",
        label: "Togo"
    },
    {
        value: "TO",
        label: "Tonga"
    },
    {
        value: "TT",
        label: "Trinidad y Tobago"
    },
    {
        value: "TM",
        label: "Turkmenistán"
    },
    {
        value: "TR",
        label: "Turquía"
    },
    {
        value: "TV",
        label: "Tuvalu"
    },
    {
        value: "TN",
        label: "Túnez"
    },
    {
        value: "UA",
        label: "Ucrania"
    },
    {
        value: "UG",
        label: "Uganda"
    },
    {
        value: "UY",
        label: "Uruguay"
    },
    {
        value: "UZ",
        label: "Uzbekistán"
    },
    {
        value: "VU",
        label: "Vanuatu"
    },
    {
        value: "VE",
        label: "Venezuela"
    },
    {
        value: "VN",
        label: "Vietnam"
    },
    {
        value: "WF",
        label: "Wallis y Futuna"
    },
    {
        value: "YE",
        label: "Yemen"
    },
    {
        value: "DJ",
        label: "Yibuti"
    },
    {
        value: "ZM",
        label: "Zambia"
    },
    {
        value: "ZW",
        label: "Zimbabue"
    }
]
