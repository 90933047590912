import styled from "styled-components";

const InterestSectionStyle = styled.div`
  .interest-container {
    padding: 2rem 2rem 4rem 2rem;
    .interest-heading {
      font-weight: bold;
      padding-left: 1rem;
      padding-bottom: 2rem;
      color: ${(props) => props.theme.colors.fontColor.titleFontColor};
      @media (max-width: 540px) {
        text-align: center;
      }
    }
    .interest-option-container {
      display: flex;
      justify-content: center;
      gap: 1.5rem;
      flex-wrap: wrap;
      .interest-option-container {
        min-width: 11rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 0 1 23%;
        background-color: ${(props) =>
          props.theme.colors.background.optionsCardBackground};
        padding: 3rem 1rem 2rem 1rem;
        border-radius: 2.18rem;
        .interest-option-icon {
          margin-bottom: 2rem;
          .option-icon {
            width: 2.5rem;
            height: 2.5rem;
          }
        }
        .interest-option-title {
          font-weight: 600;
          text-align: center;
          color: ${(props) =>
            props.theme.colors.fontColor.descriptionFontColor};
        }
        .interest-option-description {
          text-align: center;
          flex: 1;
          color: ${(props) =>
            props.theme.colors.fontColor.descriptionFontColor};
        }
      }
    }
  }
`;
export default InterestSectionStyle;