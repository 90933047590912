import styled from "styled-components";

const DocumentPopUpStyle = styled.div`
  height: 31.25rem;
  border-radius: 1.5625rem;
  overflow: hidden;
  .document-container {
    width: 100%;
    background-color: ${(props) =>
      props.theme.colors.background.defaultBackground};
    padding: 2rem;
    border-radius: 1.5625rem;
    .document-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      @media (max-width: 600px) {
        flex-direction: column;
        gap: 2rem;
        align-items: center;
      }
      .document-heading {
        color: ${(props) => props.theme.colors.fontColor.titleFontColor};
        font-weight: bold;
      }
      .upload-button-container {
        min-width: 14rem;
        .upload-button {
          height: 2.5rem;
        }
        .disable-btn {
          pointer-events: none;
          cursor: not-allowed;
          opacity: 50%;
        }
      }
    }
    .document-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-bottom: 3rem;
      @media (max-width: 835px) {
        justify-content: center;
      }
      .card-content {
        font-style: italic;
        font-weight: 400;
        padding: 2rem 0rem;
      }

      .document-description {
        font-style: italic;
        font-weight: 400;
        padding: 0.5rem 0rem 1rem 0rem;
        text-align: center;
      }
      .buttons {
        display: flex;
        justify-content: center;
        @media (max-width: 480px) {
          flex-direction: column;
        }
        .dropzone-container {
          .container {
            display: flex;
            flex-direction: column;
            align-items: center;
            .uploaded-image {
              height: 11.25rem;
              width: 18.75rem;
              @media (max-width: 450px) {
                height: 8.25rem;
                width: 15.75rem;
              }
              @media (max-width: 350px) {
                height: 6.25rem;
                width: 13.75rem;
              }
            }
            .dropzone {
              background-color: ${(props) =>
                props.theme.colors.button.background.blueButton};
              padding: 1rem;
              border-radius: 1.5625rem;
              cursor: pointer;
              min-width: 13.75rem;
              p {
                margin: 0rem !important;
                font-weight: 500;
                color: ${(props) =>
                  props.theme.colors.button.text.textTeritiory};
              }
            }
          }
        }
      }
    }
  }
  .simplebar-scrollbar::before {
    background-color: ${(props) =>
      props.theme.colors.background.primaryCardBackground};
    border-radius: 0.4375rem;
  }
  .simplebar-vertical {
    width: 0.6875rem;
  }
`;

export default DocumentPopUpStyle;
