import { useState } from "react";
import { DateRangePicker } from "react-date-range";
import { es } from "date-fns/locale";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import DateRangeStyle from "./dateRangeStyle";

const DateRanger = ({ change, start, end, minDate, maxDate }) => {
  const [dateRanges, setdateRange] = useState([
    {
      startDate: new Date(start),
      endDate: new Date(end),
      key: "selection",
      color: "#d9f7ef",
    },
  ]);

  const changeHandler = (data) => {
    setdateRange([data]);
    change(data.startDate, data.endDate);
  };

  const dateHandler = (date) => {
    let newMinDate = new Date(date);
    return newMinDate;
  };

  return (
    <DateRangeStyle>
      <DateRangePicker
        onChange={(item) => changeHandler(item.selection)}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={1}
        rangeColors={["#000"]}
        ranges={dateRanges}
        locale={es}
        direction="horizontal"
        selectRange={true}
        minDate={dateHandler(minDate)}
        maxDate={dateHandler(maxDate)}
      />
    </DateRangeStyle>
  );
};

export default DateRanger;
