import Moment from "moment";

const CancelLetter = ({ data }) => {
  let convertedExpiryDate = Moment(data.vencimiento).format(`DD/MM/YYYY`);

  const convertHandler = () => {
    let convertedDate = Moment(data.fechaActual).format(`DD MMMM YYYY`);

    var splitedDate = convertedDate.split(" ");
    var date = splitedDate[0];
    var month = splitedDate[1];
    var year = splitedDate[2];

    return `${date} de  ${month} de ${year}`;
  };

  const styles = {
    pages: {
      fontSize: "0.5rem",
      margin: "3rem 4rem",
      width: "500px",
      wordSpacing: "-1px",
    },
    lettertitle: {
      width: "100%",
      marginBottom: "1rem",
    },
    subContent: {
      width: "300px",
      marginBottom: "1rem",
      wordSpacing: "0.5px",
    },
    takerSection: {
      marginBottom: "2rem",
    },
    dateSection: {
      width: "300px",
      textAlign: "end",
    },
    introduction: {
      marginBottom: "1rem",
    },
    contentSection: {
      wordBreak: "break-word",
      width: "300px",
      marginRight: "5rem",
      marginBottom: "1rem",
    },
    mainContent: {
      marginBottom: "1rem",
      width: "300px",
    },
    greetingSection: {
      marginBottom: "3rem",
    },
  };

  return (
    <div style={styles.pages}>
      <div style={styles.lettertitle}>Solicitud de Baja del Seguro</div>
      <div style={styles.takerSection}>
        <div>Tomador: {data.nombre}</div>
        <div>NIF: {data.dni}</div>
      </div>
      <div style={styles.dateSection}>{convertHandler()}</div>
      <div style={styles.contentSection}>
        <div style={styles.introduction}>Estimados Sres.,</div>
        <div style={styles.subContent}>
          Yo, {data.nombre} con NIF {data.dni},
          {data.poliza
            ? ` como tomador de la póliza con número ${data.poliza}, `
            : " "}
          les comunico mediante este escrito, la orden de baja con carácter
          preventivo en el seguro de {data.tipo} suscrito con&nbsp;
          {data.aseguradora} y con fecha de vencimiento {convertedExpiryDate},
          siendo esta fecha en la que deseo tenga efecto la baja que solicito
        </div>
      </div>
      <div style={styles.subContent}>
        Al mismo tiempo solicito cancelar la domiciliación y que no se realicen
        nuevos cargos a mi cuenta bancaria para evitar posibles gastos
        innecesarios.
      </div>
      <div style={styles.subContent}>
        Les ruego me confirmen la tramitación de mi petición.
      </div>
      <div style={styles.greetingSection}>Reciban un cordial saludo,</div>
      <div style={styles.greetingSection}>Firmado</div>
      <div>{data.nombre}</div>
    </div>
  );
};

export default CancelLetter;
