import styled from "styled-components";

const InvestmentDetailsPopUpStyle = styled.div`
  width: 100%;
  background-color: ${(props) =>
    props.theme.colors.background.defaultBackground};
  padding: 1rem;
  border-radius: 1.5625rem;
  @media (max-width: 480px) {
    padding: 0rem;
  }
  .investment-details-popup-heading {
    color: ${(props) => props.theme.colors.fontColor.titleFontColor};
    font-weight: bold;
    padding: 1rem 1rem 0rem 1rem;
  }
  .content-section {
    height: 70vh;
    padding-bottom: 1rem;
  }
  .scroll-bar-container {
    padding: 0rem 1rem;
  }
  .simplebar-scrollbar::before {
    background-color: ${(props) =>
      props.theme.colors.background.primaryCardBackground};
    border-radius: 0.4375rem;
  }

  .simplebar-vertical {
    width: 0.6875rem;
  }
  .commission-section {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid
      ${(props) => props.theme.colors.background.bodyBackground};
    padding: 1rem 0rem;
    @media (max-width: 570px) {
      flex-direction: column;
    }
    .total-portfolio-commission {
      flex: 0 1 30%;
      @media (max-width: 570px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .commission-title {
        color: ${(props) => props.theme.colors.background.outlineBorderColor};
        font-weight: bold;
        @media (max-width: 570px) {
          margin-right: 0.3rem;
        }
      }
      .commission-value {
        color: ${(props) => props.theme.colors.fontColor.titleFontColor};
        font-weight: bold;
      }
    }
  }
  .each-fund-details-section {
    padding: 1rem 0rem;
    .each-fund-details-title {
      color: ${(props) => props.theme.colors.background.outlineBorderColor};
      font-weight: bold;
      margin-bottom: 1rem;
    }
    .fund-details {
      display: flex;
      justify-content: space-between;
      background-color: ${(props) =>
        props.theme.colors.background.defaultFieldBackground};
      padding: 0.5rem 1rem;
      border-radius: 1.5625rem;
      margin-bottom: 1rem;
      @media (max-width: 880px) {
        flex-direction: column;
        gap: 0.3rem;
      }
      .responsive-container {
        @media (max-width: 880px) {
          display: flex;
          justify-content: space-between;
        }
      }
      .width-divider {
        @media (max-width: 880px) {
          width: 50%;
        }
      }
      .name-section {
        width: 30%;
        @media (max-width: 880px) {
          width: 100%;
        }
      }
      .portfolio-section {
        margin-left: 0.4rem;
        width: 30%;
        @media (max-width: 880px) {
          margin-left: 0rem;
          width: 100%;
        }
      }
      .amount-section {
        margin-left: 0.4rem;
        width: 20%;
        text-align: center;
        @media (max-width: 880px) {
          margin-left: 0rem;
          width: 100%;
          text-align: start;
        }
      }
      .investment-commission-section {
        width: 20%;
        margin-left: 0.4rem;
        text-align: center;
        @media (max-width: 880px) {
          margin-left: 0rem;
          width: 100%;
          text-align: start;
        }
      }
      .percentage-container {
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 880px) {
          justify-content: end;
        }
        .loading-container {
          min-width: 10rem;
          margin-left: 1rem;
          height: 1.3rem;
          border-radius: 0.5rem;
          background-color: ${(props) =>
            props.theme.colors.background.defaultBackground};
          overflow: hidden;
          @media (max-width: 1040px) {
            min-width: 7rem;
            height: 1rem;
          }
          @media (max-width: 480px) {
            min-width: 5rem;
          }
          @media (max-width: 480px) {
            min-width: 4rem;
            height: 0.7rem;
          }
          .loader {
            height: 100%;
            border-radius: 0.5rem;
            background-color: ${(props) =>
              props.theme.colors.fontColor.titleFontColor};
          }
        }
      }
      .amount-section {
      }
      .investment-commission-section {
      }
      .title {
        color: ${(props) => props.theme.colors.fontColor.titleFontColor};
      }
      .value {
        color: ${(props) => props.theme.colors.fontColor.titleFontColor};
        font-weight: bold;
        @media (max-width: 880px) {
          text-align: end;
        }
      }
      .commission-inner-container {
        @media (max-width: 880px) {
          display: flex;
          align-items: baseline;
          justify-content: end;
        }
      }
      .text-container {
        text-align: center;
        @media (max-width: 880px) {
          text-align: start;
        }
      }

      .more-text {
        color: ${(props) => props.theme.colors.fontColor.titleFontColor};
        text-decoration: underline;
        cursor: pointer;
        @media (max-width: 880px) {
          margin-left: 1rem;
        }
      }
    }
  }
  .investment-description {
    color: ${(props) => props.theme.colors.background.outlineBorderColor};
    .inner-text {
      color: ${(props) => props.theme.colors.fontColor.titleFontColor};
    }
  }
  .map-section {
    .rowStyle {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      .map-labels {
        display: flex;
        justify-content: center;
        gap: 0.5rem;
        align-items: center;
        font-weight: 600;
        @media (max-width: 1300px) {
          flex-direction: column;
          align-items: flex-start;
        }
        @media (max-width: 1040px) {
          flex-direction: row;
        }
        @media (max-width: 350px) {
          flex-direction: column;
        }

        .label-div {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          .label-color {
            border-radius: 50%;
            height: 0.75rem;
            width: 0.75rem;
            flex-shrink: 0;
          }
          .primary-label-color {
            background-color: ${(props) =>
              props.theme.colors.background.primaryMapLabelColor};
          }
          .secondary-label-color {
            background-color: ${(props) =>
              props.theme.colors.background.secondaryMapLabelColor};
          }
        }
        .teritiary-label-color {
          background-color: ${(props) =>
            props.theme.colors.background.teritiaryMapLabelColor};
        }
      }
    }
  }
  .titlechart {
    color: ${(props) => props.theme.colors.background.outlineBorderColor};
    font-weight: bold;
    margin-bottom: 1.25rem;
    margin-top: 1rem;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    @media (max-width: 991px) {
      width: 100%;
      text-align: center;
      justify-content: center;
    }
  }
  .chart-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-width: 991px) {
      padding-bottom: 3rem;
    }
  }
  .doughnut {
    width: fit-content;
    max-width: 14.8rem;
    max-height: 14.8rem;
    @media (max-width: 360px) {
      max-width: 12.8rem;
      max-height: 10.8rem;
    }
  }

  .mapContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    svg {
      width: fit-content;
      height: fit-content;
      max-width: 19.7rem;
      @media (max-width: 1100px) {
        max-width: 18.7rem;
      }
      @media (max-width: 360px) {
        max-width: 13.7rem;
      }
    }
  }
`;
export default InvestmentDetailsPopUpStyle;
