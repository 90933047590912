import React, { Fragment, useContext } from "react";
import "../../../../../assets/css/InfoCambios.css";
import { cargarOptimizacion, cargarCambios } from "../../homeServices";
import Loading from "../../../../../components/commonModules/Loading";
import { useState } from "react";
import { useEffect } from "react";
import ChangeInfoStyle from "./changeInfoStyle";
import { AlertContext } from "../../../../common/alertContainer";
import ImageConstants from "../../../../../constants/imageConstants/image_constants";
import Text from "../../../../../theme/font";
import defaultTheme from "../../../../../theme/theme";
import { ThemeProvider } from "styled-components";

const ChangeInfo = () => {
  const [optimization, setOptimization] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [changes, setChanges] = useState(null);
  const { open } = useContext(AlertContext);
  const theme = defaultTheme;

  useEffect(() => {
    cargarOptimizacion()
      .then((result) => {
        if (result.statusText === "OK" && result.status === 200) {
          const optimization = result.data.data[0].optimizacion;
          setOptimization(optimization);
          setIsLoading(false);
        }
      })
      .catch(() => {
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });

    cargarCambios()
      .then((result) => {
        if (result.statusText === "OK" && result.data.status === 200) {
          const changes = result.data.cambios;
          setChanges(changes);
          setIsLoading(false);
        }
      })
      .catch(() => {
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const renderOptimizado = () => {
    return (
      <div className="sombreado InfoCambiosContainer">
        <p className="tituloOptimizacion">Cambio propuesto</p>
        <p className="">
          *No realices los cambios si tienes algún traspaso pendiente
        </p>
        <div className="col-xs-6 col-sm-4 col-lg-12 float">
          <div className="col-2 float">
            <img
              className="cambioImg"
              src={ImageConstants.InfoCambios1}
              alt="X"
            ></img>
          </div>
          <div className="col-10 float objetivoTextoContainer">
            <p className="InfoCambiosTitulo">Necesitas hacer</p>
            <div className="InfoCambiosGrande">
              <Text variant="labelFont">4 cambios</Text>
            </div>
          </div>
        </div>
        <div className="col-xs-6 col-sm-4 col-lg-12 float">
          <div className="col-2 float">
            <img
              className="cambioImg"
              src={ImageConstants.InfoCambios2}
              alt="X"
            ></img>
          </div>
          <div className="col-10 float objetivoTextoContainer">
            <p className="InfoCambiosTitulo">En tu banco</p>
            <div className="InfoCambiosGrande">
              <Text variant="labelFont">Caixabank</Text>
            </div>
          </div>
        </div>
        <div className="col-xs-6 col-sm-4 col-lg-12 float">
          <div className="col-2 float">
            <img
              className="cambioImg"
              src={ImageConstants.InfoCambios3}
              alt="X"
            ></img>
          </div>
          <div className="col-10 float objetivoTextoContainer">
            <p className="InfoCambiosTitulo">¿Cómo realizo mis cambios?</p>
            <p className="InfoCambiosVerde">Ver video tutorial</p>
          </div>
        </div>
        <button className="heHechoCambiosBoton">Ya he hecho los cambios</button>
      </div>
    );
  };

  const renderHandler = () => {
    if (isLoading || optimization == null || changes == null) {
      return <Loading />;
    } else {
      return <Fragment>{renderOptimizado()}</Fragment>;
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <ChangeInfoStyle>{renderHandler()}</ChangeInfoStyle>
    </ThemeProvider>
  );
};
export default ChangeInfo;
