import React, { useState, useEffect, useContext} from 'react';
import AltaStyle from './altaStyle';
import { useNavigate } from "react-router-dom";
import { Form,Row, Col } from "react-bootstrap";
import { ContainerContext } from '../../components/navbarContainer/containerProvider';
import { AuthTokenService } from '../../services';
import { patchIroniaData, getIroniaData, postPrimerosCambios } from '../investment/autopilot/autopilotServices';
import { AlertContext } from '../common/alertContainer';


export default function AutopilotIBAN(){

    const [error, setError]=useState(false);
    const [intento, setIntento]=useState(false);
    const [menuHidden] = useState(true);
    const { changeMenuStatus, changeNavBarStatus, changeAlertStatus } =
    useContext(ContainerContext);
    const navigate=useNavigate();
    const nombre = AuthTokenService.getNombre();
    const [iban, setIban] = useState('');
    const [banco, setBanco] = useState('');
    const { open } = useContext(AlertContext);
    const handleInputChange = (event) => {
        setIban(event.target.value);
    };


    useEffect(() => {
        window.scrollTo(0, 0);
        changeMenuStatus(menuHidden);
        changeNavBarStatus(true);
        changeAlertStatus(false);
        return () => {
          changeMenuStatus(!menuHidden);
          changeNavBarStatus(false);
          changeAlertStatus(true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [changeMenuStatus, changeNavBarStatus]);

      useEffect(() => {
    
        const userId = AuthTokenService.getUserId();
        getIroniaData(userId)
          .then((response) => {
            if(response==null){
              navigate("/resumen");
            }
            else{
                setBanco(response.data.banco);
            }
            
          })
          .catch(() => {
            navigate("/resumen");
            open({
              alertType: "spinner",
              open: false,
            });
            open({
              type: "error",
              title: "error encontrado",
              alertType: "toast",
              open: true,
            });
          });
      }, []);
   
    
      const handleGuardar = (datos) => {//funcion que llama al patch con los datos de formularios
        open({
            alertType: "spinner",
            open: true,
          }); 
        var datos={};
        datos.IBAN=iban;
        datos.idCliente=AuthTokenService.getUserId();

        
        postPrimerosCambios(datos)
            .then((result)=>{
                open({
                    alertType: "spinner",
                    open: false,
                  }); 
                if (result.data.status==200){
                    if(result.data.url.startsWith('http://') || result.data.url.startsWith('https://')){
                        window.location.href=result.data.url;
                    }
                    else{
                        setError(true);
                    }
                    
                }
                else{
                    setError(true);
                }
            });
        
      };


    return (
        <AltaStyle >
            <div className="bodyProcesoAlta">
                <div className="navbar navbar-expand-md navbar-light" style={{paddingLeft:'2.5rem'}}>
                    
                </div>
                <div className="process-popup container">
                    <div className="main-step-container">

                        <a className="navbar-brand">
                            <img height="40" src="https://micappital.com/assets/img/logo-micappital.png" alt="Micappital"></img>
                        </a>
                        
                    </div>
                    <div className="process-popup-inner-container">
                        <h3 className="process-heading mt-5 mb-4">
                            {nombre}, {banco} nos solicita el IBAN para verificar que eres el titular de los fondos. En caso de tener más de una cuenta, sería necesario sólo el número de cuenta en la que estén los fondos de inversión.
                        </h3>

                        <Form className="form-insurance-data">
                            <Row>
                                <Form.Group controlId="formIBAN">
                                        <Form.Control 
                                            type="text" 
                                            placeholder="Introduce tu IBAN" 
                                            value={iban} 
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                
                            </Row>
                                
                            {error && <p>HA HABIDO UN ERROR</p>}
                            
                            
                            
                        </Form>
                        
                        <div className="start-button-container">
                            <button className="btn" onClick={() =>navigate("/autopilot")}>
                                Atrás
                            </button>
                            <button className="botonComienza btn" type="button" onClick={handleGuardar} disabled={iban==null}>
                                Continuar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </AltaStyle>
    );
}