import styled from "styled-components";

const HomeStyle = styled.div`
  padding-right: 1.5rem;
  @media (max-width: 1040px) {
    padding-right: 0rem;
    padding-bottom: 1rem;
  }
  .home-container {
    @media (max-width: 1040px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      width: 100%;
      margin: 0 !important;
    }
    .popUp-wrapper {
      position: fixed;
      z-index: 150;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      .popUp-overlay {
        width: 100%;
        height: 100%;
        background-color: ${(props) =>
          props.theme.colors.background.overlayBackground};
        opacity: 0.5;
      }
      .popUp-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: auto;
        max-height: 100%;
        overflow: hidden;
        min-width: 50%;
        @media (max-width: 940px) {
          min-width: 85%;
        }
      }
    }
    .info-message-container {
      display: flex;
      flex-direction: column;
      padding-left: 0rem;
      @media (max-width: 1040px) {
        margin-bottom: 0rem;
        padding-right: 0rem;
      }
      .info-message {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        border-radius: 0.93rem;
        font-weight: 600;
        color: ${(props) => props.theme.colors.fontColor.defaultFontColor};
        margin-bottom: 1rem;
        .close-icon {
          stroke: ${(props) => props.theme.colors.background.defaultBackground};
          stroke-width: 0.125rem;
          height: 1rem;
          cursor: pointer;
        }
      }
      .blue-container {
        background-color: ${(props) =>
          props.theme.colors.background.primaryInfoMessage};
      }
      .red-container {
        background-color: ${(props) =>
          props.theme.colors.background.secondaryInfoMessage};
      }
      .yellow-container {
        background-color: ${(props) =>
          props.theme.colors.background.teritiaryInfoMessage};
      }
    }
    .no-info-message {
      display: none;
    }
    .home-inner-container {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      @media (max-width: 1040px) {
        width: 100%;
      }
      @media (max-width: 400px) {
        padding: 0rem;
      }
    }
    .responsive-optimization-container {
      @media (min-width: 1040px) {
        display: none;
      }
    }
    .optimization-container {
      @media (max-width: 1040px) {
        display: none;
      }
    }
  }
`;
export default HomeStyle;
