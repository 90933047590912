import styled from "styled-components";

const ChangeInfoStyle = styled.div`
.InfoCambiosContainer{
    overflow: auto;
}
.InfoCambiosTitulo{
    margin:0;
    padding-left: 5px;
}
.InfoCambiosGrande{
    font-weight: bold;
    padding-left: 10px;
}
.infoCambiosMensaje{
    margin-top:10px;
    margin-bottom: 10px;
}

.InfoCambiosVerde{
    padding-left: 10px;
    color:${(props) => props.theme.colors.fontColor.titleFontColor};
    font-weight: bold;
}

.heHechoCambiosBoton{
    margin:15px 5% 5px;
    width: 90%;
    padding: 5px 15px;
    border-radius: 18px;
    border-width: 0px;
    font-family: Asap;
    font-size: 16px;
    text-align: center;
    background-color: ${(props)=> props.theme.colors.button.background.teritiaryButton};
    color:${(props) => props.theme.colors.fontColor.defaultFontColor};
}

`
export default ChangeInfoStyle;