import styled from "styled-components";

const MicappitalEcoStyle = styled.div`
  padding-right: 1.5rem;
  @media (max-width: 1040px) {
    padding: 0;
  }
  .eco-container {
    width: 100%;
    padding: 0rem 0rem 2rem 0rem;
    display: flex;
    gap: 2%;
    margin-top: 1rem;
    @media (max-width: 1040px) {
      flex-direction: column-reverse;
      align-items: center;
      gap: 2rem;
    }
    .primary-section {
      width: calc(100% - 37%);
      display: flex;
      flex-direction: column;
      gap: 2rem;
      @media (max-width: 1040px) {
        width: 100%;
      }
    }
    .secondary-section {
      width: calc(100% - 65%);
      @media (max-width: 1040px) {
        width: 100%;
        max-width: 25rem;
      }
    }
  }
`;
export default MicappitalEcoStyle;
