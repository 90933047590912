import ImageConstants from "../../../../constants/imageConstants/image_constants";
import LeftContainerStyle from "./leftContainerStyle.js";

const LeftContainer = () => {
  return (
    <LeftContainerStyle>
      <div className="main-icon">
        <img className="icon-1" alt="x" src={ImageConstants.mainIcon} />
      </div>
      <div className="space-container"> </div>
      <div className="logIn-images">
        <div className="login-backgorund">
          <img className="initial-image" src={ImageConstants.logInBg} alt="x" />
        </div>
      </div>
    </LeftContainerStyle>
  );
};

export default LeftContainer;
