import React, { useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import defaultTheme from "../../../../theme/theme";
import Text from "../../../../theme/font";
import VideoStyle from "./videoStyle";
import ScrollBar from "../../../../components/scrollbar/scrollbar";

const Video = React.memo((props) => {
  const theme = defaultTheme;
  const [videoLink, setVideoLink] = useState({});
  const [process, setProcess] = useState([]);
  const [totalStep, setTotalStep] = useState([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (props?.video?.basicInfo && props?.video?.receta) {
      const totalData = props;
      const video = totalData?.video.basicInfo;
      const step = totalData?.video.receta;
      setTotalStep(step);
      if (step.length > 2) {
        const slicedData = step.slice(0, 2);
        setProcess(slicedData);
      } else {
        setProcess(step);
      }
      setVideoLink(video);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.video]);

  const stepHandler = () => {
    if (show) {
      const slice = totalStep.slice(0, 2);
      setProcess(slice);
    } else {
      setProcess([...totalStep]);
    }
    setShow(!show);
  };

  const videoUrl = videoLink.enlacevideo;
  const bankName = videoLink.nombre;

  return (
    <ThemeProvider theme={theme}>
      <VideoStyle>
        {videoUrl && (
          <div className="panelVideoContainer">
            <Text variant="primaryFont" className="videoTitle">
              Cómo comprar los fondos en {bankName}
            </Text>
            <div className="videoContainer">
              <div className="video-responsive">
                <iframe
                  width="853"
                  height="480"
                  src={videoUrl}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                />
              </div>
            </div>
          </div>
        )}

        <div
          className={
            videoUrl ? "stepMainContainer" : "stepMainContainer full-width"
          }
        >
          <Text variant="primaryFont" className="stepHeader">
            Guía detallada
          </Text>
          <ScrollBar max_height={"20rem"}>
            {process &&
              process.map((info, index) => {
                return (
                  <div className="stepContainer" key={Math.random()}>
                    <div className="stepDetails">
                      <Text variant="fieldFont" className="stepCount">
                        Paso {index}
                      </Text>
                      <Text variant="fieldFont" className="stepTitle">
                        {info.titulopaso}
                      </Text>
                    </div>

                    <Text variant="normal" className="stepDescription">
                      {info.descripcionpaso}
                    </Text>
                  </div>
                );
              })}
          </ScrollBar>
          <div className="seeMoreButtonContainer">
            <Text
              variant="fieldFont"
              className="seeMoreButton"
              onClick={stepHandler}
            >
              {show ? "Leer menos" : "Leer más..."}
            </Text>
          </div>
        </div>
      </VideoStyle>
    </ThemeProvider>
  );
});

export default Video;
