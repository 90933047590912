import { useEffect, useContext, useState, useRef } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { AlertContext } from "../../alertContainer";
import { AuthTokenService } from "../../../../services";
import Button from "../../../../components/button/button";
import { getTestData, postTestData } from "../accountSettingsServices";
import ScrollBar from "../../../../components/scrollbar/scrollbar";
import TestPopUpStyle from "./testPopUpStyle";
import TextField from "../../../../components/textField/textField";
import Text from "../../../../theme/font";

const TestPopUp = (props) => {
  const formRef = useRef(null);
  const [userData] = useState(
    props.datas && props.datas.mifid && props.datas.mifid.length > 0
      ? props.datas.mifid[0]
      : []
  );
  const [testData, setTestData] = useState([]);
  const [newValue, setNewValue] = useState({});
  const { open } = useContext(AlertContext);
  const validate = Yup.object({
    age: Yup.number().required("introduzca su edad"),
  });
  const userId = AuthTokenService.getUserId();

  useEffect(() => {
    open({
      alertType: "spinner",
      open: true,
    });

    getTestData(userId)
      .then((response) => {
        open({
          alertType: "spinner",
          open: false,
        });
        let totalData = response.data.mifid;
        setTestData(totalData);
        let nameData = {};
        // eslint-disable-next-line array-callback-return
        totalData.map((value) => {
          // eslint-disable-next-line array-callback-return
          value.respuestas.map((post) => {
            if (post.selected) {
              nameData[value.name] = post.value;
            }
          });
        });
        setNewValue({ ...nameData });
      })
      .catch(() => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkIfNumber = (event) => {
    const regex = new RegExp(
      /(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/
    );

    return !event.key.match(regex) && event.preventDefault();
  };

  //Used for Formik live validation
  const formValidationHandler = (conditionalValue, resultValue) => {
    let result = "";
    if (conditionalValue) {
      result = resultValue;
    }
    return result;
  };

  //for saving the changed data
  const submitHandler = (values) => {
    open({
      alertType: "spinner",
      open: true,
    });

    let changedData = newValue;
    changedData["idCliente"] = userId;
    changedData["edad"] = values.age;

    postTestData(changedData)
      .then(() => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "success",
          title: "Modificación realizada con éxito",
          alertType: "toast",
          open: true,
        });
        props.submited();
      })
      .catch((error) => {
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });
  };

  const selectFieldValues = () => {
    return testData.map((post) => {
      return (
        <div className="term-field" key={Math.random()}>
          <div className="label">
            <Text variant="labelFont">{post.titulo}</Text>
          </div>
          <div className="select-field-container">
            <select
              onChange={(e) => {
                let temp = { ...newValue };
                temp[post.name] = e.target.value;
                setNewValue({ ...temp });
              }}
              className="test-select-field"
              value={newValue[post.name]}
              name="test-details"
            >
              {post.respuestas.map((options) => {
                return (
                  <option
                    value={options.value}
                    selected={options.selected ? true : false}
                    key={Math.random()}
                  >
                    {options.value}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      );
    });
  };

  return (
    <TestPopUpStyle>
      <ScrollBar elementName={"card_header_container"} max_height={"100%"}>
        <div className="test-container">
          <div className="test-header">
            <div className="test-heading">
              <Text variant="titleFont">{props.data.title}</Text>
            </div>
            <div className="save-btn">
              <Button
                className="btn-div"
                value="Guardar datos"
                click={() => formRef.current.handleSubmit()}
              />
            </div>
          </div>
          <div className="test-description">
            <Text variant="normal">{props.data.description}</Text>
          </div>
          <Formik
            initialValues={{
              age: userData.edad,
            }}
            validationSchema={validate}
            innerRef={formRef}
            onSubmit={(values) => {
              submitHandler(values);
            }}
          >
            {({ values, errors, touched, handleChange, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <div className="form-field">
                  <div className="age-field">
                    <div className="label">Edad</div>
                    <TextField
                      type="number"
                      name="age"
                      style={{ paddingLeft: "1.5rem" }}
                      handleChange={handleChange}
                      value={values.age}
                      min={0}
                      onKeyDown={(evt) => checkIfNumber(evt)}
                      error={formValidationHandler(
                        touched.age,
                        Boolean(errors.age)
                      )}
                      helperText={formValidationHandler(
                        touched.age,
                        errors.age
                      )}
                    />
                  </div>
                  {selectFieldValues()}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </ScrollBar>
    </TestPopUpStyle>
  );
};

export default TestPopUp;
