import React, { useState, useEffect, useContext} from 'react';
import Select from 'react-select'
import AltaStyle from './altaStyle';
import { getLoginData, patchNR} from './procesoAltaServices';
import { useNavigate } from "react-router-dom";
import { Form,Row, Col } from "react-bootstrap";
import { ContainerContext } from '../../components/navbarContainer/containerProvider';
import { AuthTokenService } from '../../services';
import { patchIroniaData, getIroniaCities, getIroniaZipCodes } from '../investment/autopilot/autopilotServices';
import { countries } from './autopilotConstants';


export default function NuevoAutopilot(){
    const [error, setError]=useState(false);
    const [intento, setIntento]=useState(false);
    const [menuHidden] = useState(true);
    const navigate=useNavigate();
    const { changeMenuStatus, changeNavBarStatus, changeAlertStatus } =
    useContext(ContainerContext);
    const [inputValues, setInputValues] = useState({
        firstName: AuthTokenService.getNombre(),
        lastName: AuthTokenService.getApellidos(),
        secondLastName: '',
        birthday: '',
        birthdayCountry: ''
        // Agrega los nombres de los campos adicionales
    });

    const [nombre, setNombre]=useState(AuthTokenService.getNombre());
    const [apellido, setApellido]=useState(AuthTokenService.getApellidos());

    const nombreOriginal=AuthTokenService.getNombre();
   

    useEffect(() => {
        window.scrollTo(0, 0);
        changeMenuStatus(menuHidden);
        changeNavBarStatus(true);
        changeAlertStatus(false);
        return () => {
          changeMenuStatus(!menuHidden);
          changeNavBarStatus(false);
          changeAlertStatus(true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [changeMenuStatus, changeNavBarStatus]);

    const handleInputChange = (event) => {
        if(event.target.name=="firstName"){
            setNombre(event.target.value);
        }
        else if(event.target.name=="lastName"){
            setApellido(event.target.value);
        }
        setInputValues({
            ...inputValues,
            [event.target.name]: event.target.value,
        });
    };

 

    const handleCountryChange= (country) => {
        setInputValues({
            ...inputValues,
            ["birthdayCountry"]: country.value,
        });
    };



    const handleGuardar = (datos) => {//funcion que llama al patch con los datos de formularios
        
        var datos=inputValues;
        datos.idCliente=AuthTokenService.getUserId();

        
        patchIroniaData(datos)
            .then((result)=>{
                if (result.data.status==200){
                    navigate("/autopilot");
                }
                else{
                    setError(true);
                }
            });
        
      };


    return (
        <AltaStyle >
            <div className="bodyProcesoAlta">
                <div className="navbar navbar-expand-md navbar-light" style={{paddingLeft:'2.5rem'}}>
                    
                </div>
                <div className="process-popup container">
                    <div className="main-step-container">

                        <a className="navbar-brand">
                            <img height="40" src="https://micappital.com/assets/img/logo-micappital.png" alt="Micappital"></img>
                        </a>
                        
                    </div>
                    <div className="process-popup-inner-container">
                        <h3 className="process-heading mt-5 mb-4">
                            {nombreOriginal}, necesitamos que nos confirmes unos datos antes de comenzar el proceso de Autopilot
                        </h3>

                        <Form className="form-insurance-data">
                            <Row>
                                <Form.Group as={Col} xs={12} sm={4} className="mb-3" controlId="nombre">
                                    <Form.Label>Nombre:</Form.Label>
                                    <Form.Control className={(intento==true && inputValues.firstName == '') && 'campo-incompleto'} placeholder="" name="firstName" value={nombre} type="text" onChange={handleInputChange}/>
                                </Form.Group>
                                <Form.Group as={Col} xs={12} sm={4} className="mb-3" controlId="apellido1">
                                    <Form.Label>Primer apellido:</Form.Label>
                                    <Form.Control className={(intento==true && inputValues.lastName == '') && 'campo-incompleto'} placeholder="" name="lastName" value={apellido} type="text" onChange={handleInputChange}/>
                                </Form.Group>
                                <Form.Group as={Col} xs={12} sm={4} className="mb-3" controlId="apellido2">
                                    <Form.Label>Segundo apellido:</Form.Label>
                                    <Form.Control className={(intento==true && inputValues.secondLastName == '') && 'campo-incompleto'} placeholder="" name="secondLastName" type="text" onChange={handleInputChange}/>
                                </Form.Group>
                                

                            </Row>
                            <Row>
                                <Form.Group as={Col} xs={12} sm={6} className="mb-3" controlId="paisNacimiento">
                                    <Form.Label>País de nacimiento:</Form.Label>
                                    <Select  placeholder="Selecciona un país"  options={countries} onChange={handleCountryChange}/>
                                </Form.Group>
                                <Form.Group as={Col} xs={12} sm={6} className="mb-3" controlId="fechaNacimiento">
                                    <Form.Label>Fecha de nacimiento:</Form.Label>
                                    <Form.Control className={(intento==true && inputValues.fechaNacimiento == '') && 'campo-incompleto'} placeholder="Fecha de nacimiento" name="birthday" type="date" onChange={handleInputChange}/>
                                </Form.Group>
                               
                            </Row>
                            
                                
                            {error && <p>HA HABIDO UN ERROR</p>}
                            
                            
                            
                        </Form>

                        <div className="start-button-container">
                            <button className="botonComienza btn" onClick={handleGuardar}>
                                Continuar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </AltaStyle>
    );
}