import styled from "styled-components";

const DefaultScreenStyle = styled.div`
  width: 100%;
  display: flex;
  padding: 2rem 1.7rem 1rem 0rem;
  gap: 2%;
  @media (max-width: 1040px) {
    flex-wrap: wrap;
    padding: 2rem 0rem;
    flex-direction: column-reverse;
  }
  .primary-section {
    width: calc(100% - 37%);
    display: flex;
    flex-direction: column;
    gap: 2rem;
    @media (max-width: 1040px) {
      width: 100%;
    }
  }
  .secondary-section {
    width: calc(100% - 65%);
    @media (max-width: 1040px) {
      width: 100%;
      padding-bottom: 2rem;
      display: flex;
      justify-content: center;
    }
  }
`;

export default DefaultScreenStyle;
