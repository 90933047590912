const DropDown = () => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect
        x="28"
        y="28"
        width="28"
        height="28"
        transform="rotate(180 28 28)"
        fill="url(#pattern0)"
      />
      <defs>
        <pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#image0_15_3" transform="scale(0.0111111)" />
        </pattern>
        <image
          id="image0_15_3"
          width="90"
          height="90"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAAFBUlEQVR4nO2dS4tcRRTHCxJfRHTpE1TUL+BjpaKIUbIQdaGCL3xvDKgJknHnzphVCORrGDWLiH4ARUZiko0bjQxqT3f9T53TGnAGkik5Nw2CiW1P37p16nbXHw40w+17q35TXY9Tp851rqqqqqqqqqqqqqqqqmo2xRh3iPf3M/CGAIeE6DMBTgvwkwCBgU01/Tz52+nJNZ803/H+Pr3HjI9bLgG4lYH3GDguRGMhiq0MEAG+CMC7AG5xy6y1tbVrxt6/LERfM3ChNdz/MAbOM9FX4xBeimfPXu2WRcPh8FptaUL0W1dwp0AfMdFHIYTr3aIqxngFE33AAOUGfBngxET7tUxukRRCeEiAM9aALwFO9KMAj7m+S/tEBo4ysGUNdUrr3mKiIzHGq1wfxcy3M9G31iBldvtehsO7XJ+kP8ck0zTKbICw94+6PoiBZ5joL3NoNJ81iyGi513JEqK3u5wTSz7YF5joLVei2PundXFgDUkSwhai51xJ0n6NgQ1rOJIetvpUHnclaDwa3d3LgY+24TcRudMUss49m2mRNQzq3FZN59m6GCkAQsxhuqgxW1aXvOKT1KCBrRDCw1khxxh3CnDKuvKS24AzWR1R6oUzrzSZtez3s/mTGcASg4Yy6By0EB2wrqxYwyba3/n2ExOtG7WkDf0nnxsOb1TTz1aLJCb6vdNtsQC8YgR5M4Tw5L/LI0RPmDmwQnihM9C6kVoKZGvYDJxwXUi37XM7jfh/IFvCVqdTJ6EMGneRuSIbY6I9s5ZPr83eZwN7uwB9vLSWXEDL/tQlD9MCuGTIJrABThp+prFwpXQXY6I9s1yTrRvx/t5koJnozRJaskxa63auzVDu15KBnkR1FgFZWnyno7J/nA400eclQZayYKcbELtyifKMffK0/jbFPVoZcDIl6F9KaslSUssGfk4GOnUEKHcAxgo2Az4l6M2SIYshbO2SigPNGUDkhp0adOuugzMCyPwsX8xgyDY/6TzPTDkYtpneWU6/OMezk07v9Bzf/BU+YAF5O7AlhJVSFixzL8F1f8/Z+yOmdiN/rq/fUMQSvDmRmhi0ZPYdT4Ptvb+5xX1fLcNNGsJKEbshU7oRAT6c+56j0T2pHf8yb0uSEFbOjUY3qelny1jq5tmXlme+dQIQkp8717PVVnCkXEu7laWaHCu2rlgsyrx/JzloHTAW6YyKtDRlMW1G1UqaJcC6glKIdRZAo5qkfTCvpJRgXYaEaWCfRfoHKcyYaKABn65LLXMQuvwDep/rWoPBYJe6BpcWMjIFoqs0ENu6wmJkOs11mQ8L/WBdaVn0w0KqEMKD9fhbJukhR/NWRtnssDM+orxaAITYpTHRdzHGK52l9ED6vJ496YMBzMx3uBLE3j/S56wzMj2NxG5XkoL3Ty1gYpRnXYnS9DgLkurnvMaEu5LVpPzpcTfCF3d/ykrxMy31Ty8HSIB1vHF9UgjhNib6xhwezWyr5il92syzdVHDBSdQmZTtsPk8OdVyXUpMpAKc+oPoAbdIijHu1KQiJbhYtQzqhdMyuUXVYDDYNdlR/zU7YKKhJuoGcJ1bFkXdFgvhRQa+7HKh08yJgRO6x7dUqecvpyZiCNgrRMeat1G073v1Hsc07qKzkIC+K8a4Q+PZGHhdgIMaDaQxyPoqkCZl/OT1IM0phIuvBzk5ueagnmLV79bXg1RVVVVVVVVVVVVVVbnZ9TfSbVLof4Iu8QAAAABJRU5ErkJggg=="
        />
      </defs>
    </svg>
  );
};

export default DropDown;
