import Button from "../../../../components/button/button";
import SimulateSectioStyle from "./simulateSectionStyle";
import Text from "../../../../theme/font";

const SimulateSection = ({ showPopUp }) => {
  return (
    <SimulateSectioStyle>
      <div className="simulate-container">
        <Text variant="titleFont" type="primary" className="simulation-title">
          Nos encargamos de que siempre tengas el seguro que mejor se adapte a ti
        </Text>
        <Text
          variant="normal"
          type="primary"
          className="simulation-primary-description"
        >
          Explícanos que seguros tienes o cuales quieres contratar y lo analizamos en detalle.
        </Text>
        <Text
          variant="smallerText"
          type="primary"
          className="simulation-secondary-description"
        >
          Paga por lo que de verdad necesitas. Menos precio y más coberturas.
        </Text>
        <Text variant="labelFont" className="begin-button-container">
          <Button
            className="begin-button"
            value="Comenzar"
            click={() => showPopUp(true)}
          />
        </Text>
      </div>
    </SimulateSectioStyle>
  );
};

export default SimulateSection;
