import styled from "styled-components";

const MicappitalZeroStyle = styled.div`
  width: 100%;
  display: flex;
  padding: 1rem 1.7rem 1rem 0rem;
  gap: 2%;
  @media (max-width: 1040px) {
    flex-wrap: wrap;
    padding: 1rem 0rem;
    flex-direction: column-reverse;
  }
  .primary-section {
    flex: 0 63%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    @media (max-width: 1040px) {
      flex: 100%;
    }
  }
  .secondary-section {
    flex: 0 35%;
    @media (max-width: 1040px) {
      flex: 100%;
      padding-bottom: 2rem;
      display: flex;
      justify-content: center;
    }
  }
`;
export default MicappitalZeroStyle;
