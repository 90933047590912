import React, { useState } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import Button from "../../../../components/button/button";
// import { demoData } from "../../../../models/insuranceListArray";
import ImageConstants from "../../../../constants/imageConstants/image_constants";
import ProcessPopUpChildStyle from "./processPopUpChildStyle";
// import SelectField from "../../../../components/selectField/selectField";
import Text from "../../../../theme/font";
import TextField from "../../../../components/textField/textField";
import moment from "moment";

export const ProcessPopupOne = React.memo(({ submitClick }) => {
  const clickHandler = () => {
    submitClick(false);
  };

  return (
    <ProcessPopUpChildStyle>
      <div className="main-popup-container">
        <div className="process-popup-inner-container">
          <div className="top-image-container">
            <img
              alt="x"
              src={ImageConstants.processImage}
              className="process-image"
            />
          </div>
          <Text variant="titleFont" className="process-heading">
            Bienvenido al proceso de Micappital Seguros
          </Text>
          <Text variant="fieldFont" className="process-description">
            Vamos a necesitar unos datos para poder asesorarte de la mejor forma
            posible
          </Text>
          <Text variant="fieldFont" className="time-estimation">
            Tiempo estimado: 2 minutos
          </Text>
          <Text variant="labelFont" className="start-button-container">
            <Button
              value="Entendido, comencemos"
              className="start-process-button"
              click={() => clickHandler()}
            />
          </Text>
        </div>
      </div>
    </ProcessPopUpChildStyle>
  );
});

export const ProcessPopUpTwo = React.memo(({ buttonClick, step }) => {
  const clickHandler = () => {
    buttonClick("3");
    step("3");
  };

  const backButtonHandler = () => {
    buttonClick("1");
    step("1");
  };

  return (
    <ProcessPopUpChildStyle>
      <div className="main-popup-container">
        <div className="process-popup-inner-container">
          <div className="top-image-container">
            <img
              alt="x"
              src={ImageConstants.processImage}
              className="process-image"
            />
          </div>
          <Text variant="titleFont" className="process-heading">
            Recuerda, somos independientes y estamos regulados
          </Text>
          <Text variant="fieldFont" className="process-description">
            Micappital está regulada en la DGS (J-4108) y nuestro objetivo es
            que tú como cliente mejores tu seguro, ya sea en precio o en
            coberturas o en ambas cosas
          </Text>
        </div>
        <div className="bottom-button-container">
          <Text
            variant="popUpText"
            className="return-text"
            onClick={() => backButtonHandler()}
          >
            Volver
          </Text>
          <div className="continue-icon-button" onClick={() => clickHandler()}>
            <img
              alt="x"
              src={ImageConstants.continueIcon}
              className="continue-icon"
            />
          </div>
        </div>
      </div>
    </ProcessPopUpChildStyle>
  );
});

export const ProcessPopUpThree = React.memo(
  ({
    buttonClick,
    submitClick,
    check,
    select,
    selectedState,
    checkStep,
    step,
  }) => {
    const [primaryState, setPrimaryState] = useState(false);
    const [secondaryState, setSecondaryState] = useState(false);

    const primaryHandler = () => {
      buttonClick("7");
      check(true);
      checkStep(false);
      setPrimaryState(false);
    };

    const secondaryHandler = () => {
      buttonClick("7");
      checkStep(true);
      check(false);
      setSecondaryState(false);
    };

    const submitClickHandler = (value, text) => {
      select(value);
      step("4");
      setTimeout(() => submitClick(value, text), 500);
      if (value === "1") {
        setPrimaryState(true);
        setTimeout(() => primaryHandler(), 500);
      } else {
        setSecondaryState(true);
        setTimeout(() => secondaryHandler(), 500);
      }
    };

    return (
      <ProcessPopUpChildStyle>
        <div className="main-popup-container">
          <div className="process-popup-inner-container">
            <div className="top-image-container">
              <img
                alt="x"
                src={ImageConstants.helpImage}
                className="top-image"
              />
            </div>
            <Text variant="titleFont" className="process-heading margin-bottom">
              ¿Cómo podemos ayudarte?
            </Text>
            <div className={selectedState === "1" ? "selected" : ""}>
              <Text
                variant="labelFont"
                className={
                  primaryState
                    ? "insurance-button-container selected-insurance"
                    : "insurance-button-container"
                }
                onClick={() => submitClickHandler("1", "nuevo")}
              >
                <img
                  alt="x"
                  src={ImageConstants.selected}
                  className={
                    selectedState === "1"
                      ? "selected-icon"
                      : "not-selected-icon"
                  }
                />
                <Text variant="primaryFont" className="option-title">
                  Necesito registrar un seguro existente
                </Text>
                <Text variant="fieldFont" className="option-description">
                  Te ayudamos a darle seguimiento y ver alternativas y mejoras
                </Text>
              </Text>
            </div>
            <div className={selectedState === "0" ? "selected" : ""}>
              <Text
                variant="labelFont"
                className={
                  secondaryState
                    ? "insurance-button-container selected-insurance"
                    : "insurance-button-container"
                }
                onClick={() => submitClickHandler("0", "nuevo")}
              >
                <img
                  alt="x"
                  src={ImageConstants.selected}
                  className={
                    selectedState === "0"
                      ? "selected-icon"
                      : "not-selected-icon"
                  }
                />
                <Text variant="primaryFont" className="option-title">
                  Necesito un seguro nuevo
                </Text>
                <Text variant="fieldFont" className="option-description">
                  Tengo una situación nueva que quiero asegurar (Casa nueva,
                  coche nuevo)
                </Text>
              </Text>
            </div>
          </div>
        </div>
      </ProcessPopUpChildStyle>
    );
  }
);

export const ProcessPopUpFour = React.memo(
  ({
    buttonClick,
    submitClick,
    check,
    data,
    isNewUser,
    step,
    updateHandler,
  }) => {
    const [insuranceName, setInsuranceName] = useState("");
    const [showText, setShowText] = useState(false);
    const insuranceOptions = data;

    const clickHandler = (name) => {
      updateHandler();
      if (name === "") {
        setShowText(true);
      } else {
        submitClick(name, "tipo");
        if (check) {
          buttonClick("5");
          step("6");
        } else {
          buttonClick(name);
          step("6");
        }
      }
    };

    const backButtonHandler = (value) => {
      if (isNewUser) {
        buttonClick("7");
        step("4");
      } else {
        buttonClick("3");
        step("3");
      }
    };

    const insuranceClickHandler = (name) => {
      setInsuranceName(name);
      setShowText(false);
    };

    const selectedInsuranceHandler = (value) => {
      if (insuranceName === value) {
        return "insurance-image-container selected-goal ";
      } else {
        return "insurance-image-container";
      }
    };

    const selectedInsuranceIcon = (value) => {
      if (insuranceName === value) {
        return "selected-insurance-image";
      } else {
        return "insurance-image";
      }
    };
    const selectedInsuranceText = (value) => {
      if (insuranceName === value) {
        return "selected-insurance-name";
      } else {
        return "insurance-name";
      }
    };
    return (
      <ProcessPopUpChildStyle>
        <div className="main-popup-container">
          <div className="responsive-inner-container">
            <Text variant="titleFont" className="process-heading">
              ¿Qué tipo de seguro te interesa?
            </Text>
            <Text variant="fieldFont" className="process-description">
              Selecciona uno, si tienes varios podrás repetir el proceso para
              los demás
            </Text>
            <div className="select-insurance-container">
              {insuranceOptions &&
                insuranceOptions.map((value) => {
                  return (
                    <div
                      className={selectedInsuranceHandler(value.tipo)}
                      key={Math.random()}
                      onClick={() => insuranceClickHandler(value.tipo)}
                    >
                      <img
                        alt="x"
                        src={value.icono}
                        className={selectedInsuranceIcon(value.tipo)}
                      />
                      <Text
                        variant="tableValue"
                        className={selectedInsuranceText(value.tipo)}
                      >
                        {value.text}
                      </Text>
                    </div>
                  );
                })}
            </div>
            {showText && (
              <Text variant="smallFont" className="warning-text">
                *Por favor seleccione una opción
              </Text>
            )}
          </div>
          <div className="bottom-button-container">
            <Text
              variant="popUpText"
              className="return-text"
              onClick={() => backButtonHandler()}
            >
              Volver
            </Text>
            <div
              className="continue-icon-button"
              onClick={() => clickHandler(insuranceName)}
            >
              <img
                alt="x"
                src={ImageConstants.continueIcon}
                className="continue-icon"
              />
            </div>
          </div>
        </div>
      </ProcessPopUpChildStyle>
    );
  }
);

export const ProcessPopUpFive = React.memo(
  ({
    buttonClick,
    policyData,
    policyText,
    companyName,
    submitClick,
    step,
    goal,
  }) => {
    const validate = Yup.object({
      // comments: Yup.string().required("por favor ingrese el comentario"),
      files: Yup.mixed().required("Se necesita un archivo"),
    });

    const fileSubmitHandler = (values) => {
      submitClick();
      buttonClick("6");
      step("7");
    };

    const skipHandler = () => {
      buttonClick(goal);
      step("6");
    };

    const changeHandler = (event, setFieldValue) => {
      if (event.currentTarget.files && event.currentTarget.files.length > 0) {
        let images = null;
        images = Array.from(event.currentTarget.files);
        images && setFieldValue("files", images);
        policyData(images);
      } else {
        setFieldValue("files", "");
      }
    };

    // const companyNameHandler = (e, setFieldValue) => {
    //   companyName(e.target.value);
    //   setFieldValue("company", e.target.value);
    // };

    const changeTextHandler = (event, setFieldValue) => {
      policyText(event.target.value);
      setFieldValue("comments", event.target.value);
    };

    const backButtonHandler = () => {
      buttonClick("4");
      step("5");
    };

    //Used for Formik live validation
    // const formValidationHandler = (conditionalValue, resultValue) => {
    //   let result = "";
    //   if (conditionalValue) {
    //     result = resultValue;
    //   }
    //   return result;
    // };

    return (
      <ProcessPopUpChildStyle>
        <Formik
          initialValues={{ files: "", comments: "" }}
          onSubmit={(values) => {
            fileSubmitHandler(values);
          }}
          validationSchema={validate}
        >
          {({
            values,
            handleSubmit,
            handleChange,
            setFieldValue,
            submitForm,
            errors,
            touched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="main-popup-container">
                <div className="popup-inner-container">
                  <Text variant="titleFont" className="process-heading">
                    Adjunta tu póliza
                  </Text>
                  <Text variant="fieldFont" className="process-description">
                    Para poder mejorarte los seguros, adjunta el detalle actual
                    de tus pólizas y danos más pistas de lo que quieres.
                  </Text>
                  <Text variant="fieldFont" className="process-description">
                    Mándanos lo que tengas como por ejemplo, póliza actual,
                    recibo, condiciones generales, cuadro de coberturas actual,
                    etc
                  </Text>
                  <div className="data-container">
                    <div className="file-upload-container">
                      <input
                        className="file-upload"
                        type="file"
                        name="files"
                        accept=".pdf,.jpg,.jpeg,.docx,.png"
                        multiple="multiple"
                        onChange={(event) => {
                          changeHandler(event, setFieldValue);
                        }}
                        required
                      />
                      {errors.files && touched.files && (
                        <Text variant="smallFont" className="error-text">
                          *Por favor seleccione un archivo
                        </Text>
                      )}
                    </div>
                    {/* <div className="select-field-section">
                      <div className="select-field-container">
                        <SelectField
                          variant="primary"
                          data={demoData}
                          value={values.company}
                          handleChange={(e) =>
                            companyNameHandler(e, setFieldValue)
                          }
                          placeholder="¿De qué compañía es el seguro?"
                          name="company"
                          error={formValidationHandler(
                            touched.company,
                            Boolean(errors.company)
                          )}
                          helperText={formValidationHandler(
                            touched.company,
                            errors.company
                          )}
                        />
                      </div>
                    </div> */}
                    <div className="text-area-container">
                      <textarea
                        className="secondary-text-area"
                        placeholder="Comentarios que nos ayuden…"
                        value={values.comments}
                        name="comments"
                        onChange={(e) => changeTextHandler(e, setFieldValue)}
                      />
                      {errors.comments && touched.comments && (
                        <Text variant="smallFont" className="error-text">
                          Por favor introduzca un comentario
                        </Text>
                      )}
                    </div>
                  </div>
                </div>
                <div className="bottom-button-container">
                  <Text
                    variant="popUpText"
                    className="return-text"
                    onClick={() => backButtonHandler()}
                  >
                    Volver
                  </Text>
                  <Text
                    variant="popUpText"
                    className="return-text"
                    onClick={() => skipHandler()}
                  >
                    Omitir
                  </Text>
                  <div
                    className="continue-icon-button"
                    onClick={() => submitForm()}
                  >
                    <img
                      alt="x"
                      src={ImageConstants.continueIcon}
                      className="continue-icon"
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </ProcessPopUpChildStyle>
    );
  }
);

export const ProcessPopUpSix = React.memo(
  ({ click, close, closesProcess, buttonClick, step, reDoProcess }) => {
    const returnHandler = () => {
      reDoProcess(true);
      buttonClick("3");
      step("3");
    };

    const clickHandler = () => {
      if (close) {
        closesProcess();
      } else {
        click("close");
      }
    };

    return (
      <ProcessPopUpChildStyle>
        <div className="main-popup-container">
          <div className="process-popup-inner-container">
            <Text variant="titleFont" className="process-heading">
              ¡Genial! Todo correcto
            </Text>
            <Text variant="fieldFont" className="process-description">
              Nuestro equipo acaba de recibir tu solicitud y ya estamos
              buscándote el mejor seguro para ti
            </Text>
            <Text variant="fieldFont" className="process-description">
              Te vamos a contactar en las próximas horas para hacerte nuestra
              recomendación
            </Text>
            <div className="button-wrapper">
              <div className="return-button-container">
                <Button
                  value="Dar de alta otro seguro"
                  className="return-button"
                  click={returnHandler}
                />
              </div>
              <div className="final-button-container">
                <Button
                  value="Finalizar"
                  className="final-button"
                  click={clickHandler}
                />
              </div>
            </div>
          </div>
        </div>
      </ProcessPopUpChildStyle>
    );
  }
);

export const ProcessPopUpSeven = React.memo(
  ({
    buttonClick,
    isNewUser,
    step,
    initialDatas,
    submitClick,
    insuranceId,
  }) => {
    const validate = Yup.object({
      name: Yup.string().max(40).required("*Se requiere nombre "),
      surName: Yup.string().required("*Los apellidos son necesarios "),
      dni: Yup.string().required("*Se requiere DNI"),
      phone: Yup.string()
        .min(9, "*el teléfono debe tener al menos 9 caracteres")
        .max(15, "*El teléfono no puede tener más de 12 cifras")
        .required("*El teléfono es necesario"),
      date: Yup.string().required("*Falta la fecha de nacimiento "),
      email: Yup.string()
        .email("*El correo electrónico no es válido")
        .required("*Se requiere correo "),
      postCode: Yup.string().required("*Se requiere el código postal "),
    });
    const informationData = initialDatas && initialDatas;

    const checkIfNumber = (event) => {
      const regex = new RegExp(
        /(^(\+)?\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/
      );
      return !event.key.match(regex) && event.preventDefault();
    };

    const onBlurHandler = (e) => {
      if (e.target.value !== "") {
        e.target.type = "date";
      } else {
        e.target.type = "text";
      }
    };
    const clickHandler = (value) => {
      buttonClick("4");
      isNewUser(true);
      step("5");
      const dataInformation = {
        idClienteSeguros: insuranceId,
        nombre: value.name,
        apellidos: value.surName,
        dni: value.dni,
        telefono: value.phone,
        correo: value.email,
        fecha_nacimiento: moment(value.date).format("YYYY-MM-DD"),
        codigo_postal: value.postCode,
      };
      submitClick(dataInformation);
    };

    const backButtonHandler = (value) => {
      buttonClick(value);
      isNewUser(false);
      step("3");
    };

    //Used for Formik live validation
    const formValidationHandler = (conditionalValue, resultValue) => {
      let result = "";
      if (conditionalValue) {
        result = resultValue;
      }
      return result;
    };

    return (
      <ProcessPopUpChildStyle>
        <Formik
          initialValues={{
            name: informationData?.nombre ? informationData.nombre : "",
            surName: informationData?.apellidos ? informationData.apellidos : "",
            dni: informationData?.dni ? informationData.dni : "",
            date: informationData?.fecha_nacimiento
              ? informationData.fecha_nacimiento
              : "",
            postCode: informationData?.codigo_postal
              ? informationData.codigo_postal
              : "",
            phone: informationData?.telefono ? informationData.telefono : "",
            email: informationData?.correo ? informationData.correo : "",
          }}
          onSubmit={(values) => {
            clickHandler(values);
          }}
          validationSchema={validate}
        >
          {({
            values,
            handleSubmit,
            handleChange,
            setFieldValue,
            submitForm,
            errors,
            touched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="main-popup-container">
                <div className="popup-inner-container">
                  <Text variant="titleFont" className="process-heading">
                    Antes de nada, confírmanos tus datos
                  </Text>
                  <div className="form-field">
                    <div className="common-container">
                      <div className="first-name-field text-field">
                        <TextField
                          form="insurance-field"
                          placeholder="Nombre"
                          name="name"
                          type="text"
                          autoComplete="new-password"
                          handleChange={handleChange}
                          value={values.name}
                          error={formValidationHandler(
                            touched.name,
                            Boolean(errors.name)
                          )}
                          helperText={formValidationHandler(
                            touched.name,
                            errors.name
                          )}
                        />
                      </div>
                      <div className="surname-field text-field">
                        <TextField
                          form="insurance-field"
                          placeholder="Apellidos"
                          name="surName"
                          autoComplete="new-password"
                          handleChange={handleChange}
                          value={values.surName}
                          error={formValidationHandler(
                            touched.surName,
                            Boolean(errors.surName)
                          )}
                          helperText={formValidationHandler(
                            touched.surName,
                            errors.surName
                          )}
                        />
                      </div>
                    </div>

                    <div className="common-container">
                      <div className="dni-field text-field">
                        <TextField
                          form="insurance-field"
                          placeholder="DNI/NIE"
                          name="dni"
                          autoComplete="new-password"
                          handleChange={handleChange}
                          value={values.dni}
                          error={formValidationHandler(
                            touched.dni,
                            Boolean(errors.dni)
                          )}
                          helperText={formValidationHandler(
                            touched.dni,
                            errors.dni
                          )}
                        />
                      </div>
                      <div className="phone-field text-field">
                        <TextField
                          form="insurance-field"
                          placeholder="Teléfono"
                          name="phone"
                          type="text"
                          value={values.phone}
                          autoComplete="new-password"
                          handleChange={handleChange}
                          error={formValidationHandler(
                            touched.phone,
                            Boolean(errors.phone)
                          )}
                          helperText={formValidationHandler(
                            touched.phone,
                            errors.phone
                          )}
                          onKeyDown={(evt) => checkIfNumber(evt)}
                        />
                      </div>
                    </div>

                    <div className="common-container">
                      <div className="dob-field text-field">
                        <TextField
                          form="insurance-field"
                          placeholder="Fecha de nacimiento"
                          name="date"
                          max="9999-12-31"
                          handleChange={handleChange}
                          value={values.date}
                          error={formValidationHandler(
                            touched.date,
                            Boolean(errors.date)
                          )}
                          helperText={formValidationHandler(
                            touched.date,
                            errors.date
                          )}
                          onFocus={(e) => (e.target.type = "date")}
                          onBlur={(e) => onBlurHandler(e)}
                          type={values.date ? "date" : "text"}
                        />
                      </div>
                      <div className="email-field text-field">
                        <TextField
                          form="insurance-field"
                          placeholder="Correo"
                          name="email"
                          type="email"
                          value={values.email}
                          autoComplete="new-password"
                          handleChange={handleChange}
                          error={formValidationHandler(
                            touched.email,
                            Boolean(errors.email)
                          )}
                          helperText={formValidationHandler(
                            touched.email,
                            errors.email
                          )}
                        />
                      </div>
                    </div>
                    <div className="post-field">
                      <div className="post-code-field text-field">
                        <TextField
                          form="insurance-field"
                          placeholder="Código postal"
                          name="postCode"
                          autoComplete="new-password"
                          handleChange={handleChange}
                          value={values.postCode}
                          error={formValidationHandler(
                            touched.postCode,
                            Boolean(errors.postCode)
                          )}
                          helperText={formValidationHandler(
                            touched.postCode,
                            errors.postCode
                          )}
                          onKeyDown={(evt) => checkIfNumber(evt)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bottom-button-container">
                  <Text
                    variant="popUpText"
                    className="return-text"
                    onClick={() => backButtonHandler("3")}
                  >
                    Volver
                  </Text>
                  <div
                    className="continue-icon-button"
                    onClick={() => submitForm()}
                  >
                    <img
                      alt="x"
                      src={ImageConstants.continueIcon}
                      className="continue-icon"
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </ProcessPopUpChildStyle>
    );
  }
);
