import axios from "axios";
import AuthTokenService from "./authTokenService";

const request = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: { "Content-Type": "application/json" },
});
request.interceptors.request.use(
  (config) => {
    // Get token and add it to header "Authorization"

    const token = AuthTokenService.getAccessToken();
    if (token) {
      config.headers.Tokenmicappital = token;
      // config.headers.Tokenmicappital =
      // "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0eXBlIjoiYWNjZXNzIiwidXNlcm5hbWUiOiJtaWNhcHBpdGFsLXJlYWN0IiwidGltZXN0YW1wIjoxNjY4MDgzMTc1fQ.nRra2zPZHb9GoloXyY_mAifyTrV_rDDtcu_p-0A4f-s";
    } else {
      var pathname = window.location.pathname.split("/")[1];
      if (
        window.location.pathname !== "/" &&
        window.location.pathname !== "/recuperarClaves" &&
        pathname !== "password"
      ) {
        window.location.replace("/");
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

request.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      AuthTokenService.clearToken();

      window.location.replace("/");
    }

    // if (
    //   error.response.status === 401 &&
    //   originalRequest.url ===
    //     process.env.REACT_APP_BASE_URL + "/api/user/token/"
    // ) {
    //   return Promise.reject(error);
    // }

    // if (error.response.status === 401 && !originalRequest._retry) {
    //   originalRequest._retry = true;
    //   const refreshToken = AuthTokenService.getRefreshToken();
    //   return axios
    //     .post(process.env.REACT_APP_BASE_URL + "/api/user/refresh_token/", {
    //       refresh_token: refreshToken,
    //       device_uuid: "119.121.1.1:3455",
    //     })
    //     .then((res) => {
    //       if (res.status === 200) {
    //         AuthTokenService.setToken(res.data);
    //         axios.defaults.headers.common["Authorization"] =
    //           AuthTokenService.getAccessToken();
    //         return axios(originalRequest);
    //       } else {
    //         return null;
    //       }
    //     })
    //     .catch((error) => {
    //       AuthTokenService.clearToken();
    //       localStorage.clear();
    //       window.location.href = process.env.REACT_APP_BASE_URL;
    //       return null;
    //     });
    // }
    return Promise.reject(error);
  }
);

export default request;
