import styled from "styled-components";

const AdviceSectionStyle = styled.div`
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  .advice-container-heading {
    font-weight: bold;
    padding-left: 0.6rem;
    color: ${(props) => props.theme.colors.fontColor.titleFontColor};
    @media (max-width: 540px) {
      text-align: center;
    }
  }
  .investment-advice-container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-around;
    .advice-options-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-height: 22rem;
      min-width: 13rem;
      flex: 0 1 30%;
      padding: 3rem 1rem;
      background-color: ${(props) =>
        props.theme.colors.background.optionsCardBackground};
      border-radius: 2.18rem;
      .option-icon {
        margin-bottom: 2rem;
        img{
          width: 4.75rem;
        }
        
      }
      .option-title {
        font-weight: 500;
        text-align: center;
        padding-bottom: 1rem;
        color: ${(props) => props.theme.colors.fontColor.descriptionFontColor};
      }
      .option-description {
        text-align: center;
        color: ${(props) => props.theme.colors.fontColor.descriptionFontColor};
      }
    }
  }
  .advice-container-description {
    padding-left: 0.6rem;
    color: ${(props) => props.theme.colors.fontColor.descriptionFontColor};
  }
`;
export default AdviceSectionStyle;
