import React, { useEffect, useState, useContext } from "react";
import AutopilotStyle from "./autopilotStyle";
import { useLocation } from "react-router-dom";
import { AlertContext } from "../../common/alertContainer";
import { AuthTokenService } from "../../../services";
import AutopilotStep from "./autopilotStep/autopilotStep";
import { ContainerContext } from "../../../components/navbarContainer/containerProvider";
import { patchIroniaData, getIroniaData, postEnviarDatosClienteIronia, postCrearContratoIronia, postFirmarContratoIronia, postMensajeAutopilot, postTerminarAltaAutopilot} from "./autopilotServices";
import Text from "../../../theme/font";
import { postCardResponse } from "../../common/accountSettings/accountSettingsServices";
import { useNavigate } from "react-router-dom";
import IroniaMessageStyle from "../../common/alertContainer/ironiaMessage/ironiaMessageStyle";
import ImageConstants from "../../../constants/imageConstants/image_constants";


const Autopilot = React.memo(() => {
  const [additionalData, setAdditionalData] = useState(false);
  const [dni, setDni] = useState(false);
  const [incomeProof, setIncomeProof] = useState(false);
  const [plan, setPlan] = useState(false);
  const [contract, setContract] = useState(false);
  const [funds, setFunds] = useState(false);
  const [showPopUp, setShowPopUp]= useState(false);
  const [disabledContract, setDisabledContract] = useState(true);
  const [disabledFunds, setDisabledFunds] = useState(true);
  const [hiddenFinalStep, setHiddenFinalStep]= useState(false);
  const [needChanges, setNeedChanges]= useState(false); 
  const [urlContract, setUrlContract] = useState(null);
  const { open } = useContext(AlertContext);
  const { titleHandler, pageNameHandler, headerTitleHandler, changeAlertStatus } =
    useContext(ContainerContext);
  const nombre = AuthTokenService.getNombre();
  const { search } = useLocation();
  const navigate=useNavigate();
  const [valorInversion, setvalorInversion] = useState(0);
  const [banco, setBanco] = useState(null);

  

  useEffect(() => {
    window.scrollTo(0, 0);
    changeAlertStatus(false);
    open({
      alertType: "spinner",
      open: true,
    });
    toogleNavBar();

    const userId = AuthTokenService.getUserId();
  
    const query = new URLSearchParams(search);
    const firmaOK = query.get("contratoFirmado");
    const fondosOK=query.get("fondosComprados");
    const cambiosOK=query.get("cambiosRealizados");
    //Get url parameters if they are there
    const pagoKO = query.get("pagoKO");

    const Ds_SignatureVersion = query.get("Ds_SignatureVersion");
    const Ds_MerchantParameters = query.get("Ds_MerchantParameters");
    const Ds_Signature = query.get("Ds_Signature");
    if (firmaOK && firmaOK!="false") {
      var datos={idCliente:userId, contratoFirmado:1};
      patchIroniaData(datos).then((response)=>{
        if(response.data.cambio){
            var mensaje={idCliente:userId, texto:"ha firmado el contrato"};
            postMensajeAutopilot(mensaje);
        }
        cargarDatos(userId);
      });
    }
    else if (fondosOK && fondosOK!="false") {
      var datos={idCliente:userId, fondosComprados:1};
      patchIroniaData(datos).then((response)=>{
        if(response.data.cambio){
            var mensaje={idCliente:userId, texto:"ha comprado los fondos"};
            postMensajeAutopilot(mensaje);
        }
        cargarDatos(userId);
      });
      var datos2={idCliente:userId, cambios:0};
      postTerminarAltaAutopilot(datos2);
    }
    else if (cambiosOK && cambiosOK!="false") {
      var datos={idCliente:userId, fondosComprados:1};
      patchIroniaData(datos).then((response)=>{
        if(response.data.cambio){
            var mensaje={idCliente:userId, texto:"ha hecho los cambios"};
            postMensajeAutopilot(mensaje);
        }
        cargarDatos(userId);
      });
      var datos2={idCliente:userId, cambios:1};
      postTerminarAltaAutopilot(datos2);
    }
    else if (
      Ds_SignatureVersion != null &&
      Ds_MerchantParameters != null &&
      Ds_Signature != null &&
      !pagoKO
    ) {
      let formData = new FormData();
      formData.append("idCliente", AuthTokenService.getUserId());
      formData.append("Ds_SignatureVersion", Ds_SignatureVersion);
      formData.append("Ds_MerchantParameters", Ds_MerchantParameters);
      formData.append("Ds_Signature", Ds_Signature);
      formData.append("omitirWarning", 1);
      postCardResponse(formData)
        .then((response) => {
          console.log("Tarjeta guardada");
          cargarDatos(userId);
        })
        .catch(() => {
          open({
            alertType: "spinner",
            open: false,
          });
        });
    }
    else{
      cargarDatos(userId);
    }
    
    
    
  }, []);

  const cargarDatos = (userId) =>{
    getIroniaData(userId)
      .then((response) => {
        if(response==null){
          navigate("/resumen");
        }
        else{
          if(response.data.birthday==null || response.data.birthday=="0000-00-00"){
            navigate("/nuevoAutopilot");
          }
          else{
            open({
              alertType: "spinner",
              open: false,
            });
            setvalorInversion(response.data.valor);
            setBanco(response.data.banco);
            setAdditionalData(response.data.economicActivity!=null);
            setDni(response.data.idFront!=null);
            setIncomeProof(response.data.proofOfIncome!=null);
            setPlan(response.data.plan!=null && response.data.tokenTarjeta!=null);
            setContract(response.data.contratoFirmado!=0);
            setFunds(response.data.fondosComprados!=0);
            setShowPopUp(response.data.fondosComprados!=0);
            setNeedChanges(response.data.necesitaCambios);
            if(response.data.economicActivity!=null && response.data.idFront!=null && response.data.proofOfIncome!=null && response.data.plan!=null){
              if(response.data.patchEnviado==0){
                open({
                  alertType: "spinner",
                  open: true,
                });
                var datos={idCliente:userId};
                postEnviarDatosClienteIronia(datos)
                  .then((result)=>{
                      if (result.data.status==200){
                        postCrearContratoIronia(datos)
                        .then((result2)=>{
                          if(result2.data.status==200){
                            postFirmarContratoIronia(datos)
                            .then((result3)=>{
                              if(result3.data.status==200){
                                if(result3.data.url.startsWith('http://') || result3.data.url.startsWith('https://')){
                                  setUrlContract(result3.data.url);
                                  setDisabledContract(false);
                                  var mensaje={idCliente:userId, texto:"ha creado el contrato"};
                                  postMensajeAutopilot(mensaje);
                                }
                                else{
                                  var mensaje={idCliente:userId, texto:"ha dado un error al crear el contrato"};
                                  postMensajeAutopilot(mensaje);
                                }
                                open({
                                  alertType: "spinner",
                                  open: false,
                                });
                              }
                            });
                          }
                          else{
                            var mensaje={idCliente:userId, texto:"ha dado un error al crear el contrato"};
                            postMensajeAutopilot(mensaje);
                          }
                        });
                        
                      }
                      else{
                        console.log("Error al enviar los datos");
                      }
                  })
                  .catch(() => {
                    open({
                      alertType: "spinner",
                      open: false,
                    });
                  });
              }
              else if(response.data.contratoFirmado==0){
                open({
                  alertType: "spinner",
                  open: true,
                });
                if(response.data.contractId==0){
                  var datos={idCliente:userId};
                  postCrearContratoIronia(datos)
                  .then((result2)=>{
                    if(result2.data.status==200){
                      postFirmarContratoIronia(datos)
                      .then((result3)=>{
                        if(result3.data.status==200){
                          if(result3.data.url.startsWith('http://') || result3.data.url.startsWith('https://')){
                            setUrlContract(result3.data.url);
                            setDisabledContract(false);
                            var mensaje={idCliente:userId, texto:"ha creado el contrato"};
                            postMensajeAutopilot(mensaje);
                          }
                          else{
                            var mensaje={idCliente:userId, texto:"ha dado un error al firmar el contrato"};
                            postMensajeAutopilot(mensaje);
                          }
                          open({
                            alertType: "spinner",
                            open: false,
                          });
                        }
                      });
                    }
                    else{
                      var mensaje={idCliente:userId, texto:"ha dado un error al crear el contrato"};
                      postMensajeAutopilot(mensaje);
                    }
                  });
                }
                else{
                  var datos={idCliente:userId};
                  postFirmarContratoIronia(datos)
                  .then((result2)=>{
                    open({
                      alertType: "spinner",
                      open: false,
                    });
                    if(result2.data.status==200){
                      setUrlContract(result2.data.url);
                      console.log(result2.data.url);
                      setDisabledContract(false);
                    }
                  })
                  .catch(() => {
                    open({
                      alertType: "spinner",
                      open: false,
                    });
                  });
                }
                
              }
              if(response.data.contratoFirmado!=0){
                setDisabledFunds(false);
                if(response.data.usuarioValidado==0){
                  setHiddenFinalStep(true);
                }
              }
            }
          }
        }
        
      })
      .catch(() => {
        navigate("/resumen");
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: "error encontrado",
          alertType: "toast",
          open: true,
        });
      });
  }

  const toogleNavBar = () => {
    titleHandler("Autopilot");
    pageNameHandler("Autopilot");
    headerTitleHandler("Inversión");
  };

  return (
    <AutopilotStyle>
      <div className="autopilot-container margin30 box-container">
        <div className="autopilot-blue-section">
            <div className="autopilot-welcome-section">
                <Text variant="titleFont" className="bold">
                    Bienvenido a Micappital, {nombre}
                </Text>
                <Text variant="normal" type="primary">
                    Esta es tu zona privada donde podrás consultar toda la información sobre tus inversiones
                </Text>
            </div>
            <div className="autopilot-footer-section">
                <Text variant="labelFont" className="bold">
                    ¿Tienes dudas?
                </Text>
                <Text variant="normal" type="primary">
                    Pregúntanos por <a href="https://api.whatsapp.com/send?phone=34693039289" target="_blank" style={{ color: 'white', fontWeight: 'bold', textDecoration: 'underline' }}>aquí</a>.
                </Text>
                <Text variant="normal" type="primary">
                    Si lo prefieres, <a href="https://micappital.com/blog/guia-de-uso-micappital-autopilot/" target="_blank" style={{ color: 'white', fontWeight: 'bold', textDecoration: 'underline' }}>consulta nuestra guía</a>.
                </Text>
                
            </div>
        </div>
        <div className="autopilot-white-section">
            <Text variant="titleFont" className="invite-title bold"  >
                Para comenzar a invertir necesitas completar estos pasos:
            </Text>
            <div>
                <AutopilotStep
                    title={"Completa tus datos personales"}
                    subtitle={"Para comenzar a invertir es necesario que termines de completar los datos que nos faltan"}
                    completed={additionalData}
                    path={"/datosAutopilot"}
                ></AutopilotStep>

                <AutopilotStep
                    title={"Sube tu DNI"}
                    subtitle={"Al estar regulados en la CNMV, necesitamos tu DNI o NIE"}
                    completed={dni}
                    path={"/dniAutopilot"}
                ></AutopilotStep>
                <AutopilotStep
                    title={"Verificación de datos financieros"}
                    subtitle={"Al abrir una cuenta de inversión, necesitamos cumplir con la prevención de Blanqueo de Capitales. Para ello tienes que subir alguna de las siguientes opciones: declaración de la renta, nómina..."}
                    completed={incomeProof}
                    path={"/justificanteAutopilot"}
                ></AutopilotStep>
                <AutopilotStep
                    title={"Elige tu suscripción"}
                    subtitle={"Decide el tipo de servicio que mejor se ajusta a ti (Normal o premium). Recuerda se puede cambiar en cualquier momento."}
                    completed={plan}
                    path={"/planAutopilot"}
                ></AutopilotStep>
                <AutopilotStep
                    title={"Firma el contrato de inversión"}
                    subtitle={"Para poder invertir en clases limpias (las más baratas), tienes que completar el alta con Diaphanum. Que es la entidad que recomendamos para ello. Recuerda no cobramos nada, de ninguna entidad."}
                    completed={contract}
                    path={urlContract}
                    disabled={disabledContract}
                ></AutopilotStep>
                <AutopilotStep
                    title={"Revisa tu recomendación de inversión"}
                    subtitle={"Estamos validando tu usuario. Aprovecha para revisar el plan de inversión que tenemos para ti. Te avisaremos cuando tu usuario esté listo. Este proceso puede tardar unos minutos."}
                    buttonText={"Revisar"}
                    completed={funds}
                    path={"/propuestaAutopilot"}
                    disabled={disabledFunds}
                    hidden ={!hiddenFinalStep || valorInversion!=0}
                ></AutopilotStep>
                <AutopilotStep
                    title={"Compra tus fondos"}
                    subtitle={"Realiza la compra de los fondos que te recomendamos para ti."}
                    completed={funds}
                    path={"/propuestaAutopilot"}
                    disabled={disabledFunds}
                    hidden ={hiddenFinalStep || valorInversion!=0}
                ></AutopilotStep>
                <AutopilotStep
                    title={"Traspasa tu cartera"}
                    subtitle={"Estamos validando tu usuario. Pronto podrás hacer tus traspasos"}
                    completed={funds}
                    path={"/traspasoAutopilot"}
                    disabled={true}
                    hidden ={!hiddenFinalStep || valorInversion==0}
                ></AutopilotStep>
                <AutopilotStep
                    title={"Traspasa tu cartera"}
                    subtitle={"Vamos a traspasar tu cartera de fondos en "+banco+" a la nueva cartera de autopilot."}
                    completed={funds}
                    path={"/traspasoAutopilot"}
                    disabled={disabledFunds}
                    hidden ={needChanges==1 || hiddenFinalStep || valorInversion==0}
                ></AutopilotStep>
                <AutopilotStep
                    title={"Traspasa tu cartera"}
                    subtitle={"Estamos configurando los traspasos desde tus fondos en " + banco + " para hacerlos de la manera más eficiente. Te avisaremos cuando estén preparados."}
                    completed={funds}
                    path={"/traspasoAutopilot"}
                    disabled={true}
                    hidden ={needChanges==0 || hiddenFinalStep || valorInversion==0}
                ></AutopilotStep>
                
             
                
            </div>
            
        </div>
        <div className="autopilot-blue-section-mobile">
            <div className="autopilot-footer-section">
                <Text variant="labelFont" className="bold">
                    ¿Tienes dudas?
                </Text>
                <Text variant="normal" type="primary">
                    Pregúntanos por <a href="https://api.whatsapp.com/send?phone=34693039289" target="_blank" style={{ color: 'white', fontWeight: 'bold', textDecoration: 'underline' }}>aquí</a>.
                </Text>
                <Text variant="normal" type="primary">
                    Si lo prefieres, <a href="https://micappital.com/blog/guia-de-uso-micappital-autopilot/" target="_blank" style={{ color: 'white', fontWeight: 'bold', textDecoration: 'underline' }}>consulta nuestra guía</a>.
                </Text>
                
            </div>
        </div>
      </div>
      {
        (showPopUp && valorInversion!=0) && 
          <IroniaMessageStyle>
            <div className="changes_overlay">
              <div className="changes">
                <Text onClick={()=>{
                  setShowPopUp(false);
                }} variant="titleFont" className="closeText bold"  >
                        x
                </Text>
                <img  src={ImageConstants.TicRound} className="loaderImg" alt="X" />
                <div>
                    <Text variant="titleFont" className="invite-title bold"  >
                        ¡GENIAL!
                    </Text>
                </div>
                <div className="divTextoPopup">
                    <Text variant="popUpFont" className="invite-title semibold"  >
                        Acabas de solicitar el traspaso a la plataforma Micappital Autopilot.
                    </Text>
                </div>
                <div className="divTextoPopup">   
                    <Text variant="popUpFont" className="invite-title semibold"  >
                        Este proceso tardara algunos días, pero no te preocupes porque los estamos supervisando y te avisaremos cuando el traspaso se haya completado.
                    </Text>
                    <Text variant="popUpFont" className="invite-title semibold"  >
                        En ese momento ya podrás ver tu inversion en tu panel y app de Micappital.
                    </Text>
                </div>
                <div className="divTextoPopup"> 
                
                    <Text variant="popUpFont" className="invite-title semibold"  >
                        Si tienes cualquier duda nos puedes preguntar <a href="https://api.whatsapp.com/send?phone=34693039289" target="_blank" style={{ color: 'black', fontWeight: 'bold', textDecoration: 'underline' }}>por aquí</a>
                    </Text>
                </div>
              </div>
            </div>
          </IroniaMessageStyle>
      }
      {
        (showPopUp && valorInversion==0) && 
          <IroniaMessageStyle>
            <div className="changes_overlay">
              <div className="changes">
                <Text onClick={()=>{
                  setShowPopUp(false);
                }} variant="titleFont" className="closeText bold"  >
                        x
                </Text>
                <img  src={ImageConstants.TicRound} className="loaderImg" alt="X" />
                <div>
                    <Text variant="titleFont" className="invite-title bold"  >
                        ¡GENIAL!
                    </Text>
                </div>
                <div className="divTextoPopup">
                    <Text variant="popUpFont" className="invite-title semibold"  >
                        Acabas de confirmar las órdenes de compra de tu cartera de inversión.
                    </Text>
                </div>
                <div className="divTextoPopup">   
                    <Text variant="popUpFont" className="invite-title semibold"  >
                        Este proceso tardara algunos días, pero no te preocupes porque los estamos supervisando y te avisaremos cuando la compra se haya completado.
                    </Text>
                    <Text variant="popUpFont" className="invite-title semibold"  >
                        En ese momento ya podrás ver tu inversion en tu panel y app de Micappital.
                    </Text>
                </div>
                <div className="divTextoPopup"> 
                
                    <Text variant="popUpFont" className="invite-title semibold"  >
                        Si tienes cualquier duda nos puedes preguntar <a href="https://api.whatsapp.com/send?phone=34693039289" target="_blank" style={{ color: 'black', fontWeight: 'bold', textDecoration: 'underline' }}>por aquí</a>
                    </Text>
                </div>
              </div>
            </div>
          </IroniaMessageStyle>
      }
    </AutopilotStyle>
  );
});

export default Autopilot;
