import { useEffect, useState, useContext } from "react";
import { AuthTokenService } from "../../../../services";
import { AlertContext } from "../../../common/alertContainer";
import EcoTutorialStyle from "./ecoTutorialStyle";
import { getBankDetails } from "../../proposal/proposalServices";
import Text from "../../../../theme/font";
import ScrollBar from "../../../../components/scrollbar/scrollbar";

const EcoTutorial = ({ data }) => {
  const tutorialData = data.info;
  const [process, setProcess] = useState([]);
  const [totalStep, setTotalStep] = useState([]);
  const [show, setShow] = useState(false);
  const { open } = useContext(AlertContext);

  useEffect(() => {
    open({
      alertType: "spinner",
      open: true,
    });
    const userId = AuthTokenService.getUserId();
    getBankDetails(userId)
      .then((result) => {
        open({
          alertType: "spinner",
          open: false,
        });
        if (result.statusText === "OK" && result.status === 200) {
          const step = result.data.recetaAportacion;
          setTotalStep([...step]);
          if (step.length > 2) {
            const slicedData = step.slice(0, 2);
            setProcess(slicedData);
          } else {
            setProcess(step);
          }
        }
      })
      .catch(() => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const stepHandler = () => {
    if (show) {
      const slice = totalStep.slice(0, 2);
      setProcess(slice);
    } else {
      setProcess([...totalStep]);
    }
    setShow(!show);
  };

  const bankName = tutorialData?.banco?.nombre;
  const videoUrl = tutorialData?.banco?.enlacevideo;

  return (
    <EcoTutorialStyle>
      {videoUrl && (
        <div className="panelVideoContainer">
          <Text variant="primaryFont" className="videoTitle">
            Cómo comprar los fondos en {bankName}
          </Text>
          <div className="videoContainer">
            <div className="video-responsive">
              <iframe
                width="853"
                height="480"
                src={videoUrl}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
              />
            </div>
          </div>
        </div>
      )}

      <div
        className={
          videoUrl ? "stepMainContainer" : "stepMainContainer full-width"
        }
      >
        <Text variant="primaryFont" className="stepHeader">
          Guía detallada
        </Text>
        <ScrollBar max_height={"20rem"}>
          {process &&
            process.map((info, index) => {
              return (
                <div className="stepContainer" key={Math.random()}>
                  <div className="stepDetails">
                    <Text variant="fieldFont" className="stepCount">
                      Paso {index}
                    </Text>
                    <Text variant="fieldFont" className="stepTitle">
                      {info.titulopaso}
                    </Text>
                  </div>

                  <Text variant="normal" className="stepDescription">
                    {info.descripcionpaso}
                  </Text>
                </div>
              );
            })}
        </ScrollBar>
        <div className="seeMoreButtonContainer">
          <Text
            variant="fieldFont"
            className="seeMoreButton"
            onClick={stepHandler}
          >
            {show ? "Leer menos" : "Leer más..."}
          </Text>
        </div>
      </div>
    </EcoTutorialStyle>
  );
};

export default EcoTutorial;
