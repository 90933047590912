import styled from "styled-components";

const CardDetailsPopUpStyle = styled.div`
  .cardPopUp-header {
    width: 100%;
    border-radius: 1.5625rem;
    padding: 2rem 1rem 3rem 2rem;
    background-color: ${(props) =>
      props.theme.colors.background.defaultBackground};
    @media (max-width: 680px) {
      padding: 2rem 2rem 2rem 2rem;
    }
    .header-bar {
      display: flex;
      justify-content: start;
      align-items: center;
      padding-left: 5rem;
      @media (max-width: 680px) {
        flex-direction: column;
        gap: 2rem;
        justify-content: center;
        padding-left: 0rem;
      }
      .heading {
        color: ${(props) => props.theme.colors.fontColor.titleFontColor};
        font-weight: bold;
        @media (max-width: 680px) {
          text-align: center;
        }
      }
    }
    .cardPopUp-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      .card-content {
        font-style: italic;
        padding: 2rem 5rem;
        @media (max-width: 780px) {
          padding: 2rem 0rem;
        }
      }
      .change-btn {
        font-weight: bold;
        min-width: 8.7rem;
        max-width: 11.2rem;
        .btn-div {
          border-radius: 1.90625rem;
          background-color: ${(props) =>
            props.theme.colors.button.background.redButton};
          text-align: center;
          border: 0rem solid
            ${(props) => props.theme.colors.background.overlayBackground};
          color: ${(props) => props.theme.colors.fontColor.defaultFontColor};
          height: 3rem;
          width: 11.25rem;
        }
        .btn-div:hover {
          cursor: pointer;
        }
      }
    }
  }
`;
export default CardDetailsPopUpStyle;
