import React from "react";
import ReactDOM from 'react-dom/client';
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import { AlertProvider } from "./container/common/alertContainer/alertProvider";
import { GlobalStyles } from "./theme/globalStyles";
import "simplebar/dist/simplebar.min.css";
import { Provider } from "react-redux";
import store from "./store/configureStore";

const root = document.getElementById('root');
ReactDOM.createRoot(root).render(
  <Provider store={store}>
    <AlertProvider>
      <App />
      <GlobalStyles />
    </AlertProvider>
  </Provider>
);
