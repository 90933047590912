import React from "react";
import EuroTextFieldStyle from "./euroTextFieldStyle";
import Text from "../../theme/font";

const EuroTextField = React.memo(
  ({
    className,
    variant,
    name,
    onKeyDown,
    value,
    onChange,
    type,
    max,
    helperText,
    error,
  }) => {
    const inputHandler = (element) => {
      if (element.target.innerText.length < max || element.keyCode === 8) {
        onKeyDown(element);
      } else {
        element.preventDefault();
      }
    };

    return (
      <EuroTextFieldStyle>
        <Text variant={variant} className={className}>
          <div
            id="value-field"
            className="input"
            contentEditable={true}
            suppressContentEditableWarning={true}
            name={name}
            onKeyDown={(e) => inputHandler(e)}
            onInput={(e) => onChange(e.currentTarget.textContent, name)}
            type={type}
          >
            {value}
          </div>
          <span>€</span>
        </Text>
        {error && (
          <Text variant="smallFont" className="error-text">
            {helperText}
          </Text>
        )}
      </EuroTextFieldStyle>
    );
  }
);

export default EuroTextField;
