import Moment from "moment";

const InsuranceLetter = ({ data, micappitalData }) => {
  let convertedDate = Moment(data && data.fechaActual).format(`DD.MM.YYYY`);

  const convertHandler = () => {
    let convertedDate = Moment(data && data.fechaActual).format(`DD MMMM YYYY`);

    var splitedDate = convertedDate.split(" ");
    var date = splitedDate[0];
    var month = splitedDate[1];
    var year = splitedDate[2];

    return `${date} de ${month} de ${year}`;
  };

  const styles = {
    page: {
      fontSize: "0.5rem",
      margin: "4rem 5rem",
      width: "500px",
    },
    appoinmentTitle: {
      width: "100%",
      marginBottom: "1rem",
    },
    takerSection: {
      marginBottom: "1rem",
    },
    dateSection: {
      width: "300px",
      textAlign: "end",
    },
    introduction: {
      marginBottom: "1rem",
    },
    idSection: {
      margin: "1rem 0rem",
    },
    brokerageSection: {
      marginBottom: "1rem",
    },
    contentSection: {
      wordBreak: "break-word",
      width: "100%",
      marginRight: "5rem",
    },
    mainContent: {
      marginBottom: "1rem",

      width: "100%",
    },
    greetingSection: {
      marginBottom: "2rem",
    },
    contentStyle: {
      width: "100",
      wordSpacing: "2px",
    },
    segurosWord:{
      letterSpacing: "0.01px",

    }
  };
  return (
    <div style={styles.page}>
      <div style={styles.takerSection}>
        <div style={styles.appoinmentTitle}>
        <span style={styles.segurosWord}>Nombramiento de</span> Correduría&nbsp;de Seguros
        </div>
        <div>Tomador:&nbsp;{data && data.nombre}</div>
        <div>NIF / NIE:&nbsp;{data && data.dni}</div>
      </div>
      <div style={styles.dateSection}>{convertHandler()}</div>
      <div style={styles.contentSection}>
        <div style={styles.introduction}>Estimados señores,</div>
        <div>
          Como&nbsp;tomador&nbsp;de&nbsp;las&nbsp;pólizas&nbsp;de&nbsp;seguros&nbsp;que&nbsp;actualmente&nbsp;tengo&nbsp;en&nbsp;vigor,
          <br />
          comunico&nbsp;mi&nbsp;voluntad&nbsp;de&nbsp;nombrar&nbsp;como&nbsp;mi&nbsp;correduría&nbsp;de&nbsp;seguros&nbsp;para&nbsp;todos
          <br />
          los&nbsp;seguros&nbsp;vigentes&nbsp;con&nbsp;ustedes
        </div>
      </div>
      <div style={styles.idSection}>{micappitalData && data.poliza}</div>
      <div style={styles.brokerageSection}>
        <div style={styles.contentStyle}>
          Correduría: {micappitalData && micappitalData.nombre}
        </div>
        <div>Nº DGSFP&nbsp;: {micappitalData && micappitalData.clave}</div>
        <div>NIF: {micappitalData && micappitalData.nif}</div>
        <div>Email: {micappitalData && micappitalData.email}</div>
      </div>
      <div style={styles.brokerageSection}>
        El efecto de este <span style={styles.segurosWord}>nombramiento es</span> inmediato en los aspectos legales y de
        gestión
      </div>
      <div style={styles.mainContent}>
        Con la finalidad de que mi nueva correduría de seguros pueda cumplir con
        la
        <br /> función que marca la Ley, autorizo la cesión de todos los datos
        necesarios para <br />
        este fin, incluyendo copia de las pólizas e información relativa a
        peritos y siniestros <br />
        para actuar en beneficio de los derechos del tomador
      </div>
      <div style={styles.mainContent}>
        Cualquier deficiencia en las pólizas en vigor no será imputable a
        Micappital Insurtech
        <br /> Correduria de seguros SL
      </div>
      <div style={styles.brokerageSection}>Anexo: NIF</div>
      <div style={styles.brokerageSection}>Atentamente</div>
      <div style={styles.brokerageSection}>
        <div>Tomador: {data && data.nombre}</div>
        <div>NIF / NIE: {data && data.dni}</div>
      </div>
      <div>Fecha: {convertedDate}</div>
    </div>
  );
};

export default InsuranceLetter;
