/* eslint-disable array-callback-return */
import React, { useEffect, useState, useContext } from "react";
import { cargarCambios } from "../../homeServices";
import Changes from "../changes";
import Loading from "../../../../../components/commonModules/Loading";
import "../../../../../assets/css/GraficoObjetivos.css";
import { AlertContext } from "../../../../common/alertContainer";
import ImageConstants from "../../../../../constants/imageConstants/image_constants";

const PanelChanges = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [changes, setChanges] = useState(null);
  const { open } = useContext(AlertContext);

  useEffect(() => {
    cargarCambios()
      .then((result) => {
        if (result.statusText === "OK" && result.status === 200) {
          const changes = result.data.cambios;
          setChanges(changes);
          setIsLoading(false);
        }
      })
      .catch(() => {
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderHandler = () => {
    if (isLoading || changes == null) {
      return <Loading />;
    } else {
      return (
        <div className="sombreado">
          <div className="row">
            <div className="container center-child">
              <img
                className="imagenMicappitalPropuesta"
                src={ImageConstants.IconoCambios}
                alt="X"
              ></img>
              <p className="tituloEmpezamos">Te recomendamos hacer cambios</p>
              <p className="subtituloEmpezamos">
                Estos son los cambios que te proponemos para optimizar tu
                cartera de inversión.
              </p>
              {changes.map((cambio, i) => {
                if (i < 5) {
                  return (
                    <div className="col-lg-4 col-md-4 col-sm-6  contedorCambio" key={Math.random()}>
                      <Changes cambio={cambio} />
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      );
    }
  };
  return renderHandler();
};

export default PanelChanges;
