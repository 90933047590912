import styled from "styled-components";

const SectorsSectionStyle = styled.div`
  padding: 1.5rem;

  .sectors-section-heading {
    font-weight: bold;
    color: ${(props) => props.theme.colors.fontColor.titleFontColor};
    @media (max-width: 540px) {
      text-align: center;
    }
  }
  .sectors-container-description {
    padding-top: 1rem;
    color: ${(props) => props.theme.colors.fontColor.descriptionFontColor};
    width: 70%;
    @media (max-width: 540px) {
      text-align: center;
      width: 100%;
    }
  }
  .sector-options {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 3rem 0rem;
    .sector-options-container {
      display: grid;
      grid-template-columns: repeat(6, minmax(4.375rem, 1fr));
      grid-gap: 1.25rem;
      align-items: stretch;
      width: fit-content;
     
      @media (max-width: 650px) {
        grid-template-columns: repeat(4, minmax(4.375rem, 1fr));
      }
      @media (max-width: 450px) {
        grid-template-columns: repeat(3, minmax(4.375rem, 1fr));
      }
      .sector-icon {
        width: fit-content;
        display: flex;
        justify-content: center;
        img {
          width: 4.3125rem;
        }
      }
    }
  }
`;
export default SectorsSectionStyle;
