import { useContext, useEffect } from "react";
import { ContainerContext } from "../../../components/navbarContainer/containerProvider";
import InusranceBanner from "./investmentBanner/investmentBanner";
import BankListSection from "./bankListSection/bankListSection";
import InterestSection from "./interestSection/interestSection";
import AuthoritySection from "./authoritySection/authoritySection";
import LandingPageStyle from "./landingPageStyle";
import ServiceCostSection from "./serviceCostSection/serviceCostSection";

const InvestmentLandingPage = () => {
  const { changeAlertStatus, pageNameHandler, headerTitleHandler } =
    useContext(ContainerContext);

  useEffect(() => {
    changeAlertStatus(false);
    toogleNavBar();
    return () => {
      changeAlertStatus(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toogleNavBar = () => {
    pageNameHandler("Resumen");
    headerTitleHandler("Inversión");
  };

  return (
    <LandingPageStyle>
      <div className="landing-page-container">
        <div className="primary-section">
          <div className="banner-section">
            <InusranceBanner />
          </div>
          <div className="insurance-review-section box-container">
            <InterestSection />
          </div>
          <div className="companies-section box-container">
            <BankListSection />
          </div>
        </div>
        <div className="secondary-section">
          <div className="simulate-section box-container">
            <ServiceCostSection />
          </div>
          <div className="all-in-one-section box-container">
            <AuthoritySection />
          </div>
        </div>
      </div>
    </LandingPageStyle>
  );
};

export default InvestmentLandingPage;
