import React, { useState, useContext } from "react";
import { useNavigate } from "react-router";
import { Form, Formik } from "formik";
import { ThemeProvider } from "styled-components";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import * as Yup from "yup";
import { AlertContext } from "../../alertContainer";
import { AuthTokenService } from "../../../../services";
import Button from "../../../../components/button/button";
import defaultTheme from "../../../../theme/theme";
import LogInFormStyle from "./logInFormStyle";
import Text from "../../../../theme/font";
import TextField from "../../../../components/textField/textField";
import { cargarPosicion } from "../../../investment/home/homeServices";
import { getLoginData } from "../userServices";

const LogInForm = React.memo(() => {
  const theme = defaultTheme;
  const navigate = useNavigate();
  const { open } = useContext(AlertContext);
  const [loginForm] = useState({ email: "", password: "" });

  //Used for Formik live validation
  const validate = Yup.object({
    email: Yup.string().required("Email o DNI necesario"),
    password: Yup.string()
      // .min(6, "La contraseña debe tener al menos 6 caracteres")
      .required("Contraseña necesaria")
      .matches(/^\S*$/, "El espacio en blanco no está permitido"),
  });
  //Used for submitting data for login check
  function submitHandler(values) {
    open({
      alertType: "spinner",
      open: true,
    });
    const datas = {
      username: values.email,
      password: values.password,
    };
    getLoginData(datas)
      .then((response) => {
        const { token } = response.data;

        const { idCliente, idClienteSeguros, imagen } =
          response.data?.datosSuperCliente;

        const userId = idCliente ? idCliente : "";
        const clientInsuranceId = idClienteSeguros ? idClienteSeguros : "";
        const profileImage = imagen ? imagen : "";
        
        const { nombre, apellidos, idSuperCliente } =
          response.data?.datosSuperCliente;

        AuthTokenService.setToken({
          idCliente: userId,
          idClienteSeguros: clientInsuranceId,
          token,
          nombre,
          apellidos,
          imagen: profileImage,
          idSuperCliente,
        });
        if (userId) {
          cargarPosicion(idCliente).then((result) => {
            if (result.data) {
              const checkValor = result.data.data[0].valor;
              const autopilot = result.data.data[0].autopilot;
              if(autopilot && autopilot === "1"){
                navigate("/autopilot");
              }
              else if (checkValor && checkValor === "0") {
                navigate("/propuesta");
              } else {
                navigate("/resumen");
              }
              open({
                alertType: "spinner",
                open: false,
              });
            }
          });
        } else {
          navigate("/renderContainer");
        }
      })
      .catch((error) => {
        open({
          alertType: "spinner",
          open: false,
        });
        let message;
        if (error.response.status === 401) {
          message = error.response.data.msg;
        } else {
          message = "Usuario o contraseña incorrectos";
        }
        open({
          type: "error",
          title: message,
          alertType: "toast",
          open: true,
        });
      });
  }
  //Live validation handler functions
  const formValidationHandler = (conditionalValue, resultValue) => {
    let result = null;
    if (conditionalValue) {
      result = resultValue;
    }
    return result;
  };

  const variantHandler = (conditionalValue, resultValue) => {
    let variant = null;
    if (conditionalValue && resultValue) {
      variant = "error";
    } else {
      variant = "outlined";
    }
    return variant;
  };

  return (
    <ThemeProvider theme={theme}>
      <LogInFormStyle>
        <Formik
          initialValues={{
            email: loginForm.email,
            password: loginForm.password,
          }}
          onSubmit={(values) => {
            submitHandler(values);
          }}
          validateOnBlur="true"
          validationSchema={validate}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            submitForm,
          }) => (
            <Form
              onSubmit={handleSubmit}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubmit();
                }
              }}
            >
              <div className="form-field">
                <div className="email-field">
                  <div className="icon-div">
                    <MailOutlineIcon />
                  </div>
                  <TextField
                    autoFocused
                    autoComplete="new-password"
                    variant={variantHandler(errors.email, touched.email)}
                    name="email"
                    value={values.email}
                    handleChange={handleChange}
                    type="text"
                    placeholder="Correo electrónico o DNI"
                    error={formValidationHandler(
                      touched.email,
                      Boolean(errors.email)
                    )}
                    helperText={formValidationHandler(
                      touched.email,
                      errors.email
                    )}
                  />
                </div>
                <div className="password-field">
                  <div className="icon-div">
                    <LockOutlinedIcon />
                  </div>
                  <TextField
                    variant={variantHandler(errors.password, touched.password)}
                    name="password"
                    autoComplete="new-password"
                    value={values.password}
                    handleChange={handleChange}
                    type="password"
                    placeholder="Contraseña"
                    error={formValidationHandler(
                      touched.password,
                      Boolean(errors.password)
                    )}
                    helperText={formValidationHandler(
                      touched.password,
                      errors.password
                    )}
                  />
                </div>

                <Text
                  variant="smallFont"
                  className="description"
                  onClick={() => navigate("/recuperarClaves")}
                >
                  ¿Olvidaste tu contraseña?
                </Text>
                <div className="button-sec">
                  <Button
                    className="log-in-button"
                    variant="outlined"
                    value="Entrar"
                    click={submitForm}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </LogInFormStyle>
    </ThemeProvider>
  );
});

export default LogInForm;
