import styled from "styled-components";

const SelectFieldStyle = styled.div`
  outline: none;
  border-radius: 6rem;
  height: 2rem;
  -webkit-filter: blur(0);
  background-color: ${(props) =>
    props.theme.colors.background.inputFieldBackground};
  border: ${(props) =>
    props.variant === "error"
      ? "0.0625rem solid"`${props.theme.colors.fontColor.errorFontColor}`
      : "none"};
  color: ${(props) => props.theme.colors.text.fieldText};
  width: 100%;
  min-height: 2.7rem;
  font-weight: bold;
  background-position: 1rem;
  padding: 0rem 1.5rem;
  .select-field {
    outline: none;
    background-color: ${(props) =>
      props.theme.colors.background.inputFieldBackground};
    border: ${(props) =>
      props.variant === "error"
        ? "0.0625rem solid"`${props.theme.colors.fontColor.errorFontColor}`
        : "none"};
    color: ${(props) => props.theme.colors.text.fieldText};
    width: 100%;
    min-height: 2.7rem;
    font-weight: bold;
    ::placeholder {
      color: ${(props) => props.theme.colors.text.fieldText};
    }
  }
  .form-select[multiple],
  .form-select[size]:not([size="1"]) {
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  }
  .focused {
    position: relative;
    top: 180%;
    width: 100%;
    z-index: 1;
    ::-webkit-scrollbar {
      width: 0.312rem;
      right: 0.312rem;
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 0.312rem transparent;
      border-radius: 0.625rem;
      right: 0.312rem;
    }
    ::-webkit-scrollbar-thumb {
      background: ${(props) =>
        props.theme.colors.background.scrollbarBackground};
      border-radius: 0.625rem;
      right: 0.312rem;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: ${(props) =>
        props.theme.colors.background.scrollbarHoverbackground};
    }
  }
  select option:disabled {
    color: ${(props) => props.theme.colors.fontColor.titleFontColorOpac};
  }

  .primary-select-field {
    outline: none;
    border-radius: 0.625rem;
    height: 2rem;
    background-color: ${(props) =>
      props.theme.colors.background.defaultBackground};
    color: ${(props) => props.theme.colors.background.outlineBorderColor};
  }
`;
export default SelectFieldStyle;

export const MuiSelectFieldStyle = styled.div`
  min-height: 2.7rem;
  height: 100%;
  color: ${(props) => props.theme.colors.background.outlineBorderColor};
  .MuiFormControl-root {
    background-color: ${(props) =>
      props.theme.colors.background.defaultBackground};
    width: 100%;
    border-radius: 1rem;
    height: 100%;
    margin: 0rem;
  }
  .MuiList-root {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }
  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    height: 100%;
    color: ${(props) => props.theme.colors.background.outlineBorderColor};
  }
  .css-1869usk-MuiFormControl-root {
    margin: 0rem;
    height: 100%;
  }
  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: none;
  }

  .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
    background-color: ${(props) =>
      props.theme.colors.background.dropDownBackground};
    border-radius: 50%;
    height: 1.8rem;
    width: 1.8rem;
  }

  &.css-6hp17o-MuiList-root-MuiMenu-list {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }
  &.MuiTouchRipple-root {
    border-bottom: 1px solid
      ${(props) => props.theme.colors.background.selectFieldBorder} !important;
    width: 90% !important;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  .MuiOutlinedInput-root {
    height: 100% !important;
    color: ${(props) => props.theme.colors.fontColor.textFieldFont};
    padding-right: 0.625rem !important;
    @media (min-width: 320px) {
      font-size: 0.75rem;
    }
    @media (min-width: 500px) {
      font-size: 0.8rem;
    }
    @media (min-width: 768px) {
      font-size: 0.9rem;
    }
    @media (min-width: 1000px) {
      font-size: 0.9rem;
    }
    @media (min-width: 1500px) {
      font-size: 1rem;
    }
  }
  em {
    font-style: normal;
  }
  &ul {
    padding-bottom: 0rem !important ;
  }
  li {
    width: 90% !important;
    border-bottom: 1px solid
      ${(props) => props.theme.colors.background.selectFieldBorder} !important;
  }
  .MuiList-root {
    padding: 0rem !important;
  }
  svg {
    position: absolute !important;
    right: 0.5rem !important;
    pointer-events: none !important;
  }
  .error-val {
    color: red;
    position: absolute;
    padding-left: 1.5rem;
  }

`;
