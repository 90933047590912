import Button from "../../../../components/button/button";
import InsuranceBannerStyle from "./insuranceBannerStyle";
import Text from "../../../../theme/font";

const InusranceBanner = ({ showPopUp }) => {
  return (
    <InsuranceBannerStyle>
      <div className="banner-container">
        <div className="text-container">
          <Text variant="fieldFont" className="banner-button-container">
            <Button
              value="MEJORA TU SEGURO"
              className="banner-button"
              click={() => showPopUp(true)}
            />
          </Text>
        </div>
      </div>
    </InsuranceBannerStyle>
  );
};

export default InusranceBanner;
