
import AltaStyle from './altaStyle';
import { Form,Row, Col } from "react-bootstrap";
import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { AlertContext } from '../common/alertContainer';
import { AuthTokenService } from '../../services';
import { ContainerContext } from '../../components/navbarContainer/containerProvider';
import defaultTheme from '../../theme/theme';
import Text from '../../theme/font';

import ImageConstants from '../../constants/imageConstants/image_constants';
import Diversification from '../investment/proposal/diversification/diversification';
import Doubts from '../investment/proposal/doubts/doubts';
import Evolution from '../investment/proposal/evolution/evolution';
import ProposalStyle from '../investment/proposal/proposalStyle';
import StartingManual from '../investment/proposal/startingManual/startingManual';
import StartingManualStyle from '../investment/proposal/startingManual/startingManualStyle';
import AlertMessage from '../../components/alertmessage/alertMessage';
import { getInvestmentDetails,cargarRecomendacionInicial } from '../investment/proposal/proposalServices';
import { getIroniaData, postComprarRecomendacionInicial, postActualizarImporteInicial } from '../investment/autopilot/autopilotServices';

export default function AutopilotProposal() {
  const theme = defaultTheme;
  const [amount, setAmount] = useState(0);
  const [listShow, setListShow] = useState([]);
  const [totalFunds, setTotalFunds] = useState("");
  const [investmentData, setInvestmentData] = useState({});
  const [url, setUrl] = useState(null);
  const [newAmount, setNewAmount]=useState(0);
  const [showButton, setShowButton]=useState(0);
  const [showChange, setShowChange]=useState(0);

  const primaryRef = useRef();
  const {
    changeMenuStatus,
     changeNavBarStatus,
    titleHandler,
    headerTitleHandler,
    pageNameHandler,
    changeAlertStatus,
  } = useContext(ContainerContext);
  const { open } = useContext(AlertContext);
  const navigate = useNavigate();
  const [menuHidden] = useState(true);
  const nombre = AuthTokenService.getNombre();
  
 
  const handleChangeAmount=(event)=>{
    setNewAmount(event.target.value);
  }

  const changeAmount= () =>{
    const userId = AuthTokenService.getUserId();
    var datos={idCliente:userId, importe:newAmount};
    postActualizarImporteInicial(datos)
      .then((result) => {
        open({
          alertType: "spinner",
          open: false,
        });
        window.location.reload()
      })
      .catch(() => {
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });
    
  }

  const ClickShowChange=()=>{
    setShowChange(1);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    changeMenuStatus(menuHidden);
    changeNavBarStatus(true);
    changeAlertStatus(false);
    return () => {
      changeMenuStatus(!menuHidden);
      changeNavBarStatus(false);
      changeAlertStatus(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeMenuStatus, changeNavBarStatus]);


  useEffect(() => {

    window.history.scrollRestoration = "manual";
    window.scrollTo(0, 0);
    titleHandler("Propuesta inicial");
    headerTitleHandler("Inversión");
    pageNameHandler("Propuesta inicial");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  useEffect(() => {
    open({
      alertType: "spinner",
      open: true,
    });
    const userId = AuthTokenService.getUserId();
    var datos={idCliente:userId};
    getIroniaData(userId)
      .then((response) => {
        setShowButton(response.data.usuarioValidado);
      });
    getInvestmentDetails(userId)
      .then((result) => {
        open({
          alertType: "spinner",
          open: false,
        });
        const data = result?.data;
        setInvestmentData(data);
      })
      .catch(() => {
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const userId = AuthTokenService.getUserId();
    
    cargarRecomendacionInicial(userId)
      .then((result) => {
        open({
          alertType: "spinner",
          open: false,
        });
        if (result.statusText === "OK" && result.status === 200) {
          setTotalFunds(result.data.fondos);
          setAmount(result.data.importe);
          setNewAmount(result.data.importe);
        }
      })
      .catch(() => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });


      var datos={idCliente:userId};
      postComprarRecomendacionInicial(datos)
        .then((result)=>{
          open({
            alertType: "spinner",
            open: false,
          });
          if(result.data.url){
            if(result.data.url.startsWith('http://') || result.data.url.startsWith('https://')){
              setUrl(result.data.url);
            }
          }
          
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const renderComponents = () => {
    return (
      <div className="render-container">
        <div className="responsive-spacer"></div>
        <StartingManualStyle>
          <div className="manual-container box-container mb-4">
            <div className="manual-title">
              <Text variant="titleFont">Confirma la cantidad</Text>
            </div>
              <div>
                <button disabled={url==null} className="btn botonComienza" onClick={() => window.open(url)}>
                    Comprar {Number(amount).toLocaleString('es-ES')} €
                </button>
                <div className="spacer"></div>
              </div>
            
             <Text variant="fielFont" className='cambioImporte' onClick={ClickShowChange}>¿Quieres cambiar la cantidad?</Text>
             <div className="spacer"></div>
             {
             showChange == 1 && 
                <div className="start-button-container fila-propuesta-autopilot">
                  <Form.Group as={Col} xs={6} sm={3} className="mb-3 inputPropuesta">
                      <Form.Control placeholder="Cantidad" value={newAmount} type="text" onChange={handleChangeAmount}/>
                  </Form.Group>
                  <button className="botonComienza btn botonRosa" onClick={changeAmount}>
                      Cambiar
                  </button>
              </div>
              
             }
            
            
            
          </div>
        </StartingManualStyle>
        <StartingManual
          data={listShow}
          videoClick={null}
          popUpClick={null}
        />
        
        <div className="spacer"></div>
        <Diversification popUpClick={null} />
        <div className="spacer"></div>
        <Evolution data={amount} />
      </div>
    );
  };
  return (
    <ThemeProvider theme={theme}>
      <ProposalStyle>
      <AltaStyle >
            <div className="bodyProcesoAlta">
                <div className="navbar navbar-expand-md navbar-light" style={{paddingLeft:'2.5rem'}}>
                    
                </div>
                <div className="plan-popup container">
                    <div className="main-step-container">

                        <a className="navbar-brand">
                            <img height="40" src="https://micappital.com/assets/img/logo-micappital.png" alt="Micappital"></img>
                        </a>
                        
                    </div>
                    <div className="process-popup-inner-container">
                        <h3 className="process-heading mt-5 mb-4">
                            {nombre}, este es el plan de inversión que mejor se adapta a tus necesidades
                        </h3>
                        
                        {renderComponents()}
                        
                        <div className="start-button-container mb-3 mt-3">
                            <button className="btn " onClick={() =>navigate("/autopilot")}>
                                Atrás
                            </button>
                            
                            <button disabled={url==null} className="btn botonComienza" onClick={() => window.open(url)}>
                                Comprar
                            </button>
                        </div>
                        <div className="endProceso"></div>
                    </div>
                </div>
            </div>
        </AltaStyle>
       
      </ProposalStyle>
    </ThemeProvider>
  );
};
