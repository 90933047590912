import React, { useState, useEffect, useContext } from "react";
import { useDropzone } from "react-dropzone";
import { AuthTokenService } from "../../../../services";
import { AlertContext } from "../../alertContainer";
import {
  postProfilePic,
  uploadGeneralProfileImage,
} from "../accountSettingsServices";
import Button from "../../../../components/button/button";
import ProflePicturePopUpStyle from "./profilePicturePopUpStyle";
import Text from "../../../../theme/font";
import { thumbsContainer, thumb, thumbInner, img } from "./dropzoneStyles";

const ProfilePicturePopUp = (props) => {
  const [files, setFiles] = useState([]);
  const [uploadedImage, setUploadedImage] = useState(null);
  const { open } = useContext(AlertContext);

  //For profile picture upload
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setUploadedImage(acceptedFiles[0]);
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} alt="x" style={img} />
      </div>
    </div>
  ));

  //For showing preview of uploaded file
  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  const imageSubmitHandler = (props) => {
    open({
      alertType: "spinner",
      open: true,
    });

    if (props.section === "common") {
      let formData = new FormData();
      formData.append("idSuperCliente", AuthTokenService.getIdSuperCliente());
      formData.append("files", uploadedImage);
      uploadGeneralProfileImage(formData)
        .then((response) => {
          open({
            alertType: "spinner",
            open: false,
          });
          open({
            type: "success",
            title: response.data.msg,
            alertType: "toast",
            open: true,
          });

          localStorage.setItem("imagen", response.data?.urlImage);
          props.profilePictureHandler(uploadedImage);
          props.submited();
        })
        .catch((error) => {
          open({
            alertType: "spinner",
            open: false,
          });
          let message;
          if (
            error &&
            error.response &&
            error.response.status &&
            error.response.status === 401
          ) {
            message = error.response.data.msg;
          } else {
            message = "Algo ha salido mal, por favor inténtalo de nuevo";
          }
          open({
            type: "error",
            title: message,
            alertType: "toast",
            open: true,
          });
        });
    } else {
      let formData = new FormData();
      formData.append("idCliente", AuthTokenService.getUserId());
      formData.append("files", uploadedImage);
      postProfilePic(formData)
        .then((response) => {
          open({
            alertType: "spinner",
            open: false,
          });
          open({
            type: "success",
            title: response.data.msg,
            alertType: "toast",
            open: true,
          });

          localStorage.setItem("imagen", response.data?.urlImage);
          props.profilePictureHandler(uploadedImage);
          props.submited();
        })
        .catch((error) => {
          open({
            alertType: "spinner",
            open: false,
          });
          let message;
          if (
            error &&
            error.response &&
            error.response.status &&
            error.response.status === 401
          ) {
            message = error.response.data.msg;
          } else {
            message = "Algo ha salido mal, por favor inténtalo de nuevo";
          }
          open({
            type: "error",
            title: message,
            alertType: "toast",
            open: true,
          });
        });
    }
  };

  return (
    <ProflePicturePopUpStyle>
      <div className="profile-container">
        <div className="profile-header">
          <div className="profile-heading">
            <Text variant="titleFont">Imagen de perfil</Text>
          </div>
          <div className="save-btn">
            <Button
              className="btn-div"
              value="Cargar imagen"
              click={() => imageSubmitHandler(props)}
            />
          </div>
        </div>
        <div className="profile-content">
          <div className="dropzone-container">
            <section className="container">
              <aside style={thumbsContainer}>{thumbs}</aside>
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <p>Subir archivo...</p>
              </div>
            </section>
          </div>
        </div>
      </div>
    </ProflePicturePopUpStyle>
  );
};

export default ProfilePicturePopUp;
