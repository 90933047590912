import { alertActionType } from "./alertMessageActionType";

const alertDataState = {
  alertData: false,
  hideData: true,
  hideSecData: true,
};

export const MessageReducer = (state = alertDataState, { type, payload }) => {
  switch (type) {
    case alertActionType.CONNECTED_DATA:
      return {
        ...state,
        alertData: payload,
      };
    case alertActionType.HIDE_ALERT:
      return {
        ...state,
        hideData: payload,
      };
    case alertActionType.HIDE_SECOND_ALERT:
      return {
        ...state,
        hideSecData: payload,
      };
    default:
      return state;
  }
};
