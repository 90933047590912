import React, { useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Box from "@mui/material/Box";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DateSelectorStyle from "./datePickeStyle";
import { es } from "date-fns/locale";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const DateSelector = ({ handleChange, values, className }) => {
  const [open, setOpen] = useState(false);

  return (
    <DateSelectorStyle>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
        <DatePicker
          className={className}
          inputFormat="d MMMM  yyyy"
          label="Custom input"
          value={values}
          onChange={(newValue) => {
            handleChange(newValue);
          }}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          components={{ OpenPickerIcon: CalendarTodayOutlinedIcon }}
          renderInput={({ inputRef, inputProps, InputProps }) => (
            <Box
              sx={{ display: "flex", alignItems: "center" }}
              onClick={(e) => setOpen(true)}
            >
              <input ref={inputRef} {...inputProps} />
              {InputProps?.endAdornment}
            </Box>
          )}
        />
      </LocalizationProvider>
    </DateSelectorStyle>
  );
};
export default DateSelector;
