import React, { useState, useEffect, useContext, useRef} from 'react';
import AltaStyle from './altaStyle';
import { patchIroniaData, loadRedsysParamsAutopilot, loadPriceAutopilot } from '../investment/autopilot/autopilotServices';
import { useNavigate } from "react-router-dom";
import { Form,Row, Col } from "react-bootstrap";
import { AlertContext } from '../common/alertContainer';
import { ContainerContext } from '../../components/navbarContainer/containerProvider';
import Text from '../../theme/font';
import ImageConstants from '../../constants/imageConstants/image_constants';
import { AuthTokenService } from '../../services';

export default function AutopilotPlan(){

    const [error, setError]=useState(false);
    const [menuHidden] = useState(true);
    const { changeMenuStatus, changeNavBarStatus, changeAlertStatus } =
    useContext(ContainerContext);
    const navigate=useNavigate();
    const [price, setPrice] = useState(25);
    const nombre = AuthTokenService.getNombre();
    const [url,setUrl]=useState(null);
    const [novaPrice, setNovaPrice]=useState(0);
    const [signatureVersion,setSignatureVersion]=useState();
    const [parameters,setParameters]=useState();
    const [signature,setSignature]=useState();
    const formRef = useRef(null);
    const { open } = useContext(AlertContext);

    useEffect(() => {
        window.scrollTo(0, 0);
        changeMenuStatus(menuHidden);
        changeNavBarStatus(true);
        changeAlertStatus(false);
        return () => {
          changeMenuStatus(!menuHidden);
          changeNavBarStatus(false);
          changeAlertStatus(true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [changeMenuStatus, changeNavBarStatus]);


      useEffect(()=>{
        open({
            alertType: "spinner",
            open: true,
          }); 
        const idCliente= AuthTokenService.getUserId();
        loadPriceAutopilot(idCliente)
        .then((result)=>{
            if(result.data.status==200){
                setPrice(result.data.precio);
                setNovaPrice(result.data.nova);
                //añadir comprobacion de tarjeta
                if(result.data.necesitaTarjeta){
                    loadRedsysParamsAutopilot(idCliente)
                    .then((result)=>{
                        if (result.data.status==200){
                            setUrl(result.data.url);
                            setSignatureVersion(result.data.Ds_SignatureVersion);
                            setParameters(result.data.Ds_MerchantParameters);
                            setSignature(result.data.Ds_Signature);
                            open({
                                alertType: "spinner",
                                open: false,
                              });
                        }
                        else{
                            console.log("Error al cargar el pago");
                            open({
                                alertType: "spinner",
                                open: false,
                              });
                        }
                    });
                }
                else{
                    open({
                        alertType: "spinner",
                        open: false,
                      });
                }
            }
        })
        
        
      },[]);

      const selectPlan = (value) => {
        
        var datos={
            idCliente:AuthTokenService.getUserId(),
            plan: value
        }
        patchIroniaData(datos)
            .then((result)=>{
                if (result.data.status==200){
                    navigate("/autopilot");
                }
                else{
                    setError(true);
                }
            });
    };


   

    return (
        <AltaStyle >
            <div className="bodyProcesoAlta">
                <div className="navbar navbar-expand-md navbar-light" style={{paddingLeft:'2.5rem'}}>
                    
                </div>
                <div className="plan-popup container">
                    <div className="main-step-container">

                        <a className="navbar-brand">
                            <img height="40" src="https://micappital.com/assets/img/logo-micappital.png" alt="Micappital"></img>
                        </a>
                        
                    </div>
                    <div className="process-popup-inner-container">
                        <h3 className="process-heading mt-5 mb-4">
                            {nombre}, selecciona el plan que mejor se adapta a tus necesidades
                        </h3>

                        <Form className="form-insurance-data">
                            <Row>
                            <div className={novaPrice ? "col-md-6 mt-5" : "col-md-6"}>
                                    <div  className="mb-3 planContainer planProContainer">
                                        {
                                            !novaPrice &&
                                            <div className="recommendedPlan">
                                                RECOMENDADO
                                            </div>
                                        }
                                        
                                        <Text variant="titleFont" className="bold">
                                            WIN-WIN
                                        </Text>
                                        <Text variant="titleFont" className="bold">
                                            {price}€/mes
                                        </Text>
                                        <Text variant="normal">
                                            Solo si ganas
                                        </Text>
                                        <div className="planDescriptionDiv">
                                            <div className="autopilotPlanBenefit">
                                                <div className="autopilot-step-img">
                                                    <img src={ImageConstants.Tic}></img>
                                                </div>
                                                <div className="autopilot-step-text">
                                                    <Text variant="labelFont" >
                                                        Solo pagas si te hacemos ganar dinero
                                                    </Text>
                                                </div>
                                            </div>
                                            <div className="autopilotPlanBenefit">
                                                <div className="autopilot-step-img">
                                                    <img src={ImageConstants.Tic}></img>
                                                </div>
                                                <div className="autopilot-step-text">
                                                    <Text variant="labelFont" >
                                                        Tu inversión siempre optimizada en los mejores fondos
                                                    </Text>
                                                </div>
                                            </div>
                                            <div className="autopilotPlanBenefit">
                                                <div className="autopilot-step-img">
                                                    <img src={ImageConstants.Tic}></img>
                                                </div>
                                                <div className="autopilot-step-text">
                                                    <Text variant="labelFont" >
                                                        Rebalanceo automático de tu cartera
                                                    </Text>
                                                </div>
                                            </div>
                                            <div className="autopilotPlanBenefit">
                                                <div className="autopilot-step-img">
                                                    <img src={ImageConstants.Tic}></img>
                                                </div>
                                                <div className="autopilot-step-text">
                                                    <Text variant="labelFont" >
                                                        Informe mensual de tu inversión
                                                    </Text>
                                                </div>
                                            </div>
                                            <div className="autopilotPlanBenefit">
                                                <div className="autopilot-step-img">
                                                    <img src={ImageConstants.Tic}></img>
                                                </div>
                                                <div className="autopilot-step-text">
                                                    <Text variant="labelFont" >
                                                        Notificaciones periódicas y avisos de momentos de mercado
                                                    </Text>
                                                </div>
                                            </div>
                                            
                                        </div>
                                            <form ref={formRef} action={url} method="post">
                                                <input type="hidden"  name="Ds_SignatureVersion" value={signatureVersion}/>
                                                <input type="hidden" name="Ds_MerchantParameters" value={parameters}/>
                                                <input type="hidden" name="Ds_Signature" value={signature}/>
                                                
                                            </form>
                                        <button className="btn botonComienza mt-3" type="button"  
                                        onClick={() =>{
                                            selectPlan("win-win");
                                            if(url!=null)
                                                formRef.current.submit();
                                        }}>
                                            Seleccionar
                                        </button>
                                    </div>
                                </div>
                                <div className={novaPrice ? "col-md-6" : "col-md-6 mt-5"}>
                                    <div  className="mb-3 planContainer">
                                        {novaPrice ? 
                                            <div className="novaPlan">
                                                <img  className="logoNova" src={ImageConstants.nova}></img>
                                                OFERTA
                                            </div>:""
                                        }
                                        <Text variant="titleFont" className="bold">
                                            Pro
                                        </Text>
                                        {
                                        novaPrice ? 
                                        <div>
                                            <Text variant="titleFont" className="bold">
                                                {price}€/mes
                                            </Text>
                                            <Text variant="normal">
                                                Solo si ganas
                                            </Text>
                                        </div>
                                        :<Text variant="titleFont" className="bold">
                                             400€/año
                                        </Text>

                                        }
                                        

                                        <div className="planDescriptionDiv">
                                            <div className="autopilotPlanBenefit">
                                                <div className="autopilot-step-img">
                                                    <img src={ImageConstants.Tic}></img>
                                                </div>
                                                <div className="autopilot-step-text">
                                                    <Text variant="labelFont" >
                                                        Llamada mensual con tu asesor
                                                    </Text>
                                                </div>
                                            </div>
                                            <div className="autopilotPlanBenefit">
                                                <div className="autopilot-step-img">
                                                    <img src={ImageConstants.Tic}></img>
                                                </div>
                                                <div className="autopilot-step-text">
                                                    <Text variant="labelFont" >
                                                        Tu inversión siempre optimizada en los mejores fondos
                                                    </Text>
                                                </div>
                                            </div>
                                            <div className="autopilotPlanBenefit">
                                                <div className="autopilot-step-img">
                                                    <img src={ImageConstants.Tic}></img>
                                                </div>
                                                <div className="autopilot-step-text">
                                                    <Text variant="labelFont" >
                                                        Rebalanceo automático de tu cartera
                                                    </Text>
                                                </div>
                                            </div>
                                            <div className="autopilotPlanBenefit">
                                                <div className="autopilot-step-img">
                                                    <img src={ImageConstants.Tic}></img>
                                                </div>
                                                <div className="autopilot-step-text">
                                                    <Text variant="labelFont" >
                                                        Informe detallado de tu inversión
                                                    </Text>
                                                </div>
                                            </div>
                                            <div className="autopilotPlanBenefit">
                                                <div className="autopilot-step-img">
                                                    <img src={ImageConstants.Tic}></img>
                                                </div>
                                                <div className="autopilot-step-text">
                                                    <Text variant="labelFont" >
                                                        Notificaciones periódicas y avisos de momentos de mercado
                                                    </Text>
                                                </div>
                                            </div>
                                            <div className="autopilotPlanBenefit">
                                                <div className="autopilot-step-img">
                                                    <img src={ImageConstants.Tic}></img>
                                                </div>
                                                <div className="autopilot-step-text">
                                                    <Text variant="labelFont" >
                                                        Multibanco
                                                    </Text>
                                                </div>
                                            </div>
                                            <div className="autopilotPlanBenefit">
                                                <div className="autopilot-step-img">
                                                    <img src={ImageConstants.Tic}></img>
                                                </div>
                                                <div className="autopilot-step-text">
                                                    <Text variant="labelFont">
                                                        Seguimiento y análisis de tus planes de pensiones
                                                    </Text>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <button className="btn botonComienza mt-3" type="button" onClick={() =>{
                                            selectPlan("pro");
                                            if(url!=null)
                                                formRef.current.submit();
                                        }}>
                                            Seleccionar
                                        </button>
                                    </div>
                                </div>
                                
                            </Row>
                            {error && <p>HA HABIDO UN ERROR</p>}
                            
                            
                            
                        </Form>
                        
                        <div className="start-button-container">
                            <button className="btn" onClick={() =>navigate("/autopilot")}>
                                Atrás
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </AltaStyle>
    );
}