import { useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import defaultTheme from "../../../../theme/theme";
import ImageConstants from "../../../../constants/imageConstants/image_constants";
import InvitedFriendsStyle from "./invitedFriendsStyle";
import Text from "../../../../theme/font";
import List from "../../../../components/list/list";
import ListItem from "../../../../components/listItem/listItem";

const InvitedFriends = ({ data }) => {
  const theme = defaultTheme;
  const [totalReward, setTotalReward] = useState(data ? data.disponible : "");

  const [totalData, setTotalData] = useState([]);

  useEffect(() => {
    if (data && data.disponible) {
      setTotalReward(data.disponible);
    }

    const combinedData = [];
    if (data) {
      // eslint-disable-next-line array-callback-return
      data.amigos.map((items) => {
        const dataItems = {
          primary: `${items.nombre} ${items.apellidos}`,
          secondary: items.estado,
        };
        combinedData.push(dataItems);
      });
      setTotalData([...totalData, ...combinedData]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <ThemeProvider theme={theme}>
      <InvitedFriendsStyle>
        <div className="invited-friends-container">
          <div className="invited-friends-title">
            <Text variant="titleFont"> Amigos invitados</Text>
          </div>
          <div className="total-amount">
            <img
              className="amount-icon"
              alt="x"
              src={ImageConstants.importeIcon}
            />
            <Text variant="normal" className="reward-container">
              Crédito: {data && data.disponible === 0 ? "0" : `${totalReward}`}€
            </Text>
          </div>
        </div>

        <div className="invited-friends-description">
          <Text variant="normal" type="primary">
          Si un amigo tuyo se hace cliente de Micappital utilizando tu enlace, 
          obtendréis 50€ sin comisiones de Micappital tanto tú como tu amigo.
          </Text>
        </div>

        <List data={totalData} limit={5}>
          <ListItem variant="withIcon" />
        </List>
      </InvitedFriendsStyle>
    </ThemeProvider>
  );
};

export default InvitedFriends;
