import styled from "styled-components";
import newFrame from "../../../../assets/img/seguros/newFrame.svg";

const InsuranceBannerStyle = styled.div`
  height: 25rem;
  @media (max-width: 1150px) {
    height: 22rem;
  }
  @media (max-width: 600px) {
    height: 18rem;
  }
  @media (max-width: 470px) {
    height: 14rem;
  }
  @media (max-width: 380px) {
    height: 12rem;
  }
  .banner-container {
    background-image: url(${newFrame});
    background-repeat: no-repeat;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: end;
    justify-content: center;
    border-radius: 25px;
    .text-container {
      margin-bottom: 2rem;
      padding: 0rem 1rem;
      width: 100%;
      display: flex;
      justify-content: center;
      @media (max-width: 480px) {
        margin-bottom: 1rem;
      }
      .banner-text {
      }
      .banner-button-container {
        width: 100%;
        max-width: 24rem;
        .banner-button {
          width: 100%;
          padding: 0rem 0.2rem;
        }
      }
    }
  }
`;
export default InsuranceBannerStyle;