import styled from "styled-components";

const SelectedInsuranceStyle = styled.div`
  .selected-insurance-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;
    @media (max-width: 540px) {
      padding: 1rem;
    }
    .selected-insurance-header {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      @media (max-width: 420px) {
        flex-direction: column;
        align-items: center;
      }
      .selected-insurance-heading {
        color: ${(props) => props.theme.colors.fontColor.titleFontColor};
        font-weight: bold;
      }
      .insurance-amount {
        font-weight: bold;
        color: ${(props) => props.theme.colors.fontColor.descriptionFontColor};
        .amount-text {
          font-size: 0.9rem;
        }
      }
    }
    .insurance-details-section {
      display: flex;
      @media (max-width: 420px) {
        flex-direction: column;
        align-items: center;
      }

      .insurance-icon-container {
        margin-right: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        .insurance-icon {
          height: 4rem;
          width: 4rem;
          @media (max-width: 420px) {
            margin-bottom: 1rem;
          }
        }
        .insurance-company-icon {
          max-height: 7rem;
          max-width: 7rem;
          bottom: 1rem;
          @media (max-width: 540px) {
            bottom: 1rem;
            width: 6rem;
          }
          @media (max-width: 520px) {
            bottom: 1rem;
          }
          @media (max-width: 420px) {
            bottom: -2rem;
          }
        }
      }
      .insurance-data {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 0.3rem;
        @media (max-width: 420px) {
          align-items: center;
        }
        .insurance-name {
          color: ${(props) =>
            props.theme.colors.fontColor.descriptionFontColor};
          font-weight: bold;
        }
        .policy-id {
          color: ${(props) =>
            props.theme.colors.fontColor.descriptionFontColor};
        }
        .button-container {
          @media (max-width: 520px) {
            width: fit-content;
            padding: 1rem 0rem;
          }
          .download-button {
            width: 10rem;
            min-height: 1.5rem;
            padding-top: 0.2rem;
            align-items: center;
            font-weight: lighter;
            background-color: ${(props) =>
              props.theme.colors.fontColor.titleFontColor};
          }
        }

        .insurance-id {
          color: ${(props) => props.theme.colors.fontColor.objectiveFontColor};
        }
        .bank-name {
          color: ${(props) => props.theme.colors.fontColor.objectiveFontColor};
        }
        .insurance-type {
          color: ${(props) => props.theme.colors.fontColor.objectiveFontColor};
        }
        .date-container {
          display: flex;

          color: ${(props) => props.theme.colors.fontColor.objectiveFontColor};
          .insurance-date {
            margin-left: 0.5rem;
          }
        }
        .micappital-optimization-section {
          display: flex;
          align-items: center;
          margin-top: 0.3rem;
          .micappital-optimized-text {
            color: ${(props) => props.theme.colors.fontColor.titleFontColor};
            margin-left: 0.5rem;
          }
          .micappital-not-optimized-text {
            color: ${(props) =>
              props.theme.colors.fontColor.objectiveFontColor};
            margin-left: 0.5rem;
          }
        }
        .optimization-details {
          display: flex;
          .optimization-description {
            margin-left: 0.5rem;
            color: ${(props) =>
              props.theme.colors.fontColor.secondaryOptimizationText};
          }
        }
        .activation-section {
          display: flex;
          .activation-description {
            margin-left: 0.5rem;
            color: ${(props) =>
              props.theme.colors.fontColor.objectiveFontColor};
          }
        }
      }
    }
    .insurance-button-container {
      .optimization-button {
        max-width: 14rem;
        font-weight: bold;
        background-color: ${(props) =>
          props.theme.colors.fontColor.titleFontColor};
      }
    }
  }
  .connected-insurance {
    background-color: ${(props) =>
      props.theme.colors.background.primaryInsuranceLabelColor};
  }
`;
export default SelectedInsuranceStyle;
