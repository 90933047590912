import styled from "styled-components";

const OptimizationStyle = styled.div`
  display: flex;
  justify-content: center;

  .optimizationContainer {
    display: flex;
    flex-direction: column;
    padding: 1.25rem;
    width: 100%;
    @media (max-width: 1040px) {
      max-width: 19.37rem;
    }
    @media (max-width: 400px) {
      max-width: 100%;
    }
    .optimization {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: bold;
      color: ${(props) => props.theme.colors.fontColor.titleFontColor};
      margin-bottom: 1rem;
      flex-wrap: wrap;
      .optimizationPercentage {
        color: ${(props) =>
          props.theme.colors.button.background.teritiaryButton};
      }
    }
    .optimizationIconText {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0.5rem;
      font-weight: bold;
      color: ${(props) => props.theme.colors.fontColor.descriptionFontColor};
      margin-bottom: 1rem;
    }
    .updatedText {
      color: ${(props) => props.theme.colors.fontColor.titleFontColor};
      font-weight: bold;
      margin-bottom: 0.5rem;
    }
    .notUpdatedText {
      color: ${(props) => props.theme.colors.button.background.teritiaryButton};
      font-weight: bold;
      margin-bottom: 0.5rem;
    }
    .ticImg {
      height: 1rem;
    }
    .warningImg {
      height: 1rem;
    }

    .button-container {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .manual-user-button-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 0.3rem;
      @media (max-width: 1474px) {
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
      }
    }
    .button {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${(props) =>
        props.theme.colors.button.background.redButton};
      color: ${(props) => props.theme.colors.button.text.textSecondary};
      min-width: 10rem;
      max-width: 13rem;
      min-height: 3rem;
      border-radius: 4rem;
      padding: 0rem 0.4rem;
      text-align: center;
      cursor: pointer;
    }
    .optimization-button {
      background-color: ${(props) =>
        props.theme.colors.button.background.redButton};
    }
    .redirect-button {
      background-color: ${(props) =>
        props.theme.colors.fontColor.titleFontColor};
      font-weight: 500;
    }
    .bold-button {
      font-weight: 500;
    }
  }
`;
export default OptimizationStyle;
