import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContributionBoxStyle from "./contributionBoxStyle";
import List from "../../../../components/list/list";
import ListItem from "../../../../components/listItem/listItem";
import Text from "../../../../theme/font";
import { getContributionData } from "../contributionServices";
import { AuthTokenService } from "../../../../services";

const ContributionBox = React.memo(() => {
  const [fundData, setFundData] = useState([]);
  const [totalContributionAmount, setTotalContributionAmount] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const combinedData = [];
    const userId = AuthTokenService.getUserId();
    getContributionData(userId).then((response) => {
      if (response && response.data.data) {
        const contributionData = response.data.data.objetivos;
        setTotalContributionAmount(response.data.data.aportacionNecesaria);
        // eslint-disable-next-line array-callback-return
        contributionData.map((items) => {
          const dataItems = {
            primary: items.objetivo,
            secondary: items.ahorro,
            subData: items.periodo,
          };
          combinedData.push(dataItems);
        });
        setFundData([...fundData, ...combinedData]);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formato = (x, signo = false) => {
    if (x > 0) {
      var number = new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(x);
      if (signo && x > 0) {
        return "+" + number;
      } else return number;
    } else {
      return x;
    }
  };

  return (
    <ContributionBoxStyle>
      <div className="contribution-box-container inner-container">
        <div className="contribution-header">
          <div className="contribution-heading">
            <Text variant="titleFont">Aportaciones según objetivos</Text>
          </div>
          <div className=" contribution-description">
            {fundData.length > 0 ? (
              <Text variant="normal">
                Según los objetivos que te has marcado, deberías aportar todos
                los meses:
              </Text>
            ) : (
              <Text variant="normal">
                No has creado ningún objetivo de ahorro.¡Te recomendamos que lo
                hagas para que Micappital pueda ayudarte a conseguirlos!
              </Text>
            )}
          </div>
        </div>
        <div className="field-container">
          <List data={fundData} limit={5}>
            <ListItem variant="normal" />
          </List>
        </div>
        <div className="desc">
          <Text variant="smallFont" onClick={() => navigate("/nuevoObjetivo")}>
            Configurar objetivos
          </Text>
        </div>
        <div className="contribution-total">
          <div className="total-title">Total</div>
          <div className="total-amount">{`${formato(
            totalContributionAmount
          )}€`}</div>
        </div>
      </div>
    </ContributionBoxStyle>
  );
});

export default ContributionBox;
