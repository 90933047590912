import styled from "styled-components";

const AltaStyle = styled.div`
    @media (max-width: 1040px){
        position:relative;
        top:-8rem;
    }
       
    @media (min-width: 1041px){
          margin-left:-12rem; 
    }

    .bodyProcesoAlta {
        font-family: Asap;
        background-color: #f2f2f2 !important;
        min-height:100vh
    }
    .landingPhoto{
        width:100%;
    }
    .container {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }
    @media (min-width: 576px){
        .container {
            max-width: 540px;
        }
    }
    @media (min-width: 768px){
        .container {
            max-width: 720px;
        }
        .process-popup{
            max-width: 660px;
        }
        .plan-popup{
            max-width: 720px;
        }
    }
    @media (min-width: 992px){
        .container {
            max-width: 960px;
        }
        .process-popup{
            max-width: 660px;
        }
        .plan-popup{
            max-width: 820px;
        }
        
    }
    @media (min-width: 1200px){
        .container {
            max-width: 1140px;
        }
        .process-popup{
            max-width: 660px;
        }
        .plan-popup{
            max-width: 940px;
        }
        
    }

    
    
    .main-step-container {
        width: 100%;
        display: flex;
        -webkit-box-pack: end;
        justify-content: space-between;
        margin-bottom: 0.5rem;
    }

    .process-popup{
        text-align:center;
    }
    .plan-popup{
        text-align:center;
    }
    
    
    .step-loader {
        width: 100%;
        min-height: 0.7rem;
        border-radius: 1.56rem;
        background-color: rgb(255, 255, 255);
        border: 0.125rem solid rgb(246, 246, 246);
    }
    .inner-step-loader {
        height: 0.7rem;
        background-color: rgb(0, 204, 202);
        border-radius: 1.56rem;
    }
    .step-container {
        display: flex;
        flex-direction: column;
        -webkit-box-align: center;
        align-items: center;
        width: 8rem;
    }
    .process-heading{
        font-weight:bold;
        text-align:left
    }
    .process-description {
        color: rgb(125, 125, 125);
        margin-bottom: 1rem;
        text-align:left
    }
    .option-title{
        font-weight: bold;
        font-size: 1.25rem;
    }
    .insurance-button-container {
        width: 100%;
        text-align:left;
        
        max-width: 26rem;
        border: 1px solid #00cca9;
        border-radius: 1rem;
        position: relative;
        background-color: rgb(255, 255, 255);
        min-height: 4rem;
        padding: 0.5rem 2rem;
        cursor: pointer;
        margin: 1.5rem auto;
    }
    .columna-alta-seguros, .insurance-image-container {
        float:left;
        padding: 1rem;
    }
    .select-insurance-container{
        max-width: 500px;
        margin: auto;
    }
    .insurance-image-container {
        
        padding: 0.3rem;
        min-width: 8rem;
    }
    .border-insurance-type{
        border-radius: 0.312rem;
        border: 0.0625rem solid rgb(125, 125, 125);
        margin: 0.5rem;
        cursor: pointer;
    }

    .border-insurance-type:hover{
        border-color: green;
    }

    @media (max-width: 720px)
    
    .insurance-image {
        height: 2rem;
    }

    .insurance-image {
        height: 3rem;
        filter: invert(51%) sepia(8%) saturate(2%) hue-rotate(347deg) brightness(95%) contrast(91%);
    }

    .form-insurance-data{
        text-align:left;
    }
    
     
    .pulse {
        cursor: pointer;
        box-shadow: 0 0 0 rgba(72, 207, 185, .4);
        animation: pulse 2s infinite;
    }
    
    .botonComienza{
        font-size: 17px;
        border-color: #00cca9;
        background-color:#00cca9;
        color: #fff!important;
    }

    .botonPadding{
        padding-left: 4rem;
        padding-right: 4rem;
    }

    .botonRosa{
        background-color:#f07e7f;
        border-color:#a65757;
    }

    .cambioImporte{
        font-weight: bold;
        color: #2C787C;
        text-decoration:underline;
        cursor: pointer;
    }

    .endProceso{
        height:1px;
    }

    .botonComienza:disabled{
        font-size: 17px;
        border-color: #a6a6a6;
        background-color: #cdcdcd;
        color: black !important;
        cursor: not-allowed;
    }
    .roundedcardseguros {
        background-color: #e3edef;
        padding: 50px 15px;
        border-radius: 1rem;
    }
    .iconlanding {
        height: 80px;
    }
    .iconlandingseguros {
        height: 70px;
    }
    .iconlandingsegurosinline {
        height: 50px;
        margin-right: 10px;
    }
    .roundedcardsegurosazul {
        background-color: #efffff;
        padding: 20px 15px;
        border-radius: 35px;
    }
    .textoTitulo {
        font-size: 1.5rem;
        font-weight: bolder;
        color: var(--colorGrisTitulo);
    }
    .supertitle {
        font-size: 2.5rem;
        color: #000;
    }
    @media (max-width: 1280px){
        .supertitle {
            font-size: 1.5rem;
            color: #000;
        }
    }
    .textoExtraGrande {
        font-size: 150% !important;
    }
    .textoSubtitulo {
        color: #5a5c69;
        font-size: 20px;
    }
    @media (max-width: 1280px){
        .textoSubtitulo {
            font-size: 17px;
            font-weight: 70 !important;
        }
    }
    @media (max-width: 1280px){
        .textoExtraGrande {
            font-size: 130% !important;
        }
    }
    .textoTituloPequeño {
        font-size: 23px;
        font-weight: 100 !important;
    }
    @media (max-width: 1280px){
        .textoTituloPequeño {
            font-size: 19px;
        }
    }
    .campo-incompleto{
        border:1px solid red;
    }
    .campo-incompleto:focus{
        box-shadow: 0 0 0 0.25rem #fd0d0d40;
    }
    .bold{
        font-weight:bold;
      }
    .contractText{
        background-color: white;
        border-radius: 1rem;
        padding: 1rem;
        max-height: 45vh;
        overflow-y: auto;
        margin-bottom: 1rem;
    }

    .planContainer{
        background-color:white;
        border-radius: 15px;
        padding: 1rem 2rem;
        text-align:center;
        overflow:hidden;
    }

    .planProContainer{
        background-color:#1A2E40;
        color: white;
    }

    .recommendedPlan{
        background-color: #00cca9;
        margin: -1rem -2rem 1rem;
        height:3rem;
        padding-top:0.75rem
    }

    .novaPlan{
        background-color: #000000;
        color:white;
        margin: -1rem -2rem 1rem;
        height:3rem;
        padding-top:0.75rem
    }

    .logoNova{
        height: 63%;
        margin-right: 10px;
    }

    @media (min-width: 767px){
        .planDescriptionDiv{
            min-height:495px
        }
    }
    @media (min-width: 992px){
        .planDescriptionDiv{
            min-height:390px
        }
    }

    .autopilotPlanBenefit{
        display:flex;
        margin-top:1rem;
        text-align: left;
    }
    .autopilot-step-img{
        width: 1.5rem;
    }
    .autopilot-step-img img{
        width: 80%;
    }
    .autopilot-step-text{
        padding-right:1rem;
        width: calc(100% - 1.5rem);
    }
    .autopilot-step-button{
        width: 8rem;
    }
    .radioButtons{
        display:flex;
        gap:20px;
    }
    
`;

export default AltaStyle;
