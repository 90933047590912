import { insuranceOptions } from "../../../../models/insurancelandingPage";
import InsuranceReviewStyle from "./insuranceReviewStyle";
import Text from "../../../../theme/font";

const InsuranceReview = () => {
  return (
    <InsuranceReviewStyle>
      <div className="insurance-review-container">
        <Text variant="titleFont" className="insurance-review-heading">
          Revisamos los seguros que ya tienes y te los optimizamos
        </Text>
        <div className="insurance-review-option-container">
          {insuranceOptions &&
            insuranceOptions.map((data) => {
              return (
                <div className="review-option-container" key={Math.random()}>
                  <div className="review-option-icon">
                    <img alt="x" src={data.src} className="option-icon" />
                  </div>
                  <Text
                    variant="smallerText"
                    type="primary"
                    className="review-option-title"
                  >
                    {data.title}
                  </Text>
                  <Text
                    variant="smallFont"
                    type="primary"
                    className="review-option-description"
                  >
                    {data.description}
                  </Text>
                </div>
              );
            })}
        </div>
        <Text variant="smallFont" className="bottom-text">
          Regulados por la DGS (J-4108) para buscar entre todas las compañías el seguro más adecuado.
        </Text>
      </div>
    </InsuranceReviewStyle>
  );
};

export default InsuranceReview;
