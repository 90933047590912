import styled from "styled-components";
import logInImage from "../../../assets/img/logIn/loginBg.svg";

const LogInStyle = styled.div`
  display: flex;
  justify-content: center;
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  .log-in-container {
    width: 100%;
    height: 100%;
    align-items: center;
    @media (max-width: 768px) {
      flex-direction: column;
      overflow: scroll;
      scrollbar-width: thin;
      scrollbar-color: transparent ;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: transparent;
      }
    }
    .image-sec {
      display: flex;
      flex-direction: column;
      width: calc(100% - 50%);
      height: 100%;
      padding: 1rem;
      @media (max-width: 768px) {
        width: 100%;
        height: calc(100% - 75%);
      }

      .main-icon {
        height: 3.4375rem;
        width: 3.4375rem;
        padding: 1rem 0rem 0rem 1rem;
        @media (max-width: 768px) {
          width: 2.5rem;
          height: 2.5rem;
          padding: 0rem 0rem 0rem 0rem;
          position: absolute;
        }
        .icon-1 {
          width: 100%;
          height: 100%;
        }
      }
      .logIn-content {
        display: flex;
        justify-content: center;
        padding: 0rem 1.45rem 0rem 4.125rem;
        color: ${(props) => props.theme.colors.text.fieldText};
        font-weight: bold;

        @media (max-width: 768px) {
          display: none;
        }
      }
      .logIn-images {
        @media (min-width: 768px) {
          flex: 1;
        }
        .login-backgorund {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          @media (max-width: 768px) {
            height: auto;
            padding-top: 3rem;
          }
          .initial-image {
            width: 80%;
            height: 80%;
            @media (max-width: 860px) {
              width: 90%;
            }
            @media (max-width: 768px) {
              width: 65%;
            }
            @media (max-width: 768px) {
              width: 80%;
            }
          }
        }
        .image-container {
          height: 100%;
          width: 100%;
          .primary-image {
            max-height: 25rem;
            max-width: 37.5rem;
          }
          .secondary-image {
            max-height: 25rem;
            max-width: 37.5rem;
          }
        }
        .back-img {
          height: 100%;
          width: 100%;
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          background-image: url(${logInImage});
          @media (max-width: 768px) {
            display: none;
          }
        }
        .image-1 {
          display: none;
          @media (max-width: 768px) {
            display: block;
          }
        }
      }
    }
    .form-container {
      height: 100%;
      width: calc(100% - 50%);
      min-width: 25rem;
      background-color: ${(props) =>
        props.theme.colors.background.defaultBackground};
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      @media (max-width: 768px) {
        position: relative;
        width: 100%;
        height: calc(100% - 25%);
        min-width: 100%;
        padding: 0rem;
      }

      .logIn-title {
        color: ${(props) => props.theme.colors.fontColor.titleFontColor};
        font-weight: bold;
        margin-bottom: 2rem;
      }
    }
  }
`;

export default LogInStyle;
