import URL_CONSTANTS from "../../../constants/urlConstants/urlConstants";
import { Request } from "../../../services";

export const GetCancelLetter = (userId, id) => {
  return Request.get(`${URL_CONSTANTS.get_cancel_letter}${userId}/${id}`);
};

export const GetInsuranceLetter = (userId, id) => {
  return Request.get(`${URL_CONSTANTS.get_insurance_letter}${userId}/${id}`);
};

export const GetInsurancePolicy = (userId, id) => {
  return Request.get(`${URL_CONSTANTS.get_insurance_policy}${userId}/${id}`);
};
