import styled from "styled-components";

const NewGoalPanelStyle = styled.div`
  border-radius: 1rem;
  width: 50%;
  @media (max-width: 620px) {
    width: 100%;
  }
  .goal-panel-conatiner {
    height: 100%;
    .goal-panel-inner-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 1.25rem;
      width: fit-content;
      background-image: ${(props) => props.theme.gradient.goalGradient};
      border-end-start-radius: 1rem;
      border-start-start-radius: 1rem;
      width: 100%;
      height: 100%;
      @media (max-width: 620px) {
        width: 100%;
        border-radius: 0.625rem;
      }
      .selection-panel-title {
        text-align: center;
        font-weight: bold;
        color: ${(props) => props.theme.colors.fontColor.titleFontColor};
      }
      .selcection-panel-container {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .selection-container {
          display: grid;
          grid-template-columns: repeat(3, minmax(4.375rem, 1fr));
          align-items: start;
          text-align: -webkit-center;
          padding: 1rem 0;
          grid-gap: 1.5rem;
          @media (max-width: 1200px) {
            grid-template-columns: repeat(2, minmax(4.375rem, 1fr));
          }
          @media (max-width: 920px) {
            grid-template-columns: repeat(3, minmax(4.375rem, 1fr));
          }
          @media (max-width: 720px) {
            grid-template-columns: repeat(2, minmax(4.375rem, 1fr));
          }
          @media (max-width: 620px) {
            grid-template-columns: repeat(3, minmax(4.375rem, 1fr));
          }
          @media (max-width: 400px) {
            grid-template-columns: repeat(2, minmax(4.375rem, 1fr));
          }
          .goal-box {
            cursor: pointer;
            padding: 0.3rem;
            .goal-icon {
              width: 100%;
              .goal-img {
                height: 4.6875rem;
                width: 100%;
              }
            }
            .goal-name {
              color: ${(props) => props.theme.colors.fontColor.fieldTitleColor};
              font-weight: bold;
              word-break: break-all;
              text-align: center;
            }
          }
          .selected-goal-box {
            border-radius: 1rem;
            background-color: ${(props) =>
              props.theme.colors.fontColor.titleFontColorOpac};
          }
        }
      }
    }
  }
  .goal-img-container {
    position: relative;
    height: 100%;
    .change-btn-container {
      position: absolute;
      bottom: 2rem;
      left: 2rem;
      @media (max-width: 600px) {
        width: 100%;
        left: 0;
      }
      @media (max-width: 400px) {
        bottom: 1rem;
      }
      .change-btn {
        background-color: ${(props) =>
          props.theme.colors.button.background.cancelButton};
        height: 2.5rem;
        color: ${(props) => props.theme.colors.fontColor.descriptionFontColor};
        max-width: 13.75rem;
        min-width: 12.5rem;
      }
    }
  }
  .goal-img-panel {
    width: 100%;
    height: 100%;
    border-end-start-radius: 1rem;
    border-start-start-radius: 1rem;
    @media (max-width: 620px) {
      width: 100%;
      border-start-start-radius: 0rem;
      border-end-start-radius: 0rem;
      @media (max-width: 600px) {
        text-align: center;
      }
    }
    img {
      width: 100%;
      height: 100%;
      border-end-start-radius: 1rem;
      border-start-start-radius: 1rem;
      @media (max-width: 620px) {
        width: 100%;

        border-radius: 0.625rem;
      }
      @media (max-width: 600px) {
        max-width: 21.87rem;
      }
    }
  }
`;
export default NewGoalPanelStyle;
