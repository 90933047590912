import styled from "styled-components";

const ProflePicturePopUpStyle = styled.div`
  .profile-container {
    width: 100%;
    background-color: ${(props) =>
      props.theme.colors.background.defaultBackground};
    padding: 2rem;
    border-radius: 1.5625rem;
    .profile-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      @media (max-width: 700px) {
        flex-direction: column;
        gap: 2rem;
        align-items: center;
      }
      .profile-heading {
        color: ${(props) => props.theme.colors.fontColor.titleFontColor};
        font-weight: bold;
      }
      .save-btn {
        .btn-div {
          height: 2.5rem;
          width: 11.25rem;
        }
      }
    }
    .profile-content {
      width: 70%;
      @media (max-width: 700px) {
        width: 100%;
      }
      .dropzone-container {
        .container {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          .dropzone {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: ${(props) =>
              props.theme.colors.button.background.blueButton};
            border-radius: 1.5625rem;
            border: none;
            height: 3.125rem;
            padding: 0rem 1rem;
            cursor: pointer;
            p {
              margin-bottom: 0rem !important;
              font-weight: 500;
              color: ${(props) => props.theme.colors.button.text.textTeritiory};
            }
          }
        }
      }
    }
  }
`;
export default ProflePicturePopUpStyle;
