import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { AlertContext } from "../../../../common/alertContainer";
import AllNewsStyle from "./allNewsStyle";
import { AuthTokenService } from "../../../../../services";
import { ContainerContext } from "../../../../../components/navbarContainer/containerProvider";
import defaultTheme from "../../../../../theme/theme";
import { getNewsList } from "../newsServices";
import ImageConstants from "../../../../../constants/imageConstants/image_constants";
import Text from "../../../../../theme/font";

const AllNews = () => {
  const theme = defaultTheme;
  const [menuHidden] = useState(true);
  const [newsData, setNewsData] = useState([]);
  const {
    changeMenuStatus,
    changeNavBarStatus,
    titleHandler,
    headerTitleHandler,
    pageNameHandler,
  } = useContext(ContainerContext);
  const { open } = useContext(AlertContext);
  const navigate = useNavigate();

  useEffect(() => {
    changeNavBarStatus(false);
    changeMenuStatus(menuHidden);
    toogleNavBar();
    return () => {
      changeMenuStatus(!menuHidden);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const userId = AuthTokenService.getUserId();
    open({
      alertType: "spinner",
      open: true,
    });
    getNewsList(userId)
      .then((result) => {
        open({
          alertType: "spinner",
          open: false,
        });
        if (result?.data) {
          setNewsData(result.data.data);
        }
      })
      .catch(() => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toogleNavBar = () => {
    titleHandler("Noticias Eco");
    headerTitleHandler("");
    pageNameHandler("Noticias Eco");
  };

  const onReturnHandler = () => {
    navigate("/resumen");
  };
  const newsHandler = (link) => {
    //navigate(`/noticia?newsId=${id}`);
    window.open(link, '_blank', 'noopener,noreferrer');
  };

  const linkHandler = (url) => {
    let newLink = url.slice(1);
    let attachedLink = `https://micappital.com${newLink}`;
    return attachedLink;
  };

  return (
    <ThemeProvider theme={theme}>
      <AllNewsStyle>
        <div className="back-icon" onClick={() => onReturnHandler()}>
          <img src={ImageConstants.arrowLeftIcon} alt="x" />
        </div>
        <div className="newsGridContainer">
          {newsData &&
            newsData.map((data, i) => {
              return (
                <div className="newsContainer" key={Math.random()}>
                  <div className="imageContainer">
                    <img
                      src={linkHandler(data.urlimagen)}
                      alt="X"
                      className="newsImage"
                    />
                  </div>
                  <Text variant="primaryFont" className="newsTitle">
                    {data.titulo}
                  </Text>
                  <div className="newsDetails">
                    <Text className="author" variant="labelFont">
                      {data.autor}
                    </Text>
                    <Text className="time" variant="labelFont">
                      time to read {data.tiempo}
                    </Text>
                  </div>
                  <Text className="newsDesc" variant="smallFont">
                    {data.textocorto}
                  </Text>
                  <div className="buttonContainer">
                    <Text
                      variant="fieldFont"
                      className="button"
                      onClick={() => newsHandler(data.link)}
                    >
                      Leer
                    </Text>
                  </div>
                </div>
              );
            })}
        </div>
      </AllNewsStyle>
    </ThemeProvider>
  );
};

export default AllNews;
