import ServiceSectionStyle from "./serviceSectionStyle";
import Text from "../../../../theme/font";
import ImageConstants from "../../../../constants/imageConstants/image_constants";

const ServiceStion = () => {
  return (
    <ServiceSectionStyle>
      <div className="service-section-header">
        <Text variant="titleFont" className="service-section-heading">
          Nuestro servicio automatizado
        </Text>
        <Text
          variant="smallerText"
          type="primary"
          className="service-section-description"
        >
          Con una orden al mes, seguirás aumentando tu patrimonio sin
          preocuparte.
        </Text>
      </div>
      <div className="image-section">
        <img
          className="service-img bank-icon"
          alt="x"
          src={ImageConstants.zeroBank}
        />
        <img
          className="service-img black-arrow"
          alt="x"
          src={ImageConstants.blackArrowIcon}
        />
        <img
          className="service-img micappital-zero-icon"
          alt="x"
          src={ImageConstants.micappitalZero}
        />
        <img
          className="service-img black-arrow"
          alt="x"
          src={ImageConstants.blackArrowIcon}
        />
        <img
          className="service-img increase-icon"
          alt="x"
          src={ImageConstants.increaseIcon}
        />
      </div>
    </ServiceSectionStyle>
  );
};
export default ServiceStion;
