import React, { useState, useEffect, useContext } from "react";
import { ThemeProvider } from "styled-components";
import { AlertContext } from "../../../common/alertContainer";
import { AuthTokenService } from "../../../../services";
import { cargarRecomendacionInicial } from "../proposalServices";
import defaultTheme from "../../../../theme/theme";
import Funds from "./funds/funds";
import ImageConstants from "../../../../constants/imageConstants/image_constants";
import { micappitalConnection } from "../../../../container/investment/home/homeServices";
import StartingManualStyle from "./startingManualStyle";
import Text from "../../../../theme/font";

const StartingManual = React.memo(({ data, videoClick, popUpClick }) => {
  const theme = defaultTheme;
  const [key, setKey] = useState(0);
  const [funds, setFunds] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [active, setActive] = useState(true);
  const [connectionData, setConnectionData] = useState("");
  const userId = AuthTokenService.getUserId();

  const { open } = useContext(AlertContext);
  useEffect(() => {
    if (data) {
      const temp = [...data];
      setFunds(temp);
    }
  }, [data]);

  useEffect(() => {
    open({
      alertType: "spinner",
      open: true,
    });

    cargarRecomendacionInicial(userId)
      .then((result) => {
        open({
          alertType: "spinner",
          open: false,
        });
        if (result.statusText === "OK" && result.status === 200) {
          setFunds([...result.data.fondos]);
          setIsLoading(false);
          setKey(Math.random());
        }
      })
      .catch(() => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    micappitalConnection(userId)
      .then((result) => {
        open({
          alertType: "spinner",
          open: false,
        });
        if (result.statusText === "OK" && result.status === 200) {
          const totalConnectionData = result.data.data.mode;
          setConnectionData(totalConnectionData);
        }
      })
      .catch(() => {
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //It will call the function for scroll to video
  const nextStep = () => {
    setActive(true);
    if (active) {
      videoClick();
    }
  };

  //for opening the popup
  const popUpClickHandler = () => {
    popUpClick();
  };

  //Checking the the funds
  const renderFundsProposal = () => {
    if (funds == null) {
      return null;
    }
    return <Funds inicial={true} datosFondo={funds} />;
  };

  if (funds == null || isLoading) {
    return null;
  } else {
    return (
      <ThemeProvider theme={theme}>
        <StartingManualStyle>
          <div className="manual-container box-container">
            <div className="manual-title">
              <Text variant="titleFont">Nuestra propuesta</Text>
            </div>
            <div className="manual-description">
              <Text variant="normal">
                Este es el plan personalizado que mejor se adapta a ti
              </Text>
            </div>
            <div className="proposal-fund-container" key={key}>
              {renderFundsProposal()}
            </div>
            {videoClick!=null && (
              <div className="inversiaButtonContainer">
                <div className="button-container">
                  <div className="inversiaButton" onClick={() => nextStep()}>
                    <div className="buttonIcon">
                      <img src={ImageConstants.playIcon} alt="x" />
                    </div>
                    <Text variant="fieldFont">¿Cómo realizo mi inversión?</Text>
                  </div>
                  {connectionData === "manual" && (
                    <Text
                      variant="fieldFont"
                      className="redirect-link"
                      onClick={() => popUpClickHandler()}
                    >
                      Ya he comprado mis fondos
                    </Text>
                  )}
                </div>
              </div>
            )}
            
          </div>
        </StartingManualStyle>
      </ThemeProvider>
    );
  }
});

export default StartingManual;
