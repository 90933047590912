import styled from "styled-components";

const PasswordFormStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  height: 100%;
  min-height: 20vh;
  overflow-y: scroll;
  .password-form-heading {
    color: ${(props) => props.theme.colors.fontColor.titleFontColor};
    font-weight: bold;
    width: 100%;
    text-align: center;
  }
  .password-form-description {
    font-style: italic;
    font-weight: 300;
    text-align: center;
    padding: 2rem 0rem;
  }
  .password-form-field {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    .password-form {
      position: relative;
      min-width: 18.75rem;
      .lock-icon {
        position: absolute;
        z-index: 1;
        bottom: 0.6rem;
        left: 0.5rem;
        color: ${(props) => props.theme.colors.icon.fieldIcon};
      }
    }
    .eyeOpen-img {
      height: 0.875rem;
    }
    .eyeClosed-img {
      height: 1.125rem;
    }
  }
  .password-form-button {
    min-width: 14.31rem;
    padding: 2rem 0rem;
    margin-bottom:220px;
    .reset-button {
      max-width: 14.31rem;
    }
  }
`;
export default PasswordFormStyle;
