import { ActionTypes } from "./proposalActionType";

const initialState = {
  fundDetails: [],
};

export const fundReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SHOW_FUNDS:
      return {
        ...state,
        fundDetails: payload,
      };

    default:
      return state;
  }
};
