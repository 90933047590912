import MakeInvestmentStyle from "./makeInvestmentStyle";
import Text from "../../../../theme/font";
import Button from "../../../../components/button/button";

const MakeInvestment = () => {
  return (
    <MakeInvestmentStyle>
      <Text
        variant="titleFont"
        type="primary"
        className="make-investment-heading"
      >
        Comienza tu inversión y deja de preocuparte
      </Text>
      <Text
        variant="normal"
        type="primary"
        className="make-investment-sub-heading"
      >
        La mejor rentabilidad, automatizada
      </Text>
      <Text
        variant="smallerText"
        type="primary"
        className="make-investment-description"
      >
        Con el objetivo de ofrecerte un servicio aún más completo, hemos llegado
        a un acuerdo con Mapfre para que no tengas que estar pendiente de los
        movimientos que te recomendamos.
      </Text>
      <div className="btn-container">
        <Button className="invest-button" value="Comenzar" />
      </div>
    </MakeInvestmentStyle>
  );
};
export default MakeInvestment;
