import styled from "styled-components";

const LogInFormStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  .form-field {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .email-field {
    input {
      text-overflow: ellipsis;
    }
    padding-bottom: 1rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    text-overflow: ellipsis;
    width: 50%;
    @media (max-width: 1200px) {
      width: 60%;
    }
    @media (max-width: 1060px) {
      width: 70%;
    }
    @media (max-width: 768px) {
      width: 50%;
    }
    @media (max-width: 600px) {
      width: 70%;
      min-width: 300px;
    }
    .icon-div {
      position: absolute;
      top: 0.5rem;
      left: 1rem;
      color: ${(props) => props.theme.colors.icon.fieldIcon};

      z-index: 1;
    }
  }
  .password-field {
    padding-top: 0.5rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    width: 50%;
    @media (max-width: 1200px) {
      width: 60%;
    }
    @media (max-width: 1060px) {
      width: 70%;
    }
    @media (max-width: 768px) {
      width: 50%;
    }
    @media (max-width: 600px) {
      width: 70%;
      min-width: 300px;
    }
    .eyeOpen-img {
      height: 0.875rem;
    }
    .eyeClosed-img {
      height: 1.125rem;
    }
    .icon-div {
      position: absolute;
      top: 1rem;
      left: 0.5rem;
      color: ${(props) => props.theme.colors.icon.fieldIcon};
      transform: translateX(8px);
      z-index: 1;
    }
  }
  .description {
    display: flex;
    justify-content: center;
    padding-top: 1.1rem;
    cursor: pointer;
    text-decoration: underline;
  }
  .button-sec {
    margin: 1.2rem 0 0;

    @media (max-width: 768px) {
      width: 40%;
      margin: 10px 0rem;
    }
    @media (max-width: 400px) {
      min-width: 13rem;
    }
    .log-in-button {
      font-size: 1.1rem;
      max-height: 3.3rem;
    }
  }
`;

export default LogInFormStyle;
