import React, { useEffect, useState, useContext } from "react";
import { ThemeProvider } from "styled-components";
import { AlertContext } from "../../../common/alertContainer";
import { AuthTokenService } from "../../../../services";
import { cargarPosicion } from "../homeServices";
import defaultTheme from "../../../../theme/theme";
import ImageConstants from "../../../../constants/imageConstants/image_constants";
import Loading from "../../../../components/commonModules/Loading";
import PortfolioDataStyle from "./portfolioDataStyle";
import Text from "../../../../theme/font";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const PortfolioData = () => {
  const [portfolioData, setPortfolioData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { open } = useContext(AlertContext);
  const theme = defaultTheme;

  useEffect(() => {
    open({
      alertType: "spinner",
      open: true,
    });
    const userId = AuthTokenService.getUserId();
    cargarPosicion(userId)
      .then((result) => {
        open({
          alertType: "spinner",
          open: false,
        });
        if (result.statusText === "OK" && result.status === 200) {
          const data = result.data.data[0];
          setPortfolioData(data);
          setIsLoading(false);
        }
      })
      .catch(() => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "#30b2b8",
      boxShadow: theme.shadows[3],
      borderRadius: "0.4rem",
      fontSize: 14,
      fontFamily: "Asap",
    },
    span: {
      fontWeight: "bold",
    },
  }));

  const formato = (x, signo = false) => {
    var number = new Intl.NumberFormat("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(x);
    if (signo && x > 0) {
      return "+" + number;
    } else return number;
  };

  const renderhandler = () => {
    if (isLoading || portfolioData == null) {
      return <Loading />;
    } else {
      const { fechaValor } = portfolioData;
      const { fechaAlta } = portfolioData;
      var date = new Date(fechaValor ? fechaValor : fechaAlta);
      return (
        <div className="box-container datosCarteraContainer">
          <div className="portfolioDetailsContainer">
            <div className="dataPortfolioDetails">
              <div className="datosCarteraTitulo">
                <Text variant="labelFont" className="totalText">
                  Total invertido
                </Text>
              </div>
              <div className="datosCarteraTexto portfolio-data-container">
                <img
                  className="datosCarteraImg"
                  alt="x"
                  src={ImageConstants.Grafico}
                ></img>
                <Text variant="titleFont">
                  {formato(portfolioData.aportado)}€
                </Text>
              </div>
            </div>
          </div>
          <div className="portfolios">
            <div className="portfolioDetailsContainer">
              <div className="dataPortfolioDetails">
                <div className="datosCarteraTitulo">
                  <Text variant="labelFont" className="totalText">
                    Nivel de riesgo
                  </Text>
                </div>
                <div className="datosCarteraTexto portfolio-data-container">
                  <img
                    className="datosCarteraImg"
                    alt="x"
                    src={ImageConstants.Candado}
                  ></img>
                  <Text variant="primaryFont">{portfolioData.riesgo} de 6</Text>
                </div>
              </div>
            </div>
            <div className="portfolioDetailsContainer">
              <div className="dataPortfolioDetails date-container">
                <div className="datosCarteraTitulo">
                  <Text variant="labelFont" className="totalText">
                    Valorado a
                  </Text>
                  <LightTooltip
                    title={
                      <div>
                        Esta fecha es el último día al que están valorados
                        <span> todos los fondos</span>
                      </div>
                    }
                    placement="top-end"
                  >
                    <img
                      alt="x"
                      className="info-icon"
                      src={ImageConstants.informationIcon}
                    />
                  </LightTooltip>
                </div>
                <div className="datosCarteraTexto portfolio-data-container">
                  <img
                    className="datosCarteraImg"
                    alt="x"
                    src={ImageConstants.Calendario}
                  ></img>

                  <Text variant="primaryFont">{date.toLocaleDateString()}</Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <PortfolioDataStyle>{renderhandler()}</PortfolioDataStyle>
    </ThemeProvider>
  );
};
export default PortfolioData;
