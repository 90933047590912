import React, { cloneElement, useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import Button from "../button/button";
import ListStyle from "./listStyle";
import ScrollBar from "../scrollbar/scrollbar";
import { ThemeProvider } from "styled-components";
import defaultTheme from "../../theme/theme";
import Text from "../../theme/font";
import { ssrFadeout } from "react-reveal/globals";

const List = React.memo(({ data, children, limit }) => {
  const theme = defaultTheme;
  const [hideButton, setHideButton] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [dataArray, setDataArray] = useState(data && data);
  
  ssrFadeout(false);
  const [listStyle, setListStyle] = useState({});
  const totalData = data;

  useEffect(() => {
    if (data) {
      if (data.length > limit) {
        setHideButton(true);
        setShowButton(true);
        setDataArray(data.slice(0, limit));
      } else {
        setDataArray([...data]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const styleHandler = (list) => {
    if (list.length <= limit) {
      setListStyle({ height: "fit-content" });
    } else {
      setListStyle({
        height: "21.87rem",
      });
    }
  };

  const listHandler = () => {
    if (hideButton) {
      setDataArray(totalData);

      styleHandler(totalData);
    } else {
      setDataArray(totalData.slice(0, limit));
      styleHandler(totalData.slice(0, limit));
    }
    setHideButton(!hideButton);
  };

  return (
    <ThemeProvider theme={theme}>
      <ListStyle>
        <div className="main-list-conatainer" style={listStyle}>
          <ScrollBar elementName={"card_header_container"} max_height={"100%"}>
            <div className="list-conatainer">
              {dataArray &&
                dataArray.map((item) => {
                  return (
                    <Fade bottom key={Math.random()} ssrFadeout>
                      {cloneElement(children, { ...item })}
                    </Fade>
                  );
                })}

              {showButton && (
                <Text type="primary" className="button-container">
                  {/* if hideButton is true then the button will display else button will not display*/}
                  <Button
                    value={hideButton ? "Ver más" : "Ver menos"}
                    className="see-all-button"
                    click={() => listHandler()}
                  />
                </Text>
              )}
            </div>
          </ScrollBar>
        </div>
      </ListStyle>
    </ThemeProvider>
  );
});

export default List;
