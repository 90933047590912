import React, { useState, useContext, useEffect } from "react";
import { AlertContext } from "../../common/alertContainer";
import { AuthTokenService } from "../../../services";
import {
  CreateInsurance,
  getInsuranceOptions,
  postPolicyData,
  updateInsurance,
  getDropDownOptions,
  postNewInsuranceCustomer,
  getInitialInformation,
  getInitialData,
  postInitialData,
  infoClient,
} from "../insuranceServices";
import ProcessPopStyle from "./processPopUpStyle";
import {
  ProcessPopupOne,
  ProcessPopUpSeven,
} from "./processPopUpchild/processPopUpChild";
import { ProcessPopUpTwo } from "./processPopUpchild/processPopUpChild";
import { ProcessPopUpThree } from "./processPopUpchild/processPopUpChild";
import { ProcessPopUpFour } from "./processPopUpchild/processPopUpChild";
import { ProcessPopUpFive } from "./processPopUpchild/processPopUpChild";
import { ProcessPopUpSix } from "./processPopUpchild/processPopUpChild";
import Text from "../../../theme/font";
import {
  CivilPopUp,
  CochePopUp,
  CompanyPopUp,
  HogarPopUp,
  InformationPopUp,
  SaludPopUp,
  VidaPopUp,
} from "./insurancePopUps/insurancePopUps";
import ScrollBar from "../../../components/scrollbar/scrollbar";

const ProcessPopUp = React.memo(({ click }) => {
  const [processStep, setProcessStep] = useState("1");
  const [changeState, setChangeState] = useState(false);
  const ClientInsuranceId = AuthTokenService.getIdClienteSeguros();
  const [insuranceId, setInsuranceId] = useState("");
  const [insuranceOptions, setInsuranceOptions] = useState([]);
  const [policyImages, setPolicyImages] = useState([]);
  const [policyComment, setPolicyComment] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [selected, setSelected] = useState("1");
  const [newUser, setNewUser] = useState(false);
  const [goalValue, setGoalvalue] = useState("");
  const [loadingStep, setLoadingStep] = useState(false);
  const [stepCount, setStepCount] = useState("1");
  const [isClose, setIsClose] = useState(false);
  const [dropDownDetails, setDropDownDetails] = useState("");
  const [initialInformations, setInitialInformations] = useState("");
  const [initialData, setInitialData] = useState([]);
  const { open } = useContext(AlertContext);
  const userId = AuthTokenService.getUserId();

  const clientInsuranceId = parseInt(ClientInsuranceId);

  useEffect(() => {
    open({
      alertType: "spinner",
      open: true,
    });
    getInsuranceOptions(userId)
      .then((response) => {
        open({
          alertType: "spinner",
          open: false,
        });
        const data = response.data;
        setInsuranceOptions(data.tipos);
      })
      .catch(() => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    open({
      alertType: "spinner",
      open: true,
    });
    getDropDownOptions(ClientInsuranceId)
      .then((response) => {
        open({
          alertType: "spinner",
          open: false,
        });
        setDropDownDetails(response?.data?.opciones);
      })
      .catch(() => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: "error encontrado",
          alertType: "toast",
          open: true,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dateFieldHandler = () => {
    open({
      alertType: "spinner",
      open: true,
    });
    getInitialInformation(ClientInsuranceId)
      .then((response) => {
        open({
          alertType: "spinner",
          open: false,
        });
        const initialInformation = response.data.info;
        setInitialInformations(initialInformation);
        setInitialData(initialInformation);
        if (initialInformation === null) {
          getInitialData(ClientInsuranceId)
            .then((value) => {
              open({
                alertType: "spinner",
                open: false,
              });
              const initialData = value?.data?.info;
              setInitialInformations(initialData[0]);
            })
            .catch(() => {
              open({
                alertType: "spinner",
                open: false,
              });
              open({
                type: "error",
                title: "error encontrado",
                alertType: "toast",
                open: true,
              });
            });
        }
      })
      .catch(() => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: "error encontrado",
          alertType: "toast",
          open: true,
        });
      });
  };

  useEffect(() => {
    dateFieldHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const postInsuranceHandler = (value) => {
    open({
      alertType: "spinner",
      open: true,
    });
    let formData = new FormData();
    formData.append("idClienteSeguros", ClientInsuranceId);

    CreateInsurance(formData)
      .then((result) => {
        open({
          alertType: "spinner",
          open: false,
        });
        const data = result?.data;
        setInsuranceId(data?.idSeguro);
        if (data && value === false) {
          setProcessStep("2");
          setStepCount("2");
        }
      })
      .catch(() => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });
  };

  const updateInsuranceHandler = (value, text) => {
    setGoalvalue(value);
    open({
      alertType: "spinner",
      open: true,
    });
    let data = "";
    if (text === "tipo") {
      data = {
        idClienteSeguros: ClientInsuranceId,
        idSeguro: insuranceId,
        tipo: value,
      };
    } else {
      data = {
        idClienteSeguros: ClientInsuranceId,
        idSeguro: insuranceId,
        nuevo: value,
      };
    }
    updateInsurance(data)
      .then((response) => {
        open({
          alertType: "spinner",
          open: false,
        });
      })
      .catch((response) => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: response.response.data.msg,
          alertType: "toast",
          open: true,
        });
      });
  };

  const finalizeProcess = (value) => {
    let data = "";
    data = {
      idClienteSeguros: ClientInsuranceId,
      idSeguro: insuranceId,
      activo: 1,
    };
    updateInsurance(data)
      .then((response) => {
        open({
          alertType: "spinner",
          open: false,
        });
        //Finalize and close
        if (value === "close") {
          click();
        }
      })
      .catch((response) => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: response.response.data.msg,
          alertType: "toast",
          open: true,
        });
      });
  };

  const policyHandler = () => {
    const formData = new FormData();
    formData.append("idClienteSeguros", ClientInsuranceId);
    formData.append("observaciones", policyComment);
    formData.append("compania", companyName);
    if (policyImages.length > 0) {
      // eslint-disable-next-line array-callback-return
      policyImages.map((file, index) => {
        formData.append("files[]", file);
      });
    } else {
      formData.append("files[]", policyImages);
    }
    console.log(formData);
    // console.log([...formData.entries()]);
    open({
      alertType: "spinner",
      open: true,
    });

    postPolicyData(formData)
      .then((response) => {
        open({
          alertType: "spinner",
          open: false,
        });
      })
      .catch(() => {
        open({
          alertType: "spinner",
          open: false,
        });
      });
  };

  const initialDataSubmitHandler = (data) => {
    open({
      alertType: "spinner",
      open: true,
    });

    if (initialData === null) {
      postInitialData(data)
        .then(() => {
          open({
            alertType: "spinner",
            open: false,
          });
        })
        .catch(() => {
          open({
            alertType: "spinner",
            open: false,
          });
          open({
            type: "error",
            title: "Error al enviar datos",
            alertType: "toast",
            open: true,
          });
        });
    } else {
      infoClient(data)
        .then((response) => {
          open({
            alertType: "spinner",
            open: false,
          });
        })
        .catch(() => {
          open({
            alertType: "spinner",
            open: false,
          });
          open({
            type: "error",
            title: "Error al enviar datos",
            alertType: "toast",
            open: true,
          });
        });
    }
  };

  const dataSubmitHandler = (data) => {
    open({
      alertType: "spinner",
      open: true,
    });
    postNewInsuranceCustomer(data)
      .then((result) => {
        open({
          alertType: "spinner",
          open: false,
        });
        setProcessStep("6");
        setIsClose(true);
        setStepCount("7");
      })
      .catch(() => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });
  };

  const backButtonHandler = () => {
    if (changeState) {
      setProcessStep("5");
      setStepCount("6");
    } else {
      setProcessStep("4");
      setStepCount("5");
    }
  };

  const loaderStyleHandler = (step) => {
    // eslint-disable-next-line default-case
    if (loadingStep) {
      switch (step) {
        case "1": {
          return { width: "16.66%" };
        }
        case "2": {
          return { width: "33.33%" };
        }
        case "3": {
          return { width: "42.85%" };
        }
        case "4": {
          return { width: "57.13%" };
        }
        case "5": {
          return { width: "71.41%" };
        }
        case "6": {
          return { width: "85.69%" };
        }
        case "7": {
          return { width: "100%" };
        }
        default:
      }
    } else {
      switch (step) {
        case "1": {
          return { width: "16.66%" };
        }
        case "2": {
          return { width: "33.33%" };
        }
        case "3": {
          return { width: "50%" };
        }
        case "4": {
          return { width: "66.66%" };
        }
        case "5": {
          return { width: "83.33%" };
        }
        case "6": {
          return { width: "100%" };
        }
        default:
      }
    }
  };

  const closesProcess = () => {
    click();
  };

  const popUpContentHandler = (step) => {
    // eslint-disable-next-line default-case
    switch (step) {
      case "1": {
        return <ProcessPopupOne submitClick={(e) => postInsuranceHandler(e)} />;
      }
      case "2": {
        return (
          <ProcessPopUpTwo buttonClick={setProcessStep} step={setStepCount} />
        );
      }
      case "3": {
        return (
          <ProcessPopUpThree
            buttonClick={setProcessStep}
            submitClick={updateInsuranceHandler}
            check={setChangeState}
            select={setSelected}
            selectedState={selected}
            checkStep={setLoadingStep}
            step={setStepCount}
          />
        );
      }
      case "4": {
        return (
          <ProcessPopUpFour
            buttonClick={setProcessStep}
            submitClick={updateInsuranceHandler}
            check={changeState}
            data={insuranceOptions}
            isNewUser={newUser}
            step={setStepCount}
            updateHandler={dateFieldHandler}
          />
        );
      }
      case "5": {
        return (
          <ProcessPopUpFive
            buttonClick={setProcessStep}
            policyData={setPolicyImages}
            policyText={setPolicyComment}
            submitClick={policyHandler}
            companyName={setCompanyName}
            step={setStepCount}
            goal={goalValue}
          />
        );
      }
      case "6": {
        return (
          <ProcessPopUpSix
            click={(e) => finalizeProcess(e)}
            close={isClose}
            closesProcess={closesProcess}
            buttonClick={setProcessStep}
            step={setStepCount}
            reDoProcess={(e) => postInsuranceHandler(e)}
          />
        );
      }
      case "7": {
        return (
          <ProcessPopUpSeven
            buttonClick={setProcessStep}
            isNewUser={setNewUser}
            step={setStepCount}
            insuranceId={clientInsuranceId}
            initialDatas={initialInformations}
            submitClick={(e) => initialDataSubmitHandler(e)}
          />
        );
      }
      case "coche": {
        return (
          <CochePopUp
            buttonClick={setProcessStep}
            step={setStepCount}
            close={setIsClose}
            check={changeState}
            goal={goalValue}
            dropDownData={dropDownDetails}
            submitClick={(e) => dataSubmitHandler(e)}
            insuranceId={insuranceId}
            backButtonClick={backButtonHandler}
          />
        );
      }
      case "hogar": {
        return (
          <HogarPopUp
            check={changeState}
            dropDownData={dropDownDetails}
            submitClick={(e) => dataSubmitHandler(e)}
            insuranceId={insuranceId}
            backButtonClick={backButtonHandler}
          />
        );
      }
      case "vida": {
        return (
          <VidaPopUp
            check={changeState}
            dropDownData={dropDownDetails}
            submitClick={(e) => dataSubmitHandler(e)}
            insuranceId={insuranceId}
            backButtonClick={backButtonHandler}
            initialDatas={initialInformations}
          />
        );
      }
      case "salud": {
        return (
          <SaludPopUp
            check={changeState}
            dropDownData={dropDownDetails}
            initialDatas={initialInformations}
            submitClick={(e) => dataSubmitHandler(e)}
            insuranceId={insuranceId}
            backButtonClick={backButtonHandler}
          />
        );
      }

      case "moto": {
        return (
          <CochePopUp
            check={changeState}
            goal={goalValue}
            dropDownData={dropDownDetails}
            submitClick={(e) => dataSubmitHandler(e)}
            insuranceId={insuranceId}
            backButtonClick={backButtonHandler}
          />
        );
      }
      case "empresa": {
        return (
          <CompanyPopUp
            check={changeState}
            dropDownData={dropDownDetails}
            submitClick={(e) => dataSubmitHandler(e)}
            insuranceId={insuranceId}
            backButtonClick={backButtonHandler}
          />
        );
      }
      case "resp. civil": {
        return (
          <CivilPopUp
            check={changeState}
            dropDownData={dropDownDetails}
            submitClick={(e) => dataSubmitHandler(e)}
            insuranceId={insuranceId}
            backButtonClick={backButtonHandler}
          />
        );
      }
      case "resp. civil": {
        return (
          <CivilPopUp
            check={changeState}
            dropDownData={dropDownDetails}
            submitClick={(e) => dataSubmitHandler(e)}
            insuranceId={insuranceId}
            backButtonClick={backButtonHandler}
          />
        );
      }
      case "otros": {
        return (
          <InformationPopUp
            submitClick={(e) => dataSubmitHandler(e)}
            insuranceId={insuranceId}
            backButtonClick={backButtonHandler}
          />
        );
      }
      case "": {
        click();
      }
    }
  };

  return (
    <ProcessPopStyle>
      <ScrollBar elementName={"card_header_container"} max_height={"90vh"}>
        <div className="process-popup">
          <div className="main-step-container">
            <div className="step-container">
              <Text className="step-count">
                {loadingStep ? `Paso ${stepCount}/7` : `Paso ${stepCount}/7`}
              </Text>
              <div className="step-loader">
                <div
                  className="inner-step-loader"
                  style={loaderStyleHandler(stepCount)}
                ></div>
              </div>
            </div>
          </div>
          {popUpContentHandler(processStep)}
        </div>
      </ScrollBar>
    </ProcessPopStyle>
  );
});
export default ProcessPopUp;
