import styled from "styled-components";

const MicappitalConnectStyle = styled.div`
  display: flex;
  justify-content: center;
  .micappital-connect-container {
    padding: 1.25rem;
    width: 100%;
    @media (max-width: 1040px) {
      max-width: 19.37rem;
    }
    @media (max-width: 400px) {
      max-width: 100%;
    }
    .micappital-connect-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .micappital-connect-heading {
        font-weight: bold;
        color: ${(props) => props.theme.colors.fontColor.titleFontColor};
      }
      .connect-arrow-icon {
        height: 1.25rem;
      }
    }
    .micappital-connect-description {
      font-weight: 500;
      padding: 1rem 0;
      color: ${(props) => props.theme.colors.fontColor.descriptionFontColor};
    }
    .status-text {
      color: ${(props) => props.theme.colors.fontColor.descriptionFontColor};
      font-weight: bold;
    }
    .micappital-connected-container {
      display: flex;
      align-items: center;
      padding: 1rem 0;
      gap: 2rem;
      .connected-title {
        font-weight: bold;
        color: ${(props) => props.theme.colors.fontColor.titleFontColor};
      }
      .connected-icon {
        height: 1.25rem;
      }
    }
    .micappital-not-connected-container {
      display: flex;
      align-items: center;
      padding: 1rem 0 0 0;
      justify-content: space-between;
      flex-direction: column;
      .connect-title-container {
        display: flex;
        align-items: baseline;
        justify-content: flex-start;
        width: 100%;
        .estado-title {
          font-weight: bold;
        }
        .not-connected-description-container {
          display: flex;
          flex-direction: column;
          margin-left: 0.625rem;

          .not-connected-title {
            font-weight: bold;
            color: ${(props) =>
              props.theme.colors.button.background.redButton};
          }
          .connecting-title {
            font-weight: bold;
            color: #2C787C;
          }
          .connecting-description {
            color: #2C787C;
            font-size:0.9rem;
          }
        }
      }
      .connect-button-container {
        width: 100%;
        margin-top: 1rem;
        .connect-button {
          width: 100%;
          min-height: 2.5rem;
          max-width: 12rem;
        }
      }
    }
  }
`;
export default MicappitalConnectStyle;
