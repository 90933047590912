import { Request } from "../../../services";
import URL_CONSTANTS from "../../../constants/urlConstants/urlConstants";

export const postAmount = (data) => {
  return Request.post(URL_CONSTANTS.make_contribution, data);
};
export const getDetails = (data) => {
  return Request.get(`${URL_CONSTANTS.get_fund_details}/${data}`);
};

export const getContributionData = (data) => {
  return Request.get(`${URL_CONSTANTS.get_contribution_data}/${data}`);
};
