import { createContext, useCallback } from "react";
import { useState } from "react";
import Container from "./container";

export const ContainerContext = createContext(null);

const ContainerProvider = ({ children }) => {
  const [navBarHidden, setNavBarHidden] = useState(false);
  const [menuHidden, setMenuHidden] = useState(false);
  const [alertHidden, setAlertHidden] = useState(true);
  const [title, setTitle] = useState("");
  const [headerTitle, setHeaderTitle] = useState("");
  const [pageName, setPageName] = useState("");
  const [image, setImage] = useState(null);
  const changeNavBarStatus = useCallback((value) => setNavBarHidden(value), []);
  const changeMenuStatus = useCallback((value) => setMenuHidden(value), []);
  const changeAlertStatus = useCallback((value) => setAlertHidden(value), []);
  const titleHandler = useCallback((value) => setTitle(value), []);
  const headerTitleHandler = useCallback((value) => setHeaderTitle(value), []);
  const profileIConHandler = useCallback((value) => setImage(value), []);
  const pageNameHandler = useCallback((value) => setPageName(value), []);

  return (
    <ContainerContext.Provider
      value={{
        changeMenuStatus,
        changeNavBarStatus,
        changeAlertStatus,
        titleHandler,
        headerTitleHandler,
        profileIConHandler,
        pageNameHandler,
      }}
    >
      <Container
        isMenuHide={menuHidden}
        isNavBarHide={navBarHidden}
        title={title}
        headerTitle={headerTitle}
        profileIcon={image}
        pageName={pageName}
        isAlertHide={alertHidden}
      >
        {children}
      </Container>
    </ContainerContext.Provider>
  );
};

export default ContainerProvider;
