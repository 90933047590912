import { useEffect, useContext } from "react";
import { ThemeProvider } from "styled-components";
import { ContainerContext } from "../../../components/navbarContainer/containerProvider";
import defaultTheme from "../../../theme/theme";
import MakeInvestment from "./makeInvestmentSection/makeInvest";
import MicappitalZeroStyle from "./micappitalZeroStyle";
import ServiceStion from "./serviceSection/serviceSection";
import SupportSection from "./supportSection/supportSection";

const MicappitalZero = () => {
  const { titleHandler, headerTitleHandler, pageNameHandler } =
    useContext(ContainerContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    toogleNavBar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toogleNavBar = () => {
    titleHandler("Micappital Zero");
    headerTitleHandler("Seguros");
    pageNameHandler("Autopilot");
  };

  const theme = defaultTheme;
  return (
    <ThemeProvider theme={theme}>
      <MicappitalZeroStyle>
        <div className="primary-section">
          <div className="support-section box-container">
            <SupportSection />
          </div>
          <div className="serviceSection box-container">
            <ServiceStion />
          </div>
        </div>
        <div className="secondary-section">
          <div className="investment-section">
            <MakeInvestment />
          </div>
        </div>
      </MicappitalZeroStyle>
    </ThemeProvider>
  );
};
export default MicappitalZero;
