import { alertActionType } from "./alertMessageActionType";

export const setConnectionData = (data) => {
  return {
    type: alertActionType.CONNECTED_DATA,
    payload: data,
  };
};

export const setHideAlert = (data) => {
  return {
    type: alertActionType.HIDE_ALERT,
    payload: data,
  };
};

export const setHideSecondAlert = (data) => {
  return {
    type: alertActionType.HIDE_SECOND_ALERT,
    payload: data,
  };
};
