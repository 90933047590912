import styled from "styled-components";

const EditObjectiveStyle = styled.div`
  padding-right: 2rem;
  @media (max-width: 1040px) {
    padding: 0rem 1rem;
  }
  .back-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2, 187rem;
    width: 2, 187rem;
    position: fixed;
    top: 1.8rem;
    left: 3rem;
    border-radius: 1.562rem;
    z-index: 1;
    @media (max-width: 1040px) {
      display: none;
    }
    cursor: pointer;
    img {
      width: 1.5625rem;
      height: 1.5625rem;
    }
  }
  .Edit-inner-container {
    @media (max-width: 991px) {
      display: flex;
      gap: 2rem;
      padding-bottom: 2rem;
    }
  }
  .popUP-wrapper {
    position: fixed;
    z-index: 150;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    .popUp-overlay {
      width: 100%;
      height: 100%;
      background-color: ${(props) =>
        props.theme.colors.background.overlayBackground};
      opacity: 0.5;
    }
    .popUp-container {
      position: absolute;
      width: 45%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: auto;
      max-height: 100%;
      padding-top: 2rem;
      padding-bottom: 2rem;
      max-width: 58.125rem;
      @media (max-width: 680px) {
        width: 85%;
      }
    }
  }
`;
export default EditObjectiveStyle;
