import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Slide } from "react-toastify";
import { ToastContainer } from "react-toastify";
import AccountSettings from "./container/common/accountSettings/accountSettings.jsx";
import AddFriends from "./container/investment/addFriends/addFriends";
import Autopilot from "./container/investment/autopilot/autopilot.jsx";
import AllNews from "./container/investment/home/news/allNews/allNews";
import ContainerProvider from "./components/navbarContainer/containerProvider";
import Contributions from "./container/investment/contributions/contributions.jsx";
import DefaultScreen from "./container/investment/defaultScreen/defaultScreen";
import EcoInvestments from "./container/investment/ecoInvestment/ecoInvestment";
import EditObjective from "./container/investment/home/goal/editObjective/editObjective";
import FundProposal from "./container/investment/fundProposal/fundProposal.jsx";
import Home from "./container/investment/home/home";
import LogIn from "./container/common/logIn/logIn";
import MicappitalEco from "./container/investment/micappitalEco/micappitalEco";
import MicappitalZero from "./container/investment/micappitalZero/micappitalZero";
import MyOptimization from "./container/investment/myOptimization/myOptimization";
import NewObjective from "./container/investment/home/goal/newObjective/newObjective";
import NewPassword from "./container/common/forgotPassword/newPassword/newPassword";
import Proposal from "./container/investment/proposal/proposal";
import RecoverPassword from "./container/common/forgotPassword/recoverPassword/recoverPassword";
import SingleNews from "./container/investment/home/news/allNews/singleNews/singleNews";
import Supercuenta from "./container/common/supercuenta/supercuenta";
import AltaFinect from "./container/altafinect/altafinect";
import CodeFlanks from "./container/codeflanks/codeflanks";
import NotFound from "./container/common/notFound/notFound.jsx";
import Logout from "./container/common/logout/logout.jsx";
import LandingPage from "./container/insurance/landingPage/landingPage.jsx";
import InsuranceList from "./container/insurance/insuranceList/insuranceList.jsx";
import InsuranceDetails from "./container/insurance/insuranceDetails/insuranceDetails.jsx";
import ProcesoAlta from "./container/onboardingAutopilot/procesoAlta";
import RenderContainer from "./container/insurance/renderContainer/renderContainer.jsx";
import InvestmentLandingPage from "./container/investment/landingPage/landingPage.jsx";
import ClickPopup from "./container/insurance/processPopUp/clickPopup/clickPopup.jsx";

import AutopilotAdditionalData from "./container/autopilot/autopilotAdditionalData.js";
import AutopilotAdditionalData2 from "./container/autopilot/autopilotAdditionalData2.js";
import AutopilotDni from "./container/autopilot/autopilotDni.js";
import AutopilotIncomeProof from "./container/autopilot/autopilotIncomeProof.js";
import AutopilotContract from "./container/autopilot/autopilotContract.js";
import AutopilotProposal from "./container/autopilot/autopilotProposal.js";
import AutopilotPlan from "./container/autopilot/autopilotPlan.js";
import NuevoAutopilot from "./container/autopilot/nuevoAutopilot.js";
import AutopilotIBAN from "./container/autopilot/autopilotIBAN.js";


const App = () => {
  const appHeight = () => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
        navigator.userAgent
      )
    ) {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", `${window.innerHeight}px`);
    }
  };

  window.addEventListener("resize", appHeight);
  appHeight();

  return (
    <Router>
      <ContainerProvider>
        <div className="width-divider floatRight full-width">
          <ToastContainer
            style={{ minWidth: "18rem" }}
            transition={Slide}
            theme="colored"
            position="bottom-right"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Routes>
            <Route path="/" element={<LogIn />} />
            <Route path="/ajustes" element={<AccountSettings />} />
            <Route path="/aportaciones" element={<Contributions />} />
            <Route path="/propuesta" element={<Proposal />} />
            <Route path="/resumen" element={<Home />} />
            <Route path="/amigos" element={<AddFriends />} />
            <Route path="/autopilot" element={<Autopilot />} />
            <Route path="/nuevoAutopilot" element={<NuevoAutopilot />} />
            <Route path="/datosAutopilot" element={<AutopilotAdditionalData />} />
            <Route path="/datosAutopilot2" element={<AutopilotAdditionalData2 />} />
            <Route path="/dniAutopilot" element={<AutopilotDni />} />
            <Route path="/justificanteAutopilot" element={<AutopilotIncomeProof />} />
            <Route path="/contratoAutopilot" element={<AutopilotContract />} />
            <Route path="/propuestaAutopilot" element={<AutopilotProposal />} />
            <Route path="/planAutopilot" element={<AutopilotPlan />} />
            <Route path="/traspasoAutopilot" element={<AutopilotIBAN />} />
            <Route path="/eco" element={<MicappitalEco />} />
            <Route path="/login" element={<LogIn />} />
            <Route path="/propuestaFondos" element={<FundProposal />} />
            <Route path="/editarObjetivo" element={<EditObjective />} />
            <Route path="/conoceEco" element={<DefaultScreen />} />
            <Route path="/nuevoObjetivo" element={<NewObjective />} />
            <Route path="/optimizacion" element={<MyOptimization />} />
            <Route path="/recuperarClaves" element={<RecoverPassword />} />
            <Route path="/noticias" element={<AllNews />} />
            <Route path="/noticia" element={<SingleNews />} />
            <Route path="/password/:hash" element={<NewPassword />} />
            <Route path="/micappitalZero" element={<MicappitalZero />} />
            <Route path="/ecoInvestments" element={<EcoInvestments />} />
            <Route path="/supercuenta" element={<Supercuenta />} />
            <Route path="/bienvenido" element={<Supercuenta />} />
            <Route path="/altafinect" element={<AltaFinect />} />
            <Route path="/codeFlanks" element={<CodeFlanks />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/landingPage" element={<LandingPage />} />
            <Route path="/insuranceList" element={<InsuranceList />} />
            <Route path="/insuranceDetails" element={<InsuranceDetails />} />
            <Route path="/renderContainer" element={<RenderContainer />} />
            <Route path="/procesoAlta/:idNR" element={<ProcesoAlta key="alta" />} />
            <Route
              path="/investmentLandingPage"
              element={<InvestmentLandingPage />}
            />
            <Route path="/clickpopup" element={<ClickPopup />} />
          </Routes>
        </div>
      </ContainerProvider>
    </Router>
  );
};

export default App;
