import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { AlertContext } from "../../common/alertContainer";
import { AuthTokenService } from "../../../services";
import { ContainerContext } from "../../../components/navbarContainer/containerProvider";
import { cargarRecomendacionInicial } from "./proposalServices";
import defaultTheme from "../../../theme/theme";
import Diversification from "./diversification/diversification.jsx";
import Doubts from "./doubts/doubts";
import Evolution from "./evolution/evolution";
import FundPopUp from "../home/fundPopUp/fundPopUp";
import MicappitalConnect from "./micappitalConnect/micappitalConnect";
import { cargarPosicion, micappitalConnection } from "../home/homeServices";
import ProposalStyle from "./proposalStyle";
import StartingManual from "./startingManual/startingManual";
import SummaryActions from "./summaryActions/summaryActions.jsx";
import Video from "./video/video";
import AlertMessage from "../../../components/alertmessage/alertMessage";
import { getBankDetails } from "./proposalServices";
import { getInvestmentDetails } from "./proposalServices";
import InvestmentDetailsPopUp from "./investmentDetailsPopUP/investmentDetailsPopUp";

const Proposal = React.memo(() => {
  const theme = defaultTheme;
  const [step, setStep] = useState(1);
  const [amount, setAmount] = useState(0);
  const [listShow, setListShow] = useState([]);
  const [investmentData, setInvestmentData] = useState({});
  const [connectUrl, setConnectUrl] = useState("");
  const [connectedData, setConnectedData] = useState("");
  const [micappitalConnectionData, setMicappitalConnectionData] = useState("");
  const [checkScroll, setCheckScoll] = useState(true);
  const [showPopUp, setShowPopUp] = useState(false);
  const [showInvestmentPopUp, setShowInvestmentPopUp] = useState(false);
  const [totalFunds, setTotalFunds] = useState("");
  const [videoData, setVideoData] = useState([]);
  const [valorData, setValorData] = useState("");
  const videoRef = useRef();
  const connectRef = useRef();
  const rightModuleRef = useRef();
  const stepRef = useRef(1);
  const primaryRef = useRef();
  const {
    titleHandler,
    headerTitleHandler,
    pageNameHandler,
    changeAlertStatus,
  } = useContext(ContainerContext);
  const { open } = useContext(AlertContext);
  const navigate = useNavigate();
  let timer = null;

  useEffect(() => {
    // let browser = navigator.userAgent;
    // if(/mobi/i,test(browser)){
    //   console.log("mobile")
    // }else{
    //   console.log("not-mobile")
    // }

    window.history.scrollRestoration = "manual";
    window.scrollTo(0, 0);
    titleHandler("Propuesta inicial");
    headerTitleHandler("Inversión");
    pageNameHandler("Propuesta inicial");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let primary = document.getElementById("primary-container");
    primary.addEventListener(
      "scroll",
      () => {
        if (timer !== null) {
          clearTimeout(timer);
          setCheckScoll(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        timer = setTimeout(function () {
          setCheckScoll(true);
        }, 50);
      },
      false
    );
  });

  useEffect(() => {
    const userId = AuthTokenService.getUserId();
    micappitalConnection(userId)
      .then((result) => {
        if (result.statusText === "OK" && result.status === 200) {
          const totalConnectionData = result.data.data.mode;
          setConnectUrl(result.data.data.micappitalconnect.finectregisterurl);
          setConnectedData(totalConnectionData);
          setMicappitalConnectionData(
            result.data.data.micappitalconnect.connected
          );
        }
      })
      .catch(() => {
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });
    cargarRecomendacionInicial(userId)
      .then((result) => {
        open({
          alertType: "spinner",
          open: false,
        });
        if (result.statusText === "OK" && result.status === 200) {
          setTotalFunds(result.data.fondos);
        }
      })
      .catch(() => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    changeAlertStatus(false);
    document.addEventListener("scroll", scrollChangeHandler);

    return () => {
      changeAlertStatus(true);
      document.removeEventListener("scroll", scrollChangeHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const userId = AuthTokenService.getUserId();
    getBankDetails(userId)
      .then((result) => {
        open({
          alertType: "spinner",
          open: false,
        });
        if (result?.data) {
          const data = result.data;
          setVideoData(data);
        }
      })
      .catch(() => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });
    cargarPosicion(userId)
      .then((result) => {
        open({
          alertType: "spinner",
          open: false,
        });
        if (result?.data) {
          const data = result?.data?.data[0].valor;
          setValorData(data);
        }
      })
      .catch(() => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    open({
      alertType: "spinner",
      open: true,
    });
    const userId = AuthTokenService.getUserId();
    getInvestmentDetails(userId)
      .then((result) => {
        open({
          alertType: "spinner",
          open: false,
        });
        const data = result?.data;
        setInvestmentData(data);
      })
      .catch(() => {
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //for changing the steps according to the scroll
  const scrollChangeHandler = () => {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight / 2;

    if (windowWidth > 1040) {
      if (videoRef.current && rightModuleRef.current) {
        const videoPosition = videoRef.current.offsetTop - windowHeight;
        const connectPosition = connectRef.current.offsetTop - windowHeight;

        if (
          window.pageYOffset >= videoPosition &&
          window.pageYOffset < connectPosition &&
          stepRef.current !== 2
        ) {
          setStep(2);
          stepRef.current = 2;
        }
        if (window.pageYOffset >= connectPosition && stepRef.current !== 3) {
          setStep(3);
          stepRef.current = 3;
        }
        if (window.pageYOffset < videoPosition && stepRef.current !== 1) {
          setStep(1);
          stepRef.current = 1;
        }
      }
    }
  };

  //for changing the steps according to the scroll in responsive view
  const responsiveScrollChangeHandler = (e) => {
    let topValue = e.target.scrollTop;
    const windowHeight = window.innerHeight / 2;
    if (videoRef.current && connectRef.current) {
      const videoPosition = videoRef.current.offsetTop - windowHeight;
      const connectPosition = connectRef.current.offsetTop - windowHeight;
      if (
        topValue >= videoPosition &&
        topValue < connectPosition &&
        stepRef.current !== 2
      ) {
        setStep(2);
        stepRef.current = 2;
      }
      if (topValue >= connectPosition && stepRef.current !== 3) {
        setStep(3);
        stepRef.current = 3;
      }
      if (topValue < videoPosition && stepRef.current !== 1) {
        setStep(1);
        stepRef.current = 1;
      }
    }
  };

  //for showing the changed amount and values in list
  const listShowHandler = (params, passedData) => {
    setListShow([...params]);
    const totalData = [...params];
    const renamedData = totalData.map(({ ISIN, importe, nombre }) => ({
      ISIN: ISIN,
      valor: importe,
      nombre: nombre,
    }));
    setTotalFunds(renamedData);
    setAmount(passedData);
  };
  //scroll to video when click on the button in starting manual
  const scrollToVideo = () => {
    const element = document.getElementById("video-container");
    window.scrollTo(0, element.offsetTop - 95);
  };

  //scroll to video when click on the link on the right side
  const linkClickToVideo = () => {
    let primary = document.getElementById("primary-container");
    const videoElement = document.getElementById("video-container");
    const windowWidth = window.innerWidth;
    if (windowWidth > 1040) {
      window.scrollTo(0, videoElement.offsetTop - 95);
    } else {
      primary.scrollTo(0, videoElement.offsetTop);
    }
  };

  //for changing the steps according to the button click
  const handleNextStep = () => {
    let primary = document.getElementById("primary-container");
    // eslint-disable-next-line default-case
    const windowWidth = window.innerWidth;
    if (windowWidth > 1040) {
      switch (step) {
        case 1:
          const videoElement = videoRef.current.offsetTop - 50;
          window.scrollTo(0, videoElement);
          break;
        case 2:
          //In the second case if the user is micappital connected and valor is o, then it will redirect to resumen else to the next step
          if (micappitalConnectionData && parseFloat(valorData) === 0) {
            navigate("/resumen");
          } else {
            const connectElement = connectRef.current.offsetTop - 50;
            window.scrollTo(0, connectElement);
          }
          break;
        case 3:
          window.open(connectUrl);
          break;
        default:
          window.scrollTo(0, 0);
      }
    } else {
      switch (step) {
        case 1:
          const videoElement = videoRef.current.offsetTop - 50;
          primary.scrollTo(0, videoElement);
          break;
        case 2:
          if (micappitalConnectionData && parseFloat(valorData) === 0) {
            navigate("/resumen");
          } else {
            const connectElement = connectRef.current.offsetTop - 50;
            primary.scrollTo(0, connectElement);
          }

          break;
        case 3:
          window.open(connectUrl);
          break;
        default:
          primary.scrollTo(0, 0);
      }
    }
    setStep(step + 1);
    stepRef.current = stepRef.current + 1;
  };

  //for changing the steps according to the dot click
  const dotClickHandler = (value) => {
    let primary = document.getElementById("primary-container");
    const parentHeight = primary.clientHeight;
    switch (value) {
      case 1:
        primary.scrollTo(0, 0);
        break;
      case 2:
        const videoElement = videoData?.basicInfo?.enlacevideo
          ? videoRef.current.offsetTop
          : videoRef.current.offsetTop - parentHeight / 2;
        primary.scrollTo(0, videoElement);
        break;
      case 3:
        const connectElement = connectRef.current.offsetTop;
        primary.scrollTo(0, connectElement);
        break;
      default:
        primary.scrollTo(0, 0);
    }
    setStep(value);
    stepRef.current = value;
  };

  //changing the state for closing the state
  const popUpCloseHandler = () => {
    setShowPopUp(false);
    setShowInvestmentPopUp(false);
  };

  //changing the state for showing the popup
  const popUpOpenHandler = () => {
    setShowPopUp(true);
  };

  //For opening investment details popup
  const showInvestmentPopUpHandler = () => {
    setShowInvestmentPopUp(true);
  };

  //It contains the popUp
  const popUpHandler = () => {
    return (
      <div className="popUp-wrapper">
        <div
          className="popUp-overlay"
          onClick={() => popUpCloseHandler()}
        ></div>
        <div className="popUp-container">
          <FundPopUp data={totalFunds} closePopUp={popUpCloseHandler} />
        </div>
      </div>
    );
  };

  const openInvestmentPopUpHandler = () => {
    return (
      <div className="popUp-wrapper">
        <div
          className="popUp-overlay"
          onClick={() => popUpCloseHandler()}
        ></div>
        <div className="investment-popUp-container">
          <InvestmentDetailsPopUp data={investmentData} />
        </div>
      </div>
    );
  };

  const renderComponents = () => {
    return (
      <div className="render-container">
        <div className="responsive-spacer"></div>
        <StartingManual
          data={listShow}
          videoClick={scrollToVideo}
          popUpClick={popUpOpenHandler}
        />
        <div className="spacer"></div>
        <Diversification popUpClick={showInvestmentPopUpHandler} />
        <div className="spacer"></div>
        <Evolution data={amount} />
      </div>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <ProposalStyle>
        <div className="primary-alert-container">
          <AlertMessage />
        </div>
        <div
          className="main-container responsive-div"
          onScroll={scrollChangeHandler()}
        >
          {showPopUp && (
            <div className="investment-popup">{popUpHandler()}</div>
          )}
          {showInvestmentPopUp && (
            <div className="investment-popup">
              {openInvestmentPopUpHandler()}
            </div>
          )}
          <div
            className="primary-section responsive-container"
            id="primary-container"
            ref={primaryRef}
            onScroll={responsiveScrollChangeHandler}
          >
            <div className="secondary-alert-container">
              <AlertMessage />
            </div>
            <div className="new-section">
              {renderComponents()}
              <div className="spacer" id="video-container" ref={videoRef}></div>
              <div className="video-render-container">
                <div>
                  <Video video={videoData} />
                </div>
              </div>
              <div
                className="res-spacer"
                id="connect-container"
                ref={connectRef}
              ></div>
              <div className="connect-render-container">
                <div>
                  <MicappitalConnect
                    url={connectUrl}
                    connectedData={connectedData}
                  />
                </div>
              </div>
              <Doubts />
            </div>
          </div>
          <div className="secondary-section">
            <div className="summary-actions-container" ref={rightModuleRef}>
              <SummaryActions
                listShow={listShowHandler}
                step={step}
                clickNextStep={handleNextStep}
                dotClick={dotClickHandler}
                click={linkClickToVideo}
                check={checkScroll}
                video={videoData}
              />
            </div>
          </div>
        </div>
      </ProposalStyle>
    </ThemeProvider>
  );
});

export default Proposal;
