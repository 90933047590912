import React, { useState, useEffect, useContext} from 'react';
import AltaStyle from './altaStyle';
import { getLoginData, patchNR} from './procesoAltaServices';
import { useNavigate } from "react-router-dom";
import { Form,Row, Col } from "react-bootstrap";
import { AlertContext } from '../common/alertContainer';
import { ContainerContext } from '../../components/navbarContainer/containerProvider';
import { AuthTokenService } from '../../services';
import { subirArchivoIronia, patchIroniaData } from '../investment/autopilot/autopilotServices';


export default function AutopilotDni(){

    const [error, setError]=useState(false);
    const [intento, setIntento]=useState(false);
    const [menuHidden] = useState(true);
    const { changeMenuStatus, changeNavBarStatus, changeAlertStatus } =
    useContext(ContainerContext);
    const navigate=useNavigate();
    const [dniFront, setDniFront]=useState(null);
    const [dniBack, setDniBack]=useState(null);
    const nombre = AuthTokenService.getNombre();
    const [idExpiration, setIdExpiration]=useState(null);
    const { open } = useContext(AlertContext);

    useEffect(() => {
        window.scrollTo(0, 0);
        changeMenuStatus(menuHidden);
        changeNavBarStatus(true);
        changeAlertStatus(false);
        return () => {
          changeMenuStatus(!menuHidden);
          changeNavBarStatus(false);
          changeAlertStatus(true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [changeMenuStatus, changeNavBarStatus]);

    const handleDniFrontChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Verifica si el archivo es de tipo imagen o PDF antes de guardarlo
            if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf') {
                setDniFront(file);
            } else {
                alert('Por favor, selecciona un archivo de imagen o PDF válido.');
            }
        }
    };
    const handleDniBackChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Verifica si el archivo es de tipo imagen o PDF antes de guardarlo
            if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf') {
                setDniBack(file);
            } else {
                alert('Por favor, selecciona un archivo de imagen o PDF válido.');
            }
        }
    };

    const handleIdExpirationChange = (event) => {
        const newExpirationDate = event.target.value;
        setIdExpiration(newExpirationDate);
        // Puedes realizar más lógica si es necesario con la nueva fecha de caducidad.
    };

    
    const handleGuardar = () => {
        open({
            alertType: "spinner",
            open: true,
          }); 
        const formData = new FormData();
            formData.append('file', dniFront);
            formData.append('idCliente', AuthTokenService.getUserId());
            formData.append('tipo', 'idFront');
        subirArchivoIronia(formData)
            .then((result)=>{
                if (result.data.status==200){
                    const formData = new FormData();
                        formData.append('file', dniBack);
                        formData.append('idCliente', AuthTokenService.getUserId());
                        formData.append('tipo', 'idBack');
                    subirArchivoIronia(formData)
                        .then((result2)=>{
                            if (result2.data.status==200){
                                var datos={
                                    idCliente:AuthTokenService.getUserId(),
                                    idExpiration: idExpiration
                                };
                                patchIroniaData(datos)
                                    .then((result3)=>{
                                        if (result3.data.status==200){
                                            navigate("/autopilot");
                                        }
                                        else{
                                            setError(true);
                                        }
                                    })
                                    .catch(() => {
                                        open({
                                          alertType: "spinner",
                                          open: false,
                                        });
                                      });
                            }
                            else{
                                setError(true);
                            }
                        })
                        .catch(() => {
                            open({
                              alertType: "spinner",
                              open: false,
                            });
                          });
                }
                else{
                    setError(true);
                }
            })
            .catch(() => {
                open({
                  alertType: "spinner",
                  open: false,
                });
              });
    };


    return (
        <AltaStyle >
            <div className="bodyProcesoAlta">
                <div className="navbar navbar-expand-md navbar-light" style={{paddingLeft:'2.5rem'}}>
                    
                </div>
                <div className="process-popup container">
                    <div className="main-step-container">

                        <a className="navbar-brand">
                            <img height="40" src="https://micappital.com/assets/img/logo-micappital.png" alt="Micappital"></img>
                        </a>
                        
                    </div>
                    <div className="process-popup-inner-container">
                        <h3 className="process-heading mt-5 mb-4">
                            {nombre}, necesitamos tu DNI para crearte tu cuenta Autopilot
                        </h3>

                        <Form className="form-insurance-data">
                            <Row>
                                <Form.Group as={Col} xs={12} sm={12} className="mb-3" controlId="tipo">
                                    <Form.Label className="bold">DNI foto frontal</Form.Label>
                                    <Form.Control className={(intento==true) && 'campo-incompleto'} placeholder="DNI foto frontal" name="idFront" type="file" accept="image/*" onChange={handleDniFrontChange}/>
                                </Form.Group>
                                
                            </Row>
                            <Row>
                                <Form.Group as={Col} xs={12} sm={12} className="mb-3" controlId="tipo">
                                    <Form.Label className="bold">DNI foto trasera</Form.Label>
                                    <Form.Control className={(intento==true) && 'campo-incompleto'} placeholder="DNI foto trasera" name="idBack" type="file" accept="image/*" onChange={handleDniBackChange}/>
                                </Form.Group>
                            </Row>
                            <Form.Group as={Col} xs={12} sm={6} className="mb-3" controlId="fechaNacimiento">
                                <Form.Label>Fecha de caducidad del DNI:</Form.Label>
                                <Form.Control  type="date" onChange={handleIdExpirationChange}/>
                            </Form.Group>
                                
                            {error && <p>HA HABIDO UN ERROR</p>}
                            
                            
                            
                        </Form>
                        
                        <div className="start-button-container">
                            <button className="btn" onClick={() =>navigate("/autopilot")}>
                                Atrás
                            </button>
                            <button className="botonComienza btn" type="button" onClick={handleGuardar} disabled={dniFront==null || dniBack==null || idExpiration==null}>
                                Continuar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </AltaStyle>
    );
}