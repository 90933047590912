import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts";
import ContributionsGraphStyle from "./contributionsGraphStyle";
import Text from "../../../../theme/font";

const ContributionGraph = React.memo((props) => {
  const [graph, setGraph] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    setScreenWidth(window.innerWidth);
  }, []);
  const barColors = (data, index) => {
    let colors = null;
    if (data[index].fecha == null) {
      colors = <Cell fill="#e9f7f8" key={Math.random()} />;
    } else {
      colors = <Cell fill="#2c787c" key={Math.random()} />;
    }
    return colors;
  };

  useEffect(() => {
    const changedAmount = [];
    let graphData = null;
    props.value.map((obj) => {
      graphData = { ...obj, importe: obj.importe };
      return changedAmount.push(graphData);
    });
    setGraph(changedAmount);
  }, [props.value]);

  const formatoSinDecimales = (x) => {
    var number = new Intl.NumberFormat("de-DE", {
      minimumFractionDigits: 0, //Decimals for returns at the top of the chart
      maximumFractionDigits: 0,
    }).format(x);
    return number;
  };

  return (
    <ContributionsGraphStyle>
      <div className="heading">
        <Text variant="titleFont">Todas tus aportaciones</Text>
      </div>
      {graph && graph.length > 0 ? (
        <div className="graph-div">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={500}
              data={graph}
              margin={{
                top: 15,
                right: 10,
                left: 0,
                bottom: 5,
              }}
            >
              <XAxis
                dataKey="fecha"
                tick={{ stroke: "#2C787C" }}
                stroke="#fff"
                fontWeight={"lighter"}
                fontSize={
                  screenWidth >= 3000
                    ? 20
                    : screenWidth >= 2000
                    ? 18
                    : screenWidth >= 500
                    ? 13
                    : 12
                }
                margin={{ top: "5rem" }}
              />
              <YAxis
                unit="€"
                type="number"
                dataKey={(graph) => parseInt(graph.importe)}
                tickFormatter={(tick) => {
                  return formatoSinDecimales(tick);
                }}
                stroke="#fff"
                axisLine={false}
                fontWeight={"lighter"}
                fontSize={
                  screenWidth >= 3000
                    ? 18
                    : screenWidth >= 2000
                    ? 18
                    : screenWidth >= 500
                    ? 13
                    : 12
                }
                padding={{ bottom: 30 }}
                orientation="left"
                domain={[
                  (dataMin) => 0 - Math.abs(dataMin),
                  (dataMax) => dataMax * 2,
                ]}
                tickCount={7}
                intervalal={0}
                tick={{ stroke: "#2C787C" }}
              />
              <Tooltip cursor={{ fill: "#F4FBFB" }} />
              <ReferenceLine y={0} stroke="#2C787C" strokeWidth={5} />
              <Bar
                dataKey="importe"
                radius={[25, 25, 0, 0]}
                barSize={20}
                fill="#2C787C"
                key={Math.random()}
              >
                {graph.map((value, index) => barColors(props.value, index))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <div className="initial-text">
          <Text variant="normal">
            En esta sección mostraremos el histórico de todas las aportaciones
            que haces en Micappital.
          </Text>
          <Text variant="normal">
            Aportar de manera periódica a tu inversión es muy importante si
            queremos obtener rentabilidades en el medio y largo plazo.
          </Text>
        </div>
      )}
    </ContributionsGraphStyle>
  );
});

export default ContributionGraph;
