import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ThemeProvider } from "styled-components";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { AlertContext } from "../../common/alertContainer";
import { AuthTokenService } from "../../../services";
import Button from "../../../components/button/button";
import { ContainerContext } from "../../../components/navbarContainer/containerProvider";
import ContributionBox from "./contributionBox/contributionBox";
import ContributionGraph from "./contributionsGraph/contributionsGraph";
import ContributionStyle from "./contributionsStyle";
import defaultTheme from "../../../theme/theme";
import { getDetails, postAmount } from "./contributionServices";
import HistoryBox from "./historyBox/historyBox";
import ImageConstants from "../../../constants/imageConstants/image_constants";
// import { Radio } from "@mui/material";
import { setFunds } from "../fundProposal/proposalAction";
import TextField from "../../../components/textField/textField";
import Text from "../../../theme/font";

const Contributions = React.memo(() => {
  const theme = defaultTheme;
  const [, setAmountDetails] = useState({});
  const [fundDetails, setFundDetails] = useState([]);
  const [buttonStyle, setButtonStyle] = useState(false);
  // const [selectedValue, setSelectedValue] = useState("a");
  const { open } = useContext(AlertContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { titleHandler, pageNameHandler, headerTitleHandler } =
    useContext(ContainerContext);

  const validate = Yup.object({
    fund: Yup.number()
      .typeError("El importe es necesario")
      .required("El importe es necesario"),
  });
  const userId = AuthTokenService.getUserId();

  useEffect(() => {
    window.scrollTo(0, 0);
    open({
      alertType: "spinner",
      open: true,
    });
    toogleNavBar();

    getDetails(userId)
      .then((response) => {
        open({
          alertType: "spinner",
          open: false,
        });
        setFundDetails(response.data.aportaciones);
      })
      .catch(() => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toogleNavBar = () => {
    titleHandler("Aportaciones");
    pageNameHandler("Aportaciones");
    headerTitleHandler("Inversión");
  };

  const fundSubmitHandler = (values) => {
    open({
      alertType: "spinner",
      open: true,
    });
    const userDetails = {
      idCliente: AuthTokenService.getUserId(),
      importe: values.fund,
      Tokenmicappital: AuthTokenService.getAccessToken(),
    };
    postAmount(userDetails)
      .then((response) => {
        setAmountDetails(response.data);
        open({
          alertType: "spinner",
          open: false,
        });
        dispatch(setFunds(response.data));
        navigate("/propuestaFondos");
      })
      .catch((error) => {
        open({
          alertType: "spinner",
          open: false,
        });
        let message;
        if (
          error &&
          error.response &&
          error.response.status &&
          error.response.status === 401
        ) {
          message = error.response.data.msg;
        } else {
          message = "Algo ha salido mal, por favor inténtalo de nuevo";
        }
        open({
          type: "error",
          title: message,
          alertType: "toast",
          open: true,
        });
      });
  };

  const formValidationHandler = (conditionalValue, resultValue) => {
    let result = "";
    if (conditionalValue) {
      result = resultValue;
    }
    return result;
  };
  const variantHandler = (conditionalValue, resultValue) => {
    let variant = "";
    if (conditionalValue && resultValue) {
      variant = "none";
    } else {
      variant = "outlined";
    }
    return variant;
  };

  // const handleChange = (event) => {
  //   setSelectedValue(event.target.value);
  // };

  // const controlProps = (item) => ({
  //   checked: selectedValue === item,
  //   onChange: handleChange,
  //   value: item,
  //   name: "investment-type-radio-button",
  //   inputProps: { "aria-label": item },
  // });

  const checkIfNumber = (event) => {
    const regex = new RegExp(
      /(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/
    );

    return !event.key.match(regex) && event.preventDefault();
  };

  const changeHandler = (values, setFieldValue) => {
    if (values.length < 9) {
      setFieldValue("fund", values);
    } else {
      const split = values.slice(0, 9);
      setFieldValue("fund", split);
    }
    if (values.length > 0) {
      setButtonStyle(true);
    } else {
      setButtonStyle(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <ContributionStyle>
        <div className="contribution-container">
          <div className="primary-contribution box-container">
            <Formik
              initialValues={{ fund: "" }}
              onSubmit={(values) => {
                fundSubmitHandler(values);
              }}
              validationSchema={validate}
            >
              {({
                values,
                errors,
                touched,
                handleSubmit,
                submitForm,
                handleChange,
                setFieldValue,
              }) => (
                <Form
                  onSubmit={handleSubmit}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit();
                    }
                  }}
                >
                  <div className="heading">
                    <Text variant="titleFont">Nueva aportación</Text>
                  </div>
                  <div className="add-icon">
                    <img src={ImageConstants.addIcon} alt="x" />
                  </div>
                  <div className="description">
                    <Text variant="titleFont">¿Cuanto quieres invertir?</Text>
                  </div>
                  <div className="amount-container">
                    <div className="amount-field">
                      <TextField
                        autoComplete="off"
                        id="amount-textField"
                        placeholder="Ej. 5.000"
                        name="fund"
                        min="1"
                        form="primary"
                        type="number"
                        value={values.fund}
                        handleChange={(e) =>
                          changeHandler(e.target.value, setFieldValue)
                        }
                        variant={variantHandler(errors.fund, touched.fund)}
                        error={formValidationHandler(
                          touched.fund,
                          Boolean(errors.fund)
                        )}
                        helperText={formValidationHandler(
                          touched.fund,
                          errors.fund
                        )}
                        onKeyDown={(evt) => checkIfNumber(evt)}
                      />
                    </div>
                  </div>
                  {/* <div className="choose-container">
                    <div className="choose-option">
                      <Radio
                        {...controlProps("a")}
                        sx={{
                          "&.Mui-checked": { color: "#30B2B8" },
                          "& .MuiSvgIcon-root": { fontSize: 18 },
                        }}
                      />
                      <Text
                        className="option-label"
                        type="primary"
                        variant="smallerText"
                      >
                        Sin Eco
                      </Text>
                    </div>
                    <div className="choose-option">
                      <Radio
                        {...controlProps("b")}
                        sx={{
                          "&.Mui-checked": { color: "#30B2B8" },
                          "& .MuiSvgIcon-root": { fontSize: 18 },
                        }}
                      />
                      <Text
                        className="option-label"
                        type="primary"
                        variant="smallerText"
                      >
                        Mixto
                      </Text>
                    </div>
                    <div className="choose-option">
                      <Radio
                        {...controlProps("c")}
                        sx={{
                          "&.Mui-checked": { color: "#30B2B8" },
                          "& .MuiSvgIcon-root": { fontSize: 18 },
                        }}
                      />
                      <Text
                        className="option-label"
                        type="primary"
                        variant="smallerText"
                      >
                        Eco
                      </Text>
                    </div>
                  </div>
                  <Text
                    className="choose-option-description"
                    type="primary"
                    variant="smallFont"
                  >
                    Esta aportación se distrubuirá entre tus fondos normales y
                    eco
                  </Text> */}
                  <div className="invest-button-container">
                    <Button
                      className={
                        buttonStyle
                          ? "investment-button"
                          : "investment-button disable-btn"
                      }
                      value="Ver fondos recomendados"
                      click={submitForm}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <ContributionBox />
        </div>
        <div className="allContributions-container inner-container">
          <ContributionGraph value={fundDetails} />
          <HistoryBox value={fundDetails} />
        </div>
      </ContributionStyle>
    </ThemeProvider>
  );
});

export default Contributions;
