import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { AlertContext } from "../../../common/alertContainer";
import { AuthTokenService } from "../../../../services";
import defaultTheme from "../../../../theme/theme";
import GetEcoStyle from "./getEcoStyle.js";
import { getControlPanelData } from "../../micappitalEco/micappitalEcoServices";
import ImageConstants from "../../../../constants/imageConstants/image_constants";
import Text from "../../../../theme/font";

const GetEco = () => {
  const theme = defaultTheme;
  const [ecoData, setEcoData] = useState("");
  const [investedAmount, setInvestedAmount] = useState("");
  const [costPercentage, setCostPercentage] = useState("");
  const [totalCost, setTotalCost] = useState("");
  const { open } = useContext(AlertContext);
  const navigate = useNavigate();

  useEffect(() => {
    const userId = AuthTokenService.getUserId();
    getControlPanelData(userId)
      .then((response) => {
        let ecoResponse = response.data;
        setEcoData(ecoResponse);
        setInvestedAmount(
          ecoResponse.ecoenabled && ecoResponse.data.valorTotal
        );
        setTotalCost(ecoResponse.ecoenabled && ecoResponse.data.rentabilidad);
        setCostPercentage(
          ecoResponse.ecoenabled && ecoResponse.data.rentabilidadPor
        );
      })
      .catch(() => {
        open({
          type: "error",
          title: "error encontrado",
          alertType: "toast",
          open: true,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //for navigation
  const navHandler = () => {
    navigate("/eco");
  };

  const formato = (x) => {
    var number = new Intl.NumberFormat("de-DE", {
      minimumFractionDigits: 2, //Decimals for returns at the top of the chart
      maximumFractionDigits: 2,
    }).format(x);
    return number;
  };

  const defaultEcoHandler = () => {
    return (
      <div
        className="box-container hazteEcoContainer"
        onClick={() => navHandler()}
      >
        <div className="row getEchoInnerContainer">
          <div className="col-lg-3 col-sm-6 getEchoImg">
            <img className="hazteEcoImg" alt="" src={ImageConstants.Hoja}></img>
          </div>
          <div className="col-lg-9 col-sm-6 getEchoText">
            <div className="hazteEcoTexto1">
              <Text variant="labelFont">Sé sostenible. Descubre</Text>
            </div>
            <div className="hazteEcoTexto2">
              <Text variant="mediumFont">Micappital Eco</Text>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <GetEcoStyle>
        {ecoData && ecoData.ecoenabled ? (
          <div className="enabled-eco-container box-container">
            <Text variant="primaryFont" className="enabled-eco-heading">
              Panel Micappital Eco
            </Text>
            <div className="invested-amount-container">
              <Text variant="labelFont" className="invested-amount-title">
                Valor
              </Text>
              <div className="invested-amount-data">
                <img
                  alt="x"
                  className="invested-amount-icon"
                  src={ImageConstants.ecoGraphIcon}
                />
                <Text variant="titleFont" className="invested-amount">
                  {`${formato(investedAmount)} €`}
                </Text>
              </div>
            </div>
            <div className="cost-effect-container">
              <Text variant="labelFont" className="cost-effect-title">
                Rentabilidad
              </Text>
              <div className="cost-effect-data">
                <img
                  alt="x"
                  className="cost-effect-icon"
                  src={ImageConstants.ecoCoinsIcon}
                />
                <div className="amount-container">
                  <Text
                    variant="primaryFont"
                    className={
                      costPercentage > 0
                        ? "cost-effect-percentage"
                        : "negative-cost-effect"
                    }
                  >
                    {`${formato(costPercentage)} %`}
                  </Text>
                  <Text
                    variant="primaryFont"
                    className={
                      totalCost > 0
                        ? "cost-effect-amount"
                        : "negative-cost-effect"
                    }
                  >
                    {`${formato(totalCost)} €`}
                  </Text>
                </div>
              </div>
            </div>
          </div>
        ) : (
          defaultEcoHandler()
        )}
      </GetEcoStyle>
    </ThemeProvider>
  );
};
export default GetEco;
