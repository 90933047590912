import { AlertContext } from "../../alertContainer";
import { AuthTokenService } from "../../../../services";
import { useState, useEffect, useContext } from "react";
import CardDetailsPopUpStyle from "./cardDetailsPopUpStyle";
import defaultTheme from "../../../../theme/theme";
import { ThemeProvider } from "styled-components";
import { getCardDetails } from "../accountSettingsServices";
import Text from "../../../../theme/font";

const CardDetailsPopUp = (props) => {
  const theme = defaultTheme;
  const { open } = useContext(AlertContext);
  // eslint-disable-next-line no-unused-vars
  const [cardDetails, setCardDetails] = useState("");
  const [url, setUrl] = useState("");
  const [parameters, setParameters] = useState("");
  const [signature, setSignature] = useState("");
  const [signatureVersion, setSignatureVersion] = useState("");

  useEffect(() => {
    open({
      alertType: "spinner",
      open: true,
    });
    const userId = AuthTokenService.getUserId();
    getCardDetails(userId)
      .then((response) => {
        open({
          alertType: "spinner",
          open: false,
        });
        setCardDetails(response.data);
        setUrl(response.data.url);
        setSignature(response.data.Ds_Signature);
        setSignatureVersion(response.data.Ds_SignatureVersion);
        setParameters(response.data.Ds_MerchantParameters);
        console.log(response);
      })
      .catch(() => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  

  return (
    <ThemeProvider theme={theme}>
      <CardDetailsPopUpStyle>
            <form
              action={url}
              method="post"
            >
              <input type="hidden"  name="Ds_SignatureVersion" value={signatureVersion}/>
              <input type="hidden" name="Ds_MerchantParameters" value={parameters}/>
              <input type="hidden" name="Ds_Signature" value={signature}/>
          
        <div className="cardPopUp-header">
          <div className="header-bar">
            <div className="heading">
              <Text variant="titleFont">{props.data.title}</Text>
            </div>
          </div>
          <div className="cardPopUp-content">
            <div className="card-content">
              <Text variant="normal">{props.data.description}</Text>
            </div>
            <div className="change-btn">
              <input type="submit" className="btn-div" value="Actualizar tarjeta" />
            </div>
          </div>
        </div>
        </form>
      </CardDetailsPopUpStyle>
    </ThemeProvider>
  );
};

export default CardDetailsPopUp;
