//log-in module
import background from "../../assets/img/logIn/background.svg";
import Email from "../../assets/img/logIn/Email.svg";
import fb from "../../assets/img/logIn/fb.svg";
import gplus from "../../assets/img/logIn/gplus.svg";
import lkdIn from "../../assets/img/logIn/lkdIn.svg";
import lock from "../../assets/img/logIn/lock.svg";
import mainIcon from "../../assets/img/logIn/mainIcon.svg";
import logInBackground from "../../assets/img/logIn/logInBackground.svg";
import primaryLogin from "../../assets/img/logIn/primaryLogin.svg";
import secondaryLogin from "../../assets/img/logIn/secondaryLogin.svg";
import logInBg from "../../assets/img/logIn/loginBg.svg";

//settings module
import card from "../../assets/img/settings/card.svg";
import contract from "../../assets/img/settings/contract.svg";
import dni from "../../assets/img/settings/dni.svg";
import profile from "../../assets/img/settings/profile.svg";
import test from "../../assets/img/settings/test.svg";
import tutorial from "../../assets/img/settings/tutorial.svg";
import tick from "../../assets/img/settings/tick.svg";
import Oval from "../../assets/img/settings/Oval.svg";
import messages from "../../assets/img/settings/messages.svg";
import edit from "../../assets/img/settings/edit.svg";
import dni1 from "../../assets/img/settings/dni1.jpeg";
import dni2 from "../../assets/img/settings/dni2.webp";
import eyeClosedIcon from "../../assets/img/settings/eyeClosedIcon.svg";
import eyeOpenIcon from "../../assets/img/settings/eyeOpenIcon.svg";
import profilePhoto from "../../assets/img/settings/profilePhoto.jpg";
import bank from "../../assets/img/settings/bank.svg";
import dropDownArrow from "../../assets/img/settings/dropDownArrow.svg";
import commission from "../../assets/img/settings/commission.svg";

//fund proposal module
import arrowIcon from "../../assets/img/proposal/arrowIcon.svg";
import bankIcon from "../../assets/img/proposal/bankIcon.svg";
import videoIcon from "../../assets/img/proposal/videoIcon.svg";
import bankLogo from "../../assets/img/proposal/bankLogo.svg";
import copyIcon from "../../assets/img/proposal/copyIcon.svg";
import arrowLeftIcon from "../../assets/img/proposal/arrowLeftIcon.svg";
import chatIcon from "../../assets/img/proposal/chatIcon.svg";
import walletIcon from "../../assets/img/proposal/walletIcon.svg";
import changeIcon from "../../assets/img/proposal/changeIcon.svg";
import tutorialIcon from "../../assets/img/proposal/tutorialIcon.svg";
import copyISIN from "../../assets/img/proposal/copyISIN.svg";

//proposal module
import MicappitalPropuesta from "../../assets/img/propuesta/micappital-propuesta.svg";
import whatsapp from "../../assets/img/propuesta/whatsapp.png";
import telefono from "../../assets/img/propuesta/telefono.png";
import flecha from "../../assets/img/propuesta/rightArrow.png";
import MicappitalConnectImg from "../../assets/img/propuesta/micappitalConnect.png";
import Openbank from "../../assets/img/propuesta/openbank.png";
import playIcon from "../../assets/img/propuesta/playIcon.svg";
import cashIcon from "../../assets/img/propuesta/cashIcon.svg";
import demoImg from "../../assets/img/propuesta/demoImg.svg";
import connect1 from "../../assets/img/proposal/Connect1.svg";
import connect2 from "../../assets/img/proposal/Connect2.svg";
import connect3 from "../../assets/img/proposal/Connect3.svg";
import connect4 from "../../assets/img/proposal/Connect4.svg";

//home module
import Tic from "../../assets/img/home/tic.png";
import Warning from "../../assets/img/home/warning.png";
import TicRound from "../../assets/img/home/ticRound.svg";
import GrayRound from "../../assets/img/grayRound.svg";
import Hijos from "../../assets/img/home/fondoAzul/hijos.png";
import Ahorro from "../../assets/img/home/fondoAzul/ahorro.png";
import Casa from "../../assets/img/home/fondoAzul/casa.png";
import Coche from "../../assets/img/home/fondoAzul/coche.png";
import Universidad from "../../assets/img/home/fondoAzul/universidad.png";
import Viaje from "../../assets/img/home/fondoAzul/viaje.png";
import FondoNoticia from "../../assets/img/home/noticia1.png";
import Grafico from "../../assets/img/home/grafico.png";
import Candado from "../../assets/img/home/candado.png";
import Calendario from "../../assets/img/home/calendario.png";
import Hoja from "../../assets/img/home/hoja.svg";
import InfoCambios1 from "../../assets/img/home/cambios/infoCambios1.png";
import InfoCambios2 from "../../assets/img/home/cambios/infoCambios2.png";
import InfoCambios3 from "../../assets/img/home/cambios/infoCambios3.png";
import IconoCambios from "../../assets/img/home/cambios/IconoCambios.png";
import ferrarri from "../../assets/img/home/ferrarri.png";
import addnew from "../../assets/img/home/fondoAzul/addnew.svg";
import heart from "../../assets/img/home/cambios/heart.svg";
import calender from "../../assets/img/home/cambios/calender.svg";
import house from "../../assets/img/home/house.png";
import travel from "../../assets/img/home/travel.png";
import education from "../../assets/img/home/education.png";
import savings from "../../assets/img/home/savings.png";
import kids from "../../assets/img/home/kids.png";
import connectArrow from "../../assets/img/home/connectArrow.png";
import connected from "../../assets/img/home/connected.png";
import notConnected from "../../assets/img/home/notConnected.png";
import dateRange from "../../assets/img/home/dateRange.png";
import walletBlue from "../../assets/img/home/walletBlue.svg";
import walletYellow from "../../assets/img/home/walletYellow.svg";
import walletRed from "../../assets/img/home/walletRed.svg";
import carRed from "../../assets/img/home/carRed.svg";
import carYellow from "../../assets/img/home/carYellow.svg";
import carBlue from "../../assets/img/home/carBlue.svg";
import childRed from "../../assets/img/home/childRed.svg";
import childYellow from "../../assets/img/home/childYellow.svg";
import childBlue from "../../assets/img/home/childBlue.svg";
import flightRed from "../../assets/img/home/flightRed.svg";
import flightYellow from "../../assets/img/home/flightYellow.svg";
import flightBlue from "../../assets/img/home/flightBlue.svg";
import univercityRed from "../../assets/img/home/univercityRed.svg";
import univercityYellow from "../../assets/img/home/univercityYellow.svg";
import univercityBlue from "../../assets/img/home/univercityBlue.svg";
import redCircle from "../../assets/img/home/redCircle.svg";
import sampleImg from "../../assets/img/home/sampleImg.svg";
import sampleImage from "../../assets/img/home/sampleImage.svg";
import houseRed from "../../assets/img/home/houseRed.svg";
import houseYellow from "../../assets/img/home/houseYellow.svg";
import houseBlue from "../../assets/img/home/houseBlue.svg";
import informationIcon from "../../assets/img/home/informationIcon.png";
import rocketIcon from "../../assets/img/home/rocketIcon.svg";
import ecoCoinsIcon from "../../assets/img/home/ecoCoinsIcon.svg";
import ecoGraphIcon from "../../assets/img/home/ecoGraphIcon.svg";

//menu-icons
import propuesta from "../../assets/img/menuIcons/propuesta.svg";
import ajustesWhite from "../../assets/img/menuIcons/ajustesWhite.svg";
import amigosWhite from "../../assets/img/menuIcons/amigosWhite.svg";
import AportacionesWhite from "../../assets/img/menuIcons/AportacionesWhite.svg";
import micapitalEcoWhite from "../../assets/img/menuIcons/micapitalEcoWhite.svg";
import propustaWhite from "../../assets/img/menuIcons/propustaWhite.svg";
import resumenWhite from "../../assets/img/menuIcons/resumenWhite.svg";
import completeOval from "../../assets/img/menuIcons/completeOval.svg";
import primaryMenuIcon from "../../assets/img/menuOption1.svg";
import secondaryMenuIcon from "../../assets/img/menuOption2.svg";
import inversion from "../../assets/img/menuIcons/inversion.svg";
import selectedInversion from "../../assets/img/menuIcons/selectedInversion.svg";
import seguros from "../../assets/img/menuIcons/seguros.svg";
import selectedSeguros from "../../assets/img/menuIcons/selectedSeguros.svg";
//add-friends module
import primaryOffer from "../../assets/img/addFriends/primaryOffer.svg";
import secondaryOffer from "../../assets/img/addFriends/secondaryOffer.svg";
import teritiaryOffer from "../../assets/img/addFriends/teritiaryOffer.svg";
import importeIcon from "../../assets/img/addFriends/importeIcon.png";
import ovalTick from "../../assets/img/addFriends/ovalTick.png";
import ovalCross from "../../assets/img/addFriends/ovalCross.png";

//common images
import Logo from "../../assets/img/logoMicappital.png";
import Resumen from "../../assets/img/resumen.svg";
import Aportaciones from "../../assets/img/aportaciones.svg";
import Amigos from "../../assets/img/amigos.svg";
import Ajustes from "../../assets/img/ajustes.svg";
import Eco from "../../assets/img/micappitalEco.svg";
import AvatarLogo from "../../assets/img/icono.svg";
import addIcon from "../../assets/img/addIcon.svg";
import userIcon from "../../assets/img/userIcon.svg";
import offIcon from "../../assets/img/common/offIcon.svg";
import whatsappIcon from "../../assets/img/common/whatsappIcon.svg";
import newLogo from "../../assets/img/newLogo.svg";
import hamburgerMenuIcon from "../../assets/img/common/hamburgerMenuIcon.svg";
import popupCalender from "../../assets/img/common/popupCalender.svg";
import whatsappFino from "../../assets/img/common/WhatsappNuevo.svg";
import dropDown from "../../assets/img/common/dropDown.svg";

//eco module
import equality from "../../assets/img/eco/equality.png";
import health from "../../assets/img/eco/health.png";
import Industry from "../../assets/img/eco/Industry.png";
import inequalities from "../../assets/img/eco/inequalities.png";
import Production from "../../assets/img/eco/Production.png";
import water from "../../assets/img/eco/water.png";
import climate from "../../assets/img/eco/climate.png";
import energy from "../../assets/img/eco/energy.png";
import Qualityeducation from "../../assets/img/eco/Qualityeducation.png";
import eco from "../../assets/img/eco/eco.png";
import rightArrow from "../../assets/img/eco/rightArrow.png";
import leftArrow from "../../assets/img/eco/leftArrow.png";

//default screen module
import option1 from "../../assets/img/defaultScreen/option1.png";
import option2 from "../../assets/img/defaultScreen/option2.png";
import option3 from "../../assets/img/defaultScreen/option3.png";
import sector1 from "../../assets/img/defaultScreen/sector1.svg";
import sector2 from "../../assets/img/defaultScreen/sector2.svg";
import sector3 from "../../assets/img/defaultScreen/sector3.svg";
import sector4 from "../../assets/img/defaultScreen/sector4.svg";
import sector5 from "../../assets/img/defaultScreen/sector5.svg";
import sector6 from "../../assets/img/defaultScreen/sector6.svg";
import sector7 from "../../assets/img/defaultScreen/sector7.svg";
import sector8 from "../../assets/img/defaultScreen/sector8.svg";
import sector9 from "../../assets/img/defaultScreen/sector9.svg";
import sector10 from "../../assets/img/defaultScreen/sector10.svg";
import sector11 from "../../assets/img/defaultScreen/sector11.svg";
import sector12 from "../../assets/img/defaultScreen/sector12.svg";
import sector13 from "../../assets/img/defaultScreen/sector13.svg";
import sector14 from "../../assets/img/defaultScreen/sector14.svg";
import sector15 from "../../assets/img/defaultScreen/sector15.svg";
import sector16 from "../../assets/img/defaultScreen/sector16.svg";
import sector17 from "../../assets/img/defaultScreen/sector17.svg";

//goal icons
import ahorro0 from "../../assets/img/home/goal icons/ahorro-1.svg";

//micappitalzero icons

import relaxIcon from "../../assets/img/micappitalZero/relaxIcon.svg";
import comisionesIcon from "../../assets/img/micappitalZero/comisionesIcon.svg";
import serviceIcon from "../../assets/img/micappitalZero/serviceIcon.svg";
import blackArrowIcon from "../../assets/img/micappitalZero/blackArrow.svg";
import increaseIcon from "../../assets/img/micappitalZero/increaseIcon.svg";
import zeroBank from "../../assets/img/micappitalZero/zeroBank.svg";
import micappitalZero from "../../assets/img/micappitalZero/micappitalZero.svg";

// insurance landing icons
import appIcon from "../../assets/img/seguros/landing/appIcon.svg";
import monitorIcon from "../../assets/img/seguros/landing/monitorIcon.svg";
import payIcon from "../../assets/img/seguros/landing/payIcon.svg";
import supportIcon from "../../assets/img/seguros/landing/supportIcon.svg";
import allInOne from "../../assets/img/seguros/landing/allInOne.svg";
import alli from "../../assets/img/seguros/landing/alli.svg";
import asisa from "../../assets/img/seguros/landing/asisa.svg";
import catalana from "../../assets/img/seguros/landing/catalana.svg";
import cnp from "../../assets/img/seguros/landing/cnp.svg";
import general from "../../assets/img/seguros/landing/general.svg";
import helvetia from "../../assets/img/seguros/landing/helvetia.svg";
import plusultra from "../../assets/img/seguros/landing/plusultra.svg";
import liberty from "../../assets/img/seguros/landing/liberty.svg";
import santalucia from "../../assets/img/seguros/landing/santalucia.svg";
import ades from "../../assets/img/seguros/landing/ades.svg";
import aego from "../../assets/img/seguros/landing/aego.svg";
import axa from "../../assets/img/seguros/landing/axa.svg";
import caser from "../../assets/img/seguros/landing/caser.svg";
import dkv from "../../assets/img/seguros/landing/dkv.svg";
import mapfre from "../../assets/img/seguros/landing/mapfre.svg";
import Muta from "../../assets/img/seguros/landing/Muta.svg";
import zuri from "../../assets/img/seguros/landing/zuri.svg";
import sanitas from "../../assets/img/seguros/landing/sanitas.svg";
import cigna from "../../assets/img/seguros/landing/cigna.svg";
import pelayo from "../../assets/img/seguros/landing/pelayo.svg";
import reale from "../../assets/img/seguros/landing/reale.svg";
import healthInsurance from "../../assets/img/seguros/insurance/healthInsurance.svg";
import vehicleInsurance from "../../assets/img/seguros/insurance/vehicleInsurance.svg";
import newCalendericon from "../../assets/img/seguros/insurance/newCalenderIcon.svg";
import defaultToggle from "../../assets/img/seguros/insurance/defaultToggle.svg";
import newTick from "../../assets/img/seguros/insurance/newTick.svg";
import yellowCross from "../../assets/img/seguros/insurance/yellowCross.svg";
import redCross from "../../assets/img/seguros/insurance/redCross.svg";
import newLogoIcon from "../../assets/img/seguros/insurance/newlogoIcon.svg";
import greenToggle from "../../assets/img/seguros/insurance/greenToggle.svg";
import processImage from "../../assets/img/seguros/insurance/processImage.svg";
import car from "../../assets/img/seguros/insurance/car.svg";
import casa from "../../assets/img/seguros/insurance/casa.svg";
import dispo from "../../assets/img/seguros/insurance/dispo.svg";
import empresa from "../../assets/img/seguros/insurance/empresa.svg";
import Moto from "../../assets/img/seguros/insurance/Moto.svg";
import respcivil from "../../assets/img/seguros/insurance/respcivil.svg";
import salut from "../../assets/img/seguros/insurance/salut.svg";
import vida1 from "../../assets/img/seguros/insurance/vida1.svg";
import callIcon from "../../assets/img/seguros/insurance/callIcon.png";
import helpImage from "../../assets/img/seguros/insurance/helpImage.svg";
import continueIcon from "../../assets/img/seguros/insurance/continue.svg";
import selected from "../../assets/img/seguros/insurance/selected.svg";
import location from "../../assets/img/seguros/insurance/location.svg";

// investment icons
import plantImage from "../../assets/img/plantImage.svg";

import nova from "../../assets/img/logo-nova.svg"

const ImageConstants = {
  background,
  Email,
  fb,
  gplus,
  lkdIn,
  lock,
  mainIcon,
  logInBackground,
  userIcon,
  card,
  contract,
  dni,
  profile,
  test,
  tutorial,
  tick,
  Oval,
  messages,
  edit,
  dni1,
  dni2,
  eyeClosedIcon,
  eyeOpenIcon,
  profilePhoto,
  addIcon,
  arrowIcon,
  bankIcon,
  videoIcon,
  bankLogo,
  copyIcon,
  arrowLeftIcon,
  chatIcon,
  walletIcon,
  MicappitalPropuesta,
  whatsapp,
  telefono,
  flecha,
  MicappitalConnectImg,
  Openbank,
  AvatarLogo,
  Tic,
  Warning,
  TicRound,
  GrayRound,
  Hijos,
  Ahorro,
  Casa,
  Coche,
  Universidad,
  Viaje,
  FondoNoticia,
  Grafico,
  Candado,
  Calendario,
  Hoja,
  InfoCambios1,
  InfoCambios2,
  InfoCambios3,
  IconoCambios,
  ferrarri,
  addnew,
  heart,
  calender,
  kids,
  travel,
  education,
  savings,
  house,
  propuesta,
  ajustesWhite,
  amigosWhite,
  AportacionesWhite,
  micapitalEcoWhite,
  propustaWhite,
  resumenWhite,
  completeOval,
  primaryOffer,
  secondaryOffer,
  teritiaryOffer,
  importeIcon,
  ovalTick,
  ovalCross,
  Logo,
  Resumen,
  Aportaciones,
  Amigos,
  Ajustes,
  Eco,
  equality,
  health,
  Industry,
  inequalities,
  Production,
  Qualityeducation,
  eco,
  rightArrow,
  leftArrow,
  water,
  climate,
  energy,
  option1,
  option2,
  option3,
  sector1,
  sector2,
  sector3,
  sector4,
  sector5,
  sector6,
  sector7,
  sector8,
  sector9,
  sector10,
  sector11,
  sector12,
  sector13,
  sector14,
  sector15,
  sector16,
  sector17,
  connectArrow,
  connected,
  notConnected,
  bank,
  dateRange,
  changeIcon,
  playIcon,
  cashIcon,
  demoImg,
  walletBlue,
  walletRed,
  walletYellow,
  carBlue,
  carRed,
  carYellow,
  childRed,
  childYellow,
  childBlue,
  flightRed,
  flightYellow,
  flightBlue,
  univercityBlue,
  univercityRed,
  univercityYellow,
  houseRed,
  houseYellow,
  houseBlue,
  redCircle,
  sampleImage,
  sampleImg,
  tutorialIcon,
  copyISIN,
  informationIcon,
  ahorro0,
  offIcon,
  whatsappIcon,
  relaxIcon,
  comisionesIcon,
  serviceIcon,
  blackArrowIcon,
  micappitalZero,
  zeroBank,
  increaseIcon,
  rocketIcon,
  ecoCoinsIcon,
  ecoGraphIcon,
  connect1,
  connect2,
  connect3,
  connect4,
  dropDownArrow,
  newLogo,
  primaryMenuIcon,
  secondaryMenuIcon,
  hamburgerMenuIcon,
  commission,
  popupCalender,
  primaryLogin,
  secondaryLogin,
  logInBg,
  whatsappFino,
  inversion,
  selectedInversion,
  seguros,
  selectedSeguros,
  payIcon,
  supportIcon,
  monitorIcon,
  appIcon,
  allInOne,
  alli,
  asisa,
  catalana,
  cnp,
  general,
  helvetia,
  plusultra,
  liberty,
  santalucia,
  ades,
  aego,
  axa,
  caser,
  dkv,
  mapfre,
  Muta,
  zuri,
  sanitas,
  cigna,
  pelayo,
  reale,
  healthInsurance,
  vehicleInsurance,
  newCalendericon,
  defaultToggle,
  newTick,
  yellowCross,
  redCross,
  newLogoIcon,
  greenToggle,
  processImage,
  casa,
  car,
  dispo,
  empresa,
  Moto,
  respcivil,
  salut,
  vida1,
  plantImage,
  callIcon,
  helpImage,
  continueIcon,
  selected,
  location,
  dropDown,
  nova
};

export default ImageConstants;
