import React from "react";
import Text from "../../../../theme/font";
import ClickPopupStyle from "./clickPopupStyle";

const ClickPopup = React.memo(({ closeAllPopup, closePopUp }) => {
  return (
    <ClickPopupStyle>
      <div className="click-popup-container">
        <div className="click-process-popup-container">
          <Text variant="titleFont" className="click-heading">
            ¿Estás seguro de cerrar el proceso?{" "}
          </Text>
          <div className="click-button-container">
            <Text
              variant="popUpText"
              className="return-text"
              onClick={() => closePopUp()}
            >
              Volver
            </Text>
            <Text
              variant="popUpText"
              className="continue-icons-button"
              onClick={() => closeAllPopup()}
            >
              Sí, cerrar
            </Text>
          </div>
        </div>
      </div>
    </ClickPopupStyle>
  );
});

export default ClickPopup;
