import styled from "styled-components";

const EcoChangePopUpStyle = styled.div`
  .popup-inner {
    width: 100%;
    max-width: 40.625rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 1.56rem;
    background-color: ${(props) =>
      props.theme.colors.background.defaultBackground};
    padding: 2rem;
    .accountDetails-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.5rem;
      @media (max-width: 470px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .heading {
        color: ${(props) => props.theme.colors.fontColor.titleFontColor};
        font-weight: bold;
        @media (max-width: 470px) {
          margin-bottom: 1rem;
        }
      }
    }
    .form-container {
      width: 100%;
      display: flex;
      gap: 0.5rem;
      @media (max-width: 470px) {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
      }
      .form-field {
        width: calc(100% - 8.5rem);
        @media (max-width: 470px) {
          width: 100%;
        }
        .label {
          font-weight: bold;
          padding: 0.5rem 0.5rem;
        }
        .field-div {
          padding-left: 1.5rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-right: 0.5rem;
        }
      }
      .header-button {
        font-weight: bold;
        width: 100%;
        max-width: 8rem;
        cursor: pointer;
        @media (max-width: 470px) {
          padding-top: 1rem;
        }
        .button-container {
          height: 2.5rem;
        }
      }
    }
  }
`;
export default EcoChangePopUpStyle;
