import { combineReducers } from "redux";
import { MessageReducer } from "../components/alertmessage/alertMessageReducer";
import { detailsReducer} from "../container/investment/home/goal/goalReducer";
import { fundReducer } from "../container/investment/fundProposal/proposalReducer";

const reducers = combineReducers({
  allFunds: fundReducer,
  allGoals: detailsReducer,
  alert: MessageReducer,
});

export default reducers;
