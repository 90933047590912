import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { ThemeProvider } from "styled-components";
import { AlertContext } from "../../../common/alertContainer";
import { AuthTokenService } from "../../../../services";
import {
  cargarGraficoObjetivos,
  cargarObjetivos,
  cargarPosicion,
} from "../homeServices";
import defaultTheme from "../../../../theme/theme";
import GraphObjectivesStyle from "./graphObjectivesStyle";
import ImageConstants from "../../../../constants/imageConstants/image_constants";
import Loading from "../../../../components/commonModules/Loading";
import Objective from "../objective/objective";
import { setDetails } from "../goal/goalAction";
import Text from "../../../../theme/font";
import {
  AhorroBlue,
  CocheBlue,
  CasaBlue,
  UniversidadBlue,
  HijosBlue,
  ViajeBlue,
} from "../newGraphIcons/newGraphIcons";
import moment from "moment";

const CustomizedDot = (props) => {
  const { cx, cy, payload } = props;

  const { objetivo } = payload;

  if (objetivo === "coche") {
    return <CocheBlue cx={cx} cy={cy}></CocheBlue>;
  }
  if (objetivo === "ahorro") {
    return <AhorroBlue cx={cx} cy={cy}></AhorroBlue>;
  }
  if (objetivo === "casa") {
    return <CasaBlue cx={cx} cy={cy}></CasaBlue>;
  }
  if (objetivo === "universidad") {
    return <UniversidadBlue cx={cx} cy={cy}></UniversidadBlue>;
  }
  if (objetivo === "hijos") {
    return <HijosBlue cx={cx} cy={cy}></HijosBlue>;
  }
  if (objetivo === "viaje") {
    return <ViajeBlue cx={cx} cy={cy}></ViajeBlue>;
  }

  return null;
};

const GraphObjectives = ({ click }) => {
  const theme = defaultTheme;
  const GoalState = useSelector((state) => state.allGoals);
  const [data, setData] = useState(null);
  const [goalData, setGoalData] = useState(
    GoalState.goalDetails ? GoalState.goalDetails : {}
  );
  const [objectives] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [total, setTotal] = useState("");
  const { open } = useContext(AlertContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [checkWorth, setCheckWorth] = useState("");

  useEffect(() => {
    setScreenWidth(window.innerWidth);
  }, []);
  useEffect(() => {
    open({
      alertType: "spinner",
      open: true,
    });
    const userId = AuthTokenService.getUserId();
    if (userId) {
      cargarGraficoObjetivos(userId)
        .then((result) => {
          open({
            alertType: "spinner",
            open: false,
          });
          if (result.statusText === "OK" && result.status === 200) {
            const data = result.data.data;
            setData(data);
          }
        })
        .catch(() => {
          open({
            alertType: "spinner",
            open: false,
          });
          open({
            type: "error",
            title: "Error al enviar datos",
            alertType: "toast",
            open: true,
          });
        });
      cargarObjetivos(userId)
        .then((result) => {
          open({
            alertType: "spinner",
            open: false,
          });
          if (result.statusText === "OK" && result.status === 200) {
            dispatch(setDetails(result.data.data));
            setGoalData(result.data.data);
            setIsLoading(false);
            setTotal(result.data.ahorroTotal);
          }
        })
        .catch(() => {
          open({
            alertType: "spinner",
            open: false,
          });
          open({
            type: "error",
            title: "Error al enviar datos",
            alertType: "toast",
            open: true,
          });
        });
      cargarPosicion(userId)
        .then((result) => {
          open({
            alertType: "spinner",
            open: false,
          });
          if (result.statusText === "OK" && result.status === 200) {
            const checkData = result.data.data[0].valor;
            setCheckWorth(checkData);
          }
        })
        .catch(() => {
          open({
            alertType: "spinner",
            open: false,
          });
          open({
            type: "error",
            title: "Error al enviar datos",
            alertType: "toast",
            open: true,
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectives]);

  const formatoSinDecimales = (x) => {
    var number = new Intl.NumberFormat("de-DE", {
      minimumFractionDigits: 0, //Decimals for returns at the top of the chart
      maximumFractionDigits: 0,
    }).format(x);
    return number;
  };

  const financial = (x) => {
    return Number.parseFloat(x).toFixed(0);
  };

  const formatoFecha = (x) => {
    var date = moment(x).locale("es").format("MMM yyyy");
    return date;
  };

  const renderHandler = () => {
    if (data == null || isLoading) {
      return <Loading />;
    } else {
      const { valores } = data;
      return (
        <div className="graph-objective-container">
          <div className="graficoObjetivosContainer box-container">
            <div className="header-container">
              <Text className="tituloGraficoObjetivos" variant="primaryFont">
                Tus objetivos de ahorro
              </Text>
              <div className="top-text-container">
                <img
                  src={ImageConstants.walletRed}
                  alt="X"
                  className="walletImg"
                />
                <Text variant="fieldFont" className="top-text">
                  Ahorro necesario:
                </Text>
                <Text
                  variant="fieldFont"
                  className="top-text"
                >{` ${formatoSinDecimales(total)}€/mes`}</Text>
              </div>
            </div>
            <div className="graficoObjetivosDatosContainer">
              {checkWorth === "0" && (
                <div className="alert-container">
                  <div className="alert-message">
                    No tienes todavía ningún objetivo creado, para poder ver los
                    objetivos crea uno nuevo
                  </div>
                </div>
              )}
              <ResponsiveContainer>
                <AreaChart
                  width={500}
                  height={300}
                  data={valores}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 50,
                    bottom: 0,
                  }}
                >
                  <XAxis
                    tickFormatter={(tick) => {
                      return formatoFecha(tick);
                    }}
                    dataKey="fecha"
                    tick={{
                      fontSize:
                        screenWidth >= 3000
                          ? 24
                          : screenWidth >= 2000
                          ? 18
                          : screenWidth >= 500
                          ? 13
                          : 12,
                      margin: 20,
                    }}
                  />
                  <YAxis
                    tickFormatter={(tick) => {
                      return formatoSinDecimales(tick);
                    }}
                    tick={{
                      fontSize:
                        screenWidth >= 3000
                          ? 24
                          : screenWidth >= 2000
                          ? 18
                          : screenWidth >= 500
                          ? 13
                          : 12,
                      margin: 10,
                    }}
                    type="number"
                    allowDataOverflow={true}
                    domain={[0, (dataMax) => financial(dataMax * 1.3)]}
                  />
                  <Tooltip
                    labelFormatter={t => formatoFecha(t)}
                    formatter={(value) =>
                      new Intl.NumberFormat("de", {
                        style: "currency",
                        currency: "EUR",
                      }).format(value)
                    }
                  />
                  <Area
                    type="monotone"
                    dataKey="cartera"
                    stroke="#30b2b8"
                    fill="transparent"
                    strokeWidth={3}
                    dot={<CustomizedDot />}
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
            <div className="objectiveContainer">
              {goalData &&
                goalData.length > 0 &&
                goalData.map(function (objetivo) {
                  return (
                    <div
                      className="objectiveCard"
                      onClick={() =>
                        navigate(`/editarObjetivo?id=${objetivo.idObjetivo}`)
                      }
                      key={Math.random()}
                    >
                      <Objective objetivo={objetivo} />
                    </div>
                  );
                })}
              <div className="objectiveCard">
                <Objective objetivo={null} click={() => click()} />
              </div>
              {/* <div className="bottomContainer">
                <div>
                  <img
                    src={ImageConstants.walletRed}
                    alt="X"
                    className="walletImg"
                  />
                </div>
                <div className="textBottomContainer">
                  <Text variant="smallFont">
                    Si ahorras e inviertes lo que te recomendamos, serás capaz
                    de llegar a cada uno de los objetivos que te marques
                  </Text>
                  <Text variant="smallFont">
                    Lo que tienes que ahorrar e invertir este mes es:
                    <span className="ammountToSave">{` ${total}€`}</span>
                  </Text>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      );
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <GraphObjectivesStyle>{renderHandler()}</GraphObjectivesStyle>
    </ThemeProvider>
  );
};
export default GraphObjectives;
