import { Request } from "../../../services";
import URL_CONSTANTS from "../../../constants/urlConstants/urlConstants";

export const CheckEcoContribution = (data) => {
  return Request.get(`${URL_CONSTANTS.check_eco}/${data}`);
};

export const postEcoInvestment = (data) => {
  return Request.post(`${URL_CONSTANTS.invest_eco}`, data);
};
