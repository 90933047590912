import React, { useState, useContext } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import FundChangePopUpStyle from "./fundChangePopupStyle";
import { AlertContext } from "../../../../common/alertContainer";
import { AuthTokenService } from "../../../../../services";
import Button from "../../../../../components/button/button";
import TextField from "../../../../../components/textField/textField";
import { postFund } from "../../proposalServices";
import Text from "../../../../../theme/font";

const FundChangePopUp = React.memo((props) => {
  const { open } = useContext(AlertContext);
  const [buttonStyle, setButtonStyle] = useState(false);
  const minimumBalance = props?.minimumAmount;

  const variantHandler = (conditionalValue, resultValue) => {
    let variant = "";
    if ((conditionalValue, resultValue)) {
      variant = "none";
    } else {
      variant = "outlined";
    }
    return variant;
  };

  const validate = Yup.object({
    amount: Yup.number()
      .required("Por favor introduzca un valor")
      .min(minimumBalance, `Se requiere un valor mínimo de ${minimumBalance}`),
  });

  const submitHandler = (values) => {
    open({
      alertType: "spinner",
      open: true,
    });

    const details = {
      idCliente: AuthTokenService.getUserId(),
      importe: values.amount,
    };
    postFund(details)
      .then((response) => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "success",
          title: "Actualizado correctamente",
          alertType: "toast",
          open: true,
        });
        props.click(false, values.amount, response.data.fondos);
      })
      .catch(() => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: "Fallo al actualizar",
          alertType: "toast",
          open: true,
        });
        props.click(false);
      });
  };

  const checkIfNumber = (event) => {
    const regex = new RegExp(
      /(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/
    );

    return !event.key.match(regex) && event.preventDefault();
  };

  const changeHandler = (values, setFieldValue) => {
    if (values.length < 9) {
      setFieldValue("amount", values);
    } else {
      const split = values.slice(0, 9);
      setFieldValue("amount", split);
    }
    if (values.length > 0) {
      setButtonStyle(true);
    } else {
      setButtonStyle(false);
    }
  };

  return (
    <FundChangePopUpStyle>
      <div className="popup-container">
        <div
          className="overlay"
          onClick={() => {
            props.click(false);
          }}
        ></div>
        <div className="popup-inner">
          <Formik
            enableReinitialize
            initialValues={{
              amount: "",
            }}
            onSubmit={(values) => {
              submitHandler(values);
            }}
            validationSchema={validate}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              submitForm,
              setFieldValue,
            }) => (
              <Form>
                <div className="accountDetails-header">
                  <div className="heading">
                    <Text variant="titleFont">Importe a invertir</Text>
                  </div>
                </div>

                <div className="form-container">
                  <div className="form-field email-field">
                    <TextField
                      variant={variantHandler(errors.amount, touched.amount)}
                      name="amount"
                      value={values.amount}
                      type="number"
                      handleChange={(e) =>
                        changeHandler(e.target.value, setFieldValue)
                      }
                      placeholder="Ej. 10000"
                      error={touched.amount && Boolean(errors.amount)}
                      helperText={touched.amount && errors.amount}
                      className="field-div"
                      onKeyDown={(evt) => checkIfNumber(evt)}
                    />
                  </div>
                  <div className="header-btn">
                    <Button
                      className={
                        buttonStyle ? "but-div" : "disabled-btn but-div"
                      }
                      value="Actualizar"
                      click={submitForm}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </FundChangePopUpStyle>
  );
});

export default FundChangePopUp;
