import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { AuthTokenService } from "../../../services";
import { ContainerContext } from "../../../components/navbarContainer/containerProvider";
import defaultTheme from "../../../theme/theme";
import ImageConstants from "../../../constants/imageConstants/image_constants";
import LogInForm from "./logInForm/logInForm";
import LogInStyle from "./logInStyle";
import Text from "../../../theme/font";

const LogIn = React.memo((props) => {
  const theme = defaultTheme;
  const [menuHidden] = useState(true);
  const { changeMenuStatus, changeNavBarStatus, changeAlertStatus } =
    useContext(ContainerContext);
  const tokenMicappital = AuthTokenService.getAccessToken();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    changeMenuStatus(menuHidden);
    changeNavBarStatus(true);
    changeAlertStatus(false);
    return () => {
      changeMenuStatus(!menuHidden);
      changeNavBarStatus(false);
      changeAlertStatus(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeMenuStatus, changeNavBarStatus]);

  useEffect(() => {
    if (tokenMicappital) {
      navigate("/resumen");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <LogInStyle>
        <div className="log-in-container inner-container">
          {/* Left module containing image */}
          <div className="image-sec">
            <div className="main-icon">
              <img className="icon-1" alt="x" src={ImageConstants.mainIcon} />
            </div>
            <Text variant="mediumFont" type="primary" className="logIn-content">
              Sigue tu inversión desde dentro.
              <br />
              Inicia sesión para seguir invirtiendo
            </Text>
            <div className="logIn-images">
              <div className="login-backgorund">
                <img
                  className="initial-image"
                  src={ImageConstants.logInBg}
                  alt="x"
                />
              </div>
            </div>
          </div>
          {/* Right module containg login form */}
          <div className="form-container">
            <Text variant="outlined" className="logIn-title">
              Inicia Sesión
            </Text>
            {/* Right module Social media icons */}
            <LogInForm />
            <div />
          </div>
        </div>
      </LogInStyle>
    </ThemeProvider>
  );
});

export default LogIn;
