import React, { useEffect, useState } from "react";
import defaultTheme from "../../../../../theme/theme";
import FundStyle from "./fundsStyle";
import { ThemeProvider } from "styled-components";
import List from "../../../../../components/list/list";
import ListItem from "../../../../../components/listItem/listItem";

const Funds = React.memo(({ datosFondo }) => {
  const theme = defaultTheme;
  const [fundData, setFundData] = useState([]);
  const [data] = useState([...datosFondo]);

  useEffect(() => {
    const combinedData = [];
    if (data && data.length > 0) {
      // eslint-disable-next-line array-callback-return
      data.map((items) => {
        const dataItems = {
          primary: items.nombre,
          secondary: items.ISIN,
          teritioary: `${items.valor || items.importe}`,
        };
        combinedData.push(dataItems);
      });
      setFundData([...combinedData]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datosFondo]);

  return (
    <ThemeProvider theme={theme}>
      <FundStyle>
        <List data={fundData} limit={10}>
          <ListItem variant="proposal" />
        </List>
      </FundStyle>
    </ThemeProvider>
  );
});

export default Funds;
