const AuthTokenService = (() => {
  const _setToken = (tokenObj) => {
    localStorage.setItem("token", `${tokenObj.token}`);
    localStorage.setItem("idCliente", `${tokenObj.idCliente}`);
    localStorage.setItem("idClienteSeguros", `${tokenObj.idClienteSeguros}`);
    localStorage.setItem("nombre", `${tokenObj.nombre}`);
    localStorage.setItem("apellidos", `${tokenObj.apellidos}`);
    localStorage.setItem("imagen", `${tokenObj.imagen}`);
    localStorage.setItem("idSuperCliente", `${tokenObj.idSuperCliente}`);
  };
  const _getAccessToken = () => {
    return localStorage.getItem("token");
  };
  const _getUserId = () => {
    return localStorage.getItem("idCliente");
  };
  const _getIdClienteSeguros = () => {
    return localStorage.getItem("idClienteSeguros");
  };
  const _getNombre = () => {
    return localStorage.getItem("nombre");
  };
  const _getApellidos = () => {
    return localStorage.getItem("apellidos");
  };
  const _getUserImage = () => {
    return localStorage.getItem("imagen");
  };
  const _getIdSuperCliente = () => {
    return localStorage.getItem("idSuperCliente");
  };

  const _clearToken = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("idCliente");
    localStorage.removeItem("idClienteSeguros");
    localStorage.removeItem("nombre");
    localStorage.removeItem("apellidos");
    localStorage.removeItem("imagen");
    localStorage.removeItem("idSuperCliente");
  };

  return {
    setToken: _setToken,
    getAccessToken: _getAccessToken,
    getUserId: _getUserId,
    clearToken: _clearToken,
    getIdClienteSeguros: _getIdClienteSeguros,
    getNombre: _getNombre,
    getApellidos: _getApellidos,
    getUserImage: _getUserImage,
    getIdSuperCliente: _getIdSuperCliente
  };
})();

export default AuthTokenService;
