import React, { useState, useEffect, useContext } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip as GraphTooltip,
  ResponsiveContainer,
  ReferenceLine,
  Label,
} from "recharts";
import Moment from "moment";
import { ThemeProvider } from "styled-components";
import { AlertContext } from "../../../common/alertContainer";
import { AuthTokenService } from "../../../../services";
import {
  cargarPosicion,
  cargarGrafico,
  getGraphDateRange,
} from "../homeServices";
import DateRanger from "../../../../components/dateRangePicker/dateRange";
import defaultTheme from "../../../../theme/theme";
import { DownArrow, DualArrow, UpArrow } from "./arrowIcons/arrowIcons";
import GraphStyle from "./graphStyle";
import ImageConstants from "../../../../constants/imageConstants/image_constants";
import Loading from "../../../../components/commonModules/Loading";
import Switch from "@mui/material/Switch";
import Text from "../../../../theme/font";
import useClickOutside from "../../../../components/outSiderClicker/outsideClicker";

const CustomLabel = (props) => {
  const graphData = props.data;
  const { x, y } = props.viewBox;
  const d = 5;
  const r = 12;
  const transform = `translate(${x - r} ${y - d})`;

  if (graphData === "https://micappital.com/assets/img/iconos/entrada.svg") {
    return (
      <g transform={transform}>
        <UpArrow />
      </g>
    );
  } else if (
    graphData === "https://micappital.com/assets/img/iconos/salida.svg"
  ) {
    return (
      <g transform={transform}>
        <DownArrow />
      </g>
    );
  } else {
    return (
      <g transform={transform}>
        <DualArrow />
      </g>
    );
  }
};

const Graph = () => {
  const theme = defaultTheme;
  const [graphics, setGraphics] = useState(null);
  const [data, setData] = useState(null);
  const [period, setPeriod] = useState("Todo");
  const [isLoading, setIsLoading] = useState(true);
  const [dateRange, setDateRange] = useState([]);
  const [showCalender, setShowCalender] = useState(false);
  const [show, setShow] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [referenceValue, setReferenceValue] = useState([]);
  const [showIcons, setShowicons] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [investmentValue, setInvestmentValue] = useState(true);
  const [contributionValue, setContributionValue] = useState(true);
  const [filteredValue, setFilteredValue] = useState(null);
  const [filteredProfit, setFilteredProfit] = useState(null);
  const [filteredCost, setFilteredCost] = useState(null);
  const [filteredDataMax, setFilteredDataMax] = useState(null);
  const [filteredDataMin, setFilteredDataMin] = useState(null);
  const [minimumDate, setMinimumDate] = useState("");
  const [maximumDate, setMaximumDate] = useState("");
  // const [style, setStyle] = useState("rentabilidadNumero");
  let defaultDate = "";
  const [date, setDate] = useState({
    start: "",
    end: "",
  });
  const { open } = useContext(AlertContext);

  useEffect(() => {
    setScreenWidth(window.innerWidth);
    if (screenWidth < 600) {
      setShowicons(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //for changing the period of graph
  const changePeriod = (actual) => {
    setPeriod(actual);
  };

  const formato = (x, signo = false) => {
    var number = new Intl.NumberFormat("de-DE", {
      minimumFractionDigits: 2, //Decimals for returns at the top of the chart
      maximumFractionDigits: 2,
    }).format(x);
    if (signo && x > 0) {
      return "+" + number;
    } else return number;
  };

  const formatoSinDecimales = (x) => {
    var number = new Intl.NumberFormat("de-DE", {
      minimumFractionDigits: 0, //Decimals for returns at the top of the chart
      maximumFractionDigits: 0,
    }).format(x);
    return number;
  };

  //formatting the date
  const formatoFecha = (x) => {
    var date = Moment(x).format("DD-MM-YY");
    return date;
  };

  //for disabling the calender when click out side
  const domNode = useClickOutside(() => {
    setShowCalender(!showCalender);
    setTimeout(() => {
      setShow(false);
    }, [100]);
  });

  //for showing date range button
  const dateRangeClick = () => {
    setShowCalender(true);
    setShow(true);
  };

  //showing the icons in graph
  const handleChange = () => {
    setShowicons(!showIcons);
  };

  const contributionHandler = () => {
    setContributionValue(!contributionValue);
  };

  useEffect(() => {
    open({
      alertType: "spinner",
      open: true,
    });
    const userId = AuthTokenService.getUserId();
    if (userId) {
      cargarGrafico(userId)
        .then((result) => {
          open({
            alertType: "spinner",
            open: false,
          });
          if (result.statusText === "OK" && result.status === 200) {
            const graphicsData = result?.data?.data;
            setGraphics(graphicsData);
            setMinimumDate(result?.data?.fechaInicial);
            setMaximumDate(
              result?.data?.fechaValor ? result.data.fechaValor : new Date()
            );
            date.start =
              result?.data?.fechaFiltro === null
                ? result?.data?.fechaInicial
                : result?.data?.fechaFiltro;
            date.end =
              result?.data?.fechaValor === null
                ? new Date()
                : result.data.fechaValor;
            setIsLoading(false);
            let iteratedValues = [];

            // eslint-disable-next-line array-callback-return
            graphicsData &&
              // eslint-disable-next-line array-callback-return
              graphicsData.todo.map((value) => {
                if (value.icono) {
                  const obj = { value: value.fecha, icon: value.icono };
                  iteratedValues.push(obj);
                }
              });
            setReferenceValue([...iteratedValues]);
          }
        })
        .catch(() => {
          open({
            alertType: "spinner",
            open: false,
          });
          open({
            type: "error",
            title: "Error al enviar datos",
            alertType: "toast",
            open: true,
          });
        });
      cargarPosicion(userId)
        .then((result) => {
          open({
            alertType: "spinner",
            open: false,
          });
          if (result.statusText === "OK" && result.status === 200) {
            const data = result.data.data[0];
            setData(data);
            setIsLoading(false);
          }
        })
        .catch(() => {
          open({
            alertType: "spinner",
            open: false,
          });
          open({
            type: "error",
            title: "Error al enviar datos",
            alertType: "toast",
            open: true,
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultDate]);

  const renderHandler = () => {
    if (data == null || graphics == null || isLoading) {
      return <Loading />;
    } else {
      const {
        valor,
        rentabilidad,
        rentabilidadPor,
        rentabilidadMensual,
        rentabilidadMensualPor,
        rentabilidadAnual,
        rentabilidadAnualPor,
      } = data;
      const {
        todo,
        anio,
        mes,
        anioDatamax,
        anioDatamin,
        mesDatamax,
        mesDatamin,
        todoDatamax,
        todoDatamin,
        fakeGraphActive,
      } = graphics;

      const dateHandler = (start, end) => {
        const startDate = Moment(start).format("YYYY-MM-DD");
        const endDate = Moment(end).format("YYYY-MM-DD");
        setDate({ start: start, end: end });
        const userId = AuthTokenService.getUserId();
        getGraphDateRange(userId, startDate, endDate)
          .then((result) => {
            if (result.statusText === "OK" && result.status === 200) {
              if (result.data) {
                const dateData = result.data.data.betweendates;
                if (startDate !== endDate) {
                  setShowCalender(false);
                  setShow(false);
                }
                setDateRange(dateData);
                setFilteredCost(result.data.data.rentabilidad);
                setFilteredValue(result.data.data.valor);
                setFilteredProfit(result.data.data.rentabilidadPor);
                const DataMax = result.data.data.datamax;
                const DataMin = result.data.data.datamin;
                setFilteredDataMax(DataMax);
                setFilteredDataMin(DataMin);
                setPeriod("dateRange");
              } else {
                if (startDate !== endDate) {
                  setShowCalender(false);
                  setShow(false);
                }
                setDateRange([
                  {
                    aportado: 0,
                    fecha: startDate,
                    valor: "0",
                  },
                ]);
                setFilteredCost("0");
                setFilteredValue("0");
                setFilteredProfit("0");
                const DataMax = "0";
                const DataMin = "0";
                setFilteredDataMax(DataMax);
                setFilteredDataMin(DataMin);
                setPeriod("dateRange");
              }
            }
          })
          .catch(() => {
            open({
              type: "error",
              title: "Error al enviar datos",
              alertType: "toast",
              open: true,
            });
          });
      };

      const showCalenderHandler = () => {
        if (showCalender) {
          return (
            <div ref={domNode}>
              <DateRanger
                change={(start, end) => dateHandler(start, end)}
                start={date.start}
                end={date.end}
                minDate={minimumDate}
                maxDate={maximumDate}
              />
            </div>
          );
        } else {
          // eslint-disable-next-line no-unused-expressions
          null;
        }
      };

      //for getting maximum value for domain
      const maximumValueHandler = () => {
        switch (period) {
          case "Todo": {
            return todoDatamax;
          }
          case "Anio": {
            return anioDatamax;
          }
          case "Mes": {
            return mesDatamax;
          }
          default: {
            return filteredDataMax;
          }
        }
      };

      //for getting minimum value for domain
      const minimumValueHandler = () => {
        switch (period) {
          case "Todo": {
            return todoDatamin;
          }
          case "Anio": {
            return anioDatamin;
          }
          case "Mes": {
            return mesDatamin;
          }
          default: {
            return filteredDataMin;
          }
        }
      };

      const totalInvestmentHandler = () => {
        switch (period) {
          case "Todo": {
            return formato(valor, false);
          }
          case "Anio": {
            return formato(valor, false);
          }
          case "Mes": {
            return formato(valor, false);
          }
          default: {
            return formato(filteredValue, false);
          }
        }
      };

      //for getting cost
      const costHandler = (period) => {
        switch (period) {
          case "Todo": {
            return formato(rentabilidad, true);
          }
          case "Anio": {
            return formato(rentabilidadAnual, true);
          }
          case "Mes": {
            return formato(rentabilidadMensual, true);
          }
          default: {
            return formato(filteredCost, true);
          }
        }
      };

      //for getting profit
      const profitHandler = (period) => {
        switch (period) {
          case "Todo": {
            return formato(rentabilidadPor, true);
          }
          case "Anio": {
            return formato(rentabilidadAnualPor, true);
          }
          case "Mes": {
            return formato(rentabilidadMensualPor, true);
          }
          default: {
            return formato(filteredProfit, true);
          }
        }
      };

      const graphCostValueHandler = (period) => {
        switch (period) {
          case "Todo": {
            if (rentabilidad && parseFloat(rentabilidad) < 0) {
              return "negative-value";
            } else {
              return "rentabilidadNumero";
            }
          }
          case "Anio": {
            if (rentabilidadAnual && parseFloat(rentabilidadAnual) < 0) {
              return "negative-value";
            } else {
              return "rentabilidadNumero";
            }
          }
          case "Mes": {
            if (rentabilidadMensual && parseFloat(rentabilidadMensual) < 0) {
              return "negative-value";
            } else {
              return "rentabilidadNumero";
            }
          }
          case "dateRange": {
            if (filteredCost && parseFloat(filteredCost) < 0) {
              return "negative-value";
            } else {
              return "rentabilidadNumero";
            }
          }
          default: {
            return "rentabilidadNumero";
          }
        }
      };

      const graphProfitValueHandler = (period) => {
        switch (period) {
          case "Todo": {
            if (rentabilidadPor && parseFloat(rentabilidadPor) < 0) {
              return "negative-value";
            } else {
              return "rentabilidadNumero";
            }
          }
          case "Anio": {
            if (rentabilidadAnualPor && parseFloat(rentabilidadAnualPor) < 0) {
              return "negative-value";
            } else {
              return "rentabilidadNumero";
            }
          }
          case "Mes": {
            if (
              rentabilidadMensualPor &&
              parseFloat(rentabilidadMensualPor) < 0
            ) {
              return "negative-value";
            } else {
              return "rentabilidadNumero";
            }
          }
          case "dateRange": {
            if (filteredProfit && parseFloat(filteredProfit) < 0) {
              return "negative-value";
            } else {
              return "rentabilidadNumero";
            }
          }
          default: {
            return "rentabilidadNumero";
          }
        }
      };

      const graphHandler = () => {
        return (
          <div className="graficoDatosContainer">
            {(valor === "0" || fakeGraphActive) && (
              <div className="alert-container">
                <div className="alert-message">
                  Todavía no podemos ver tu inversión. Aquí podrás ver la
                  evolución de tu cartera.
                </div>
              </div>
            )}
            <ResponsiveContainer>
              <AreaChart
                width={500}
                height={300}
                data={
                  period === "Todo"
                    ? todo
                    : period === "Anio"
                    ? anio
                    : period === "Mes"
                    ? mes
                    : dateRange
                }
              >
                <XAxis
                  tickFormatter={(tick) => {
                    return formatoFecha(tick);
                  }}
                  dataKey="fecha"
                  tick={{
                    fontSize:
                      screenWidth >= 3000
                        ? 24
                        : screenWidth >= 2000
                        ? 18
                        : screenWidth >= 500
                        ? 13
                        : 12,
                    margin: 10,
                  }}
                />
                <YAxis
                  dataKey={(todo) => parseInt(todo.valor)}
                  intervalal={0}
                  tickCount={7}
                  tickFormatter={(tick) => {
                    return formatoSinDecimales(tick);
                  }}
                  tick={{
                    fontSize:
                      screenWidth >= 3000
                        ? 24
                        : screenWidth >= 2000
                        ? 18
                        : screenWidth >= 500
                        ? 13
                        : 12,
                    margin: 10,
                  }}
                  type="number"
                  domain={[
                    minimumValueHandler(period),
                    maximumValueHandler(period),
                  ]}
                  allowDataOverflow={true}
                />
                <GraphTooltip
                  formatter={(value) =>
                    new Intl.NumberFormat("de", {
                      style: "currency",
                      currency: "EUR",
                    }).format(value)
                  }
                  labelFormatter={(name) => Moment(name).format("DD-MM-YY")}
                />
                {referenceValue &&
                  referenceValue.length > 0 &&
                  referenceValue.map((value) => {
                    return (
                      showIcons && (
                        <ReferenceLine
                          key={Math.random()}
                          x={value.value}
                          stroke="#a6f3e2"
                          strokeWidth={2}
                          label={
                            <Label
                              position="top"
                              content={<CustomLabel data={value.icon} />}
                            />
                          }
                        />
                      )
                    );
                  })}
                <Area
                  type="monotone"
                  dataKey="aportado"
                  stroke="#2C787C"
                  fill="transparent"
                  strokeWidth={contributionValue ? 3 : 0}
                />
                <Area
                  type="monotone"
                  dataKey="valor"
                  stroke="#30B2B8"
                  fill="transparent"
                  strokeWidth={investmentValue ? 3 : 0}
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        );
      };

      return (
        <div className="graficoContainer box-container">
          <div className="graph-main-container">
            <div className="graph-data">
              <div className="float marginRight">
                <div className="valorTexto">
                  <Text variant="smallerText" type="primary">
                    Valor de tu inversión
                  </Text>
                </div>
                <div className="valorNumero">
                  <Text variant="largeText" type="primary">
                    {totalInvestmentHandler(period)}
                  </Text>
                  <span className="text-style">
                    <Text variant="labelFont">€</Text>
                  </span>
                </div>
              </div>
              <div className="value-container">
                <div className="value-inner-container">
                  <div className="valorTexto">
                    <Text variant="smallerText" type="primary">
                      Tu Rentabilidad
                    </Text>
                  </div>
                  <div className={graphCostValueHandler(period)}>
                    <Text variant="primaryFont">{costHandler(period)}</Text>
                    <span className="text-style">
                      <Text variant="smallerText">€</Text>
                    </span>
                  </div>
                </div>
                <div className="value-inner-container-renta">
                  <div className={graphProfitValueHandler(period)}>
                    <Text variant="primaryFont">{profitHandler(period)}</Text>
                    <span className="text-style">
                      <Text variant="smallerText">%</Text>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="floatRight graph-button-container">
              <button
                onClick={(e) => changePeriod("Todo", e)}
                className={
                  period === "Todo"
                    ? "graficoBoton graficoBotonSelected"
                    : "graficoBoton"
                }
                id="BotonTodo"
              >
                <Text variant="fieldFont" type="primary">
                  Todo
                </Text>
              </button>
              <button
                onClick={(e) => changePeriod("Anio", e)}
                className={
                  period === "Anio"
                    ? "graficoBoton graficoBotonSelected"
                    : "graficoBoton"
                }
                id="BotonAno"
              >
                <Text variant="fieldFont" type="primary">
                  Año
                </Text>
              </button>
              <button
                onClick={(e) => changePeriod("Mes", e)}
                className={
                  period === "Mes"
                    ? "graficoBoton graficoBotonSelected"
                    : "graficoBoton"
                }
                id="BotonMes"
              >
                <Text variant="fieldFont" type="primary">
                  Mes
                </Text>
              </button>

              <button
                className="graficoBoton date-button"
                onClick={() => dateRangeClick()}
                disabled={show}
              >
                <img alt="x" src={ImageConstants.dateRange} />
                <Text
                  variant="fieldFont"
                  type="primary"
                  className="calendar-button"
                >
                  Elige fecha
                </Text>
              </button>
              {showCalenderHandler()}
            </div>
          </div>
          {graphHandler()}
          <div className="labelContainer">
            <div className="inner-label-container">
              <div className="graphLabel">
                <div className="circuloPequeño verde"></div>
                <Text variant="smallerText">Valor de tu inversión</Text>
              </div>
              <div className="graphLabel">
                <div className="contribution-value-toggle icon-toggle">
                  <Switch
                    checked={contributionValue}
                    onChange={contributionHandler}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </div>
                <Text variant="smallerText">Aportaciones</Text>
              </div>
              <div className="graphLabel">
                <div className="arrow-icon-toggle icon-toggle">
                  <Switch
                    checked={showIcons}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </div>
                <Text variant="smallerText">Movimientos</Text>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <GraphStyle>{renderHandler()}</GraphStyle>
    </ThemeProvider>
  );
};
export default Graph;
