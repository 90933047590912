import React, { useEffect, useState } from "react";
import HistoryBoxStyle from "./historyBoxStyle";
import List from "../../../../components/list/list";
import ListItem from "../../../../components/listItem/listItem";
import Text from "../../../../theme/font";

const HistoryBox = React.memo((props) => {
  const [totalData, setTotalData] = useState([]);
  const [totalSum, setTotalSum] = useState(0);

  useEffect(() => {
    const combinedData = [];
    let sum = 0;
    if (props.value && props.value.length > 0) {
      // eslint-disable-next-line array-callback-return
      props.value.map((items) => {
        sum = sum + parseFloat(items.importe);
        const dataItems = {
          primary: items.fecha,
          secondary: items.importe,
        };
        combinedData.push(dataItems);
      });
      setTotalSum(sum);
      setTotalData([...totalData, ...combinedData]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  const formato = (x, signo = false) => {
    if (x > 0) {
      var number = new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(x);
      if (signo && x > 0) {
        return "+" + number;
      } else return number;
    } else {
      return x;
    }
  };

  return (
    <HistoryBoxStyle>
      <div
        className="history-box-container inner-container"
        key={Math.random()}
      >
        <div className="history-box-header">
          <div className="history-box-heading">
            <Text variant="titleFont">Histórico de aportaciones</Text>
          </div>
        </div>

        <div className="history-field">
          <List data={totalData} limit={4}>
            <ListItem variant="normal" />
          </List>
        </div>
        <div className="history-total">
          <div className="total-title">Total</div>
          <div className="total-amount">{`${formato(totalSum)}€`}</div>
        </div>
      </div>
    </HistoryBoxStyle>
  );
});

export default HistoryBox;
