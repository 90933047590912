import { Request } from "../../../../services";
import URL_CONSTANTS from "../../../../constants/urlConstants/urlConstants";

export const postGoal = (data) => {
  return Request.post(URL_CONSTANTS.add_new_goal, data);
};

export const deleteGoal = (cliente, objective) => {
  return Request.delete(`${URL_CONSTANTS.delete_goal}/${cliente}/${objective}`);
};

export const editGoal = (data) => {
  return Request.patch(URL_CONSTANTS.edit_goal, data);
};

export const getGoal = (cliente, objective) => {
  return Request.get(
    `${URL_CONSTANTS.get_objective_from_id}/${cliente}/${objective}`
  );
};

export const getIcons = (data) => {
  return Request.get(`${URL_CONSTANTS.get_objective_icons}/${data}`);
};
