import styled from "styled-components";

const InsuranceDetailsStyle = styled.div`
  padding: 1rem 1.7rem 1rem 0rem;
  @media (max-width: 1040px) {
    padding: 1rem 0rem;
  }
  .insurance-details-container {
    display: flex;
    gap: 2%;
    @media (max-width: 1040px) {
      flex-direction: column;
    }
    .primary-section {
      width: calc(100% - 35%);
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      @media (max-width: 1040px) {
        width: 100%;
        padding-bottom: 1.5rem;
        align-items: center;
      }
      .selected-insurance-section {
        width: 100%;
        overflow: hidden;
      }
      .policy-section {
        @media (max-width: 1040px) {
          max-width: 22rem;
        }
        .policy-section-container {
          padding: 2rem;
          display: flex;
          flex-direction: column;
          gap: 1rem;
          .policy-section-heading {
            color: ${(props) => props.theme.colors.fontColor.titleFontColor};
            font-weight: bold;
            @media (max-width: 540px) {
              text-align: center;
            }
          }
          .policy-description {
            color: ${(props) =>
              props.theme.colors.fontColor.descriptionFontColor};
          }
          .policy-button-container {
            .download-button {
              max-width: 14rem;
              font-weight: bold;
              background-color: ${(props) =>
                props.theme.colors.fontColor.titleFontColor};
            }
          }
        }
      }
      .cancel-letter-section {
        @media (max-width: 1040px) {
          max-width: 22rem;
        }
      }
    }
    .secondary-section {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      width: calc(100% - 67%);
      @media (max-width: 1040px) {
        width: 100%;
        display: flex;
        align-items: center;
      }
      .optimization-section {
        @media (max-width: 1040px) {
          max-width: 22rem;
        }
        .optimization-section-container {
          padding: 1rem;
          display: flex;
          flex-direction: column;
          gap: 1rem;
          .optimization-section-heading {
            color: ${(props) => props.theme.colors.fontColor.titleFontColor};
            font-weight: bold;
            @media (max-width: 540px) {
              text-align: center;
            }
          }
          .optimization-section-description {
            font-weight: bold;
            color: ${(props) =>
              props.theme.colors.fontColor.descriptionFontColor};
          }
          .optimization-section-button-container {
            .see-optimization-button {
              max-width: 14rem;
              font-weight: bold;
              background-color: ${(props) =>
                props.theme.colors.fontColor.titleFontColor};
            }
          }
        }
      }
      .claim-section {
        @media (max-width: 1040px) {
          max-width: 22rem;
        }
        .claim-section-container {
          padding: 1rem;
          display: flex;
          flex-direction: column;
          gap: 1rem;
          .claim-section-heading {
            color: ${(props) => props.theme.colors.fontColor.titleFontColor};
            font-weight: bold;
            @media (max-width: 540px) {
              text-align: center;
            }
          }

          .claim-section-button-container {
            .start-button {
              font-weight: bold;
              max-width: 10rem;
            }
          }
        }
      }
    }
  }
`;
export default InsuranceDetailsStyle;
