import { useEffect, useState, useContext } from "react";
import { ContainerContext } from "../../../../components/navbarContainer/containerProvider";
import defaultTheme from "../../../../theme/theme";
import LeftContainer from "../leftConainer/leftContainer";
import RecoverForm from "./recoverForm/recoverForm";
import RecoverPasswordStyle from "./recoverPasswordStyle";
import { ThemeProvider } from "styled-components";

const RecoverPassword = () => {
  const theme = defaultTheme;
  const [menuHidden] = useState(true);
  const { changeMenuStatus, changeNavBarStatus, changeAlertStatus } =
    useContext(ContainerContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    changeMenuStatus(menuHidden);
    changeNavBarStatus(true);
    changeAlertStatus(false);
    return () => {
      changeMenuStatus(!menuHidden);
      changeNavBarStatus(false);
      changeAlertStatus(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeMenuStatus, changeNavBarStatus]);

  return (
    <ThemeProvider theme={theme}>
      <RecoverPasswordStyle>
        <div className="forgot-password-container">
          <LeftContainer />
          <div className="form-container">
            <RecoverForm />
          </div>
        </div>
      </RecoverPasswordStyle>
    </ThemeProvider>
  );
};

export default RecoverPassword;
