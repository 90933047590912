import React, { useEffect, useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import Moment from "moment";
import GraphicoEvolutionStyle from "./graphicoEvolutionStyle";

const GraphEvolution = React.memo((props) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    setScreenWidth(window.innerWidth);
  }, []);

  const formatoSinDecimales = (x) => {
    var number = new Intl.NumberFormat("de-DE", {
      minimumFractionDigits: 0, //Decimals for the returns above the graph
      maximumFractionDigits: 0,
    }).format(x);
    return number;
  };

  const formatoFecha = (x) => {
    var date = Moment(x, "YYYY-MM-DD").format("DD-MM-YY");
    return date;
  };

  const financial = (x) => {
    return Number.parseFloat(x).toFixed(0);
  };

  return (
    <GraphicoEvolutionStyle>
      <div className="graficoDatosContainer">
        <ResponsiveContainer>
          <AreaChart
            width={300}
            height={380}
            minWidth={300}
            padding={20}
            data={props.graficos}
          >
            <XAxis
              tickFormatter={(tick) => {
                return formatoFecha(tick);
              }}
              dataKey="fecha"
              tick={{
                fontSize:
                  screenWidth >= 3000
                    ? 24
                    : screenWidth >= 2000
                    ? 18
                    : screenWidth >= 500
                    ? 13
                    : 12,
                margin: 10,
              }}
            />
            <YAxis
              unit="€"
              tickFormatter={(tick) => {
                return formatoSinDecimales(tick);
              }}
              tick={{
                fontSize:
                  screenWidth >= 3000
                    ? 24
                    : screenWidth >= 2000
                    ? 18
                    : screenWidth >= 500
                    ? 13
                    : 12,
                margin: 10,
              }}
              type="number"
              allowDataOverflow={true}
              domain={[(dataMin) => financial(dataMin * 0.95), "auto"]}
            />
            <Tooltip
              formatter={(value) =>
                new Intl.NumberFormat("de", {
                  style: "currency",
                  currency: "EUR",
                }).format(value)
              }
              labelFormatter={(name) =>
                Moment(name, "YYYY-MM-DD").format("DD-MM-YY")
              }
              wrapperStyle={{ minWidth: "150px" }}
            />
            <Area
              name="Plan Micappital"
              type="monotone"
              dataKey="carteraModelo"
              stroke="#30B2B8"
              fill="transparent"
              strokeWidth={2}
            />
            <Area
              name="Depósito"
              type="monotone"
              dataKey="deposito"
              stroke="#2C787C"
              fill="transparent"
              strokeWidth={3}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </GraphicoEvolutionStyle>
  );
});

export default GraphEvolution;
