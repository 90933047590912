/* eslint-disable array-callback-return */
import React, { useState, useContext, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import AccountStyle from "./accountSettingsStyle";
import { AlertContext } from "../../common/alertContainer";
import { AuthTokenService } from "../../../services";
import { ContainerContext } from "../../../components/navbarContainer/containerProvider";
import defaultTheme from "../../../theme/theme";
import {
  getBankData,
  getDatos,
  postCardResponse,
  getGeneralData,
} from "./accountSettingsServices";
import settingsOptionsDetails from "../../../models/settingsArray";
import { Titles } from "./enum";
import { useSearchParams } from "react-router-dom";
import {
  AccountDetailsPopUp,
  AddBankPopUp,
  CardDetailsPopUp,
  CommissionPopUp,
  ContractPopUp,
  DocumentPopUp,
  ProfileBox,
  ProfilePicturePopUp,
  SettingsOptions,
  TestPopUp,
  TutorialPopUp,
} from "./index";
import ScrollBar from "../../../components/scrollbar/scrollbar";
import { useDispatch, useSelector } from "react-redux";
import {
  setConnectionData,
  setHideAlert,
} from "../../../components/alertmessage/alertMessageAction";

const AccountSettings = React.memo(() => {
  const theme = defaultTheme;
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [popUpValue, setPopUpvalue] = useState("");
  const [popUpType, setPopUpType] = useState("tarjeta");
  const [userDetails, setUserDetails] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [bankDetails, setBankDetails] = useState({});
  const [bankDictionary, setBankDictionary] = useState({});
  const [banks, setBanks] = useState([]);
  const [image, setImage] = useState(null);
  const [filteredOptions, setFilteredOptions] = useState(
    settingsOptionsDetails
  );
  const { open } = useContext(AlertContext);
  const { titleHandler, headerTitleHandler, pageNameHandler } =
    useContext(ContainerContext);
  const { profileIConHandler } = useContext(ContainerContext);
  const [searchParams] = useSearchParams();
  const section = searchParams.get("section");
  const alertMessageData = useSelector((state) => state.alert);
  const dispatch = useDispatch();
  //Used to load current user details whenever page renders
  useEffect(() => {
    window.scrollTo(0, 0);
    open({
      alertType: "spinner",
      open: true,
    });
    toogleNavBar();

    setPopUpOpen(false);
    const id = AuthTokenService.getUserId();
    getBankData(id)
      .then((response) => {
        open({
          alertType: "spinner",
          open: false,
        });
        setBankDetails(response.data);
        const banks = response.data.bancos;
        let bankArray = {};
        banks.map((data) => {
          bankArray[data.idBanco] = false;
        });
        setBankDictionary({ ...bankArray });
      })
      .catch(() => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });
    //Get url parameters if they are there
    const Ds_SignatureVersion = searchParams.get("Ds_SignatureVersion");
    const Ds_MerchantParameters = searchParams.get("Ds_MerchantParameters");
    const Ds_Signature = searchParams.get("Ds_Signature");
    if (
      Ds_SignatureVersion != null &&
      Ds_MerchantParameters != null &&
      Ds_Signature != null
    ) {
      let formData = new FormData();
      formData.append("idCliente", AuthTokenService.getUserId());
      formData.append("Ds_SignatureVersion", Ds_SignatureVersion);
      formData.append("Ds_MerchantParameters", Ds_MerchantParameters);
      formData.append("Ds_Signature", Ds_Signature);
      postCardResponse(formData)
        .then((response) => {
          open({
            type: "success",
            title: response.data.msg,
            alertType: "toast",
            open: true,
          });
          dispatch(setHideAlert(false));
        })
        .catch(() => {
          open({
            alertType: "spinner",
            open: false,
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, section]);

  useEffect(() => {
    open({
      alertType: "spinner",
      open: true,
    });
    if (section === "common") {
      const idSuperCliente = AuthTokenService.getIdSuperCliente();
      getGeneralData(idSuperCliente)
        .then((result) => {
          open({
            alertType: "spinner",
            open: false,
          });
          setUserDetails(result.data);
        })
        .catch(() => {
          open({
            alertType: "spinner",
            open: false,
          });
          open({
            type: "error",
            title: "Error al enviar datos",
            alertType: "toast",
            open: true,
          });
        });
    } else {
      const id = AuthTokenService.getUserId();
      getDatos(id)
        .then((response) => {
          open({
            alertType: "spinner",
            open: false,
          });
          setUserDetails(response.data);
        })
        .catch(() => {
          open({
            alertType: "spinner",
            open: false,
          });
          open({
            type: "error",
            title: "Error al enviar datos",
            alertType: "toast",
            open: true,
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, section]);

  useEffect(() => {
    let settingsOptions = "";
    if (section === "common") {
      settingsOptions = settingsOptionsDetails.filter(
        (post) => post.title === "Datos de tu cuenta"
      );
      setFilteredOptions(settingsOptions);
    } else {
      settingsOptions = settingsOptionsDetails.filter(
        (post) => post.title !== "Datos de tu cuenta"
      );

      setFilteredOptions(settingsOptions);
    }
  }, [section]);

  const toogleNavBar = () => {
    titleHandler("Ajustes de tu cuenta");
    headerTitleHandler(section === "common" ? "" : "Inversión");
    pageNameHandler("Ajustes");
  };

  //for getting the bank data
  const bankHandler = (data) => {
    let tempKeys = Object.keys(data);
    let selectedBankKeys = [];
    tempKeys.map((value) => {
      if (data[value] === true) {
        selectedBankKeys.push(value);
      }
    });
    let selectedBanks = [];
    bankDetails.bancos.map((bank) => {
      selectedBankKeys.map((key) => {
        if (key === bank.idBanco) {
          selectedBanks.push(bank);
        }
      });
    });
    setBanks([...selectedBanks]);
    if (selectedBanks && selectedBanks.length === 0) {
      open({
        type: "error",
        title: "No has seleccionado ningún banco",
        alertType: "toast",
        open: true,
      });
    }
  };

  //Popup functions used for setting title and content, loading content component according to popup type
  const popUpHandler = (open, value, title) => {
    if (open) {
      setPopUpType(title);
      setPopUpvalue(value);
    } else {
      setPopUpvalue(null);
      dispatch(setConnectionData(false));
    }
    setPopUpOpen(open);
  };

  const imageHandler = (data) => {
    setImage(data);
    profileIConHandler(data);
  };

  const renderPopUptype = (titles) => {
    // eslint-disable-next-line default-case
    switch (titles) {
      case Titles.CARD: {
        return <CardDetailsPopUp data={popUpValue} />;
      }
      case Titles.ACCOUNT_DETAILS: {
        return (
          <AccountDetailsPopUp
            data={popUpValue}
            submited={() => setIsEdit(!isEdit)}
            datas={userDetails}
            section={section}
          />
        );
      }
      case Titles.DOCUMENT: {
        return (
          <DocumentPopUp
            data={popUpValue}
            submited={() => setIsEdit(!isEdit)}
          />
        );
      }
      case Titles.TEST: {
        return (
          <TestPopUp
            data={popUpValue}
            datas={userDetails}
            submited={() => setIsEdit(!isEdit)}
          />
        );
      }
      case Titles.CONTRACT: {
        return <ContractPopUp data={popUpValue} datas={userDetails} />;
      }
      case Titles.TUTORIAL: {
        return <TutorialPopUp data={popUpValue} />;
      }
      case Titles.PROFILE_PICTURE: {
        return (
          <ProfilePicturePopUp
            submited={() => popUpHandler(false)}
            profilePictureHandler={(e) => imageHandler(e)}
            section={section}
          />
        );
      }
      case Titles.CHOOSE_YOUR_BANK: {
        return (
          <AddBankPopUp
            data={bankDetails}
            submit={bankHandler}
            datas={bankDictionary}
            submited={() => popUpHandler(false)}
          />
        );
      }
      case Titles.COMMISSION: {
        return <CommissionPopUp />;
      }
    }
  };

  const popUpOpenHandler = () => {
    let popUp = null;
    if (popUpOpen) {
      popUp = (
        <div className="popUp-wrapper">
          <div
            className="popUp-overlay"
            onClick={() => popUpHandler(false, null)}
          ></div>
          <div className="popUp-container">
            <ScrollBar max_height={"32rem"}>
              {renderPopUptype(popUpType)}
            </ScrollBar>
          </div>
        </div>
      );
    } else {
    }
    return popUp;
  };

  return (
    <ThemeProvider theme={theme}>
      <AccountStyle>
        {popUpOpenHandler()}
        <div className="content-box">
          {/* Left module contents loaded from array  */}
          <div className="grid-box-wrapper">
            <div className="grid-box box-container">
              {filteredOptions &&
                filteredOptions.map((post) => {
                  return (
                    <SettingsOptions
                      src={post.src}
                      title={post.title}
                      content={post.content}
                      tick={post.flag}
                      click={() => popUpHandler(true, post, post.title)}
                      datas={userDetails}
                      key={Math.random()}
                      banks={post.isBanks ? banks : []}
                      connected={alertMessageData.alertData}
                    />
                  );
                })}
            </div>
          </div>
          {/* Right module contents including right profile details and image upload popup */}
          <div className="profile-box-wrapper">
            <ProfileBox
              datas={userDetails}
              profileImage={image}
              click={() =>
                popUpHandler(
                  true,
                  { title: "Imagen de perfil" },
                  "Imagen de perfil"
                )
              }
              section={section}
            />
          </div>
        </div>
      </AccountStyle>
    </ThemeProvider>
  );
});
export default AccountSettings;
