import styled from "styled-components";

const ImpactMapStyle = styled.div`
  .impact-map-section {
    padding: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 1240px) {
      flex-direction: column;
    }
    @media (max-width: 1040px) {
      flex-direction: row;
    }
    @media (max-width: 780px) {
      flex-direction: column;
      padding: 1rem 0;
    }
    .impact-map-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 1200px) {
        flex-direction: column;
      }
      .impact-map-title {
        padding-bottom: 1rem;
        font-weight: bold;
        color: ${(props) => props.theme.colors.fontColor.titleFontColor};
        @media (max-width: 780px) {
          text-align: center;
        }
      }
      .map-container {
        min-width: 26.25rem;
        @media (max-width: 540px) {
          min-width: 18.75rem;
        }
        @media (max-width: 400px) {
          min-width: 16.87rem;
        }
      }
    }
    .impact-map-labels {
      flex: 0 1 30%;
      .less-than-label {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        .primary-label-color {
          height: 1rem;
          width: 1rem;
          background-color: ${(props) =>props.theme.colors.background.primaryMapLabelColor};
          border-radius: 1.56rem;
        }
      }
      .between-label {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        .secondary-label-color {
          height: 1rem;
          width: 1rem;
          background-color: ${(props) =>props.theme.colors.background.secondaryMapLabelColor};
          border-radius: 1.56rem;
        }
      }
      .more-than-label {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        .teritiary-label-color {
          height: 1rem;
          width: 1rem;
          background-color: ${(props) =>props.theme.colors.background.teritiaryMapLabelColor};
          border-radius: 1.56rem;
        }
      }
    }
  }
`;
export default ImpactMapStyle;
