import React, { Fragment, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { AlertContext } from "../../../common/alertContainer";
import { AuthTokenService } from "../../../../services";
import {
  cargarOptimizacion,
  cargarCambios,
  cargarPosicion,
} from "../homeServices";
import defaultTheme from "../../../../theme/theme";
import ImageConstants from "../../../../constants/imageConstants/image_constants";
import Loading from "../../../../components/commonModules/Loading";
import OptimizationStyle from "./optimizationStyle";
import Text from "../../../../theme/font";

const Optimization = ({ popUpClick, data }) => {
  const theme = defaultTheme;
  const navigate = useNavigate();
  const [optimization, setOptimization] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [changes, setChanges] = useState(null);
  const [valorData, setValorData] = useState("");
  const [autopilot, setAutopilot] = useState(0);
  const { open } = useContext(AlertContext);
  const userId = AuthTokenService.getUserId();
  const connectionData = data && data.mode;

  useEffect(() => {
    open({
      alertType: "spinner",
      open: true,
    });
    const userId = AuthTokenService.getUserId();
    cargarOptimizacion(userId)
      .then((result) => {
        open({
          alertType: "spinner",
          open: false,
        });
        if (result.statusText === "OK" && result.status === 200) {
          const optimizationData = result.data.data[0].optimizacion;
          setOptimization(optimizationData);
          setIsLoading(false);
        }
      })
      .catch(() => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });

    cargarCambios(userId).then((result) => {
      if (result.statusText === "OK" && result.status === 200) {
        const optimizationChanges = result.data.cambios;
        setChanges(optimizationChanges);
        setIsLoading(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    cargarPosicion(userId)
      .then((result) => {
        open({
          alertType: "spinner",
          open: false,
        });
        const totalData = result?.data?.data[0].valor;
        setValorData(totalData);
        const autopilotData = result?.data?.data[0].autopilot;
        setAutopilot(autopilotData);
      })
      .catch(() => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //It controls the navigation of red button
  const clickHandler = () => {
    if( autopilot==1){
      navigate("/autopilot");
    }else if (valorData === "0") {
      navigate("/propuesta");
    } else {
      navigate("/optimizacion");
    }
  };

  //for changing the state in parent
  const popUpClickHandler = () => {
    popUpClick();
  };

  const autopilotRendering = () => {
    return (
      <div className="box-container optimizationContainer">
        <div className="optimization">
          <Text variant="primaryFont">Tu optimización</Text>
          <Text
            variant="primaryFont"
            type="primary"
            className="optimizationPercentage"
          >
            0%
          </Text>
        </div>
        <div className="optimizationIconText">
          <img
            className="warningImg"
            alt="x"
            src={ImageConstants.Warning}
          ></img>{" "}
          <Text variant="labelFont">Tu cartera no está optimizada.</Text>
        </div>
        <Text className="notUpdatedText" variant="popUpText">
          ¡Aun no has comprado tus fondos!
        </Text>

        <div
          className="button-container"
        >
          <Text
            variant="fieldFont"
            className="button optimization-button"
            onClick={() => clickHandler()}
          >
            Siguiente paso
          </Text>
        </div>
      </div>
    );
  };
  const optimizedRendering = () => {
    return (
      <div className="box-container optimizationContainer">
        <div className="optimization">
          <Text variant="primaryFont">Tu optimización</Text>
          <Text variant="primaryFont" type="primary">
            {Math.round(optimization)}%
          </Text>
        </div>
        <div className="optimizationIconText">
          <img className="ticImg" alt="x" src={ImageConstants.Tic}></img>
          <Text variant="labelFont">Tu cartera está optimizada.</Text>
        </div>
        <Text variant="normal" className="updatedText">
          ¡Genial! Todas tus inversiones están actualizadas y optimizadas.
        </Text>
        <Text variant="normal" className="notifyText">
          En este panel te avisaremos cuando debes optimizar tus fondos.
        </Text>
      </div>
    );
  };
  const notOptimizedRendering = () => {
    return (
      <div className="box-container optimizationContainer">
        <div className="optimization">
          <Text variant="primaryFont">Tu optimización</Text>
          <Text
            variant="primaryFont"
            type="primary"
            className="optimizationPercentage"
          >
            {Math.round(optimization)}%
          </Text>
        </div>
        <div className="optimizationIconText">
          <img
            className="warningImg"
            alt="x"
            src={ImageConstants.Warning}
          ></img>{" "}
          <Text variant="labelFont">Tu cartera no está optimizada.</Text>
        </div>
        {valorData === "0" ? (
          <Text className="notUpdatedText" variant="popUpText">
            ¡Aun no has comprado tus fondos!
          </Text>
        ) : (
          <Text variant="normal" className="notUpdatedText">
            Tienes que hacer {changes.length} cambio
            {changes.length !== 1 ? "s" : ""} en tu cartera
          </Text>
        )}

        {/* condition added for changing the style according to the connected value */}
        <div
          className={
            connectionData === "manual" && valorData === "0"
              ? "manual-user-button-container"
              : "button-container"
          }
        >
          {/* if the user is connected, this link won't display */}
          {connectionData === "manual" && valorData === "0" && (
            <Text
              variant="smallerText"
              className="button redirect-button"
              onClick={() => popUpClickHandler()}
            >
              Ya he comprado mis fondos
            </Text>
          )}
          <Text
            variant={connectionData === "manual" ? "smallerText" : "fieldFont"}
            className={
              connectionData === "manual"
                ? "button optimization-button bold-button"
                : "button optimization-button"
            }
            onClick={() => clickHandler()}
          >
            {/* button text changes according to optimization value */}
            {valorData === "0"
              ? "Ver recomendación inicial"
              : `Ver cambio${changes.length !== 1 ? "s" : ""}`}
          </Text>
        </div>
      </div>
    );
  };

  const renderHnadler = () => {
    if (isLoading || optimization == null || changes == null) {
      return <Loading />;
    } else {
      if(autopilot==1){
        return <Fragment>{autopilotRendering()}</Fragment>;
      }else if (optimization === "100") {
        return <Fragment>{optimizedRendering()}</Fragment>;
      } else {
        return <Fragment>{notOptimizedRendering()}</Fragment>;
      }
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <OptimizationStyle>{renderHnadler()}</OptimizationStyle>
    </ThemeProvider>
  );
};
export default Optimization;
