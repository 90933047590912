import styled from "styled-components";

const StartingManualStyle = styled.div`
  .manual-container {
    padding: 1.2rem;
    /* @media (max-width: 991px) {
      margin-top: 4rem;
    }
    @media (max-width: 500px) {
      margin-top: 2rem;
    } */
    .center-child {
      padding: 1.5rem 0 0 0;
      text-align: center;
      .proposal-image {
        height: 3.75rem;
      }
    }
    .manual-title {
      text-align: left;
      font-weight: bold;
      color: ${(props) => props.theme.colors.fontColor.titleFontColor};
      padding: 1rem 2.5rem;
    }
    .manual-description {
      text-align: left;
      color: ${(props) => props.theme.colors.fontColor.descriptionFontColor};
      padding: 0rem 0rem 1.5rem 2.5rem;
    }
    .fila-propuesta-autopilot{
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }
    .inputPropuesta{
      margin-right:10px;
    }
    .proposal-fund-container {
      padding: 0rem 1.5rem;
      gap: 0.3rem;
      display: flex;
      flex-direction: column;
      @media (max-width: 420px) {
        padding: 0rem;
      }
      .header-background {
        display: flex;
        width: 100%;
        padding: 0.8rem 1.8rem;
        @media (max-width: 680px) {
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }
        .title-name {
          width: 60%;
          font-weight: bold;

          color: ${(props) => props.theme.colors.fontColor.fieldTitleColor};
          @media (max-width: 680px) {
            width: auto;
          }
        }
        .isin-title {
          width: 20%;
          font-weight: bold;
          text-align: center;
          color: ${(props) => props.theme.colors.fontColor.fieldTitleColor};
          @media (max-width: 680px) {
            width: auto;
          }
        }
        .amount-title {
          width: 20%;
          font-weight: bold;
          text-align: end;
          color: ${(props) => props.theme.colors.fontColor.fieldTitleColor};
          @media (max-width: 680px) {
            width: auto;
          }
        }
      }
      .buttonContainer {
        display: none;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1.8rem;
        .masFondosBoton {
          display: none;
          min-width: 10rem;
          min-height: 2.5rem;
          padding: 5px;
          border-radius: 4rem;
          border-width: 0px;
          text-align: center;
          background-color: ${(props) =>
            props.theme.colors.background.inputFieldBackground};
          color: ${(props) => props.theme.colors.fontColor.fieldTitleColor};
        }
      }
    }
    .inversiaButtonContainer {
      display: flex;
      /* width: 100%; */
      min-height: 3.5625rem;
      justify-content: flex-end;
      align-items: flex-end;
      padding: 1rem 2.5rem 0rem;
      color: ${(props) => props.theme.colors.text.textPrimary};
      @media (max-width: 450px) {
        justify-content: center;
        align-items: center;
      }
      .button-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        .inversiaButton {
          padding: 0.5rem 1rem;
          cursor: pointer;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          border-radius: 1.34375rem;
          min-width: 11rem;
          min-height: 2.68rem;
          gap: 1rem;
          background-color: ${(props) =>
            props.theme.colors.button.background.redButton};

          img {
            width: 1.8rem;
          }
        }
        .redirect-link {
          color: ${(props) => props.theme.colors.fontColor.titleFontColor};
          font-weight: bold;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
`;
export default StartingManualStyle;
