
export const iterationvalues = {
  "Salud" : true,
  "Educación" :true,
  "Agua" :true,
  "Energías alt" :true,
  "Infraestructura" :true,
  "Red. desigualdades" :true,
  "Prod. responsable" :true,
  "Clima" :true,
  "Vida terrestre":true,
}
