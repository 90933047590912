import styled from "styled-components";

const AutopilotStepStyle = styled.div`
  padding-right: 2rem;
  display:flex;
  justify-content: space-between;
  margin-top:1rem;
  @media (max-width: 1080px) {
    flex-direction:column;
  }
  
  .autopilot-step-img{
    width: 2rem;
  }

  .autopilot-step-row{
    display: flex;
    flex-direction: row;
  }

  .autopilot-step-text{
    padding-right:1rem;
    width:calc(100% - 2rem);
  }
  .autopilot-step-button{
    @media (max-width: 1080px) {
      margin-top: 0.5rem;
      margin-left: 2rem;
    }
  }
  .autopilot-step-button button{
    background-color:#00ca90;
    color:white;
    border-width:0px;
    border-radius:5px;
    padding: 0.4rem 1rem;
    @media (max-width: 1080px) {
      font-size: 15px;
      padding: 0.2rem 0.5rem;
    }
  }
  .autopilot-step-button button:disabled{
    
    border-color: #a6a6a6;
    background-color: #cdcdcd;
    color: black !important;
    cursor: not-allowed;
}
  @media (max-width: 1040px) {
    padding: 0;
  }
  .bold{
    font-weight:bold;
  }
`;
export default AutopilotStepStyle;
