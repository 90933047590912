import styled from "styled-components";

const ImpactSectorSwiperStyle = styled.div`
  width: 100%;
  background-color: ${(props) =>
    props.theme.colors.background.inputFieldBackground};
  padding: 0 3rem;
  border-radius: 1.5625rem;
  min-height: 6.25rem;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  .arrow-icon {
    cursor: pointer;
    height: 1rem;
  }
  .arrow-icon-left {
    padding-right: 0.5rem;
  }
  .swiper-initialized {
    min-height: 8.43rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    .swiper-wrapper {
      .swiper-slide {
        display: flex;
        justify-content: center;
        .swiper-icon {
          cursor: pointer;
          height: 2.8125rem;
        }
        .icon-container {
          padding: 1rem;
          border-radius: 3.375rem;
          opacity: 1;
        }
        .icon-container-not {
          padding: 1rem;
          border-radius: 3.375rem;
          opacity: 0.1;
        }
        .not-selected {
          opacity: 10%;
        }
        .icon-selected {
          opacity: 100%;
        }
      }
    }
  }
  .arrow-icon-right {
    padding-left: 0.5rem;
  }
`;
export default ImpactSectorSwiperStyle;
