import { useEffect, useContext } from "react";
import { AlertContext } from "../alertContainer";
import { AuthTokenService } from "../../../services";
import { ContainerContext } from "../../../components/navbarContainer/containerProvider";

const Logout = () => {
  const { open } = useContext(AlertContext);

  const { changeMenuStatus, changeNavBarStatus, changeAlertStatus } =
    useContext(ContainerContext);

  useEffect(() => {
    changeMenuStatus(true);
    changeNavBarStatus(true);
    changeAlertStatus(false);
    return () => {
      changeMenuStatus(false);
      changeNavBarStatus(false);
      changeAlertStatus(true);
    };
  }, [changeAlertStatus, changeMenuStatus, changeNavBarStatus]);

  useEffect(() => {
    open({
      alertType: "spinner",
      open: true,
    });
    AuthTokenService.clearToken();
    open({
      alertType: "spinner",
      open: false,
    });
  });

  return <div></div>;
};

export default Logout;
