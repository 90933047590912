import styled from "styled-components";

const HistoryBoxStyle = styled.div`
  width: calc(100% - 65%);
  height: fit-content;
  min-width: 18rem;
  background-color: ${(props) =>
    props.theme.colors.background.defaultBackground};
  border-radius: 1rem;
  padding: 1.5rem;
  @media (max-width: 1040px) {
    width: 100%;
    max-width: 38.75rem;
  }
  .history-box-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    height: 100%;
    .history-box-header {
      width: 100%;
      @media (max-width: 920px) {
        text-align: center;
      }
      .history-box-heading {
        font-weight: bold;
        color: ${(props) => props.theme.colors.fontColor.titleFontColor};
        @media (max-width: 920px) {
          width: 100%;
        }
      }
    }
    .history-field {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.3rem;
      .details-field {
        font-weight: bold;
        color: ${(props) => props.theme.colors.fontColor.titleFontColor};
        background-color: ${(props) =>
          props.theme.colors.background.defaultFieldBackground};
        height: 3.1rem;
        width: 100%;
        min-width: 14.6rem;
        border-radius: 1.25rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0rem 2rem;
        max-width: 22.3rem;
        .field {
          .date {
          }
        }
        .amount {
          font-weight: 400;
        }
      }
    }
    .history-total {
      display: flex;
      background-color: ${(props) =>
        props.theme.colors.fontColor.titleFontColor};
      justify-content: space-between;
      align-items: center;
      border-radius: 1.25rem;
      height: 3.1rem;
      padding: 0rem 2rem;
      width: 100%;
      max-width: 22.3rem;
      .total-title {
        color: ${(props) => props.theme.colors.fontColor.defaultFontColor};
        font-weight: bold;
      }
      .total-amount {
        color: ${(props) => props.theme.colors.fontColor.defaultFontColor};
        font-weight: 400;
        padding-left: 1rem;
      }
    }
  }
  .simplebar-scrollbar::before {
    background-color: ${(props) =>
      props.theme.colors.background.primaryCardBackground};
    border-radius: 0.4375rem;
  }
  .simplebar-vertical {
    width: 0.6875rem;
  }
`;
export default HistoryBoxStyle;
