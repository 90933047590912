import styled from "styled-components";

const DeletePopUpStyle = styled.div`
  .delete-pop-up-container {
    width: 100%;
    border-radius: 1.562rem;
    padding: 2rem 2rem 5rem 2rem;
    background-color: ${(props) =>
      props.theme.colors.background.defaultBackground};
    @media (max-width: 680px) {
      padding: 2rem 2rem 2rem 2rem;
    }
    .pop-up-header {
      display: flex;
      justify-content: start;
      align-items: center;
      @media (max-width: 680px) {
        flex-direction: column;
        gap: 2rem;
        justify-content: center;
        padding-left: 0rem;
      }
      .pop-up-heading {
        color: ${(props) => props.theme.colors.fontColor.titleFontColor};
        font-weight: bold;
        @media (max-width: 680px) {
          text-align: center;
        }
      }
    }
    .pop-up-content-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      .content-container {
        font-weight: bold;
        padding: 2rem 1rem 5rem 1rem;
        text-align: center;
        @media (max-width: 780px) {
          padding: 2rem 0rem;
        }
      }
      .pop-up-btn {
        width: 100%;
        display: flex;
        justify-content: space-between;
        @media (max-width: 1180px) {
          flex-direction: column;
          gap: 1rem;
          justify-content: center;
          align-items: center;
        }
        .cancel-button {
          width: 100%;
          max-width:15.62rem;
          .pop-up-cancel {
            background-color: ${(props) =>
              props.theme.colors.button.background.cancelButton};
            height: 2.5rem;
            color: ${(props) =>
              props.theme.colors.fontColor.descriptionFontColor};
          }
        }
        .delete-button {
          width: 100%;
          max-width:15.62rem;
          .pop-up-delete {
            height: 2.5rem;
          }
        }
      }
    }
  }
`;

export default DeletePopUpStyle;
