import styled from "styled-components";

const MyOptimizationStyle = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 1040px) {
    height: calc(100vh - 9rem);
  }
  @media (max-width: 395px) {
    padding-top: 2rem;
  }
  .primary-alert-container {
    padding-right: 1.5rem;
    @media (max-width: 1040px) {
      display: none;
    }
  }
  .optimization-container {
    width: 100%;
    gap: 2%;
    display: flex;
    padding: 3rem 1.5rem 3rem 0rem;
    @media (max-width: 1040px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0;
      height: 100%;
      max-height: 100%;
      gap: 0;
    }
    .popUp-wrpper {
      position: fixed;
      z-index: 150;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      .popUp-overlay {
        width: 100%;
        height: 100%;
        background-color: ${(props) =>
          props.theme.colors.background.overlayBackground};
        opacity: 0.5;
      }
      .popUp-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: auto;
        max-height: 100%;
        overflow: hidden;
        min-width: 50%;
        @media (max-width: 940px) {
          min-width: 85%;
        }
      }
    }
    .back-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 2.1875rem;
      width: 2.1875rem;
      position: fixed;
      top: 1.5rem;
      left: 2rem;
      border-radius: 1.5625rem;
      z-index: 1;
      @media (max-width: 1040px) {
        display: none;
      }
      cursor: pointer;
      img {
        width: 1.5625rem;
        height: 1.5625rem;
      }
    }
    .primary-section {
      width: calc(100% - 36%);
      gap: 2rem;
      @media (max-width: 1040px) {
        overflow: scroll;
        width: 100%;
        overflow-x: hidden;
        height: 70%;
        padding: 1rem;
      }
      ::-webkit-scrollbar {
        width: 0.31rem;
        right: 0.31rem;
      }
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 0.31rem transparent;
        border-radius: 0.625rem;
        right: 0.31rem;
      }
      ::-webkit-scrollbar-thumb {
        background: ${(props) =>
          props.theme.colors.background.scrollbarBackground};
        border-radius: 0.62rem;
        right: 0.31rem;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: ${(props) =>
          props.theme.colors.background.scrollbarHoverbackground};
      }
      .secondary-alert-container {
        @media (min-width: 1040px) {
          display: none;
        }
      }
      .primary-inner-container {
        height: 100%;
        flex-direction: column;
        gap: 2rem;
        display: flex;
        align-items: center;

        .change-proposal-section {
          width: 100%;
        }
        .tutorial-section {
          width: 100%;
          @media (max-width: 1040px) {
            padding-bottom: 1rem;
          }
        }
      }
    }
    .secondary-section {
      width: calc(100% - 66%);
      @media (max-width: 1040px) {
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;
export default MyOptimizationStyle;
