import { React, useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AlertContext } from "../../../../common/alertContainer";
import moment from "moment";
import { ThemeProvider } from "styled-components";
import AuthTokenService from "../../../../../services/authTokenService.js";
import { ContainerContext } from "../../../../../components/navbarContainer/containerProvider.jsx";
import defaultTheme from "../../../../../theme/theme.js";
import { deleteGoal, getGoal } from "../goalServices.js";
import DeletePopUp from "./deletePopUp/deletePopUp.jsx";
import EditGoal from "./editGoal/editGoal.jsx";
import EditObjectiveStyle from "./editObjectiveStyle.js";
import ImageConstants from "../../../../../constants/imageConstants/image_constants.jsx";
import NewGoalInfo from "../subComponents/newGoalInfo/newGoalInfo";

const EditObjective = () => {
  const theme = defaultTheme;

  const [deleter, setDeleter] = useState(false);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const goalId = query.get("id");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [amount, setAmount] = useState("");
  const [title, setTitle] = useState("");
  const [goalDetails, setGoalDetails] = useState({});
  const navigate = useNavigate();
  moment.locale("es");
  const userId = AuthTokenService.getUserId();
  const [menuHidden] = useState(true);
  const {
    changeMenuStatus,
    changeNavBarStatus,
    titleHandler,
    headerTitleHandler,
    pageNameHandler,
  } = useContext(ContainerContext);
  const { open } = useContext(AlertContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    open({
      alertType: "spinner",
      open: true,
    });
    getGoal(userId, goalId)
      .then((response) => {
        open({
          alertType: "spinner",
          open: false,
        });
        const resultData = response.data.data;
        const splitedDate = resultData.periodo.split("-");
        const newData = { month: splitedDate[1], year: splitedDate[0] };
        setGoalDetails({ ...resultData, ...newData });
      })
      .catch(() => {
        open({
          alertType: "spinner",
          open: false,
        });
      });

    changeNavBarStatus(false);
    changeMenuStatus(menuHidden);

    toogleNavBar();
    return () => {
      changeMenuStatus(!menuHidden);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toogleNavBar = () => {
    titleHandler("Editar Objetivo");
    pageNameHandler("editarObjetivo");
    headerTitleHandler("");
  };

  const deleteHandler = () => {
    if (!deleter) {
      setDeleter(true);
    } else {
      setDeleter(false);
    }
  };

  const disableHandler = () => {
    setDeleter(false);
  };

  const infoValueChange = (value, element) => {
    // eslint-disable-next-line default-case
    switch (element) {
      case "month": {
        setMonth(value);
        break;
      }

      case "year": {
        setYear(value);
        break;
      }
      case "amount": {
        setAmount(value);
        break;
      }
      case "title": {
        setTitle(value);
      }
    }
  };

  const removeHandler = () => {
    deleteGoal(userId, goalDetails.idObjetivo)
      .then(() => {
        disableHandler();
        navigate("/resumen");
      })
      .catch(() => {});
  };

  const popUpHandler = () => {
    if (deleter) {
      return (
        <div className="popUP-wrapper">
          <div className="popUp-overlay" onClick={() => disableHandler()}></div>
          <div className="popUp-container">
            <DeletePopUp
              onCancel={() => disableHandler()}
              submit={() => removeHandler()}
            />
          </div>
        </div>
      );
    }
  };

  const valueHandler = (type) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "month": {
        if (month) {
          return month;
        } else {
          if (goalDetails) {
            return goalDetails.month;
          }
        }
        break;
      }
      case "year": {
        if (year) {
          return year;
        } else {
          if (goalDetails) {
            return goalDetails.year;
          }
        }
        break;
      }
      case "amount": {
        if (amount) {
          return amount;
        } else {
          if (goalDetails) {
            return goalDetails.importe;
          }
        }
        break;
      }
      case "title": {
        if (title) {
          return title;
        } else {
          if (goalDetails) {
            return goalDetails.objetivo;
          }
        }
        break;
      }
      case "idObjetivo": {
        if (goalDetails) {
          return goalDetails.idObjetivo;
        }
        break;
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <EditObjectiveStyle>
        <div className="col-lg-12">
          <div className="row margin30 responsive-container Edit-inner-container">
            <div className="col-lg-8">
              <EditGoal
                data={goalDetails && goalDetails}
                changeData={infoValueChange}
              />
            </div>
            <div className="col-lg-4">
              <div className="col-lg-12 full-height">
                <NewGoalInfo
                  buttonValue="Eliminar"
                  onCancel={() => deleteHandler()}
                  month={valueHandler("month")}
                  year={valueHandler("year")}
                  amount={valueHandler("amount")}
                  title={valueHandler("title")}
                  submit={() => navigate("/resumen")}
                  idObjetivo={valueHandler("idObjetivo")}
                  isEdit={true}
                />
              </div>
            </div>
          </div>
          {popUpHandler()}
        </div>
        <div className="back-icon" onClick={() => navigate(-1)}>
          <img src={ImageConstants.arrowLeftIcon} alt="x" />
        </div>
      </EditObjectiveStyle>
    </ThemeProvider>
  );
};

export default EditObjective;
