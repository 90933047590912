import { useState, useContext, useEffect, useCallback } from "react";
import { FieldArray, getIn, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { AlertContext } from "../../../common/alertContainer";
import { AuthTokenService } from "../../../../services";
import Button from "../../../../components/button/button";
import DateSelector from "../../../../components/datePicker/datePicker";
import EuroTextField from "../../../../components/euroTextField/euroTextField";
import FundPopUpStyle from "./fundPopUpStyle";
import Moment from "moment";
import { postEcoInvestmentData } from "../../fundProposal/fundProposalServices";
import ScrollBar from "../../../../components/scrollbar/scrollbar";
import Text from "../../../../theme/font";
import { useNavigate } from "react-router-dom";
import { postOptimizationData } from "../homeServices";

const FundPopUp = (props) => {
  const { open } = useContext(AlertContext);
  const [dateValue, setDateValue] = useState(new Date());
  const [heightStyle, setHeightStyle] = useState({});
  const [errorHandler, setErrorHandler] = useState(false);
  const [initialAMount, setInitialAmount] = useState(props.data);
  const [NewIsin, setNewIsin] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setInitialAmount(props.data);
    setNewIsin(props.data[0].isinOrigen);
    if (props.data[0].isinOrigen) {
      if (props.data.length >= 2) {
        setHeightStyle({ height: "40rem" });
      } else {
        setHeightStyle({ height: "fit-content" });
      }
    } else {
      if (props.data.length >= 5) {
        setHeightStyle({ height: "40rem" });
      } else {
        setHeightStyle({ height: "fit-content" });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //For formik validation
  const validate = Yup.object({
    amount: Yup.array().of(
      Yup.object().shape({
        valor: Yup.string().required("Introduce un importe válido"),
      })
    ),
    date: Yup.date().nullable(),
  });

  const formik = useFormik({
    initialValues: {
      date: "",
      amount: props.data,
    },
    onSubmit: (value) => {
      if (dateValue) {
        if (NewIsin) {
          optimizationSubmitHandler(value);
        } else {
          submitHandler(value);
        }
      } else {
        setErrorHandler(true);
      }
    },
    validationSchema: validate,
  });

  //For formik validation
  const formValidationHandler = (conditionalValue, resultValue) => {
    let result = "";
    if (conditionalValue) {
      result = resultValue;
    }
    return result;
  };

  const formatter = (x) => {
    var number = new Intl.NumberFormat("de-DE", {
      minimumFractionDigits: 2, //Decimals for returns at the top of the chart
      maximumFractionDigits: 2,
    }).format(x);
    return number;
  };

  //For limiting the numbers in input field
  const changeHandler = useCallback((values, name) => {
    if (values.length < 9) {
      formik.setFieldValue(name, values);
    } else {
      const split = values.slice(0, 9);
      formik.setFieldValue(name, split);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //For checking the input is number
  const checkIfNumber = (event) => {
    const regex = new RegExp(
      /(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/
    );

    return !event.key.match(regex) && event.preventDefault();
  };

  //For submitting the investment data
  const submitHandler = (values) => {
    setErrorHandler(false);
    open({
      alertType: "spinner",
      open: true,
    });
    let newArray = [];
    let totalSum = 0;
    let total = "";
    // eslint-disable-next-line array-callback-return
    values.amount.map((item) => {
      const obj = { isin: item.ISIN, importe: item.valor.toString() };
      totalSum = totalSum + parseInt(item.valor);
      total = totalSum.toString();
      newArray.push(obj);
    });

    let convertedDate = Moment(dateValue).format("YYYY-MM-DD");

    const ChangedData = {
      idCliente: AuthTokenService.getUserId(),
      idCartera: AuthTokenService.getUserId(),
      fechaAportacion: convertedDate,
      importeAportacion: total,
      fondos: newArray,
    };
    postEcoInvestmentData(ChangedData)
      .then(() => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "success",
          title: "Aportación confirmada correctamente",
          alertType: "toast",
          open: true,
        });
        props.closePopUp();
        navigate("/resumen");
      })
      .catch((error) => {
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });
  };

  const optimizationSubmitHandler = (values) => {
    setErrorHandler(false);
    open({
      alertType: "spinner",
      open: true,
    });
    let newArray = [];
    // eslint-disable-next-line array-callback-return
    values.amount.map((item) => {
      const obj = {
        isinOrigen: item.isinOrigen,
        isinDestino: item.ISIN,
        importe: item.valor.toString(),
      };
      newArray.push(obj);
    });
    let convertedDate = Moment(dateValue).format("YYYY-MM-DD");
    const ChangedData = {
      idCliente: AuthTokenService.getUserId(),
      fechaAportacion: convertedDate,
      cambios: newArray,
    };
    postOptimizationData(ChangedData)
      .then(() => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "success",
          title: "Aportación confirmada correctamente",
          alertType: "toast",
          open: true,
        });
        props.closePopUp();
        navigate("/resumen");
      })
      .catch((error) => {
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });
  };

  return (
    <FundPopUpStyle>
      <div className="popup-main-container" style={heightStyle}>
        <ScrollBar elementName={"card_header_container"} max_height={"100%"}>
          <div className="investment-popup-container">
            <FormikProvider value={formik}>
              <form onSubmit={formik.handleSubmit}>
                <FieldArray
                  name="amount"
                  render={() => (
                    <div className="investment-popup-inner-container">
                      <div className="header-container">
                        <Text
                          variant="fieldFont"
                          className="investment-popup-heading"
                        >
                          Confirma la fecha de compra de tus fondos
                        </Text>
                        <div className="date-field-container">
                          <DateSelector
                            values={dateValue}
                            handleChange={(e) => setDateValue(e)}
                            className="date-selector"
                          />
                          {errorHandler && (
                            <Text
                              variant="extraSmall"
                              className="error-container"
                            >
                              seleccione una fecha
                            </Text>
                          )}
                        </div>
                      </div>
                      <div className="investment-details">
                        {formik.values.amount &&
                          formik.values.amount.map((value, index) => {
                            const touchedAmounts = getIn(
                              formik.touched,
                              `amount.${index}.valor`
                            );
                            const errorAmounts = getIn(
                              formik.errors,
                              `amount.${index}.valor`
                            );
                            return !value.isinOrigen ? (
                              <div className="investment-container" key={index}>
                                <Text
                                  variant="popUpFont"
                                  className="investment-title"
                                >
                                  {value.nombre}
                                </Text>
                                <Text
                                  variant="popUpFont"
                                  className="ISIN-number"
                                >
                                  Isin: {value.ISIN}
                                </Text>
                                <div className="amount-container">
                                  <Text
                                    variant="popUpFont"
                                    className="amount-label"
                                  >
                                    Importe de la compra:
                                  </Text>
                                  <div className="amount-field-container">
                                    <EuroTextField
                                      className="amount-field"
                                      name={`amount.${index}.valor`}
                                      value={formatter(
                                        initialAMount[index].valor ===
                                          "Traspaso total"
                                          ? " "
                                          : initialAMount[index].valor
                                      )}
                                      max={9}
                                      onChange={changeHandler}
                                      onKeyDown={(evt) => checkIfNumber(evt)}
                                      error={formValidationHandler(
                                        touchedAmounts,
                                        Boolean(errorAmounts)
                                      )}
                                      helperText={formValidationHandler(
                                        touchedAmounts,
                                        errorAmounts
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="optimization-investment-container">
                                <div className="withdraw-section">
                                  <div className="withdraw-heading">
                                    Sale de:
                                  </div>
                                  <div className="withdraw-details">
                                    <Text
                                      variant="popUpFont"
                                      className="withdraw-name"
                                    >
                                      {value.nombreOrigen}
                                    </Text>
                                    <Text
                                      variant="popUpFont"
                                      className="withdraw-isin"
                                    >
                                      isin: {value.isinOrigen}
                                    </Text>
                                    <div className="withdraw-amount-container">
                                      <Text
                                        variant="popUpFont"
                                        className="withdraw-amount"
                                      >
                                        {`${formatter(
                                          initialAMount[index].valor ===
                                            "Traspaso total"
                                            ? " "
                                            : `-${initialAMount[index].valor}`
                                        )}€`}
                                      </Text>
                                    </div>
                                  </div>
                                </div>
                                <div className="incoming-section">
                                  <Text
                                    variant="popUpFont"
                                    className="incoming-heading"
                                  >
                                    Entra a:
                                  </Text>
                                  <div className="incoming-details">
                                    <Text
                                      variant="popUpFont"
                                      className="incoming-name"
                                    >
                                      {value.nombre}
                                    </Text>
                                    <Text
                                      variant="popUpFont"
                                      className="incoming-isin"
                                    >
                                      isin: {value.ISIN}
                                    </Text>
                                    <div className="incoming-amount">
                                      <EuroTextField
                                        className="amount-field"
                                        name={`amount.${index}.valor`}
                                        value={formatter(
                                          initialAMount[index].valor ===
                                            "Traspaso total"
                                            ? " "
                                            : initialAMount[index].valor
                                        )}
                                        max={9}
                                        onChange={changeHandler}
                                        onKeyDown={(evt) => checkIfNumber(evt)}
                                        error={formValidationHandler(
                                          touchedAmounts,
                                          Boolean(errorAmounts)
                                        )}
                                        helperText={formValidationHandler(
                                          touchedAmounts,
                                          errorAmounts
                                        )}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                      <div className="button--container">
                        <div className="button-inner-container">
                          <Button
                            value="Confirmar"
                            className="finalize-button"
                            click={formik.submitForm}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                />
              </form>
            </FormikProvider>
          </div>
        </ScrollBar>
      </div>
    </FundPopUpStyle>
  );
};

export default FundPopUp;
