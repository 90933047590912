import { Request } from "../../../services";
import URL_CONSTANTS from "../../../constants/urlConstants/urlConstants";

export const getFriendsDetails = (data) => {
    return Request.get(`${URL_CONSTANTS.get_invited_friends_details}/${data}`)
}
export const getIroniaData = (data) => {
    return Request.get(`${URL_CONSTANTS.get_datosIronia}/${data}`)
}
export const patchIroniaData = (data) =>{
    return Request.patch(URL_CONSTANTS.get_datosIronia,data);
}
export const subirArchivoIronia = (data) => {
    return Request.post(URL_CONSTANTS.post_subirArchivoIronia, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
};
export const getIroniaCities = (data) => {
    return Request.get(`${URL_CONSTANTS.get_cities}/${data}`)
};
export const getIroniaZipCodes = (data) => {
    return Request.get(`${URL_CONSTANTS.get_zipCodes}/${data}`)
};

export const postEnviarDatosClienteIronia = (data) =>{
    return Request.post(URL_CONSTANTS.post_enviarDatosClienteIronia,data);
}

export const postCrearContratoIronia = (data) =>{
    return Request.post(URL_CONSTANTS.post_crearContratoIronia,data);
}

export const postFirmarContratoIronia = (data) =>{
    return Request.post(URL_CONSTANTS.post_firmarContratoIronia,data);
}

export const postComprarRecomendacionInicial = (data) =>{
    return Request.post(URL_CONSTANTS.post_comprarRecomendacionInicial,data);
}

export const postActualizarImporteInicial = (data) =>{
    return Request.post(URL_CONSTANTS.post_actualizarImporteInicial,data);
}

export const postMensajeAutopilot = (data) =>{
    return Request.post(URL_CONSTANTS.post_mensajeAutopilot,data);
}

export const postPrimerosCambios = (data) =>{
    return Request.post(URL_CONSTANTS.post_primerosCambios,data);
}

export const loadRedsysParamsAutopilot = (data) => {
    return Request.get(`${URL_CONSTANTS.get_loadRedsysParamsAutopilot}/${data}`)
};

export const loadPriceAutopilot = (data) => {
    return Request.get(`${URL_CONSTANTS.get_loadPriceAutopilot}/${data}`)
};

export const postAutopilotContribution = (data) =>{
    return Request.post(URL_CONSTANTS.post_autopilotContribution,data);
}

export const postTerminarAltaAutopilot = (data) =>{
    return Request.post(URL_CONSTANTS.post_terminarAltaAutopilot,data);
}

export const loadAutopilotChanges = (data) => {
    return Request.get(`${URL_CONSTANTS.get_cambiosInternos}/${data}`)
};

