import styled from "styled-components";

const ListItemStyle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  .list-item-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: ${(props) =>
      props.theme.colors.background.defaultFieldBackground};
    border-radius: 0.93rem;
    justify-content: space-around;
    flex: 1;
    padding: 0.75rem;
    .primary-data {
      flex: 2;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      word-break: break-word;
      font-weight: bold;
      color: ${(props) => props.theme.colors.fontColor.titleFontColor};
    }
    .secondary-data {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      word-break: break-word;
      color: ${(props) => props.theme.colors.fontColor.titleFontColor};
    }
    .teritiory-data {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      word-break: break-word;
      color: ${(props) => props.theme.colors.fontColor.titleFontColor};
    }
  }
  .fondo {
    overflow: auto;
    background-color: ${(props) =>
      props.theme.colors.background.defaultFieldBackground};
    min-height: 3.12rem;

    border-radius: 10px;
    padding: 0.75rem;
  }
  .reponsiveFondo {
    display: flex;
    /* flex-direction: column; */
    width: 100%;
    @media (max-width: 600px) {
      flex-direction: column;
      width: 100%;
      justify-content: center;
      text-align: center;
    }
  }
  .fondoNombre {
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    font-weight: bold;
    margin-bottom: 0;
    color: ${(props) => props.theme.colors.fontColor.titleFontColor};
    @media (max-width: 600px) {
      width: 100%;
      justify-content: center;
      text-align: center;
    }
  }
  .fondoISIN {
    display: flex;
    margin-bottom: 0;
    /* align-items: center; */
    flex-direction: column;
    padding-left: 0.4rem;
    /* text-align: center; */
    word-break: break-all;
    font-weight: bold;
    color: ${(props) => props.theme.colors.fontColor.titleFontColor};
    @media (max-width: 600px) {
      width: 100%;

      justify-content: center;
    }
  }
  .fondoImporte {
    display: flex;
    /* flex-direction: column; */
    justify-content: flex-end;
    /* align-items: center; */
    font-weight: bold;
    margin-bottom: 0;
    color: ${(props) => props.theme.colors.fontColor.titleFontColor};
    text-align: right;
    padding-left: 0.3rem;
    @media (max-width: 600px) {
      width: 100%;
      text-align: center;
      justify-content: center;
      padding-left: 0;
    }
  }
  .value-field {
    font-weight: bold;
    color: ${(props) => props.theme.colors.fontColor.titleFontColor};
    background-color: ${(props) =>
      props.theme.colors.background.defaultFieldBackground};
    min-height: 3.1rem;
    width: 100%;
    min-width: 14.6rem;
    border-radius: 1.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 2rem;
    max-width: 22.3rem;
    .field {
      .value-name {
      }
      .date {
        font-weight: 800;
        padding-left: 0.7rem;
      }
    }
    .value {
      font-weight: 400;
      padding-left: 0.3rem;
    }
  }
  .invested-list {
    width: 100%;
    background-color: ${(props) =>
      props.theme.colors.background.defaultFieldBackground};
    display: flex;
    justify-content: space-between;
    border-radius: 1.56rem;
    padding: 0.5rem 2rem;
    align-items: center;
    @media (max-width: 500px) {
      flex-direction: column;
    }
    .invest-info {
      .invested-title {
        font-weight: 500;
        color: ${(props) => props.theme.colors.fontColor.descriptionFontColor};
        @media (max-width: 500px) {
          text-align: center;
        }
      }
      .invest-code {
        font-weight: 300;
        color: ${(props) => props.theme.colors.fontColor.descriptionFontColor};
        @media (max-width: 500px) {
          text-align: center;
        }
      }
    }
    .invested-amount {
      font-weight: 700;
      color: ${(props) => props.theme.colors.fontColor.descriptionFontColor};
    }
  }

  .fund-evolution-list {
    width: 100%;
    background-color: ${(props) =>
      props.theme.colors.background.defaultFieldBackground};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.62rem 1rem;
    border-radius: 0.93rem;
    .fund-evolution-title {
      padding-right: 1rem;
      color: ${(props) => props.theme.colors.fontColor.titleFontColor};
    }
    .fund-evolution-values {
      color: ${(props) => props.theme.colors.fontColor.titleFontColor};
      font-weight: 700;
      word-break: break-word;
    }
  }
  .dataFund-container {
    display: flex;
    width: 100%;
    background-color: ${(props) =>
      props.theme.colors.background.defaultFieldBackground};
    padding: 0.8rem;
    border-radius: 0.62rem;
    @media (max-width: 680px) {
      flex-direction: column;
      align-items: center;
    }
    .fund-name {
      width: 60%;
      font-weight: bold;
      color: ${(props) => props.theme.colors.fontColor.titleFontColor};
      @media (max-width: 680px) {
        text-align: center;
        width: 100%;
      }
    }
    .fund-ISIN {
      width: 25%;
      color: ${(props) => props.theme.colors.fontColor.titleFontColor};
      text-align: center;
      word-break: break-all;
      text-align: start;
      padding-left: 0.4rem;
      font-weight: bold;
      @media (max-width: 680px) {
        width: 100%;
        text-align: center;
        padding-left: 0;
      }
    }
    .fund-amount {
      width: 15%;
      color: ${(props) => props.theme.colors.fontColor.titleFontColor};
      font-weight: bold;
      text-align: end;
      word-break: break-all;
      display: flex;
      justify-content: end;
      padding-left: 0.4rem;
      @media (max-width: 680px) {
        width: 100%;
        text-align: center;
        justify-content: center;
        padding-left: 0;
      }
    }
  }
  .no-data {
    display: none;
  }
  .home-name-container {
    display: flex;
    align-items: center;
    @media (max-width: 600px) {
      justify-content: center;
    }
  }
  .small-text {
    @media (max-width: 1280px) {
      font-size: 0.7rem;
    }
  }
  .importe-container {
    text-align: start;
    width: fit-content;
  }
  .proposal-fund-title {
    font-weight: 200;
  }
`;

export default ListItemStyle;
