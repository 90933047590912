import React, { useEffect, useContext, useState } from "react";
import { ThemeProvider } from "styled-components";
import { AlertContext } from "../../../common/alertContainer";
import { AuthTokenService } from "../../../../services";
import { cargarGraficoEvolucion } from "../proposalServices";
import defaultTheme from "../../../../theme/theme";
import EvolutionStyle from "./evolutionStyle";
import GraphEvolution from "../graphEvolution/graphEvolution";
import ImageConstants from "../../../../constants/imageConstants/image_constants";
import Text from "../../../../theme/font";

const Evolution = React.memo((data) => {
  const theme = defaultTheme;
  const [graphics, setGraphics] = useState(null);
  const [amount, setAmount] = useState(data || null);
  const [isLoading, setIsLoading] = useState(true);
  const { open } = useContext(AlertContext);
  const userId = AuthTokenService.getUserId();
  const getYear = () => {
    return new Date().getFullYear();
  };

  useEffect(() => {
    if (userId) {
      cargarGraficoEvolucion(userId)
        .then((result) => {
          if (result.statusText === "OK" && result.data.status === 200) {
            const graficos = result.data.data;
            const amountData = result.data.rentabilidades;
            setAmount(amountData);
            setGraphics(graficos);
            setIsLoading(false);
          }
        })
        .catch(() => {
          open({
            alertType: "spinner",
            open: false,
          });
          open({
            type: "error",
            title: "Error al enviar datos",
            alertType: "toast",
            open: true,
          });
        });
      return () => {
        setGraphics({});
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const formato = (x, signo = false) => {
    var number = new Intl.NumberFormat("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(x);
    if (signo && x > 0) {
      return "+" + number;
    } else return number;
  };

  const renderHandler = () => {
    if (graphics == null || isLoading) {
      return null;
    } else {
      return (
        <div className="panelEvolucionContainer box-container">
          <div className="row">
            <div className="col-lg-12 col-12">
              <div className="float titlechart asapText float">
                <Text variant="primaryFont">
                  Esta hubiera sido la evolución de tu plan
                </Text>
              </div>
            </div>
          </div>
          <GraphEvolution graficos={graphics} />
          <div className="graphLabelContainer">
            <div className="labelDeposite">
              <div className="labelSquare"></div>
              <Text variant="smallerText">Depósito</Text>
            </div>
            <div className="labelMiCap">
              <div className="miLabelSquare"></div>
              <Text variant="smallerText">Plan de Micappital</Text>
            </div>
          </div>
          <div className="row containerRentabilidad graph-details">
            <div className="growthContainer">
              <div className="depositBox">
                <div className="depositIconContainer">
                  <img src={ImageConstants.cashIcon} alt="X" />
                </div>
                <div className="depositTextContainer">
                  <Text variant="fieldFont">2015</Text>
                  <div className="depositText">
                    <Text variant="mediumFont" className="mainText">
                      {formato(amount.inversionInicial)}
                    </Text>
                    <Text variant="normal">€</Text>
                  </div>
                </div>
                <div className="arrowRight"></div>
                <div className="arrowBottom"></div>
              </div>
              <div className="growthBox">
                <div className="leftTexts">
                  <Text variant="extraSmall">{getYear()} Con Micappital</Text>
                  <div className="incrementText">
                    <Text variant="mediumFont" className="mainText">
                      {formato(amount.rentabilidadMicappitalEuros)}
                    </Text>
                    <Text variant="normal">€</Text>
                  </div>
                  <div className="rentaText">
                    <Text variant="extraSmall" className="percentageText">
                      {amount.rentabilidadMicappitalPorcentaje}%
                    </Text>
                    <Text variant="extraSmall">Rentabilidad </Text>
                  </div>
                </div>
                <div className="verticalLine"></div>
                <div className="rightTexts">
                  <Text variant="extraSmall">Sin Micappital</Text>
                  <Text variant="extraSmall">
                    {formato(amount.rentabilidadDepositoEuros)}€
                  </Text>
                  <Text variant="extraSmall">
                    {amount.rentabilidadDepositoPorcentaje}% Rentabilidad
                  </Text>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-12">
              <div className="float asapText smallerText">
                <Text variant="smallFont">
                  *Rentabilidades pasadas no garantizan rentabilidades futuras
                </Text>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <EvolutionStyle>{renderHandler()}</EvolutionStyle>
    </ThemeProvider>
  );
});

export default Evolution;
