import styled from "styled-components";

const ListStyle = styled.div`
  width: 100%;
  .main-list-conatainer {
    .list-conatainer {
      display: flex;
      gap: 0.5rem;
      padding: 0.5rem 1rem;
      flex-direction: column;
      overflow: hidden;
    }
    .button-container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1.8rem;
      .see-all-button {
        min-width: 12rem;
        max-height: 2.5rem;
        padding: 5px;
        border-radius: 4rem;
        border-width: 0px;
        text-align: center;
        background-color: ${(props) =>
          props.theme.colors.background.inputFieldBackground};
        color: ${(props) => props.theme.colors.fontColor.fieldTitleColor};
        @media(max-width:768px){
         max-width: 12rem;
        }
      }
    }
    .simplebar-content {
    
    }
    .simplebar-scrollbar {
      background: ${(props) =>
        props.theme.colors.background.scrollbarBackground};
      border-radius: 10px;
      width: 8px;
    }
    .simplebar-scrollbar::before {
      background: ${(props) =>
        props.theme.colors.background.scrollbarBackground};
    }
  }
`;
export default ListStyle;
