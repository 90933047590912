import { useState, useContext } from "react";
import { useDropzone } from "react-dropzone";
import { AlertContext } from "../../alertContainer";
import { AuthTokenService } from "../../../../services";
import Button from "../../../../components/button/button";
import DocumentPopUpStyle from "./documentPopUpStyle";
// import { getCardDetails } from "../accountSettingsServices";
import { postDocumentImage } from "../accountSettingsServices";
import ScrollBar from "../../../../components/scrollbar/scrollbar";
import Text from "../../../../theme/font";
import {
  thumbsContainer,
  thumb,
  thumbInner,
  img,
} from "./documentDropzoneStyles";

const DocumentPopUp = (props) => {
  const [uploadedImage, setUploadedImage] = useState([]);
  const [files, setFiles] = useState();
  const { open } = useContext(AlertContext);
  const [buttonStyle, setButtonStyle] = useState(false);
  // const { open } = useContext(AlertContext);
  // const [cardDetails, setCardDetails] = useState("");
  // const [url, setUrl] = useState("");
  // const [parameters, setParameters] = useState("");
  // const [signature, setSignature] = useState("");
  // const [signatureVersion, setSignatureVersion] = useState("");

  // useEffect(() => {
  //   open({
  //     alertType: "spinner",
  //     open: true,
  //   });
  //   const userId = AuthTokenService.getUserId();
  //   getCardDetails(userId)
  //     .then((response) => {
  //       open({
  //         alertType: "spinner",
  //         open: false,
  //       });
  //       setCardDetails(response.data);
  //       setUrl(response.data.url);
  //       setSignature(response.data.Ds_Signature);
  //       setSignatureVersion(response.data.Ds_SignatureVersion);
  //       setParameters(response.data.Ds_MerchantParameters);
  //     })
  //     .catch(() => {
  //       open({
  //         alertType: "spinner",
  //         open: false,
  //       });
  //       open({
  //         type: "error",
  //         title: "Error al enviar datos",
  //         alertType: "toast",
  //         open: true,
  //       });
  //     });

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // image processing of  dropzone
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setUploadedImage(acceptedFiles);
      setButtonStyle(true);
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const thumbs =
    files &&
    files.map((file) => (
      <div style={thumb} key={file.name}>
        <div style={thumbInner}>
          <img
            src={file.preview}
            alt="x"
            style={img}
            className="uploaded-image"
          />
        </div>
      </div>
    ));

  //for submitting the image
  const imageUploadHandler = () => {
    open({
      alertType: "spinner",
      open: true,
    });
    let formData = new FormData();
    formData.append("idCliente", AuthTokenService.getUserId());
    // eslint-disable-next-line array-callback-return
    uploadedImage.map((file) => {
      formData.append("files[]", file);
    });

    postDocumentImage(formData)
      .then((response) => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "success",
          title: response.data.msg,
          alertType: "toast",
          open: true,
        });
        props.submited();
      })
      .catch((error) => {
        open({
          alertType: "spinner",
          open: false,
        });
        let message;
        if (
          error &&
          error.response &&
          error.response.status &&
          error.response.status === 401
        ) {
          message = error.response.data.msg;
        } else {
          message = "Algo ha salido mal, por favor inténtalo de nuevo";
        }
        open({
          type: "error",
          title: message,
          alertType: "toast",
          open: true,
        });
      });
  };

  return (
    <DocumentPopUpStyle>
      <ScrollBar elementName={"card_header_container"} max_height={"100%"}>
        <div className="document-container">
          <div className="document-header">
            <div className="document-heading">
              <Text variant="titleFont">{props.data.title}</Text>
            </div>
            <div className="upload-button-container">
              <Button
                className={
                  buttonStyle ? "upload-button" : "upload-button disable-btn"
                }
                value="Subir documentos"
                click={imageUploadHandler}
              />
            </div>
          </div>
          <div className="document-content">
            <div className="card-content">{props.data.description}</div>
            <div className="document-description">{props.data.content}</div>
            <div className="buttons">
              <div className="dropzone-container">
                <section className="container">
                  <aside style={thumbsContainer}>{thumbs}</aside>
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <p>Subir archivo...</p>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </ScrollBar>
    </DocumentPopUpStyle>
  );
};

export default DocumentPopUp;
