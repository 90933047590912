import { Request } from "../../../services";
import URL_CONSTANTS from "../../../constants/urlConstants/urlConstants";

// GET GRAFICO *//
export const cargarGrafico = (data) => {
  return Request.get(`${URL_CONSTANTS.get_user_graph}${data}`);
};
//* GET POSICION *//
export const cargarPosicion = (data) => {
  return Request.get(`${URL_CONSTANTS.get_user_position}${data}`);
};
// GET OPTIMIZACION *//
export const cargarOptimizacion = (data) => {
  return Request.get(`${URL_CONSTANTS.get_user_optimization}${data}`);
};
// GET CAMBIOS *//
export const cargarCambios = (data) => {
  return Request.get(`${URL_CONSTANTS.get_user_changes}${data}`);
};
//* GET FONDOS *//
export const cargarFondos = (data) => {
  return Request.get(`${URL_CONSTANTS.get_funds}${data}`);
};
// GET GRAFICO OBJETIVOS *//
export const cargarGraficoObjetivos = (data) => {
  return Request.get(`${URL_CONSTANTS.get_graph_objectives}${data}`);
};
// GET OBJETIVOS *//
export const cargarObjetivos = (data) => {
  return Request.get(`${URL_CONSTANTS.get_objectives}${data}`);
};

//GET CONNECT MICAPPITAL
export const micappitalConnection = (data) => {
  return Request.get(`${URL_CONSTANTS.get_micappital_connection}${data}`);
};

//GET GRAPH DATE
export const getGraphDateRange = (data, start, end) => {
  return Request.get(`${URL_CONSTANTS.get_date_range}${data}/${start}/${end}`);
};

//GET TIPO VALUE
export const getTipoValue = (data) => {
  return Request.get(`${URL_CONSTANTS.check_tipo}${data}`);
};

//SUBMIT OPTIMIZATION DATA
export const postOptimizationData = (data) => {
  return Request.post(URL_CONSTANTS.optimizationData, data);
};

//SUBMIT MICAPPITAL CONNECT ERROR
export const postConnectError = (data) => {
  return Request.post(URL_CONSTANTS.connect_error, data);
};
