import styled from "styled-components";

const PanelFundsStyle = styled.div`
  .panelFondosContainer {
    padding: 1.25rem;
    border-radius: 1rem;

    background-color: ${(props) =>
      props.theme.colors.background.defaultBackground};
  }

  .fondo {
    overflow: auto;
    background-color: ${(props) =>
      props.theme.colors.background.defaultFieldBackground};
    min-height: 3.12rem;
    border-radius: 0.62rem;
    padding: 0.75rem;
  }
  .reponsiveFondo {
    display: flex;
    @media (max-width: 600px) {
      flex-direction: column;
      width: 100%;
      justify-content: center;
      text-align: center;
    }
  }
  .fondoHide {
    display: none;
  }
  .buttonContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.8rem;
    .masFondosBoton {
      min-width: 10rem;
      min-height: 2.5rem;
      padding: 0.312rem;
      border-radius: 4rem;
      border-width: 0rem;
      text-align: center;
      background-color: ${(props) =>
        props.theme.colors.background.inputFieldBackground};
      color: ${(props) => props.theme.colors.fontColor.fieldTitleColor};
    }
  }

  .ticImgRound {
    display: none;
    height: 0.9rem;
    margin-right: 0.312rem;
  }
  .tituloOptimizacion {
    font-weight: bold;
    padding: 0rem 1rem;
    color: ${(props) => props.theme.colors.fontColor.titleFontColor};
    margin-bottom: 1.3rem;
    @media (max-width: 600px) {
      text-align: center;
    }
  }
`;
export default PanelFundsStyle;
