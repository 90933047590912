import React, { useState, useEffect, useContext} from 'react';
import AltaStyle from './altaStyle';
import { useNavigate } from "react-router-dom";
import { patchIroniaData } from '../investment/autopilot/autopilotServices';
import { Form, Row, Col, Button } from "react-bootstrap";
import { ContainerContext } from '../../components/navbarContainer/containerProvider';
import { AuthTokenService } from '../../services';


export default function AutopilotContract(){
    const [error, setError]=useState(false);
    const [intento, setIntento]=useState(false);
    const [menuHidden] = useState(true);
    const { changeMenuStatus, changeNavBarStatus, changeAlertStatus } =
    useContext(ContainerContext);
    const navigate=useNavigate();
    const [inputValues, setInputValues] = useState({
        nombre: '',
        apellidos: '',
        dni: '',
        telefono: '',
        fechaNacimiento: '',
        correo: '',
        password: ''
        // Agrega los nombres de los campos adicionales
    });
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [telefono, setTelefono] = useState('');
    const [prefijo, setPrefijo] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const nombre = AuthTokenService.getNombre();

    // Función para manejar el cambio en el campo de checkbox
    const handleCheckboxChange = (event) => {
        setIsCheckboxChecked(event.target.checked);
        updateButtonState(event.target.checked, telefono);
    };

    // Función para manejar el cambio en el campo de teléfono
    const handlePrefijoChange = (event) => {
        setPrefijo(event.target.value);
    };

    // Función para manejar el cambio en el campo de teléfono
    const handleTelefonoChange = (event) => {
        const newTelefono = event.target.value;
        setTelefono(newTelefono);
        updateButtonState(isCheckboxChecked, newTelefono);
    };

    // Función para actualizar el estado del botón
    const updateButtonState = (checkboxChecked, newTelefono) => {
        if (checkboxChecked && newTelefono.length >= 9) {
        setIsButtonDisabled(false);
        } else {
        setIsButtonDisabled(true);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        changeMenuStatus(menuHidden);
        changeNavBarStatus(true);
        changeAlertStatus(false);
        return () => {
          changeMenuStatus(!menuHidden);
          changeNavBarStatus(false);
          changeAlertStatus(true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [changeMenuStatus, changeNavBarStatus]);

    const handleInputChange = (event) => {
        setInputValues({
            ...inputValues,
            [event.target.name]: event.target.value,
        });
    };

    const signContract = () => {
        
        var datos={
            idCliente:AuthTokenService.getUserId(),
            signCountryPrefix: prefijo,
            signPhone: telefono,
            contratoFirmado: 1
        }
        patchIroniaData(datos)
            .then((result)=>{
                if (result.data.status==200){
                    navigate("/autopilot");
                }
                else{
                    setError(true);
                }
            });
    };


    return (
        <AltaStyle >
            <div className="bodyProcesoAlta">
                <div className="navbar navbar-expand-md navbar-light" style={{paddingLeft:'2.5rem'}}>
                    
                </div>
                <div className="process-popup container">
                    <div className="main-step-container">

                        <a className="navbar-brand">
                            <img height="40" src="https://micappital.com/assets/img/logo-micappital.png" alt="Micappital"></img>
                        </a>
                        
                    </div>
                    <div className="process-popup-inner-container">
                        <h3 className="process-heading mt-5 mb-4">
                            {nombre}, necesitamos que revises y firmes el contrado de asesoramiento
                        </h3>

                        <Form className="form-insurance-data">
                            <Row>
                                <div className="contractText">
                                    <p>
                                        Mediante la aceptación expresa del Aviso legal a través de la casilla creada,
                                        Carlos Bernabeu; con DNI 51087688L, de ahora en adelante "el cliente", confirma
                                        su solicitud de adhesión desde el día en el que confirma el alta como cliente en
                                        la plataforma, en los términos descritos en este Aviso Legal.

                                    </p>
                                    <p>
                                        Con la aceptación expresa del presente documento el cliente asume
                                        las obligaciones que se establecen para el en relación con el servicio de
                                        Micappital sin que ello suponga ninguna limitación para proporcionar información
                                        necesaria para Micappital de cara a prestar el mejor servicio posible.
                                    </p>
                                    <p>
                                        Con la aceptación expresa del presente documento el cliente asume
                                        las obligaciones que se establecen para el en relación con el servicio de
                                        Micappital sin que ello suponga ninguna limitación para proporcionar información
                                        necesaria para Micappital de cara a prestar el mejor servicio posible.
                                    </p>
                                </div>
                                
                            </Row>
                            <Row>
                            <Form.Group controlId="formBasicCheckbox">
                                <Form.Check
                                    type="checkbox"
                                    label="Acepto las condiciones"
                                    checked={isCheckboxChecked}
                                    onChange={handleCheckboxChange}
                                />
                            </Form.Group>
                            </Row>
                            {error && <p>HA HABIDO UN ERROR</p>}
                            
                            <Row>
                                <Form.Group className="mb-3 mt-2" style={{display:'flex'}} controlId="tipo">
                                    <Col sm={2}>
                                        <Form.Control className={(intento==true && inputValues.nombre == '') && 'campo-incompleto'} value ="+34" placeholder="Prefijo" name="prefijo" type="text" onChange={handlePrefijoChange}/>
                                    </Col>
                                    <Col sm={5}>
                                        <Form.Control className={(intento==true && inputValues.nombre == '') && 'campo-incompleto'} placeholder="Teléfono" name="telefono" type="text" onChange={handleTelefonoChange}/>
                                    </Col>
                                    <Button style={{marginLeft:"2rem"}} disabled={isButtonDisabled} className="botonComienza btn" type="button" onClick={signContract}>
                                        Firmar vía sms
                                    </Button>
                                    
                                </Form.Group>
                            </Row>
                            
                        </Form>
                        
                        <div className="start-button-container">
                            <button className="btn" onClick={() =>navigate("/autopilot")}>
                                Atrás
                            </button>
                           
                        </div>
                    </div>
                </div>
            </div>
        </AltaStyle>
    );
}