import { Form,Row, Col } from "react-bootstrap";
import React, { useState, useEffect } from 'react';

export function Paso4({error, handleButtonGuardarClick}){
    const [intento, setIntento]= useState(false);
    const [camposCompletos, setCamposCompletos] = useState(false);
    const [inputValues, setInputValues] = useState({
        nombre: '',
        apellidos: '',
        dni: '',
        telefono: '',
        fechaNacimiento: '',
        correo: '',
        password: ''
        // Agrega los nombres de los campos adicionales
    });

    const handleInputChange = (event) => {
        setInputValues({
            ...inputValues,
            [event.target.name]: event.target.value,
        });
    };
    
    useEffect(() => {
        const camposRequeridos = ['nombre', 'apellido', 'dni','telefono','fechaNacimiento','correo','password'];
        // Agrega aquí los nombres de los campos requeridos
        const camposCompletados = camposRequeridos.every((campo) => inputValues[campo] !== '');
        setCamposCompletos(camposCompletados);
      }, [inputValues]);

    const handleGuardar = () => {
        // Llama a la función onGuardar y pasa el objeto inputValues
        setIntento(true);
        if(camposCompletos){
            handleButtonGuardarClick(inputValues);
        }
      };
    

    return(
    
        <div className="process-popup-inner-container">
            <h3 className="process-heading mt-5 mb-4">
                Muy buena elección, necesitamos un par de datos para create tu usuario
            </h3>

            <Form className="form-insurance-data">
                <Row>
                    <Form.Group as={Col} xs={12} sm={6} className="mb-3" controlId="nombre">
                        <Form.Control className={(intento==true && inputValues.nombre == '') && 'campo-incompleto'} placeholder="Nombre" name="nombre" type="text" onChange={handleInputChange}/>
                    </Form.Group>
                    <Form.Group as={Col} xs={12} sm={6} className="mb-3" controlId="apellidos">
                        <Form.Control className={(intento==true && inputValues.apellidos == '') && 'campo-incompleto'} placeholder="Apellidos" name="apellidos" type="text" onChange={handleInputChange}/>
                    </Form.Group>
                </Row>
                    <Form.Group as={Col} xs={12} sm={12} className="mb-3" controlId="dni">
                        <Form.Control className={(intento==true && inputValues.dni == '') && 'campo-incompleto'} placeholder="DNI/NIE" name="dni" type="text"  onChange={handleInputChange}/>
                    </Form.Group>
            
                    <Form.Group as={Col} xs={12} sm={12} className="mb-3" controlId="telefono">
                        <Form.Control className={(intento==true && inputValues.telefono == '') && 'campo-incompleto'} placeholder="Teléfono" name="telefono" type="text" onChange={handleInputChange}/>
                    </Form.Group>
                    <Form.Group as={Col} xs={12} sm={12} className="mb-3" controlId="fechaNacimiento">
                        <Form.Label>Fecha de nacimiento</Form.Label>
                        <Form.Control className={(intento==true && inputValues.fecha_nacimiento == '') && 'campo-incompleto'} name="fechaNacimiento" type="date" onChange={handleInputChange}/>
                    </Form.Group>
                    <Form.Group as={Col} xs={12} sm={12} className="mb-3" controlId="correo">
                        <Form.Control className={(intento==true && inputValues.correo == '') && 'campo-incompleto'} placeholder="Email" name="correo" type="email"  onChange={handleInputChange}/>
                    </Form.Group>
                    <Form.Group as={Col} xs={12} sm={12} className="mb-3" controlId="password">
                        <Form.Control className={(intento==true && inputValues.contrasena == '') && 'campo-incompleto'} placeholder="Contraseña" name="password" type="password" onChange={handleInputChange}/>
                    </Form.Group>
                {error && <p>HA HABIDO UN ERROR</p>}
                
                
                
            </Form>

            <div className="start-button-container">
                <button className="botonComienza btn" onClick={handleGuardar}>
                    Continuar
                </button>
            </div>
        </div>
    
    );
}

export function Paso8({handleButtonClick}){
    return(
    <div className="main-popup-container">
        <div className="process-popup-inner-container">
            <h3 className="process-heading">
                ¡Genial! Todo correcto
            </h3>
            <div className="process-description">
                Nuestro equipo acaba de recibir tu solicitud y ya estamos buscándote el mejor seguro para ti
            </div>
            <div className="process-description">
                Te vamos a contactar en las próximas horas para hacerte nuestra recomendación
            </div>
            
            <div className="start-button-container">
                <button className="botonComienza btn" onClick={handleButtonClick}>
                    Continuar
                </button>
            </div>
        </div>
    </div>
    );
}
