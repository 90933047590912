import React, { Fragment} from "react";
import PanelChanges from "./panelChanges/panelChanges";
import ChangeInfo from "./changeInfo/changeInfo";
import VideoChanges from "./videoChanges/videoChanges";
import Navbar from "../../../../components/navbar/navbar";

const Changes = () => {

  return (
    <Fragment>
      <div className="col-lg-12 ">
        <Navbar title="Propuesta de cambio" />
      </div>
      <div className="row margin30">
        <div className="col-lg-8">
          <PanelChanges/>
        </div>
        <div className="col-lg-4">
          <div className="col-lg-12">
            <ChangeInfo />
          </div>
        </div>
      </div>
      <div className="row margin30">
        <div className="col-lg-8">
          <VideoChanges />
        </div>
      </div>
    </Fragment>
  );
};

export default Changes;
