import React, { useState, useEffect } from 'react';
import AltaStyle from './altaStyle';
import { Paso4,Paso8} from './pasos/pasos';
import { getLoginData, patchNR} from './procesoAltaServices';
import { AuthTokenService } from '../../services';
import { useParams } from 'react-router-dom';

export default function ProcesoAlta(){
    const {idNR}=useParams();
    const [error, setError]=useState(false);
    const [paso, setPaso] = useState(4);


    const handleButtonGuardarClick = (datos) => {//funcion que llama al patch con los datos de formularios
        datos.idNoRegistrado=idNR;
        patchNR(datos)
            .then((result)=>{
                console.log(result);
                if (result.data.status==200){
                        setPaso(paso+1);
                }
                else{
                    setError(true);
                }
            });
        
      };

     useEffect(()=>{
        getLoginData()
            .then((result)=>{
                const token  = result.data.accessToken;
                AuthTokenService.setToken(token);
                
            })
     },[]);//para que solo se cargue al inicio

    return (
        <AltaStyle >
            <div className="bodyProcesoAlta">
                <div className="navbar navbar-expand-md navbar-light" style={{paddingLeft:'2.5rem'}}>
                    
                </div>
                <div className="process-popup container">
                    <div className="main-step-container">

                        <a className="navbar-brand">
                            <img height="40" src="https://micappital.com/assets/img/logo-micappital.png" alt="Micappital"></img>
                        </a>
                        
                    </div>
                    {paso==4 && <Paso4 error={error} handleButtonGuardarClick={handleButtonGuardarClick} />}
                    {paso==5 && <Paso8/>}
                    
                
                    
                </div>
            </div>
        </AltaStyle>
    );
}