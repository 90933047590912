import styled from "styled-components";
import defaultTheme from "../../theme/theme";
const theme = defaultTheme;

const LocationFieldStyle = styled.div`
  .location-field-container {
    position: relative;
    border-radius: 0.625rem;
    overflow: hidden;
  }
  .input-field {
    width: 100%;
    outline: none;
    min-height: 3rem;
    background-color: ${theme.colors.background.defaultBackground};
    color: ${theme.colors.background.outlineBorderColor};
    padding-left: 1rem;
    font-weight: 400;
    border: none;
    ::-ms-reveal,
    ::-ms-clear {
      display: none;
    }
    ::placeholder {
      color: ${theme.colors.background.outlineBorderColor};
      font-weight: 400;
    }
  }
  .icon-container {
    padding: 0.5rem;
    position: absolute;
    top: 0rem;
    right: 0rem;
    min-height: 3rem;
    background-color: #f0eded;
    display: flex;
    align-items: center;
    justify-content: center;
    .location-icon {
    }
  }
  .error-val {
    position: absolute;
    padding-left: 1.5rem;
    color: ${(props) => props.theme.colors.fontColor.errorFontColor};
  }
`;
export default LocationFieldStyle;
