const URL_CONSTANTS = {
  get_login_tokens: "/Login/loginUser",
  client_settings: "/Clientes/ajustes",
  make_contribution: "/Clientes/realizarAportacion",
  get_fund_details: "/Clientes/aportaciones",
  get_contribution_data: "/Clientes/aportacionPorObjetivos",
  get_user_diversification: "/Clientes/diversificacionCartera/",
  get_user_graphic_evolution: "/Clientes/graficosEvolucion/",
  get_initial_recommendation: "/Clientes/recomendacionInicial/",
  get_video: "/Clientes/videosBanco/",
  open_whatsapp: "https://api.whatsapp.com/send?phone=34693039289",
  open_calendly: "https://calendly.com/micappital",
  geo_url:
    "https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json",
  get_user_graph: "/Clientes/graficosDuo/",
  get_user_position: "/Clientes/posicion/",
  get_user_optimization: "/Clientes/optimizacion/",
  get_user_changes: "/Clientes/cambiosPropuestosNuevaZP/",
  get_funds: "/Clientes/fondos/",
  get_graph_objectives: "/Clientes/graficoObjetivos/",
  get_objectives: "/Clientes/objetivos/",
  add_new_goal: "/Clientes/crearObjetivo",
  delete_goal: "/Clientes/objetivos",
  edit_goal: "/Clientes/objetivos",
  get_objective_from_id: "/Clientes/objetivo",
  get_invited_friends_details: "/Clientes/Promos/",
  get_control_panel_data: "/Eco/posicionEco",
  get_impact_sector_data: "/Eco/odsEco/",
  get_invested_details: "/Eco/fondosEco/",
  get_settings: "/Clientes/ajustes/",
  get_micappital_connection: "/Clientes/micappitalConnect/",
  get_bank_details: "/Onboarding/bancos",
  get_date_range: "/Clientes/graficosDate/",
  post_add_fund: "/Clientes/actualizarImporte",
  get_bank: "/Clientes/bancos",
  get_total_bank_details: "/Clientes/infoBancoCompleta/",
  post_pic: "/Clientes/imagen/",
  get_optimization_data: "/Clientes/cambiosPropuestosNuevaZP/",
  get_objective_icons: "/Clientes/iconosObjetivos",
  forgot_password: "/Login/forgot",
  reset_password: "/Login/resetPassword",
  check_link: "/Login/checkForgotLink",
  post_investment: "/Clientes/confirmarAportacion",
  get_card_details: "/Clientes/loadRedsysParams",
  post_card_response: "/Clientes/receiveRedsysParams",
  get_map_data: "/Clientes/propuestaMap/",
  get_eco_map_data: "/Eco/ecoMap/",
  check_tipo: "/Clientes/mensajesCliente/",
  test_api: "/Clientes/mifid/",
  post_test_data: "/Clientes/mifid",
  check_eco: "/Eco/checkSiAportacionEcoNueva",
  invest_eco: "/Eco/realizarAportacionEcoNueva/0",
  postDocumentImg: "/Clientes/subirDni/",
  getCommissionData: "/Clientes/comisiones",
  newsList: "/Clientes/noticias/",
  specificNews: "/Clientes/noticia/",
  alertMessages: "/Clientes/mensajesAlerta/",
  optimizationData: "/Clientes/confirmarCambios",
  postRegisterCodeFinect: "/Clientes/altaFinect",
  postRegisterCodeFlanks: "/Flanks/generarClavesFlanks",
  check_minimum_amount: "/Clientes/minimoBanco",
  get_insurance: "/Seguros/listaSeguros/",
  insurance_optimization: "/Seguros/optimizacionSeguros/",
  check_insurance: "/Seguros/checkClienteSeguro/",
  get_insurance_details: "/Seguros/detalleSeguro/",
  start_insurance: "/Seguros/nuevoClienteSeguros/",
  create_insurance: "/Seguros/nuevoSeguroCliente",
  update_insurance: "/Seguros/updateSeguro",
  insurance_options: "/Seguros/listaTipoSeguros/",
  post_policy: "/Seguros/subirPolizasCliente",
  get_login_data: "/Supercliente/loginSuperCliente",
  superClient_login: "/Supercliente/emulateLoginSupercuenta",
  connect_error: "/Clientes/registerFlanksError",
  get_profile_image: "Supercliente/imagen/idSuperCliente",
  upload_image: "/Supercliente/imagen/",
  patch_settings: "/Supercliente/ajustes/",
  forgot_password_superCliente: "Login/forgotSupercliente",
  reset_password_superCliente: "Login/resetPasswordSupercliente",
  get_settings_superCliente: "/Supercliente/ajustes",
  get_cancel_letter: "/Seguros/datosCartaBajaSeguro/",
  get_insurance_letter: "/Seguros/datosCartaNombramiento/",
  get_investment_details: "/Clientes/propuestaCNMV/",
  get_insurance_policy: "/Seguros/polizaSeguro/",
  get_opcionesSeguros: "/Seguros/opcionesSeguros",
  post_newOptions_Insurance_Customer: "/Seguros/nuevasOpcionesSeguroCliente",
  get_Seguros_infoCliente: "/Seguros/infoCliente",
  get_Seguros_infoClienteInversion: "/Seguros/infoClienteInversion",
  post_Seguros_infoCliente: "/Seguros/infoCliente",
  patch_Seguros_infoCliente:"/Seguros/infoCliente",
  login_login: "/Login/login",
  get_empezar_seguros: "/Onboarding/empezarSeguros",
  patch_noRegistradoSeguros: "/Onboarding/noRegistradoSeguros",
  post_crearClienteSeguros: "/Onboarding/crearClienteSeguros",
  post_crearSeguro: "/Seguros/nuevoSeguroCliente",
  patch_updateSeguro: "Seguros/updateSeguro",
  post_infoCliente: "Seguros/infoCliente",
  post_nuevasOpcionesSeguroCliente: "Seguros/nuevasOpcionesSeguroCliente",
  patch_noRegistrado: "/Onboarding/noRegistrado",
  get_datosIronia: "/Autopilot/datosIronia",
  post_subirArchivoIronia: "/Autopilot/subirArchivo",
  get_cities:"/Autopilot/cities",
  get_zipCodes:"/Autopilot/zipCodes",
  post_enviarDatosClienteIronia:"/Autopilot/enviarDatosClienteIronia",
  post_crearContratoIronia: "/Autopilot/crearContratoIronia",
  post_firmarContratoIronia: "/Autopilot/firmarContratoIronia",
  post_comprarRecomendacionInicial: "/Autopilot/comprarRecomendacionInicial",
  post_actualizarImporteInicial: "/Clientes/actualizarImporte",
  post_primerosCambios: "/Autopilot/PrimerosCambios",
  get_cambiosInternos: "/Autopilot/cambiosInternos",
  post_mensajeAutopilot: "/Autopilot/mensajeSlackAutopilot",
  get_loadRedsysParamsAutopilot: "/Clientes/loadRedsysParamsAutopilot",
  get_loadPriceAutopilot: "/Autopilot/loadPriceAutopilot",
  post_autopilotContribution: "/Autopilot/comprarAportacion",
  post_terminarAltaAutopilot: "/Autopilot/terminarAltaAutopilot"
};

export default URL_CONSTANTS;
