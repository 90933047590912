import URL_CONSTANTS from "../../constants/urlConstants/urlConstants";
import { Request } from "../../services";

export const getInsurance = (data) => {
  return Request.get(`${URL_CONSTANTS.get_insurance}${data}`);
};

export const CheckInsurance = (data) => {
  return Request.get(`${URL_CONSTANTS.check_insurance}${data}`);
};
export const getInsuranceDetails = (id, userid) => {
  return Request.get(`${URL_CONSTANTS.get_insurance_details}${userid}/${id}`);
};
export const startNewInsurance = (data) => {
  return Request.get(`${URL_CONSTANTS.start_insurance}${data}`);
};

export const CreateInsurance = (data) => {
  return Request.post(URL_CONSTANTS.create_insurance, data);
};

export const updateInsurance = (data) => {
  return Request.patch(URL_CONSTANTS.update_insurance, data);
};

export const getInsuranceOptions = (data) => {
  return Request.get(`${URL_CONSTANTS.insurance_options}${data}`);
};

export const postPolicyData = (data) => {
  return Request.post(URL_CONSTANTS.post_policy, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const getDropDownOptions = (data) => {
  return Request.get(`${URL_CONSTANTS.get_opcionesSeguros}/${data}`);
};

export const postNewInsuranceCustomer = (data) => {
  return Request.post(URL_CONSTANTS.post_newOptions_Insurance_Customer, data);
};

export const getInitialInformation = (data) => {
  return Request.get(`${URL_CONSTANTS.get_Seguros_infoCliente}/${data}`);
};

export const getInitialData = (data) => {
  return Request.get(
    `${URL_CONSTANTS.get_Seguros_infoClienteInversion}/${data}`
  );
};

export const postInitialData = (data) => {
  return Request.post(URL_CONSTANTS.post_Seguros_infoCliente, data);
};
export const infoClient = (data) => {
  return Request.patch(URL_CONSTANTS.patch_Seguros_infoCliente, data);
};
