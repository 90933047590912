import styled from "styled-components";

const ContributionBoxStyle = styled.div`
  width: calc(100% - 65%);
  height: fit-content;
  min-width: 18rem;
  background-color: ${(props) =>
    props.theme.colors.background.defaultBackground};
  border-radius: 1rem;
  padding: 1.5rem;
  @media (max-width: 1040px) {
    width: 100%;
    max-width: 38.75rem;
  }
  .contribution-box-container {
    flex-direction: column;
    align-items: center;
    .contribution-header {
      width: 100%;
      @media (max-width: 920px) {
        text-align: center;
      }
      .contribution-heading {
        font-weight: bold;
        color: ${(props) => props.theme.colors.fontColor.titleFontColor};
        @media (max-width: 920px) {
          width: 100%;
        }
      }
      .contribution-description {
        font-weight: 500;
        padding: 1.5rem 0rem;
        @media (max-width: 920px) {
          width: 100%;
        }
      }
    }
    .field-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.3rem;
      .value-field {
        font-weight: bold;
        color: ${(props) => props.theme.colors.fontColor.titleFontColor};
        background-color: ${(props) =>
          props.theme.colors.background.defaultFieldBackground};
        width: 100%;
        min-width: 14.6rem;
        border-radius: 1.25rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0rem 2rem;
        max-width: 22.3rem;
        .field {
          .value-name {
          }
          .date {
            font-weight: 800;
            padding-left: 0.7rem;
          }
        }
        .value {
          font-weight: 400;
        }
      }
    }
    .desc {
      text-align: center;
      text-decoration: underline;
      padding: 1rem 0rem 1rem 0rem;
      color: ${(props) => props.theme.colors.fontColor.titleFontColor};
      cursor: pointer;
    }
    .contribution-total {
      display: flex;
      background-color: ${(props) =>
        props.theme.colors.fontColor.titleFontColor};
      justify-content: space-between;
      align-items: center;
      border-radius: 1.25rem;
      height: 3.1rem;
      padding: 0rem 2rem;
      width: 100%;
      max-width: 22.3rem;
      .total-title {
        color: ${(props) => props.theme.colors.fontColor.defaultFontColor};
        font-weight: bold;
      }
      .total-amount {
        color: ${(props) => props.theme.colors.fontColor.defaultFontColor};
        font-weight: 400;
      }
    }
  }
`;
export default ContributionBoxStyle;
