import AllInOneSectionStyle from "./allInOneStyle";
import ImageConstants from "../../../../constants/imageConstants/image_constants";
import Text from "../../../../theme/font";

const AllInOneSection = () => {
  return (
    <AllInOneSectionStyle>
      <div className="section-container">
        <Text variant="titleFont" className="all-in-one-heading">
          Todo en el mismo lugar
        </Text>
        <div className="all-in-one-image-container">
          <img
            alt="x"
            src={ImageConstants.allInOne}
            className="all-in-one-image"
          />
        </div>
        <Text variant="smallFont" className="bottom-text">
          Unificamos toda la informacion de tus seguros.
          -Vencimientos
          -Recibos
          -Contacto de asistencia.
        </Text>
      </div>
    </AllInOneSectionStyle>
  );
};
export default AllInOneSection;
