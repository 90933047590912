import React, { useContext } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { AlertContext } from "../../../common/alertContainer";
import { AuthTokenService } from "../../../../services";
import Button from "../../../../components/button/button";
import EcoChangePopUpStyle from "./ecoChangePopUpStyle";
import { postEcoInvestment } from "../../defaultScreen/defaultScreenServices";
import TextField from "../../../../components/textField/textField";
import Text from "../../../../theme/font";

const EcoChangePopUp = React.memo((props) => {
  const { open } = useContext(AlertContext);
  const minimumBalance = props?.minimumAmount;

  const variantHandler = (conditionalValue, resultValue) => {
    let variant = "";
    if ((conditionalValue, resultValue)) {
      variant = "none";
    } else {
      variant = "outlined";
    }
    return variant;
  };

  const validate = Yup.object({
    amount: Yup.number()
      .required("porfavor introduzca un valor")
      .min(minimumBalance, `Se requiere un valor mínimo de ${minimumBalance}`),
  });

  const userId = AuthTokenService.getUserId();

  //for submitting the changed amount
  const submitHandler = (values) => {
    open({
      alertType: "spinner",
      open: true,
    });

    const details = {
      idCliente: userId,
      importe: values.amount,
    };
    postEcoInvestment(details)
      .then((response) => {
        const totalIvestment = response?.data;
        props.getInvestData(totalIvestment);
        props.changedData(totalIvestment?.fondos);
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "success",
          title: "Actualizado correctamente",
          alertType: "toast",
          open: true,
        });
        props.closePopUp();
      })
      .catch(() => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: "Fallo al actualizar",
          alertType: "toast",
          open: true,
        });
      });
  };

  const checkIfNumber = (event) => {
    const regex = new RegExp(
      /(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/
    );

    return !event.key.match(regex) && event.preventDefault();
  };

  const changeHandler = (values, setFieldValue) => {
    if (values.length < 9) {
      setFieldValue("amount", values);
    } else {
      const split = values.slice(0, 9);
      setFieldValue("amount", split);
    }
  };

  return (
    <EcoChangePopUpStyle>
      <div className="popup-container">
        <div
          className="overlay"
          onClick={() => {
            props.click(false);
          }}
        ></div>
        <div className="popup-inner">
          <Formik
            enableReinitialize
            initialValues={{
              amount: "",
            }}
            onSubmit={(values) => {
              submitHandler(values);
            }}
            validationSchema={validate}
          >
            {({ values, errors, touched, submitForm, setFieldValue }) => (
              <Form>
                <div className="accountDetails-header">
                  <div className="heading">
                    <Text variant="titleFont">Importe a invertir</Text>
                  </div>
                </div>

                <div className="form-container">
                  <div className="form-field email-field">
                    <TextField
                      variant={variantHandler(errors.amount, touched.amount)}
                      name="amount"
                      value={values.amount}
                      type="number"
                      handleChange={(e) =>
                        changeHandler(e.target.value, setFieldValue)
                      }
                      placeholder="Ej. 10000"
                      error={touched.amount && Boolean(errors.amount)}
                      helperText={touched.amount && errors.amount}
                      className="field-div"
                      onKeyDown={(evt) => checkIfNumber(evt)}
                    />
                  </div>
                  <div className="header-button">
                    <Button
                      className="button-container"
                      value="Actualizar"
                      click={submitForm}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </EcoChangePopUpStyle>
  );
});

export default EcoChangePopUp;
