import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { AlertContext } from "../../../container/common/alertContainer";
import { AuthTokenService } from "../../../services";
import ChangeProposal from "./changeProposal/changeProposal";
import { ContainerContext } from "../../../components/navbarContainer/containerProvider";
import defaultTheme from "../../../theme/theme";
import FundPopUp from "../home/fundPopUp/fundPopUp";
import { getOptimizationData } from "./myOptimizationServices";
import ImageConstants from "../../../constants/imageConstants/image_constants";
import OptimizationStyle from "./myOptimizationStyle";
import ProposedChange from "./proposedChange/proposedChange";
import VideoTutorial from "./videoTutorial/videoTutorial";
import { micappitalConnection } from "../home/homeServices";
import AlertMessage from "../../../components/alertmessage/alertMessage";

const MyOptimization = () => {
  const navigate = useNavigate();
  const theme = defaultTheme;
  const [menuHidden] = useState(true);
  const [optimizationData, setOptimizationData] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const [connectionData, setConnectionData] = useState("");
  const [popUpData, setPopData] = useState([]);
  const userId = AuthTokenService.getUserId();
  const { open } = useContext(AlertContext);
  const {
    changeMenuStatus,
    changeNavBarStatus,
    changeAlertStatus,
    titleHandler,
    pageNameHandler,
    headerTitleHandler,
  } = useContext(ContainerContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    getOptimizationData(userId)
      .then((response) => {
        open({
          alertType: "spinner",
          open: false,
        });
        if (response.data.cambios.length === 0) {
          navigate("/resumen");
        } else {
          setOptimizationData(response.data);
          setPopData(response.data.cambios);
        }
      })
      .catch(() => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: "Data not found",
          alertType: "toast",
          open: true,
        });
      });
    micappitalConnection(userId)
      .then((result) => {
        if (result.statusText === "OK" && result.status === 200) {
          const totalConnectionData = result.data.data.mode;
          setConnectionData(totalConnectionData);
        }
      })
      .catch(() => {
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });

    changeNavBarStatus(false);
    changeMenuStatus(menuHidden);
    changeAlertStatus(false);
    toogleNavBar();
    return () => {
      changeAlertStatus(true);
      changeMenuStatus(!menuHidden);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toogleNavBar = () => {
    titleHandler("Propuesta de cambio");
    pageNameHandler("optimizacion");
    headerTitleHandler("");
  };

  const scrollToVideo = () => {
    let primary = document.getElementById("primary-container");
    const videoElement = document.getElementById("video-container");
    const windowWidth = window.innerWidth;
    if (windowWidth > 1040) {
      window.scrollTo(0, videoElement.offsetTop);
    } else {
      primary.scrollTo(0, videoElement.offsetTop);
    }
  };

  //For closing the popup
  const popUpCloseHandler = () => {
    setShowPopUp(false);
  };

  //For opening the popup
  const popUpOpenHandler = () => {
    setShowPopUp(true);
  };

  //Making the key values same for passing it to the fund popup
  const renameKey = popUpData.map(
    ({
      importe,
      isinDestino,
      isinOrigen,
      nombreDestino,
      nombreOrigen,
      tipo,
    }) => ({
      valor: importe,
      ISIN: isinDestino,
      isinOrigen: isinOrigen,
      nombre: nombreDestino,
      nombreOrigen: nombreOrigen,
      tipo: tipo,
    })
  );

  //It contains the Fund popup
  const popUpHandler = () => {
    return (
      <div className="popUp-wrpper">
        <div
          className="popUp-overlay"
          onClick={() => popUpCloseHandler()}
        ></div>
        <div className="popUp-container">
          <FundPopUp data={renameKey} closePopUp={popUpCloseHandler} />
        </div>
      </div>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <OptimizationStyle>
        <div className="primary-alert-container">
          <AlertMessage />
        </div>
        <div className="optimization-container">
          {showPopUp && (
            <div className="investment-popup">{popUpHandler()}</div>
          )}
          <div className="back-icon" onClick={() => navigate("/resumen")}>
            <img src={ImageConstants.arrowLeftIcon} alt="x" />
          </div>
          {optimizationData.length !== 0 && (
            <>
              <div className="primary-section" id="primary-container">
                <div className="secondary-alert-container">
                  <AlertMessage />
                </div>
                <div className="primary-inner-container">
                  <div className="change-proposal-section">
                    <ChangeProposal data={optimizationData} />
                  </div>
                  <div className="tutorial-section" id="video-container">
                    <VideoTutorial data={optimizationData} />
                  </div>
                </div>
              </div>
              <div className="secondary-section">
                <ProposedChange
                  data={optimizationData}
                  click={scrollToVideo}
                  popUpClick={popUpOpenHandler}
                  connectData={connectionData}
                />
              </div>
            </>
          )}
        </div>
      </OptimizationStyle>
    </ThemeProvider>
  );
};

export default MyOptimization;
