import ImageConstants from "../constants/imageConstants/image_constants";

export const supportOptionsData = [
  {
    src: ImageConstants.relaxIcon,
    title: "Relájate y disfruta",
    description:
      "Lo dejamos todo automatizado, cuando haya que hacer cambios te avisamos y con un click los dejas hechos.",
  },
  {
    src: ImageConstants.comisionesIcon,
    title: "Menos comisiones",
    description:
      "Con la cuenta de Mapfre, todos los 15 de marzo de cada año Mapfre te retrocedererá un 40% de las comisiones de los fondos que hayas contratado",
  },
  {
    src: ImageConstants.serviceIcon,
    title: "Mismo servicio",
    description:
      "Seguirás disfrutando del mismo servicio que tenías antes con Micappital, solo que esta vez estará automatizado al 100%",
  },
];
