import React from "react";
import ButtonContainer from "./buttonStyle";
import { ButtonStyle } from "./buttonStyle";

const Button = ({
  type,
  variant,
  click,
  value,
  style,
  icon,
  className,
  container,
}) => {
  return (
    <ButtonContainer container={container}>
      {icon && <div className="btn-icon">{icon}</div>}
      <ButtonStyle
        type={type}
        variant={variant}
        onClick={click}
        style={style}
        icon={icon}
        className={className}
      >
        {value}
      </ButtonStyle>
    </ButtonContainer>
  );
};

export default Button;
