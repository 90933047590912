import defaultTheme from "../../../../../../theme/theme.js";
import DefineObjective from "../../subComponents/defineObjective/defineObjective.jsx";
import EditGoalStyle from "./editGoalStyle.js";
import NewGoalPanel from "../../subComponents/newGoalPanel/newGoalPanel.jsx";
import { ThemeProvider } from "styled-components";

const EditGoal = ({ data, changeData }) => {
  const theme = defaultTheme;

  const changeHandler = (values, element) => {
    changeData(values, element);
  };

  const goalDataHandler = (type) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "title": {
        if (data) {
          return data.objetivo;
        }
        break;
      }
      case "amount": {
        if (data) {
          return data.importe;
        }
        break;
      }
      case "month": {
        if (data) {
          return data.month;
        }
        break;
      }
      case "year": {
        if (data) {
          return data.year;
        }
        break;
      }
      case "img": {
        if (data) {
          return data.imagen;
        }
        break;
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <EditGoalStyle>
        <div className="edit-goal-container box-container">
          <NewGoalPanel
            goal={true}
            changeData={changeHandler}
            data={goalDataHandler("title")}
            imgSrc={goalDataHandler("img")}
          />
          <DefineObjective
            amount={goalDataHandler("amount")}
            month={goalDataHandler("month")}
            year={goalDataHandler("year")}
            changeData={changeHandler}
          />
        </div>
      </EditGoalStyle>
    </ThemeProvider>
  );
};

export default EditGoal;
