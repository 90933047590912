import React, { useEffect, useContext, useState } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { Doughnut } from "react-chartjs-2";
import { ThemeProvider } from "styled-components";
import "../../../../assets/css/Comun.css";
import { AlertContext } from "../../../common/alertContainer";
import { AuthTokenService } from "../../../../services";
import { cargarDiversificacion, getMapData } from "../proposalServices";
import defaultTheme from "../../../../theme/theme";
import DiversificationStyle from "./diversificationStyle";
import Text from "../../../../theme/font";

const Diversification = React.memo(({ popUpClick }) => {
  const theme = defaultTheme;
  const [fixedRent, setFixedRent] = useState(null);
  const [variableIncome, setVariableIncom] = useState(null);
  const [liquidity, setLiquidity] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [lessThan3, setLessThan3] = useState("");
  const [between3And10, setBetween3And10] = useState("");
  const [moreThan10, setMoreThan10] = useState("");
  const { open } = useContext(AlertContext);

  useEffect(() => {
    open({
      alertType: "spinner",
      open: true,
    });
    const userId = AuthTokenService.getUserId();
    cargarDiversificacion(userId)
      .then((result) => {
        open({
          alertType: "spinner",
          open: false,
        });
        if (result.statusText === "OK" && result.status === 200) {
          const diversificacion = result.data.data;
          const rentaFija = diversificacion.rentaFija;
          const rentaVariable = diversificacion.rentaVariable;
          const liquidez = diversificacion.liquidez;

          setFixedRent(rentaFija);
          setVariableIncom(rentaVariable);
          setLiquidity(liquidez);
          setIsLoading(false);
        }
      })
      .catch(() => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });
    getMapData(userId)
      .then((result) => {
        open({
          alertType: "spinner",
          open: false,
        });
        const tempData = JSON.parse(result.data.data);
        setLessThan3(tempData[`Menos del 3%`]);
        setBetween3And10(tempData[`Entre el 3% y el 10%`]);
        setMoreThan10(tempData[`Más del 10%`]);
      })
      .catch(() => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderHandler = () => {
    if (fixedRent == null || isLoading) {
      return null;
    } else {
      const geoUrl = require("./worldMap.json");
      const data = {
        datasets: [
          {
            data: [variableIncome, fixedRent, liquidity],
            backgroundColor: ["#30b2b8", "#1c88c8", "#a8d115"],
          },
        ],
      };

      const options = {
        cutout: "75%",
        borderWidth: 0,
        responsive: true,

        plugins: {
          legend: {
            position: "bottom",
            labels: {
              usePointStyle: true,
              pointStyle: "circle",
            },
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                let label = context.formattedValue;
                if (label) {
                  label += "%";
                }
                return label;
              },
            },
          },
        },
      };

      const checkHandler = (lessThan3, between3And10, moreThan10) => {
        if (lessThan3) {
          return "#a8d115";
        } else if (between3And10) {
          return "#1c88c8";
        } else if (moreThan10) {
          return "#30b2b8";
        } else {
          return "#ccc";
        }
      };

      //For opening the popup
      const popUpClickHandler = () => {
        popUpClick();
      };

      return (
        <div className="panelDiversificacionContainer box-container">
          <div className="row rowStyle">
            <div className="col-lg-6 col-12 chart-container">
              <div className="float titlechart asapText">
                <Text variant="titleFont">¿Cómo lo invertirás?</Text>
              </div>
              <Doughnut className="doughnut" data={data} options={options} />
              <div className="map-labels">
                <div className="label-div">
                  <div className="primary-label-color label-color"></div>
                  <Text variant="smallFont">Liquidez</Text>
                </div>
                <div className="label-div">
                  <div className="secondary-label-color label-color"></div>
                  <Text variant="smallFont">Renta Fija</Text>
                </div>
                <div className="label-div">
                  <div className="teritiary-label-color label-color"></div>
                  <Text variant="smallFont">Renta Variable</Text>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12 mapContainer">
              <div className="float titlechart asapText">
                <Text variant="titleFont">¿Dónde lo invertirás?</Text>
              </div>
              <ComposableMap>
                <Geographies geography={geoUrl}>
                  {({ geographies }) =>
                    geographies.map((geo) => {
                      const islessThan3 =
                        lessThan3.indexOf(geo.properties.name) !== -1;
                      const isbetween3And10 =
                        between3And10.indexOf(geo.properties.name) !== -1;
                      const ismoreThan10 =
                        moreThan10.indexOf(geo.properties.name) !== -1;
                      return (
                        <Geography
                          key={geo.rsmKey}
                          geography={geo}
                          fill={checkHandler(
                            islessThan3,
                            isbetween3And10,
                            ismoreThan10
                          )}
                        />
                      );
                    })
                  }
                </Geographies>
              </ComposableMap>

              <div className="map-labels">
                <div className="label-div">
                  <div className="primary-label-color label-color"></div>
                  <Text variant="smallFont">Menos del 3%</Text>
                </div>
                <div className="label-div">
                  <div className="secondary-label-color label-color"></div>
                  <Text variant="smallFont">Entre el 3% y el 10%</Text>
                </div>
                <div className="label-div">
                  <div className="teritiary-label-color label-color"></div>
                  <Text variant="smallFont">Más del 10%</Text>
                </div>
              </div>
            </div>
          </div>
          {popUpClick!=null && (
            <Text
              className="more-information-text"
              onClick={() => popUpClickHandler()}
            >
              Más información
            </Text>
          )}
          
        </div>
      );
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <DiversificationStyle>{renderHandler()}</DiversificationStyle>
    </ThemeProvider>
  );
});

export default Diversification;
