import { useEffect, useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { AlertContext } from "../common/alertContainer";
import { ContainerContext } from "../../components/navbarContainer/containerProvider";
import { registerCodeFlanks } from "./codeflanksServices";
import { AuthTokenService } from "../../services";

const Codeflanks = () => {
  const [menuHidden] = useState(true);
  const { open } = useContext(AlertContext);
  const { search } = useLocation();
  const { changeMenuStatus, changeNavBarStatus } = useContext(ContainerContext);

  useEffect(() => {
    changeNavBarStatus(true);
    changeMenuStatus(menuHidden);
    return () => {
      changeMenuStatus(!menuHidden);
      changeNavBarStatus(false);
    };
  });

  useEffect(() => {
    open({
      alertType: "spinner",
      open: true,
    });
    


    const query = new URLSearchParams(search);
    const flanksCode = query.get("code");
    if (flanksCode) {
      
      open({
        alertType: "spinner",
        open: true,
      });
      const dataFlanks = {
        code: flanksCode
      };
      registerCodeFlanks(dataFlanks)
        .then((result) => {
          open({
            alertType: "spinner",
            open: false,
          });
          if (result?.data) 
          {
            const userId = AuthTokenService.getUserId();
            if(userId==null){
              //redireccion a una success page
            }
            else{
              var url = "https://clientes.micappital.com/resumen";
              window.open(url, '_self', 'noopener,noreferrer');
            }
           
          }else
          {
            open({
              type: "error",
              title: "Error con el código",
              alertType: "toast",
              open: true,
            });
          }
        })
        .catch(() => {
          open({
            alertType: "spinner",
            open: false,
          });
          open({
            type: "error",
            title: "Error al enviar datos",
            alertType: "toast",
            open: true,
          });
        });

    } else {
      open({
        type: "error",
        title: "No se ha podido recibir el código",
        alertType: "toast",
        open: true,
      });
    }
    open({
      alertType: "spinner",
      open: false,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div className="altafinect-container"></div>;
};

export default Codeflanks;
