import React, { useState, useEffect, useContext} from 'react';
import AltaStyle from './altaStyle';
import Select from 'react-select'
import { getLoginData, patchNR} from './procesoAltaServices';
import { useNavigate } from "react-router-dom";
import { Form,Row, Col } from "react-bootstrap";
import { ContainerContext } from '../../components/navbarContainer/containerProvider';
import { AuthTokenService } from '../../services';
import { patchIroniaData } from '../investment/autopilot/autopilotServices';
import { getJSON } from 'jquery';
import { countries } from './autopilotConstants';


export default function AutopilotAdditionalData2(){
    const [error, setError]=useState(false);
    const [intento, setIntento]=useState(false);
    const [menuHidden] = useState(true);
    const navigate=useNavigate();
    const { changeMenuStatus, changeNavBarStatus, changeAlertStatus } =
    useContext(ContainerContext);
    const [inputValues, setInputValues] = useState({
        nationalCountry: 'ES',
        economicActivity: 'OTHER_EMPLOYEES',
        publicPosition: false,
        economicStudies: true
        // Agrega los nombres de los campos adicionales
    });
    const nombre = AuthTokenService.getNombre();

    useEffect(() => {
        window.scrollTo(0, 0);
        changeMenuStatus(menuHidden);
        changeNavBarStatus(true);
        changeAlertStatus(false);
        return () => {
          changeMenuStatus(!menuHidden);
          changeNavBarStatus(false);
          changeAlertStatus(true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [changeMenuStatus, changeNavBarStatus]);

    const handleInputChange = (event) => {
        if(event.target.value=="true"){
            setInputValues({
                ...inputValues,
                [event.target.name]: true,
            });
        }
        else if(event.target.value=="false"){
            setInputValues({
                ...inputValues,
                [event.target.name]: false,
            });
        }
        else{
            setInputValues({
                ...inputValues,
                [event.target.name]: event.target.value,
            });
        }
    };

    const handleCountryChange= (country) => {
        
        setInputValues({
            ...inputValues,
            ["nationalCountry"]: country.value,
        });
    };


    const handleGuardar = (datos) => {//funcion que llama al patch con los datos de formularios
        
        var datos=inputValues;
        datos.idCliente=AuthTokenService.getUserId();

        
        patchIroniaData(datos)
            .then((result)=>{
                if (result.data.status==200){
                    navigate("/autopilot");
                }
                else{
                    setError(true);
                }
            });
        
      };


    return (
        <AltaStyle >
            <div className="bodyProcesoAlta">
                <div className="navbar navbar-expand-md navbar-light" style={{paddingLeft:'2.5rem'}}>
                    
                </div>
                <div className="process-popup container">
                    <div className="main-step-container">

                        <a className="navbar-brand">
                            <img height="40" src="https://micappital.com/assets/img/logo-micappital.png" alt="Micappital"></img>
                        </a>
                        
                    </div>
                    <div className="process-popup-inner-container">
                        <h3 className="process-heading mt-5 mb-4">
                            {nombre}, necesitamos un par de datos más para crearte tu cuenta Autopilot
                        </h3>

                        <Form className="form-insurance-data">
                            <Row>
                                <Select className="mb-3 col-sm-6 col-12" placeholder="País de nacionalidad"  options={countries} onChange={handleCountryChange}/>
                            </Row>
                            <Row>
                                <Form.Group controlId="economicActivity">
                                    <Form.Label>¿Trabajas por cuenta propia o por cuenta ajena?</Form.Label>
                                    <div className='radioButtons'>
                                        <Form.Check
                                            type="radio"
                                            label="Cuenta propia"
                                            name="economicActivity"
                                            value="SELF_EMPLOYED"
                                            checked={inputValues.economicActivity == "SELF_EMPLOYED"}
                                            onChange={handleInputChange}
                                        />
                                        <Form.Check
                                            type="radio"
                                            label="Cuenta ajena"
                                            name="economicActivity"
                                            value="OTHER_EMPLOYEES"
                                            checked={inputValues.economicActivity == "OTHER_EMPLOYEES"}
                                            onChange={handleInputChange} 
                                        />
                                    </div>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group controlId="publicPosition">
                                    <Form.Label>¿Ocupas o has ocupado un cargo electo o de designación política?</Form.Label>
                                    <div className='radioButtons'>
                                        <Form.Check
                                            type="radio"
                                            label="Sí"
                                            name="publicPosition"
                                            value={true}
                                            checked={inputValues.publicPosition == true}
                                            onChange={handleInputChange}
                                        />
                                        <Form.Check
                                            type="radio"
                                            label="No"
                                            name="publicPosition"
                                            value={false}
                                            checked={inputValues.publicPosition == false}
                                            onChange={handleInputChange} 
                                        />
                                    </div>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group controlId="economicStudies">
                                    <Form.Label>¿Tienes estudios universitarios?</Form.Label>
                                    <div className='radioButtons'>
                                        <Form.Check
                                            type="radio"
                                            label="Sí"
                                            name="economicStudies"
                                            value={true}
                                            checked={inputValues.economicStudies == true}
                                            onChange={handleInputChange}
                                        />
                                        <Form.Check
                                            type="radio"
                                            label="No"
                                            name="economicStudies"
                                            value={false}
                                            checked={inputValues.economicStudies == false}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </Form.Group>
                            </Row>
                                
                            {error && <p>HA HABIDO UN ERROR</p>}
                            
                            
                            
                        </Form>
                        

                        <div className="start-button-container">
                            <button className="btn" onClick={() =>navigate("/datosAutopilot")}>
                                Atrás
                            </button>
                            <button className="botonComienza btn" onClick={handleGuardar}>
                                Finalizar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </AltaStyle>
    );
}