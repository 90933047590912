import styled from "styled-components";

const InsurancesStyle = styled.div`
  padding: 2rem;
  @media (max-width: 640px) {
    padding: 1rem;
  }
  .insurances-container {
    .header-container {
      display: flex;
      justify-content: space-between;
      @media (max-width: 420px) {
        text-align: center;
        flex-direction: column;
        align-items: center;
      }
      .insurances-heading {
        color: ${(props) => props.theme.colors.fontColor.titleFontColor};
        font-weight: bold;
      }
      .total-insurance-amount {
        display: flex;
        flex-direction: column;
        align-items: center;
        .total-amount {
          font-weight: bold;
          .amount-text {
            font-size: 0.9rem;
          }
        }
        .label-text {
          border-radius: 1.56rem;
          padding: 0.3rem 1rem;
          font-style: italic;
          background-color: ${(props) =>
            props.theme.colors.background.primaryInsuranceLabelColor};
        }
      }
    }
    .insurances-list-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-top: 1.5rem;
      
      .insurances-list-inner-container {
        display: flex;
        flex-direction: column;
        gap: 1.8rem;
        padding: 1rem;

        .insurances-list {
          display: flex;
          box-shadow: 0rem 0rem 0.5rem 0rem rgb(11 57 59 / 18%);
          padding: 1rem;
          border-radius: 1.56rem;
          position: relative;
          cursor: pointer;
          @media (max-width: 640px) {
            flex-direction: column;
            align-items: center;
          }

          .images-section {
            margin-right: 1rem;
            display: flex;
            flex-direction: column;
            .insurance-icon {
              height: 2.62rem;
              width: 3.75rem;
            }
            .company-icon {
              max-height: 3.18rem;
              max-width: 4rem;
            }
          }
          .insurances-details-section {
            flex: 1;
            @media (max-width: 640px) {
              display: flex;
              flex-direction: column;
              align-items: center;
            }
            .insurance-title {
              color: ${(props) =>
                props.theme.colors.fontColor.descriptionFontColor};
              font-weight: bold;
            }
            .insurance-type {
              color: ${(props) =>
                props.theme.colors.fontColor.objectiveFontColor};
              @media (max-width: 640px) {
                text-align: center;
              }
            }
            .insurance-date-container {
              display: flex;
              align-items: center;
              margin-top: 0.3rem;
              flex-wrap: wrap;
              @media (max-width: 640px) {
                justify-content: center;
              }
              .insurance-date {
                padding-left: 0.5rem;
                color: ${(props) =>
                  props.theme.colors.fontColor.objectiveFontColor};
              }
              .renewal-label {
                padding: 0.2rem 0.5rem;
                background-color: ${(props) =>
                  props.theme.colors.background.renewalLabelBackground};
                border-radius: 1.56rem;
                margin-left: 1rem;
                color: ${(props) =>
                  props.theme.colors.fontColor.renewalLabelFont};
                font-weight: bold;
              }
            }
            .micappital-optimization-section {
              display: flex;
              align-items: center;
              margin-top: 0.3rem;
              .micappital-optimized-text {
                color: ${(props) =>
                  props.theme.colors.fontColor.titleFontColor};
                margin-left: 0.5rem;
              }
              .micappital-not-optimized-text {
                color: ${(props) =>
                  props.theme.colors.fontColor.objectiveFontColor};
                margin-left: 0.5rem;
              }
            }
            .optimization-details {
              display: flex;
              align-items: start;
              .optimization-icon {
                padding-top: 0.3rem;
              }
              .optimization-text {
                padding-left: 0.5rem;
              }
              .yellow-text {
                color: ${(props) =>
                  props.theme.colors.fontColor.primaryOptimizationText};
              }
              .green-text {
                color: ${(props) =>
                  props.theme.colors.fontColor.titleFontColor};
              }
              .red-text {
                color: ${(props) =>
                  props.theme.colors.fontColor.secondaryOptimizationText};
              }
            }
            .activation-section {
              display: flex;
              align-items: start;
              .activation-icon {
                padding-top: 0.3rem;
              }
              .activation-text {
                padding-left: 0.5rem;
                color: ${(props) =>
                  props.theme.colors.fontColor.objectiveFontColor};
              }
              .green-text {
                padding-left: 0.5rem;
                color: ${(props) =>
                  props.theme.colors.fontColor.titleFontColor};
              }
            }
          }
          .insurances-amount-section {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-left: 0.3rem;
            .insurance-amount {
              font-weight: bold;
              margin-bottom: 0.5rem;
              .amount-text {
                font-size: 0.9rem;
              }
            }
            .insurance-label-text {
              border-radius: 1.56rem;
              padding: 0.3rem 1rem;
              font-style: italic;
            }
            .primary-background {
              background-color: ${(props) =>
                props.theme.colors.background.primaryInsuranceLabelColor};
            }
            .secondary-background {
              background-color: ${(props) =>
                props.theme.colors.background.secondaryInsuranceLabelColor};
            }
            .tertiary-background {
              background-color: ${(props) =>
                props.theme.colors.background.teritiaryInsuranceLabelColor};
            }
          }
          .logo-icon {
            position: absolute;
            top: 1rem;
            right: 1rem;
          }
        }
        .insurance-list-dateCount {
          background-color: ${(props) =>
            props.theme.colors.background.primaryInsuranceLabelColor};
        }
        .insurances-list-inactive {
          display: flex;
          box-shadow: 0rem 0rem 0.5rem 0rem rgb(11 57 59 / 18%);
          padding: 1rem;
          border-radius: 1.56rem;
          position: relative;
          align-items: center;
          justify-content: center;
          min-height: 10rem;
          .insurance-non-active {
            display: flex;
            align-items: flex-end;

            position: absolute;
            left: 1rem;
            top: 1rem;

            .images-section-non-active {
              display: flex;
              flex-direction: column;
              gap: 1rem;
              padding-right: 1rem;
              .company-icon-non-active {
                width: 4rem;
                height: 4rem;
                border-radius: 50%;
                background-color: ${(props) =>
                  props.theme.colors.background.processPopUpBorder};
              }
              .company-name-icon-non-active {
                width: 4rem;
                height: 1rem;
                border-radius: 2rem;
                background-color: ${(props) =>
                  props.theme.colors.background.processPopUpBorder};
              }
            }
            .insurance-section-non-active {
              display: flex;
              flex-direction: column;
              gap: 1rem;

              .insurance-name-non-active {
                font-size: 1.4rem;
                font-weight: bold;
                color: ${(props) =>
                  props.theme.colors.background.processPopUpBorder};
                @media (max-width: 1350px) {
                  font-size: 1.2rem;
                }
                @media (max-width: 1040px) {
                  font-size: 1.4rem;
                }
                @media (max-width: 750px) {
                  font-size: 1.2rem;
                }
              }
              .insurance-details-container-non-active {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                .insurance-details-non-active {
                  width: 5rem;
                  height: 1rem;
                  border-radius: 1rem;
                  background-color: ${(props) =>
                    props.theme.colors.background.processPopUpBorder};
                }
              }
            }
          }
          .insurance-info-container {
            background-color: ${(props) =>
              props.theme.colors.background.processPopUpBorder};
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0.5rem 2rem;
            border-radius: 1rem;
            min-height: 4.5rem;
            z-index: 1;
            box-shadow: 0rem 0rem 0.5rem 0rem rgb(11 57 59 / 18%);
            @media (max-width: 420px) {
              padding: 0.5rem 2rem;
            }
            .insurance-details-L-txt {
              font-size: 1rem;
              font-weight: 700;
              color: ${(props) => props.theme.colors.fontColor.titleFontColor};
              @media (max-width: 350px) {
                font-size: 0.9rem;
                font-weight: 600;
              }
            }
            .insurance-details-S-txt {
              font-size: 0.6rem;

              color: ${(props) => props.theme.colors.fontColor.titleFontColor};
            }
          }
          .insurance-amount-non-active {
            width: 5rem;
            height: 1.5rem;

            border-radius: 2rem;
            background-color: ${(props) =>
              props.theme.colors.background.processPopUpBorder};
            position: absolute;
            top: 1rem;
            right: 1rem;
            @media (max-width: 420px) {
              display: none;
            }
          }
        }
      }
    }
  }
  .bottom-description {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
    .bottom-logo {
      height: 0.9rem;
    }
    .bottom-text {
      margin-left: 0.5rem;
      text-align: center;
      color: ${(props) => props.theme.colors.fontColor.micappitalDefaultColor};
    }
  }
  /* @media (max-width: 640px) { */

  .simplebar-scrollbar::before {
    background-color: ${(props) =>
      props.theme.colors.background.primaryCardBackground};
    border-radius: 0.4375rem;
  }
  .simplebar-scroll-content {
    max-height: inherit !important;
  }
  .simplebar-vertical {
    width: 0.6875rem;
  }
  /* } */
`;
export default InsurancesStyle;
