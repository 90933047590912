import jsPDF from "jspdf";
import ReactDOMServer from "react-dom/server";
import Button from "../../../../components/button/button";
import CancelLetterSectionStyle from "./cancelLetterSectionStyle";
import CancelLetter from "../cancelLetter/cancelLetter";
import Text from "../../../../theme/font";

const CancelLetterSection = ({ data }) => {
  const cancelLetterHandler = () => {
    const string = ReactDOMServer.renderToString(<CancelLetter data={data} />);
    const doc = new jsPDF("p", "px", "a4");
    doc.setFont("Inter-Regular", "normal");
    doc.setFontSize("10px");
    doc.html(string, {
      callback: function (doc) {
        doc.save(`2024_${data.nombre}_Baja${data.tipo}CartaSeguro`);
      },
    });
  };

  return (
    <CancelLetterSectionStyle>
      <Text variant="titleFont" className="cancel-section-heading">
        Solicitar la baja
      </Text>
      <Text className="cancel-section-description" variant="popUpFont">
        Para solicitar la baja, es necesario que firmes la carta de baja y se la hagas llegar a tu seguro junto con una copia de tu DNI
      </Text>
      {/* <Text className="cancel-section-description" variant="popUpFont">
        XXXX@XXXX.com
      </Text> */}
      <Text variant="labelFont" className="cancel-button-container">
        <Button
          className="download-button"
          value="Carta baja"
          click={cancelLetterHandler}
        />
      </Text>
    </CancelLetterSectionStyle>
  );
};
export default CancelLetterSection;
