import styled from "styled-components";

const MicappitalConnectStyle = styled.div`
  margin-top: 2rem;
  width: 100%;
  height: 100%;
  min-height: 38rem;
  padding: 1.2rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  background-color: ${(props) =>
    props.theme.colors.background.defaultBackground};
  border-radius: 1rem;
  @media (max-width: 1040px) {
  }
  .title {
    font-weight: bold;
    color: ${(props) => props.theme.colors.fontColor.titleFontColor};
    padding: 1rem 2rem;
  }
  .subTitle {
    padding: 0rem 2rem;
    color: ${(props) => props.theme.colors.background.overlayBackground};
  }
  .dataMainContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* align-items: center; */
    padding: 3rem 1.5rem 2rem 1.5rem;
    gap: 1rem;
    @media (max-width: 1450px) {
      padding: 2rem 2rem;
    }
    @media (max-width: 1040px) {
      padding: 1rem 2rem;
    }
    @media (max-width: 860px) {
      padding: 2rem 0rem;
    }
    .dataSubContainer {
      display: flex;
      flex: 0 0 23%;
      flex-direction: column;
      align-items: center;
      background-color: ${(props) =>
        props.theme.colors.background.defaultFieldBackground};
      /* height: 100%; */
      /* min-height: 24rem; */
      border-radius: 3rem;
      padding: 0.5rem;
      text-align: center;
      max-width: 17.5rem;

      color: ${(props) => props.theme.colors.fontColor.descriptionFontColor};
      @media (max-width: 1450px) {
        flex: 0 1 48%;
        max-width: 12.5rem;
        /* min-height: 18rem; */
      }
      @media (max-width: 560px) {
        flex: 0 1 100%;
      }
      .imageContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 5rem;
        margin-bottom: 1rem;
        .iconImage {
          width: 3rem;
        }
      }
      .heading {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90%;
        min-height: 2rem;
        font-weight: bold;
        margin-bottom: 1rem;
      }
      .description {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        font-style: italic;
        text-align: center;
        padding: 0rem 1rem 1rem 1rem;
        color: ${(props) => props.theme.colors.background.overlayBackground};
      }
    }
  }
  .buttonBox {
    width: 100%;
    padding: 2rem 2rem 0rem 0rem;
    display: flex;
    align-items: end;
    flex-direction: column;
    @media (max-width: 1450px) {
      align-items: center;
      padding-right: 0rem;
    }
    .connect-button {
      width: fit-content;
      .micappital-connect-button {
        min-height: 2.68rem;
      }
    }

    .skip-button {
      text-align: center;
      padding: 1rem 0rem;
      font-weight: bold;
      color: ${(props) => props.theme.colors.fontColor.titleFontColor};
      cursor: pointer;
      text-decoration: underline;
      width: 100%;
      max-width: 16rem;
    }
  }
`;
export default MicappitalConnectStyle;
