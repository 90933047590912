import styled from "styled-components";

const ProcessPopUpChildStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .main-popup-container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .process-popup-inner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    max-width: 70%;
    backface-visibility: hidden;
    @media (max-width: 440px) {
      max-width: 100%;
    }
  }
  .popup-inner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    backface-visibility: hidden;
  }
  .responsive-inner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
  .grid-container {
    @media (max-width: 520px) {
      max-width: 100%;
    }
  }
  .top-image-container {
    .process-image {
      height: 16rem;
      @media (max-width: 540px) {
        height: 11rem;
      }
    }
    .top-image {
      width: 100%;
    }
  }
  .margin-bottom {
    margin-bottom: 1rem;
  }
  .process-heading {
    text-align: center;
    font-weight: bold;
    color: ${(props) => props.theme.colors.fontColor.darkFont};
  }
  .process-sub-heading {
    color: ${(props) => props.theme.colors.fontColor.objectiveFontColor};
    width: 100%;
    text-align: start;
    font-weight: bold;
  }
  .process-description {
    text-align: center;
    color: ${(props) => props.theme.colors.fontColor.objectiveFontColor};
    margin-bottom: 1rem;
  }
  .time-estimation {
    text-align: center;
    color: ${(props) => props.theme.colors.fontColor.objectiveFontColor};
  }
  .start-button-container {
    width: 100%;
    max-width: 24rem;
  }
  .start-process-button {
    background-color: ${(props) =>
      props.theme.colors.background.processPopUpBackground};
    width: 100%;
    min-height: 4rem;
    font-weight: 500;
    min-width: 13rem;
  }
  .bottom-button-container {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 1.5rem;
    margin-top: 2rem;
    @media (max-width: 540px) {
      justify-content: center;
    }
    .return-text {
      text-decoration: underline;
      cursor: pointer;
      color: ${(props) => props.theme.colors.fontColor.objectiveFontColor};
    }
    .continue-icon-button {
      min-width: 7rem;
      background-color: ${(props) =>
        props.theme.colors.background.processPopUpBackground};
      border-radius: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .continue-icon {
        @media (max-width: 540px) {
          height: 70%;
        }
      }
    }
  }
  .insurance-button-container {
    width: 100%;
    max-width: 26rem;
    border-radius: 1rem;
    position: relative;
    background-color: ${(props) =>
      props.theme.colors.background.defaultBackground};
    min-height: 4rem;
    padding: 0.5rem 0.5rem;

    cursor: pointer;
    .selected-icon {
      position: absolute;
      top: -0.5rem;
      right: -0.5rem;
    }
    .not-selected-icon {
      display: none;
    }
    .option-title {
      text-align: center;
      color: ${(props) => props.theme.colors.fontColor.darkFont};
    }
    .option-description {
      text-align: center;
      color: ${(props) => props.theme.colors.fontColor.objectiveFontColor};
    }
  }
  .selected-goal {
    outline: 0.0625rem solid
      ${(props) => props.theme.colors.background.processPopUpBackground};
    box-shadow: rgb(30 217 225 / 20%) 0rem 0rem 0.5rem 0.4rem;
  }
  .selected-insurance-image {
    height: 3rem;
    filter: invert(34%) sepia(86%) saturate(344%) hue-rotate(134deg)
      brightness(97%) contrast(83%);
    @media (max-width: 720px) {
      height: 2rem;
    }
  }
  .selected-insurance-name {
    color: ${(props) => props.theme.colors.fontColor.titleFontColor};
  }
  .selected-insurance {
    outline: 0.0625rem solid
      ${(props) => props.theme.colors.background.processPopUpBackground};
    animation: pulse 0.5s infinite;

    @keyframes pulse {
      25% {
        background-color: ${(props) =>
          props.theme.colors.background.animationBackground};
      }

      50% {
        background-color: ${(props) =>
          props.theme.colors.background.defaultBackground};
      }

      75% {
        background-color: ${(props) =>
          props.theme.colors.background.animationBackground};
      }
      100% {
        background-color: ${(props) =>
          props.theme.colors.background.defaultBackground};
      }
    }
  }
  .selected {
    outline: 0.0625rem solid
      ${(props) => props.theme.colors.background.processPopUpBackground};
    border-radius: 1rem;
  }
  .select-insurance-container {
    display: grid;
    grid-template-columns: repeat(4, minmax(5rem, 1fr));
    align-items: start;
    text-align: -webkit-center;
    padding: 1rem;
    grid-gap: 1.5rem;
    @media (max-width: 1200px) {
      grid-template-columns: repeat(3, minmax(4.375rem, 1fr));
    }
    @media (max-width: 720px) {
      grid-template-columns: repeat(2, minmax(4.375rem, 1fr));
      grid-gap: 1rem;
    }
    .insurance-image-container {
      cursor: pointer;
      padding: 0.3rem;
      min-width: 8rem;
      border-radius: 0.312rem;
      border: 0.0625rem solid
        ${(props) => props.theme.colors.fontColor.objectiveFontColor};
      @media (max-width: 1340px) {
        min-width: 7rem;
      }
      @media (max-width: 760px) {
        min-width: 4rem;
      }
      .insurance-image {
        height: 3rem;
        filter: invert(51%) sepia(8%) saturate(2%) hue-rotate(347deg)
          brightness(95%) contrast(91%);
        @media (max-width: 720px) {
          height: 2rem;
        }
      }
      .insurance-name {
        color: ${(props) => props.theme.colors.fontColor.objectiveFontColor};
      }
    }
    .selected-insurance {
      border: 0.0625rem solid
        ${(props) => props.theme.colors.fontColor.titleFontColor};
      box-shadow: 0rem 0rem 0.5rem 0.4rem rgb(30 217 225 / 20%);
      .selected-insurance-image {
        height: 3rem;
        filter: invert(34%) sepia(86%) saturate(344%) hue-rotate(134deg)
          brightness(97%) contrast(83%);
        @media (max-width: 720px) {
          height: 2rem;
        }
      }
      .selected-insurance-name {
        color: ${(props) => props.theme.colors.fontColor.titleFontColor};
      }
    }
  }
  .insurance-selection-button-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
  }
  .return-button {
    margin: 0rem 0.2rem;
    height: 4rem;
    background-color: ${(props) =>
      props.theme.colors.background.defaultBackground};
    color: ${(props) => props.theme.colors.fontColor.objectiveFontColor};
    padding: 0rem 0.5rem;
    min-width: 13rem;
    outline: 0.0625rem solid
      ${(props) => props.theme.colors.fontColor.objectiveFontColor};
    @media (max-width: 540px) {
      height: 2.5rem;
    }
  }
  .continue-button-container {
    margin: 0rem 0.2rem;
    .continue-button {
      height: 4rem;
      background-color: ${(props) =>
        props.theme.colors.background.processPopUpBackground};
      min-width: 13rem;

      @media (max-width: 540px) {
        height: 2.5rem;
      }
    }
  }
  .warning-text {
    color: ${(props) => props.theme.colors.fontColor.errorFontColor};
  }
  .data-container {
    width: 100%;
  }
  .file-upload-container {
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .file-upload {
    width: 100%;
    text-align-last: center;
    max-width: 18rem;
  }
  .error-text {
    padding-top: 0.5rem;
    color: ${(props) => props.theme.colors.fontColor.errorFontColor};
  }
  .select-field-section {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }

  .select-field-container {
    max-width: 22rem;
    width: 100%;
  }
  .text-area-container {
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
    flex-direction: column;
    align-items: center;
  }

  textarea {
    width: 100%;
    max-width: 60%;
    min-height: 8rem;
    resize: none;
    outline: none;
    border: 0rem;
    background-color: ${(props) =>
      props.theme.colors.background.processPopUpBorder};
    padding-left: 1rem;
    @media (max-width: 620px) {
      max-width: 100%;
    }
  }
  .secondary-text-area {
    background-color: ${(props) =>
      props.theme.colors.background.defaultBackground};
    border-radius: 1rem;
  }
  .final-button-container {
    .final-button {
      min-height: 4rem;
      background-color: ${(props) =>
        props.theme.colors.background.processPopUpBackground};
      min-width: 13rem;
    }
  }
  .return-button-container {
    margin-bottom: 2rem;
    .return-button {
      min-height: 4rem;
      background-color: ${(props) =>
        props.theme.colors.background.outlineBorderColor};
      color: ${(props) => props.theme.colors.background.defaultBackground};
      min-width: 13rem;
      outline: none;
    }
  }
  .form-field {
    .common-container {
      display: flex;
      margin-bottom: 1.5rem;
      gap: 2rem;
      @media (max-width: 700px) {
        flex-direction: column;
        gap: 1rem;
      }
    }

    .post-field {
      display: flex;
      justify-content: flex-end;
    }
    .text-field {
      min-width: 16rem;
      height: 3rem;
      @media (max-width: 420px) {
        min-width: 14rem;
      }
    }
    .mini-text-field {
      min-width: 9rem;
    }
    .regime-field {
    }

    .multiple-field-container {
      display: flex;
      margin-bottom: 1rem;
      gap: 1rem;
      @media (max-width: 740px) {
        flex-direction: column;
      }
    }
    .flex-container {
      display: flex;
      justify-content: center;
    }
    .MuiCheckbox-colorSecondary.Mui-checked {
      color: ${(props) => props.theme.colors.background.checkBoxColor};
    }
    .checkbox-field {
    }
    .checkbox-container {
    }
    .MuiTypography-body1 {
      color: ${(props) => props.theme.colors.fontColor.objectiveFontColor};
    }
  }
`;
export default ProcessPopUpChildStyle;
