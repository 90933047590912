export const summaryContent = [
  {
    title: "Analiza y confirma",
    content:
      "Analiza los fondos que te recomendamos y confirma que quieres invertir",
    linkTitle: "Cambiar cantidad",
  },
  {
    title: "Compra los fondos",
    content: "Te hemos hecho un vídeo para que no te pierdas en el proceso",
    linkTitle: "Ver vídeo",
    newLinkTitle: "Ver receta tutorial",
  },
  {
    title: " Activa Micappital Connect",
    content:
      " Activa Micappital Connect para que el seguimiento de tu inversión sea 100% automático",
    linkTitle: "Ahora no",
  },
];
