import URL_CONSTANTS from "../../constants/urlConstants/urlConstants";
import axios from "axios";
import { Request } from "../../services";

export const getLoginToken = (data) => {
  return axios.post(
    process.env.REACT_APP_BASE_URL + URL_CONSTANTS.get_login_tokens,
    data
  );
};

export const patchNR = (data) =>{
    return Request.patch(URL_CONSTANTS.patch_noRegistrado,data);
}

export const getLoginData = () => {
    const data= LoginInfo();
    console.log(data);
    return axios.post(
        process.env.REACT_APP_BASE_URL + URL_CONSTANTS.login_login,
        data
    );
};

function LoginInfo()
    {
        const username= "Micappital-proceso-alta-web";
        const pw= "36c1e0454ab4b37e6d1bf99fbcc5935a";
        let json={
            "username":username,
            "password":pw
            };

        return json;
    }
