import Button from "../../../../components/button/button";
import InvestmentBannerStyle from "./investmentBannerStyle";
import Text from "../../../../theme/font";

const InvestmentBanner = () => {
  return (
    <InvestmentBannerStyle>
      <div className="banner-container">
        <div className="title-container">
          <Text variant="largeText" type="primary" className="banner-text">
            Te ayudamos a invertir sin moverte de tu banco
          </Text>
          <Text variant="titleFont" type="primary" className="banner-text">
            Solo cobramos si te hacemos ganar dinero
          </Text>
        </div>
        <div className="text-container">
          <Text variant="fieldFont" className="banner-button-container">
            <Button value="Comienza hoy a invertir" className="banner-button" />
          </Text>
        </div>
      </div>
    </InvestmentBannerStyle>
  );
};

export default InvestmentBanner;
