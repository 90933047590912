import React, { useState, useEffect, useContext} from 'react';
import AltaStyle from './altaStyle';
import { subirArchivoIronia } from '../investment/autopilot/autopilotServices';
import { useNavigate } from "react-router-dom";
import { Form,Row, Col } from "react-bootstrap";
import { ContainerContext } from '../../components/navbarContainer/containerProvider';
import { AuthTokenService } from '../../services';


export default function AutopilotIncomeProof(){
    const [error, setError]=useState(false);
    const [intento, setIntento]=useState(false);
    const [menuHidden] = useState(true);
    const { changeMenuStatus, changeNavBarStatus, changeAlertStatus } =
    useContext(ContainerContext);
    const navigate=useNavigate();
    const [incomeProof, setIncomeProof] = useState();
    const nombre = AuthTokenService.getNombre();

    useEffect(() => {
        window.scrollTo(0, 0);
        changeMenuStatus(menuHidden);
        changeNavBarStatus(true);
        changeAlertStatus(false);
        return () => {
          changeMenuStatus(!menuHidden);
          changeNavBarStatus(false);
          changeAlertStatus(true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [changeMenuStatus, changeNavBarStatus]);

    const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
        // Verifica si el archivo es de tipo imagen o PDF antes de guardarlo
        if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf') {
            setIncomeProof(file);
        } else {
            alert('Por favor, selecciona un archivo de imagen o PDF válido.');
        }
    }
    };

      const handleGuardar = () => {
        
        const formData = new FormData();
            formData.append('file', incomeProof);
            formData.append('idCliente', AuthTokenService.getUserId());
            formData.append('tipo', 'proofOfIncome');
        subirArchivoIronia(formData)
            .then((result)=>{
                if (result.data.status==200){
                    navigate("/autopilot");
                }
                else{
                    setError(true);
                }
            });
    };


    return (
        <AltaStyle >
            <div className="bodyProcesoAlta">
                <div className="navbar navbar-expand-md navbar-light" style={{paddingLeft:'2.5rem'}}>
                    
                </div>
                <div className="process-popup container">
                    <div className="main-step-container">

                        <a className="navbar-brand">
                            <img height="40" src="https://micappital.com/assets/img/logo-micappital.png" alt="Micappital"></img>
                        </a>
                        
                    </div>
                    <div className="process-popup-inner-container">
                        <h3 className="process-heading mt-5 mb-2">
                            {nombre}, necesitamos un justificante de ingresos
                        </h3>
                        <p className="process-description mt-2 mb-4">
                            Un justificante de ingresos puede ser una nómina, una declaración de la renta.
                            Si tienes dudas no dudes en contactarnos.
                        </p>

                        <Form className="form-insurance-data">
                            <Row>
                                <Form.Group as={Col} xs={12} sm={12} className="mb-3" controlId="tipo">
                                    <Form.Label className="bold">Justificante de ingresos</Form.Label>
                                    <Form.Control className={(intento==true) && 'campo-incompleto'} placeholder="DNI foto frontal" accept=".jpg, .jpeg, .png, .pdf" name="tipo" type="file" onChange={handleFileChange}/>
                                </Form.Group>
                                
                            </Row>
                            
                                
                            {error && <p>HA HABIDO UN ERROR</p>}
                            
                            
                            
                        </Form>

                        <div className="start-button-container">
                            <button className="btn" onClick={() =>navigate("/autopilot")}>
                                Atrás
                            </button>
                            <button className="botonComienza btn" type='button' onClick={handleGuardar}>
                                Continuar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </AltaStyle>
    );
}