import styled from "styled-components";

const EcoFundInvestmentStyle = styled.div`
  border-radius: 1rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  position: fixed;
  width: 29.5%;
  background-color: ${(props) =>
        props.theme.colors.background.defaultBackground};
  @media (max-width: 1040px) {
    position: relative;
    width: 100%;
    max-width: 28.12rem;
  }
  .eco-investment-heading {
    font-weight: bold;
    color: ${(props) => props.theme.colors.fontColor.titleFontColor};
    @media (max-width: 540px) {
      text-align: center;
    }
  }
  .eco-investment-sub-heading {
    font-weight: bold;
    color: ${(props) => props.theme.colors.background.secondaryLoadingColor};
    @media (max-width: 540px) {
      text-align: center;
    }
  }
  .eco-investment-description {
    color: ${(props) => props.theme.colors.fontColor.descriptionFontColor};
    @media (max-width: 540px) {
      text-align: center;
    }
  }
  .eco-investment-amount-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    .amount-field {
      width: 100%;
      max-width: 25.62rem;
      #eco-investment {
        width: 100%;
      }
    }
    .button-container {
      width: 100%;
      padding-top: 0.5rem;
      max-width: 25.62rem;
      .invest-button {
        width: 100%;
        height: 3rem;
      }
    }
  }
`;
export default EcoFundInvestmentStyle;
