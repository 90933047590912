import { ThemeProvider } from "styled-components";
import { useState } from "react";
import Button from "../../../../components/button/button";
import defaultTheme from "../../../../theme/theme";
import ImageConstants from "../../../../constants/imageConstants/image_constants";
import MicappitalConnectStyle from "./micappitalConnectStyle";
import Text from "../../../../theme/font";

const MicappitalConnect = ({ data }) => {
  const theme = defaultTheme;
  const totalData = data && data.micappitalconnect;
  const connectionData = data && data.micappitalconnect;
  const failureText = totalData && totalData.failure;
  const description = data && data.description;
  const [connecting, setConnecting] = useState(false);
  let connectUrl = "";
  if (totalData && totalData.failure === "bank_url") {
    connectUrl = totalData && totalData.loadurl;
  } else {
    connectUrl = totalData && totalData.finectregisterurl;
  }

  const connectedHandler = () => {
    return (
      <div className="micappital-connected-container">
        <Text variant="normal" className="connected-title">
          Conectado
        </Text>
        <img
          alt="x"
          className="connected-icon"
          src={ImageConstants.connected}
        />
      </div>
    );
  };

  const buttonValueHandeler = (type) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "manual_client": {
        return "Conectar";
      }
      case "no_bank_registered": {
        return "Conectar";
      }
      case "bank_login": {
        return "Volver a intentarlo";
      }
      case "bank_url": {
        return "Acceder";
      }
    }
  };

  const notConnectedHandler = () => {
    return (
      <div>
        <div className="micappital-not-connected-container">
          <div className="connect-title-container">
            <Text variant="popUpText" type="primary" className="estado-title">
              Estado:
            </Text>
            <div className="not-connected-description-container">
              <Text
                variant="popUpText"
                type="primary"
                className="not-connected-title"
              >
                No conectado
              </Text>
              <Text
                variant="fieldFont"
                type="primary"
                className="not-connected-description"
              >
                {description && description}
              </Text>
            </div>
          </div>
          <div className="connect-button-container">
            <Button
              value={buttonValueHandeler(failureText && failureText)}
              className="connect-button"
              click={() => {
                setConnecting(true);
                window.open(connectUrl)}}
            />
          </div>
        </div>
      </div>
    );
  };

  const connectingHandler = () =>{
    return (
      <div>
        <div className="micappital-not-connected-container">
          <div className="connect-title-container">
            <Text variant="popUpText" type="primary" className="estado-title">
              Estado:
            </Text>
            <div className="not-connected-description-container">
              <Text
                variant="popUpText"
                type="primary"
                className="connecting-title"
              >
                Conectando
              </Text>
              <Text
                variant="fieldFont"
                type="primary"
                className="connecting-description"
              >
                Se está realizando la conexión con tu banco. Este proceso puede tardar unos minutos.
              </Text>
            </div>
          </div>
          <div className="connect-button-container">
            <Button
              value="Actualizar"
              className="connect-button"
              click={() => window.location.reload()}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <MicappitalConnectStyle>
        <div className="box-container micappital-connect-container">
          <div className="micappital-connect-header">
            <Text variant="primaryFont" className="micappital-connect-heading">
              Micappital Connect
            </Text>
            <img
              className="connect-arrow-icon"
              alt="x"
              src={ImageConstants.connectArrow}
            />
          </div>
          <Text
            variant="normal"
            type="primary"
            className="micappital-connect-description"
          >
            Conecta con tu banco para actualizar <br />
            tus movimientos de manera automática
          </Text>
          {connecting
              ? connectingHandler()
              : connectionData && connectionData.connected === true
                  ? connectedHandler()
                  : notConnectedHandler()
          }
        </div>
      </MicappitalConnectStyle>
    </ThemeProvider>
  );
};
export default MicappitalConnect;
