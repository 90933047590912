import styled from "styled-components";

const ButtonContainer = styled.div`
  width: 100%;
  height: 100%;
  background: transparent;
  letter-spacing: 1px;
  position: relative;
  display: flex;
  justify-content: center;
  ${(props, style) => {
    // eslint-disable-next-line default-case
    switch (props.container) {
      case "red":
        return `
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 768px) {
  justify-content: center;
  align-items: center;
  }
    `;
    }
  }}
`;
export default ButtonContainer;

export const ButtonStyle = styled.div`
  width: 15rem;
  height: 100%;
  min-height: 2.68rem;
  border-radius: 1.90625rem;
  background-color: ${(props) =>
    props.theme.colors.button.background.redButton};
  text-align: center;
  color: ${(props) => props.theme.colors.button.text.textSecondary};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  padding-bottom: 2px;
  cursor: pointer;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
  }

  ${(props, variant) => {
    // eslint-disable-next-line default-case
    switch (props.variant) {
      case "blue":
        return `
    background-color: ${props.theme.colors.button.background.blueButton};
    border-radius: 0.65rem;
    color: ${props.theme.colors.button.text.textTeritiory};
    justify-content: center;
    font-size: 0.8rem;
    font-weight: 400;
    min-width: 10.625rem;
  `;
      case "red":
        return `
    color: ${(props) => props.theme.colors.text.textPrimary};
    border-radius: 1.90625rem;
    background-color: ${(props) =>
      props.theme.colors.button.background.redButton};
    min-width: 16rem;
    @media (max-width: 768px) {
    width: 100%;
    max-width: 16rem;
  }
    `;
    }
  }}
`;
