import ImageConstants from "../constants/imageConstants/image_constants";

export const insuranceOptions = [
  {
    src: ImageConstants.payIcon,
    title: "No pagues de más",
    description:
      "Somos  independientes, mejoramos las condiciones de tus pólizas a partir de una revisión de tus coberturas adaptandonos a tus necesidades",
  },
  {
    src: ImageConstants.supportIcon,
    title: "Estamos de tu lado",
    description:
      "Te ayudamos con todo tipo de seguros: hogar, salud, vida, coche, empresa  o cualquier tipo de seguro",
  },
  {
    src: ImageConstants.monitorIcon,
    title: "Monitorización",
    description:
      "Sigue de cerca los recibos y las renovaciones de tus seguros sin que se te pase, ten tus gastos controlados",
  },
  {
    src: ImageConstants.appIcon,
    title: "Todo desde tu app",
    description:
      "Ten todos tus seguros centralizados en un único sitio tanto web como app móvil",
  },
];

export const interestOptions = [
  {
    src: ImageConstants.payIcon,
    title: "Los mejores fondos",
    description: `Lorem ipsum dolor sit
    amet, consectetuer
    adipiscing elit, sed diam
    nonummy nibh euismod
    tincidunt ut laoreet
    dolore magna aliqua`,
  },
  {
    src: ImageConstants.supportIcon,
    title: "Menos comisiones",
    description: `Lorem ipsum dolor sit
    amet, consectetuer
    adipiscing elit, sed diam
    nonummy nibh euismod
    tincidunt ut laoreet
    dolore magna aliqua`,
  },
  {
    src: ImageConstants.monitorIcon,
    title: "A éxito contigo",
    description: `Lorem ipsum dolor sit
    amet, consectetuer
    adipiscing elit, sed diam
    nonummy nibh euismod
    tincidunt ut laoreet
    dolore magna aliqua`,
  },
];

export const insuranceCompanies = [
  { image: ImageConstants.alli },
  { image: ImageConstants.asisa },
  { image: ImageConstants.catalana },
  { image: ImageConstants.cnp },
  { image: ImageConstants.general },
  { image: ImageConstants.helvetia },
  { image: ImageConstants.plusultra },
  { image: ImageConstants.liberty },
  { image: ImageConstants.santalucia },
  { image: ImageConstants.ades },
  { image: ImageConstants.aego },
  { image: ImageConstants.axa },
  { image: ImageConstants.caser },
  { image: ImageConstants.dkv },
  { image: ImageConstants.mapfre },
  { image: ImageConstants.Muta },
  { image: ImageConstants.zuri },
  { image: ImageConstants.sanitas },
  { image: ImageConstants.cigna },
  { image: ImageConstants.pelayo },
  { image: ImageConstants.reale },
];

export const regimenData = [
  { option: "Soy el propietario", value: "1" },
  { option: "Soy el inquilino", value: "2" },
];
