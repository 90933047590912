import styled from "styled-components";

const NewPasswordStyle = styled.div`
  display: flex;
  justify-content: center;
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  .new-password-container {
    width: 100%;
    height: 100%;
    display: flex;
    @media (max-width: 768px) {
      flex-direction: column;
      overflow: scroll;
      scrollbar-width: thin;
      scrollbar-color: transparent ;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: transparent;
      }
    }
  }
  .password-form-container {
    width: calc(50%);
    height: 100%;
    background-color: ${(props) =>
      props.theme.colors.background.defaultBackground};
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;
export default NewPasswordStyle;
