import styled from "styled-components";

const PortfolioDataStyle = styled.div`
  @media (max-width: 1040px) {
    display: flex;
    justify-content: center;
  }
  .datosCarteraContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1.25rem;
    @media (max-width: 1040px) {
      width: 19.37rem;
    }
    @media (max-width: 400px) {
      width: 100%;
    }
    .totalText {
      color: ${(props) => props.theme.colors.fontColor.titleFontColor};
      font-weight: bold;
    }
  }
  .portfolios {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    color: ${(props) => props.theme.colors.fontColor.descriptionFontColor};
    gap: 0.5rem;
  }
  .portfolioDetailsContainer {
    width: 100%;
    @media (max-width: 991px) {
      padding: 0;
    }
  }
  .dataPortfolioDetails {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }
  .date-container {
    @media (max-width: 1069px) {
      gap: 2.4rem;
    }
    @media (max-width: 1040px) {
      gap: 0.5rem;
    }
  }
  .datosCarteraTitulo {
    display: flex;
    align-items: center;
    .info-icon {
      height: 0.8rem;
      cursor: pointer;
      margin-left: 0.2rem;
    }
  }
  .datosCarteraTexto {
    font-weight: 500;
    color: ${(props) => props.theme.colors.fontColor.descriptionFontColor};
  }
  .portfolio-data-container {
    display: flex;
    align-items: center;
  }

  .datosCarteraImg {
    height: 1.25rem;
    margin-right: 0.5rem;
  }
`;
export default PortfolioDataStyle;
