import React from "react";
// import ImageConstants from "../../../constants/imageConstants/image_constants";
import SettingsOptionsStyle from "./settingsOptionsStyle";
import Text from "../../../../theme/font";
import { useEffect } from "react";

const SettingsOptions = (props) => {
  useEffect(() => {
    if (props.connected === true && props.title === "Tarjeta") {
      props.click();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.connected]);

  //For showing blue tick/ red sign on each left module card
  const iconHandler = (props) => {
    let icons = null;
    //No icons for now
    // if (
    //   (props.datas &&
    //     props.datas.estado === "1" &&
    //     props.title === "Tarjeta") ||
    //   (props.title && props.title !== "Tarjeta" && props.tick)
    // ) {
    //   icons = <img className="tick-icon" alt="x" src={ImageConstants.tick} />;
    // } else {
    //   icons = <img className="oval-icon" alt="x" src={ImageConstants.Oval} />;
    // }
    return icons;
  };

  //first checking the banks is there or not, if it is true then it will check the length of banks is greater than 0, if yes then it will take first 3 banks for showing in the pop up option
  let iteratedBanks =
    props.banks && props.banks.length > 0 && props.banks.slice(0, 3);

  return (
    <SettingsOptionsStyle>
      <div className="card" onClick={() => props.click()}>
        <div className="card-icon">
          <img src={props.src} alt="x" />
        </div>
        <div className="title">
          <Text variant="normal">{props.title}</Text>
        </div>
        <div className="card-content">
          {props.banks && props.banks.length === 0 ? ( //if props.banks is there then it will check the length of props.banks is equal to 0, if yes it will how the text or it will show an empty string
            <Text variant="normal" type="primary">
              {props.content}
            </Text>
          ) : (
            ""
          )}
          <div className="banks-container">
            {iteratedBanks && //if iteratedBanks is there then check the length of iteratedBanks is greater than 0, then map the iteratedBanks
              iteratedBanks.length > 0 &&
              iteratedBanks.map((value) => {
                return (
                  <img
                    alt=""
                    className="bank-icon"
                    src={value.image}
                    key={Math.random()}
                  />
                );
              })}
          </div>
        </div>
        <div className="fill-icon">{iconHandler(props)}</div>
      </div>
    </SettingsOptionsStyle>
  );
};

export default SettingsOptions;
