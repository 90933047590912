import React, { useState, useEffect, useContext } from "react";
import { ThemeProvider } from "styled-components";
import moment from "moment";
import "moment/locale/es";
import { AlertContext } from "../../../../../../container/common/alertContainer";
import { AuthTokenService } from "../../../../../../services";
import Button from "../../../../../../components/button/button";
import defaultTheme from "../../../../../../theme/theme";
import { editGoal, postGoal } from "../../goalServices";
import ImageConstants from "../../../../../../constants/imageConstants/image_constants";
import NewGoalInfoStyle from "./newGoalInfoStyle";
import Text from "../../../../../../theme/font";

const NewGoalInfo = ({
  month,
  year,
  amount,
  title,
  submit,
  buttonValue,
  onCancel,
  isEdit,
  idObjetivo,
}) => {
  const theme = defaultTheme;
  const [, setGoalValues] = useState({});
  const [checkTitle, setCheckTitle] = useState(false);
  const [checkAmount, setCheckAmount] = useState(false);
  const [checkMonth, setCheckMonth] = useState(false);
  const [checkYear, setCheckYear] = useState(false);
  const { open } = useContext(AlertContext);
  moment.locale("es");

  useEffect(() => {
    if (title && title !== "-") {
      setCheckTitle(false);
    }
    if (amount && amount !== "-") {
      setCheckAmount(false);
    }
    if (month && month !== "-") {
      setCheckMonth(false);
    }
    if (year && year !== "-") {
      setCheckYear(false);
    }
  }, [title, amount, month, year]);

  //for saving the data
  const submitHandler = () => {
    const values = {
      idCliente: AuthTokenService.getUserId(),
      objetivo: title,
      importe: amount,
      periodo: `${year}-${month}-01`,
    };

    let flag = true;
    if (title.length === 0 || title === "-") {
      setCheckTitle(true);
      flag = false;
    }
    if (amount.length === 0 || amount === "-") {
      setCheckAmount(true);
      flag = false;
    }
    if (month.length === 0 || month === "-") {
      setCheckMonth(true);
      flag = false;
    }
    if (year.length === 0 || year === "-") {
      setCheckYear(true);
      flag = false;
    }

    if (flag) {
      open({
        alertType: "spinner",
        open: true,
      });

      if (isEdit) {
        const editvalues = {
          idCliente: AuthTokenService.getUserId(),
          idObjetivo: parseInt(idObjetivo),
          objetivo: title,
          importe: parseInt(amount),
          periodo: `${year}-${month}-01`,
        };

        editGoal(editvalues)
          .then((response) => {
            submit();
            open({
              alertType: "spinner",
              open: false,
            });
          })
          .catch((error) => {
            console.log(error);
            open({
              alertType: "spinner",
              open: false,
            });
          });
      } else {
        postGoal(values)
          .then((response) => {
            setGoalValues(response.config.data);
            submit();
            open({
              alertType: "spinner",
              open: false,
            });
          })
          .catch((error) => {
            console.log(error);
            open({
              alertType: "spinner",
              open: false,
            });
          });
      }
    }
  };

  const formato = (x, signo = false) => {
    var number = new Intl.NumberFormat("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(x);
    if (signo && x > 0) {
      return "+" + number;
    } else return number;
  };

  //for formatting the number
  const amountHandler = () => {
    if (amount) {
      return `${formato(amount)}€`;
    } else {
      return "-";
    }
  };

  //for showing the date
  const dateHandler = () => {
    if (month || year) {
      return `${month ? moment(month, "MM").format("MMMM") : " "} ${
        year ? year : " "
      }`;
    } else {
      return "-";
    }
  };

  //validation for goal
  const checkTitleHandler = () => {
    if (checkTitle) {
      return (
        <Text variant="smallerText" className="label">
          por favor seleccione un objetivo
        </Text>
      );
    }
  };

  //validation for amount
  const checkAmountHandler = () => {
    if (checkAmount) {
      return (
        <Text variant="smallerText" className="label">
          por favor seleccione un cuesta
        </Text>
      );
    }
  };

  //validation for date
  const checkDateHandler = () => {
    if (checkMonth || checkYear) {
      return (
        <Text variant="smallerText" className="label">
          por favor seleccione cuando
        </Text>
      );
    }
  };

  const upperCaseHandler = (value) => {
    let changedValue = "";
    if (value) {
      changedValue = value.charAt(0).toUpperCase() + value.slice(1);
      return changedValue;
    } else {
      return changedValue;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <NewGoalInfoStyle>
        <div className="goal-info-container box-container">
          <div className="optimization-title">
            <Text variant="primaryFont">Resumen de tu objetivo</Text>
          </div>
          <div className="goal-data-container">
            <div className="data-inner-container">
              <div className="goal-img">
                <img
                  className="change-img"
                  alt="x"
                  src={ImageConstants.heart}
                />
              </div>
              <div className="new-goal-info">
                <div className="new-goal-title">
                  <Text variant="fieldFont">Objetivo</Text>
                </div>
                <div className="new-goal-change">
                  <Text variant="primaryFont">{upperCaseHandler(title)}</Text>
                </div>
                {checkTitleHandler()}
              </div>
            </div>
            <div className="data-inner-container">
              <div className="goal-img">
                <img
                  className="change-img"
                  alt="x"
                  src={ImageConstants.walletIcon}
                />
              </div>
              <div className="new-goal-info">
                <div className="new-goal-title">
                  <Text variant="fieldFont">Cuesta</Text>
                </div>
                <div className="new-goal-change">
                  <Text variant="primaryFont">{amountHandler()}</Text>
                </div>
                {checkAmountHandler()}
              </div>
            </div>
            <div className="data-inner-container">
              <div className="goal-img">
                <img
                  className="change-img"
                  alt="x"
                  src={ImageConstants.calender}
                />
              </div>
              <div className="new-goal-info">
                <div className="new-goal-title">
                  <Text variant="fieldFont">Para cuándo</Text>
                </div>
                <div className="new-goal-change">
                  <Text variant="primaryFont">{dateHandler()}</Text>
                </div>
                {checkDateHandler()}
              </div>
            </div>
          </div>
          <div className="buttons-container">
            <div className="goal-btn">
              <Button
                className="cancel-btn"
                value={buttonValue}
                click={() => onCancel()}
              />
            </div>
            <div className="goal-btn">
              <Button
                className="guardar-btn"
                value="Guardar"
                click={() => submitHandler()}
              />
            </div>
          </div>
        </div>
      </NewGoalInfoStyle>
    </ThemeProvider>
  );
};

export default NewGoalInfo;
