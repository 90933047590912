import URL_CONSTANTS from "../../../constants/urlConstants/urlConstants";
import { Request } from "../../../services";

export const getInsuranceList = (data) => {
  return Request.get(`${URL_CONSTANTS.get_insurance}${data}`);
};

export const getInsuranceOptimization = (data) => {
  return Request.get(`${URL_CONSTANTS.insurance_optimization}${data}`);
};
